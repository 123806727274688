import React, { useEffect, useState } from 'react';
import styles from './bottomInteractiveBar.module.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as Spinner } from 'assets/icons/rolling-spinner.svg';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { ReactComponent as ArrDown } from 'assets/icons/collapsingarrow.svg';

function BottomInteractiveBar () {
  const { uploadQueue } = useSelector((state) => state.contentUpload);
  const [showUploads, setShowUploads] = useState(false);
  const [extendedContainer, setExtendedContainer] = useState(true);

  useEffect(() => {
    if (uploadQueue.length) {
      setShowUploads(true);
    }
  }, [uploadQueue]);

  return (
    <div className={styles.container}>
      {showUploads && (
        <div
          className={
            extendedContainer
              ? `${styles.uploadContainer} ${styles.extended}`
              : styles.uploadContainer
          }
        >
          <div className={styles.upperSliderInteractiveSection}>
            <span className={styles.header}>Uploads</span>
            <button
              className={styles.sliderActionContainer}
              onClick={() => setExtendedContainer((prev) => !prev)}
            >
              <ArrDown
                className={styles.sliderBtn}
                style={
                  !extendedContainer ? { transform: 'rotate(180deg)' } : {}
                }
              />
            </button>
          </div>
          <ul className={styles.uploadsContainer}>
            {uploadQueue.length
? (
              uploadQueue.map((item) => (
                <li className={styles.uploadItem} key={item.id}>
                  <div className={styles.uploadItemLeft}>
                    <div className={styles.nameContainer}>
                      {item?.name.length > 15
                        ? `${item?.name?.slice(0, 15)}...`
                        : item?.name}
                    </div>
                    <div className={styles.progressContainer}>
                      {item.progress === 0 && item.error
? (
                        <span className={styles.errorMsg}>{item.error}</span>
                      )
: (
                        <div
                          className={styles.progressBar}
                          style={{ width: `${item.progress}%` }}
                        />
                      )}
                    </div>
                    {item.finalizing && (
                      <span className={styles.finalizing}>Finalizing</span>
                    )}
                  </div>
                  {item.uploading && !item.finalizing
? (
                    <span className={styles.progress}>{item.progress}</span>
                  )
: item.uploading && item.finalizing
? (
                    <Spinner className={styles.spinner} />
                  )
: (
                    <>
                      {!item.error
? (
                        <Success className={styles.progressResultLogo} />
                      )
: (
                        <Failed className={styles.progressResultLogo} />
                      )}
                    </>
                  )}
                  <div className={styles.uploadItemRight}>
                    <button
                      className={styles.cancelBtn}
                      disabled={!item.uploading}
                    >
                      x
                    </button>
                  </div>
                </li>
              ))
            )
: (
              <div className={styles.noDownloads}>No downloads</div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
export default BottomInteractiveBar;
