/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { ReactComponent as SearchMag } from 'assets/icons/searchmag.svg';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const Input = ({ textValue, onTextChange, selectValue, onSelectChange, selectArray }) => {
  const [dropDwnMenu, setDropDwnMenu] = useState(false)
  const { t } = useTranslation();

  const selectChange = (e, item) => {
    setDropDwnMenu(false)
    onSelectChange(item)
  }

  return (
    <div className={styles.input_wrapper}>
      {/* <div className={dropDwnMenu ? `${styles.selectWrapper} ${styles.active}` : styles.selectWrapper}>
        <button className={styles.selectButton} onClick={() => setDropDwnMenu(prev => !prev)}>{selectValue?.label || 'Asset type'}</button>
        <ul className={styles.selectContainer}>
          {selectValue?.label && <li className={styles.selectItem} onClick={(e) => selectChange(e, null)}>Any</li>}
            {selectArray.map((item, index) => (
              <li key={index} className={styles.selectItem} onClick={(e) => selectChange(e, item)}>
                {item.label}
              </li>
            ))}
        </ul>
      </div> */}
      <input
        type="text"
        className={styles.searchInput}
        placeholder={t('Search')}
        value={textValue}
        onChange={e => onTextChange(e.target.value)}
      />
      <button className={styles.searchButton}>
        <SearchMag className={styles.searchIcon} />
      </button>
    </div>
  );
}

Input.propTypes = {
  onTextChange: PropTypes.func,
  textValue: PropTypes.string,
  selectValue: PropTypes.string,
  onSelectChange: PropTypes.func,
  selectArray: PropTypes.array
}

export default Input;
