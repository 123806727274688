import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  Fragment
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import Photo, { photoPropType } from './Photo';
import Video from './Video';
import { computeColumnLayout } from './layouts/columns';
import { computeRowLayout } from './layouts/justified';
import { findIdealNodeSearch } from './utils/findIdealNodeSearch';
import { useNavigate } from 'react-router-dom';

const Gallery = React.memo(function Gallery ({
  photos,
  onClick,
  direction,
  margin,
  limitNodeSearch,
  targetRowHeight,
  columns,
  renderImage,
  noInteraction,
  templates,
  subCatalogView,
  reverseThumbnail,
  noWrap,
  mixedContent,
  isTouchScreen,
  OverRuledRoles
}) {
  const [containerWidth, setContainerWidth] = useState(0);
  const galleryEl = useRef(null);
  const user = useSelector((state) => state.auth?.user);
  const profile = useSelector((state) => state.profile);
  const [currentUser, setCurrentUser] = useState({});
  const navigate = useNavigate();

  const navigateToEditor = (data) => {
    navigate(`/mpublisher/edit/${data?.id}`);
  }

  useEffect(() => {
    const userWithProfile = {
      user,
      profile
    };
    setCurrentUser(userWithProfile);
  }, [user, profile]);

  useLayoutEffect(() => {
    let animationFrameID = null;
    const observer = new ResizeObserver((entries) => {
      // only do something if width changes
      const newWidth = entries[0].contentRect.width;
      if (containerWidth !== newWidth) {
        // put in an animation frame to stop "benign errors" from
        // ResizObserver https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        animationFrameID = window.requestAnimationFrame(() => {
          setContainerWidth(Math.floor(newWidth));
        });
      }
    });
    observer.observe(galleryEl.current);
    return () => {
      observer.disconnect();
      window.cancelAnimationFrame(animationFrameID);
    };
  });

  // no containerWidth until after first render with refs, skip calculations and render nothing
  if (!containerWidth) return <div ref={galleryEl}>&nbsp;</div>;
  // subtract 1 pixel because the browser may round up a pixel
  const width = containerWidth - 1;
  let galleryStyle, thumbs;

  if (direction === 'row') {
    // allow user to calculate limitNodeSearch from containerWidth
    if (typeof limitNodeSearch === 'function') {
      limitNodeSearch = limitNodeSearch(containerWidth);
    }
    if (typeof targetRowHeight === 'function') {
      targetRowHeight = targetRowHeight(containerWidth);
    }
    // set how many neighboring nodes the graph will visit
    if (limitNodeSearch === undefined) {
      limitNodeSearch = 2;
      if (containerWidth >= 450) {
        limitNodeSearch = findIdealNodeSearch({
          containerWidth,
          targetRowHeight
        });
      }
    }

    galleryStyle = noWrap
      ? { display: 'flex', flexDirection: 'row' }
      : { display: 'flex', flexWrap: 'wrap', flexDirection: 'row' };
    thumbs = computeRowLayout({
      containerWidth: width,
      limitNodeSearch,
      targetRowHeight,
      margin,
      photos
    });
  }
  if (direction === 'column') {
    // allow user to calculate columns from containerWidth
    if (typeof columns === 'function') {
      columns = columns(containerWidth);
    }
    // set default breakpoints if user doesn't specify columns prop
    if (columns === undefined) {
      columns = 1;
      if (containerWidth >= 500) columns = 2;
      if (containerWidth >= 900) columns = 3;
      if (containerWidth >= 1500) columns = 4;
    }
    galleryStyle = { position: 'relative' };
    thumbs = computeColumnLayout({
      containerWidth: width,
      columns,
      margin,
      photos
    });
    galleryStyle.height = thumbs[thumbs.length - 1].containerHeight;
  }

  const renderComponent = renderImage || Photo;
  const renderVidComp = Video;
  return (
    <div
      className={
        noWrap
          ? 'react-photo-gallery--gallery overflow'
          : 'react-photo-gallery--gallery'
      }
    >
      <div ref={galleryEl} style={galleryStyle}>
        {thumbs.map((thumb, index) => {
          const { left, top, userId, containerHeight, ...photo } = thumb;
          return photo.assest_type !== 'Videos'
? (
            <Fragment key={thumb.id}>
              {renderComponent({
                left,
                top,
                containerHeight,
                index,
                margin,
                direction,
                onClick: onClick || null,
                catalog: thumb || null,
                photo,
                allCatalogs: photos,
                noInteraction,
                templates,
                subCatalogView,
                reverseThumbnail,
                noWrap,
                mixedContent,
                isTouchScreen,
                currentUser,
                assetUserId: userId,
                OverRuledRoles,
                navigateToEditor
              })}
            </Fragment>
          )
: (
            <Fragment key={thumb.id}>
              {renderVidComp({
                left,
                top,
                containerHeight,
                index,
                margin,
                direction,
                onClick: onClick || null,
                catalog: thumb || null,
                photo,
                allCatalogs: photos,
                noInteraction,
                templates,
                subCatalogView,
                reverseThumbnail,
                noWrap,
                mixedContent,
                isTouchScreen,
                currentUser,
                assetUserId: userId,
                OverRuledRoles
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
});

Gallery.propTypes = {
  photos: PropTypes.arrayOf(photoPropType),
  direction: PropTypes.string,
  onClick: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  targetRowHeight: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  limitNodeSearch: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  margin: PropTypes.number,
  renderImage: PropTypes.func,
  noInteraction: PropTypes.bool,
  templates: PropTypes.bool,
  subCatalogView: PropTypes.bool,
  reverseThumbnail: PropTypes.bool,
  noWrap: PropTypes.bool,
  mixedContent: PropTypes.bool,
  isTouchScreen: PropTypes.bool,
  deleteACatalog: PropTypes.func,
  OverRuledRoles: PropTypes.array || PropTypes.any
};

Gallery.defaultProps = {
  margin: 2,
  direction: 'row',
  targetRowHeight: 300
};
export default Gallery;
