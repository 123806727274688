/* eslint-disable no-unused-vars */
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import styles from './FilesContainer.module.scss';
import SearhBar from '../SearchBar/SearchBar';
import Tags from '../Tags/Tags';
import ExtandableContainer from '../components/ExtandableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCollectionFolders, manualCollectionUpdate } from 'Redux/collection/collectionSlice';
import {
  addNewCollectionFolderAPI,
  getCollectionFolderCountByIdAPI,
  updateCollectionsAPI,
  editCollectionFolderAPI,
  deleteCollectionFolderAPI
} from 'api/collections';
import { ReactComponent as AddIcon } from './Assets/icons/add-icon.svg';
import { Link, useLocation } from 'react-router-dom';
import { errorToast, successToast } from 'toasts/Toasts';
import ItemsComponent from '../components/ItemsComponent';
import { getCatalogByCollectionIdAPI } from 'api/contentUploadAPI';
import FolderCard from './FolderCard';
import MiniPopupManager from '../components/MiniPopupManager';
import { useTranslation } from 'react-i18next';
import DraftCard from './DraftCard/DraftCard';
import { getDrafts, softDeleteDraft } from 'Redux/draft/draftSlice';
import { getUserData } from 'Redux/userdata/userdataSlice';

const arrOfTags = [
  { name: 'All', active: true },
  { name: 'Recent', active: false },
  { name: 'Folders', active: false },
  { name: 'Designs', active: false }
];

const FilesContainer = () => {
  const dispatch = useDispatch();
  const { collectionFolders, collections } = useSelector(
    (state) => state.collections
  );
  const { drafts } = useSelector(
    (state) => state.drafts
  );

  const { userdata } = useSelector((state) => state.userdata);
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [addPopup, setAddPopup] = useState(false);
  const [selectedTags, setTags] = useState(arrOfTags);
  const [myCollectionFolders, setCollectionFolders] = useState([]);
  const [addCollectionPopup, setAddCollectionPopup] = useState(false);
  const [interactionPopups, setInteractionPopups] = useState(null);
  const [allmyCollection, setAllmyCollection] = useState([]);
  const [targetElement, setTargetElement] = useState([]);

  const bannerStyle = {
    backgroundImage: 'var(--col-s-banner)',
    backgroundColor: 'var(--button-normal)',
    backgroundPosition: '0 60%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  };

  const currentDraggedElement = useRef(null);

  useEffect(() => {
    if (collections) {
      setAllmyCollection(collections);
    }
  }, [collections]);

  useEffect(() => {
    dispatch(getAllCollectionFolders());
    dispatch(getDrafts());
  }, []);

  const fetchCollectionFoldersCount = async (folders) => {
    folders.forEach(async (folder) => {
      const { id } = folder;
      getCollectionFolderCountByIdAPI(id).then((res) => {
        const count = res?.data?.data;
        setCollectionFolders((prev) =>
          prev.map((item) => {
            if (item?.id === id) {
              return {
                ...item,
                count
              };
            }
            return item;
          })
        );
      });
    });
  };

  useEffect(() => {
    if (collectionFolders && (!myCollectionFolders.length || myCollectionFolders.length !== collectionFolders.length)) {
      setCollectionFolders(collectionFolders);
      setTimeout(() => {
        fetchCollectionFoldersCount(collectionFolders);
      }, 1000);
    }
  }, [collectionFolders]);

  const getACollectionCatalogs = async (id) => {
    try {
      const response = await getCatalogByCollectionIdAPI(id);
      const catalogsData = response?.data?.data?.result || null;
      if (catalogsData && catalogsData.length) {
        const previewCatalogData = catalogsData.map((catalog, i) => {
          if (catalog?.assest_type === 'Templates') {
            return {
              ...catalog,
              index: i,
              id: catalog?._id || catalog?.id,
              src: catalog?.template_data?.templates[0],
              width: catalog?.template_data?.templates[0]?.width || 1920,
              height: catalog?.template_data?.templates[0]?.height || 1080
            };
          } else if (catalog.assest_type === 'Videos') {
            return {
              ...catalog,
              src: catalog.video_urls,
              width: catalog?.vid_metadata?.width || 16,
              height: catalog?.vid_metadata?.height || 9
            }
          }
          return {
            ...catalog,
            index: i,
            id: catalog?._id || catalog?.id,
            src: catalog?.assest_urls[0],
            width: catalog?.image_size?.width || 16,
            height: catalog?.image_size?.height || 9
          };
        });
        return previewCatalogData;
      }
      return [];
    } catch {
      return [];
    }
  };

  const handleDragEnd = async () => {
    const folderId = targetElement;
    const collection = currentDraggedElement.current;
    setTargetElement(null);
    if (!collection || !folderId) {
      return;
    } else {
      if (collection.folders?.includes(folderId)) {
        errorToast(t('This collection is already in this folder'));
      } else if (collection.folders && !collection.folders?.includes(folderId)) {
        try {
          const response = await updateCollectionsAPI({
            ...collection,
            folders: [...collection.folders, folderId]
          });
          if (response?.data?.statusCode === 200) {
            dispatch(manualCollectionUpdate({
              ...collection,
              folders: [...collection.folders, folderId]
            }))
            setCollectionFolders((prev) =>
              prev.map((item) => {
                if (item?.id === folderId) {
                  return {
                    ...item,
                    count: item?.count !== undefined ? item.count + 1 : 1
                  };
                }
                return item;
              })
            );
            successToast(t('Collection inserted successfully'));
          } else {
            errorToast(t('Something went wrong'));
          }
        } catch {
          return null
        }
      }
    };
  }

  const onSubmitAddFolder = async (data) => {
    const newData = {
      ...data,
      is_public: true
    };
    try {
      const response = await addNewCollectionFolderAPI(newData);
      const { data } = response.data;
      data.updatedAt = new Date();
      data.count = 0;
      dispatch(getUserData());
      successToast(t('Folder created successfully'));
      setCollectionFolders([data, ...myCollectionFolders]);
      setAddPopup(false);
    } catch (error) {
    }
  };

  const manualDeleteCollectionFromMain = (collection) => {
    setAllmyCollection((prev) => prev.filter((item) => item?.id !== collection.id));
    collection.folders?.forEach((folderId) => {
      setCollectionFolders((prev) =>
        prev.map((item) => {
          if (item?.id === folderId) {
            return {
              ...item,
              count: item?.count !== undefined ? item.count - 1 : 0
            };
          }
          return item;
        })
      );
    })
  }

  const handleEditingTrigger = (folder) => {
    setAddPopup({
      edit: true,
      data: folder
    })
  }

  const handleEditFolder = async (folder) => {
    const { data, id } = folder
    try {
      editCollectionFolderAPI(id, data).then(res => {
        if (res?.data?.data && res?.data?.statusCode === 200) {
          setCollectionFolders((prev) =>
            prev.map((item) => {
              if (item?.id === id) {
                return {
                  ...item,
                  ...data
                };
              }
              return item;
            })
          );
          successToast(t('Folder updated successfully'));
          setAddPopup(false);
        } else {
          errorToast(t('Something went wrong'));
        }
      })
    } catch {
      errorToast(t('Something went wrong'));
    }
  }

  const handleDeleteFolder = async (folder) => {
    const id = folder.id || folder._id
    deleteCollectionFolderAPI(id).then(res => {
      if (res?.data?.statusCode === 200) {
        setCollectionFolders((prev) =>
          prev.filter((item) => item?.id !== id)
        );
        dispatch(getUserData());
        successToast(t('Folder deleted successfully'));
        setAddPopup(false);
      } else {
        errorToast(t('Something went wrong'));
      }
    })
  }

  const unCategorizedCollections = allmyCollection.filter(el => !el.folders?.length)

  const foldersCount = myCollectionFolders?.length || 0

  const folderLimit = userdata?.monthlyLimits?.folders || 0

  const canAddFolders = foldersCount < folderLimit

  return (
    <div className={styles.files__container}>
      <MiniPopupManager
        interactionPopups={interactionPopups}
        addCollectionPopup={addCollectionPopup}
        setAddCollectionPopup={setAddCollectionPopup}
        setInteractionPopups={setInteractionPopups}
        manualDeleteCollectionFromMain={manualDeleteCollectionFromMain}
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        onSubmitAddFolder={onSubmitAddFolder}
        handleEditFolder={handleEditFolder}
        handleDeleteFolder={handleDeleteFolder}
      />
      <div className={styles.search_container} style={bannerStyle}>
        <SearhBar
          onSelectChange={setSelectedAssetType}
          selectValue={selectedAssetType}
          onTextChange={setSearchInput}
          textValue={searchInput}
        />
      </div>
      {/* <div className={styles.tags_container}>
        <Tags setTags={setTags} selectedTags={selectedTags} />
      </div> */}
      <div className={styles.content_container}>
        {/* <ExtandableContainer title="Recent">
          <RecentsGrid />
        </ExtandableContainer> */}
        <ExtandableContainer title={t('Folders') + ' ' + userdata?.folders?.length + '/' + (userdata?.monthlyLimits?.folders || 'N/A')}>
          <div className={styles.folders_wraper}>
            <button
              onClick={() => setAddPopup(true)}
              className={styles.addBtn}
              disabled={!canAddFolders}
            >
              <AddIcon />
            </button>
            {myCollectionFolders &&
              myCollectionFolders
                .filter((folder) =>
                  folder.name.toLowerCase().includes(searchInput?.toLowerCase())
                )
                .map((folder, index) => (
                  <FolderCard
                    key={folder?.id}
                    folder={folder}
                    setTargetElement={setTargetElement}
                    targetElement={targetElement}
                    triggerEdit={handleEditingTrigger}
                  />
                ))}
          </div>
        </ExtandableContainer>
        {
          unCategorizedCollections.length > 0 &&
          <ExtandableContainer title={t('Uncategorized Collections')} zIndex={10}>
            <ItemsComponent
              collections={unCategorizedCollections}
              searchInput={searchInput}
              setCollections={setAllmyCollection}
              openPopup={setAddCollectionPopup}
              setInteractionPopups={setInteractionPopups}
              getACollectionCatalogs={getACollectionCatalogs}
              mainView={true}
              noAdding={true}
              currentDraggedElement={currentDraggedElement}
              handleDragEnd={handleDragEnd}
            />
          </ExtandableContainer>
        }
        <ExtandableContainer title={t('My Collections') + ' ' + userdata?.collections?.length + '/' + (userdata?.monthlyLimits?.collections || 'N/A')} zIndex={9}>
          <ItemsComponent
            collections={allmyCollection}
            searchInput={searchInput}
            setCollections={setAllmyCollection}
            openPopup={setAddCollectionPopup}
            setInteractionPopups={setInteractionPopups}
            getACollectionCatalogs={getACollectionCatalogs}
            mainView={true}
            currentDraggedElement={currentDraggedElement}
            handleDragEnd={handleDragEnd}
          />
        </ExtandableContainer>
        {
          drafts.length > 0 &&
          <ExtandableContainer title={t('My drafts') + ' ' + userdata?.drafts?.length + '/' + (userdata?.monthlyLimits?.drafts || 'N/A')} zIndex={8}>
            <div className={styles.folders_wraper}>
            {
              drafts && 
              drafts.map((folder, index) => (
                  <DraftCard
                    key={folder?.id}
                    card={folder}
                    setInteractionPopups={setInteractionPopups}
                  />
                ))}
            </div>
          </ExtandableContainer>
        }
      </div>
    </div>
  );
};

export default FilesContainer;
