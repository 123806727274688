import React from 'react'
import styles from './styles.module.scss'

export default function ComponentLoaderTri () {
  return (
    <div className={styles.loader_container}>
      <div className={styles.loader} />
    </div>
  )
}
