import activatecatalog from '../../../molecules/HorizontalMenu/functions/activatecatalog';
import displayNewRelease from './displayNewRelease';
import displayPopular from './displayPopular';
const getMenuItem = (menuData) => {
    const { menuItems, dispatch, navigate, menuItem, link } = menuData;
    const displayData = { dispatch, link, menuItems, navigate };
    if (menuItem === 'catalog') {
        return activatecatalog(menuItems);
    } else if (menuItem === 'new-release') {
        return displayNewRelease(displayData);
    } else {
        return displayPopular(displayData);
    }
};

export default getMenuItem;
