/* eslint-disable react/prop-types */
import React from 'react';
import styles from './styles.module.scss';
import { ReactComponent as SearchMag } from 'assets/icons/searchmag.svg';

const Searh = ({ onChange }) => {
  return (
    <div className={styles.searchContainer}>
      <div className={styles['input-wrapper']}>
        <button className={styles.searchButton}>
          <SearchMag className={styles.searchIcon} />
        </button>
        <input
          type="text"
          className={styles.formTextInput}
          placeholder="Search all"
          onChange={e => onChange && onChange(e.target.value)}
        />
      </div>
    </div>
  );
}
export default Searh;
