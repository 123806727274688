import React from 'react';
import Header from '../../organisms/ComingSoon/Header';
import Countdown from '../../organisms/ComingSoon/Countdown';
import PropTypes from 'prop-types';

function ComingSoon ({ launchDate }) {
  return (
    <div>
      <Header />
      <Countdown launchDate={launchDate}/>
    </div>
  );
}

ComingSoon.propTypes = {
    launchDate: PropTypes.func
  };

export default ComingSoon;
