/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'
import { errorToast, successToast } from 'toasts/Toasts'
import { deleteShareCollectionAPI } from 'api/collections'
import { useDispatch, useSelector } from 'react-redux'
import { manualSharedCollectionUpdate } from 'Redux/collection/collectionSlice';

const index = ({ exitPopup, popupData, setInteractionPopups }) => {
  const { collection } = popupData || {}
  const dispatch = useDispatch()
  const { sharedByMeCollections, sharedWithMeCollections } = useSelector(
    (state) => state.collections
  );
  const updatedCollections = !collection.withMe ? sharedByMeCollections : sharedWithMeCollections

  const handleExit = () => {
    const newPopupData = updatedCollections?.filter(el => el.collectionId === collection.collectionId)
    if (newPopupData?.length) {
      setInteractionPopups({
        state: 4,
        collection: newPopupData
      })
    } else {
      setInteractionPopups(null)
    }
  }

  const handleDeleteAftermath = () => {
    const newUpdatedCollections = updatedCollections?.filter(el => el._id !== collection._id)
    dispatch(manualSharedCollectionUpdate({
      newCollections: newUpdatedCollections,
      type: collection.withMe ? 1 : 2
    }))
    const newPopupData = newUpdatedCollections?.filter(el => el.collectionId === collection.collectionId)
    if (newPopupData?.length) {
      setInteractionPopups({
        state: 4,
        collection: newPopupData
      })
    } else {
      setInteractionPopups(null)
    }
  }

  const handleShareDelete = async () => {
    const shareId = collection?._id || null
    try {
      const response = await deleteShareCollectionAPI(shareId)
      if (response) {
        handleDeleteAftermath()
        successToast('Shared collection deleted successfully')
      } else {
        setInteractionPopups(null)
        throw new Error('Something went wrong')
      }
    } catch (e) {
      errorToast('Something went wrong')
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Are you sure you would like<br /> to delete this shared collection ?</h3>
      </div>
      <div className={styles.buttonsArea}>
        {/* <p className={styles.title}>Would you like to delete this collection</p> */}
        <div className={styles.cancelContainerLeft}>
          <button className={styles.cancelBtn} onClick={handleExit}>Cancel</button>
        </div>
        <div className={styles.deleteContainerRight}>
          <button className={styles.deleteBtn} onClick={handleShareDelete}>Delete</button>
        </div>
      </div>
    </div>
  )
}

export default index
