import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.scss'

const Button = ({ style, bgColor, color, children, onClick }) => {
  return (
    <button className={`${styles.button} ${style}`} style={{ backgroundColor: bgColor, color }} onClick={onClick}>
      {children}
    </button>
  )
}

Button.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func
}

export default Button
