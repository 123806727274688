/* eslint-disable no-unused-vars */
import React from 'react'
import FilterSection from 'components/molecules/FilterSection/FilterSection'
import HorizontalMenu from '../molecules/HorizontalMenu/HorizontalMenu';
import AssetLinks from '../organisms/HomeContent/AssetLinks/AssetLinks';
import CreativeSpace from '../organisms/HomeContent/CreativeSpace/CreativeSpace';
import EasyUpload from '../organisms/HomeContent/EasyUpload/EasyUpload';
import AnEditor from '../organisms/HomeContent/AnEditor/AnEditor';
import HomeContent from 'components/organisms/HomeContent/HomeContent';
import PropTypes from 'prop-types';

function Home ({ setUploadModalVisibility, currentUserRole }) {
  return (
    <>
      <div className='home'>
        <HorizontalMenu />
        <FilterSection />
        <AssetLinks />
        <CreativeSpace />
        <EasyUpload setUploadModalVisibility={setUploadModalVisibility} currentUserRole={currentUserRole}/>
        <AnEditor setUploadModalVisibility={setUploadModalVisibility} currentUserRole={currentUserRole} />
        <HomeContent />
      </div>
    </>
  )
}
Home.propTypes = {
  setUploadModalVisibility: PropTypes.function || PropTypes.any,
  currentUserRole: PropTypes.any
};
export default Home
