import styles from './UploadAddFiles.module.scss';
import React, { useEffect } from 'react';
import UploadOptions from '../UploadOptions';
import RoundedButton from '../../../atoms/RoundedButton/RoundedButton';
import PropTypes from 'prop-types';
import Popup from 'components/atoms/Popup/Popup';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const UploadAddFiles = ({ data, cancel }) => {
  const {
    setShowMetaDataForm,
    showUploadOptionsModal,
    setShowUploadOptionsModal,
    images,
    setImages,
    setUploadModalVisibility,
    setImageURL,
    imageURL,
    selectedType,
    setSelectedType
  } = data;
  const { userdata, loadingUD } = useSelector(state => state.userdata)
  const { uploads, monthlyLimits } = userdata
  const uploadsBasedOnType = !loadingUD && uploads ? uploads[selectedType?.toLowerCase()] : []
  const remainingUploads = !loadingUD && monthlyLimits?.uploads ? monthlyLimits?.uploads[selectedType?.toLowerCase()] - uploadsBasedOnType?.length : 0
  useEffect(() => {
    if (!userdata || !selectedType || !showUploadOptionsModal) {
      return
    } else {
    const toastId = 'uploadLimitToast'
    const toastMsg = 'You can download up to ' + remainingUploads + ' ' + selectedType?.toLowerCase() + ' this month.'
    const toastExists = toast.isActive(toastId)
    if (toastExists) {
      toast.update(toastId, {
        render: toastMsg
      })
    } else {
      toast.info(
        toastMsg,
        { 
          toastId
        }
      )
    }
  }
  }, [selectedType, userdata, showUploadOptionsModal, remainingUploads])

  useEffect(() => {
    setImages(prev => {
      if (selectedType === 'Templates' || selectedType === 'Images') {
        return prev.filter(image => image?.src?.type && image?.src?.type.includes('image') && !image?.video)
      } else if (selectedType === 'Videos') {
        return prev.filter(image => image?.src?.type && image?.src?.type.includes('image') && image?.video)
      } else {
        return []
      }
    })
  }, [selectedType]);

  return (
    <>
      {showUploadOptionsModal && (
        <Popup
          popupData={{}}
          transparent={true}
          closePopup={() => null}
          bgColor={'#121212'}
          padding={window?.innerWidth > 800 ? '50px 60px 20px 60px' : '30px 20px 20px 20px'}
          extraStyles={{ width: '90%', maxWidth: '1200px' }}
        >
          <UploadOptions
            images={images}
            setImages={setImages}
            setImageURL={setImageURL}
            imageURL={imageURL}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            remainingUploads={remainingUploads}
          />
          <section className={styles.add__files__footer__section}>
            <RoundedButton
              extraClass={styles.add__files__cancel__btn}
              onClick={() => cancel ? cancel() : setShowUploadOptionsModal(false)}
            >
              Cancel
            </RoundedButton>
            <RoundedButton
              extraClass={styles.add__files__button}
              onClick={() => {
                setShowUploadOptionsModal(false);
                setUploadModalVisibility(false);
                setShowMetaDataForm(true);
              }}
              disabled={images.length === 0 || ((remainingUploads < images.length && selectedType === 'Images') || (remainingUploads < 1 && selectedType === 'Templates'))}
              disableMsg={((remainingUploads < images.length && selectedType === 'Images')) ? 'You need to remove ' + (images.length - remainingUploads) + ' images to proceed.' : (remainingUploads < 1 && selectedType === 'Templates') ? 'You have reached your monthly limit' : null}
            >
              Next
            </RoundedButton>
          </section>
        </Popup>
      )}
    </>
  );
};

UploadAddFiles.propTypes = {
  data: PropTypes.object,
  cancel: PropTypes.func
};

export default UploadAddFiles;
