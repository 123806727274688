import styles from '../css/HorizontalMenu.module.scss';

const updateHorizontalLines = (others) => {
    const { first, second } = others;
    first.classList.add(styles.horizontal__menu__container__hide);
    first.classList.remove(styles.horizontal__menu__container__display);
    second.classList.remove(styles.horizontal__menu__container__display);
    return second.classList.add(styles.horizontal__menu__container__hide);
};

export default updateHorizontalLines;
