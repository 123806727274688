import React from 'react';
import './Header.scss';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <header className="header">
      <h1 className="title">{t('Coming Soon')}</h1>
      <p className="description"> {t('coming-soon-desc')}</p>
    </header>
  );
};

export default Header;
