import React, { useEffect, useLayoutEffect, useState, Fragment, useMemo } from 'react';
import style from './style.module.scss';
import './custom-tags.scss';
import Checkbox from 'react-custom-checkbox';
import { constants as data } from './constants';
import camelize from './camelize';
import PropTypes from 'prop-types'
import { TagsInput } from 'react-tag-input-component';

const options = [
  {
    name: 'Public',
    value: true
  },
  {
    name: 'Private',
    value: false
  }
];

const INITIAL_SELECT_STATE = {
  value: null,
  active: false,
  data
}

const camelizedValues = true;

function InteractionArea ({
  contentState,
  setContentState,
  onChangeData,
  folders,
  errors,
  setErrors,
  currentUserRole
}) {
  const [selectedColors, setSelectedColors] = useState([]);
  const [dropDownMenuPublic, setDropDownMenuPublic] = useState(INITIAL_SELECT_STATE);
  const [dropDownMenuFolder, setDropDownMenuFolder] = useState(INITIAL_SELECT_STATE);
  const [folderType, setFolderType] = useState(contentState.folder?.is_global_folder ? 1 : !(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR') ? 1 : 2);
  useLayoutEffect(() => {
    const inputTag = document.querySelector('.rti--input')
    if (inputTag) {
      inputTag.autocomplete = 'off'
    }
  }, []);

  const isTouchScreen = useMemo(() => {
    return ('ontouchstart' in document.documentElement);
  }, []);

  const errorStyle = {
    border: '1px solid red'
  }

  useEffect(() => {
    setDropDownMenuFolder(() => {
      if (contentState.folder.id) {
        return {
          value: contentState.folder.name,
          active: false,
          data: contentState.folder
        }
      } else return INITIAL_SELECT_STATE
    });
    setDropDownMenuPublic(() => {
      if (contentState.isPublic !== undefined) {
        return {
          value: contentState.isPublic ? 'Public' : 'Private',
          active: false
        }
      } else return INITIAL_SELECT_STATE
    });
  }, [contentState]);

  useEffect(() => {
    contentState.color && setSelectedColors(contentState.color);
  }, [contentState]);
  const handleChange = (isColor, itemTitle, value, checked) => {
    let obj = {};
    const categoryTitle = camelizedValues ? camelize(itemTitle) : itemTitle;
    if (!isColor) {
      obj = {
        category: categoryTitle,
        value,
        selected: checked
      };
      setContentState(prev => ({ ...prev, [obj.category]: obj.value }))
    } else {
      if (selectedColors.includes(value)) {
        setSelectedColors(selectedColors.filter((color) => color !== value));
        obj = {
          category: categoryTitle,
          value,
          selected: false
        };
      } else {
        setSelectedColors([...selectedColors, value]);
        obj = {
          category: categoryTitle,
          value,
          selected: true
        };
      }
    }
    onChangeData(obj);
  };

  const handleDescriptionChange = (event) => {
    setContentState((prev) => {
      return {
        ...prev,
        contentDescription: event.target.value
      };
    });
  };

  const selectFolder = (folder) => {
    setContentState((prev) => ({
      ...prev,
      folder
    }))
    setErrors(prev => {
      return {
        ...prev,
        folder: false
      }
    })
  }

  const selectPriOption = (option) => {
    setContentState((prev) => ({
      ...prev,
      isPublic: option.value
    }))
    setErrors(prev => {
      return {
        ...prev,
        isPublic: false
      }
    })
  }

  const handleAddingTags = (newTags) => {
    setContentState((prev) => {
      return {
        ...prev,
        wordTags: newTags
      };
    });
    setErrors(prev => {
      return {
        ...prev,
        wordTags: false
      }
    })
  }

  const handlePastingTags = (event) => {
    event.preventDefault()
    try {
      const tagsArr = event.clipboardData.getData('Text').split((/[,.\s]/)).filter((tag) => tag !== '' && !contentState.wordTags.includes(tag)).map(txt => txt.replace('-', ' '));
      setContentState((prev) => {
        return {
          ...prev,
          wordTags: [...new Set([...prev.wordTags, ...tagsArr])]
        };
      });
      setErrors(prev => {
        return {
          ...prev,
          wordTags: false
        }
      })
    } catch {
      return null;
    }
  }

  const handleSomeSpecialKeysForTagging = (event) => {
    if (event.key === ' ' && event.target.value !== '' && isTouchScreen) {
      event.preventDefault();
      const { value } = event.target || '';
      if (value && value?.trim() !== '' && !contentState.wordTags.includes(value)) {
        setContentState((prev) => {
          return {
            ...prev,
            wordTags: [...new Set([...prev.wordTags, value?.trim()])]
          };
        });
        event.target.value = ''
      }
    }
  }

  const handleFolderSelection = (e, type) => {
    e.stopPropagation()
    if (type === 2 && !(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR')) {
      return
    }
    setFolderType(type)
    if (type !== folderType) {
      setDropDownMenuFolder(prev => { 
        return {
          ...prev,
          value: null,
          active: true
        }
       })
    }
  }

  const checkBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: '0.6rem',
    backgroundColor: 'var(--main-color)',
    alignSelf: 'stretch',
    color: 'white'
  }

  return (
    <div className={style.formContainer}>
      <div className={style.upperInputs}>
        <div className={style.inputWrapper}>
          <label>Name</label>
          <input className={style.input} style={errors.name ? errorStyle : {}} value={contentState.name} placeholder="Type name..."
            onChange={(e) => {
              setContentState((prev) => ({
                ...prev,
                name: e.target.value
              }));
              setErrors(prev => {
                return {
                  ...prev,
                  name: false
                }
              })
            }} />
        </div>
        <div className={style.selectorContainer} style={{ zIndex: 2 }}>
          <label>Privacy options</label>
          <div className={style.selector} style={errors.isPublic ? errorStyle : {}} onClick={() => setDropDownMenuPublic(prev => {
            return {
              ...prev,
              active: !prev.active
            }
          })}>
            <span style={!dropDownMenuPublic.value ? { color: '#bbbbbb' } : {}}>{dropDownMenuPublic.value || 'Choose option'}</span>
            <ul className={dropDownMenuPublic.active ? `${style.dropDownMenu} ${style.active}` : style.dropDownMenu}>
              {options.map((option) => (
                <li key={option.name} onClick={(e) => { e.stopPropagation(); selectPriOption(option) }}>{option.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={style.upperInputs}>
        <div className={style.inputWrapper}>
          <label>Contributor</label>
          <input className={style.input} style={errors.contributers ? errorStyle : {}} value={contentState.contributers} placeholder="Type contributor..."
            onChange={(e) => {
              setContentState((prev) => ({
                ...prev,
                contributers: e.target.value
              }))
              setErrors(prev => {
                return {
                  ...prev,
                  contributers: false
                }
              })
            }} />
        </div>
        <div className={style.selectorContainer} style={{ zIndex: 1 }}>
        <div className={style.labling_type_container}>
            <label>Folder</label>
            <div className={style.folder_type_sect}>
              <button className={`${style.folder_type_btn} ${folderType === 1 ? style.active : ''}`} onClick={(e) => handleFolderSelection(e, 1)}><strong>General upload</strong></button>
              <button className={`${style.folder_type_btn} ${folderType === 2 ? style.active : ''}`} disabled={!(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR')} onClick={(e) => handleFolderSelection(e, 2)}><strong>Company upload</strong>
                <span className={style.disableMsg}>You are not an administrator</span>
              </button>
            </div>
          </div>
          <div className={style.selector} style={errors.folder ? errorStyle : {}} onClick={() => setDropDownMenuFolder(prev => {
            return {
              ...prev,
              active: !prev.active
            }
          })}>
            <span>{dropDownMenuFolder.value || 'Select a folder'}</span>
            <div className={dropDownMenuFolder.active ? `${style.dropDownMenu} ${style.active} ${style.folder_selector}` : `${style.dropDownMenu} ${style.folder_selector}`}>
              <ul className={style.folder_list}>
                {folders && folders?.filter(folder => {
                  if (folderType === 1) {
                    return folder?.is_global_folder
                  } else {
                    return !folder?.is_global_folder
                  }
                })?.map((folder) => (
                  <li key={folder.id} onClick={(e) => { e.stopPropagation(); selectFolder(folder) }}>{folder.name}</li>
                ))}
              </ul>
              {
                dropDownMenuFolder.active && (
                  <div className={style.mobile_folder_type}>
                    <button className={`${style.folder_type_btn_mob} ${folderType === 1 ? style.active : ''}`} onClick={(e) => handleFolderSelection(e, 1)}><strong>General upload</strong></button>
                    <button className={`${style.folder_type_btn_mob} ${folderType === 2 ? style.active : ''}`} disabled={!(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR')} onClick={(e) => handleFolderSelection(e, 2)}><strong>Company upload</strong></button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <label className={style.headers}>Word Tags</label>
      <div className={style.tagsContainer} style={errors.wordTags ? errorStyle : {}} onPaste={handlePastingTags}>
        <TagsInput
          value={contentState.wordTags}
          onChange={handleAddingTags}
          // onPaste={handleAddingTags}
          name="tags"
          placeHolder="Add new tags..."
          onKeyUp={e =>
            handleSomeSpecialKeysForTagging(e)
          }
        />
      </div>
      <div className={style.filterContainer}>
        {data.map((element) => (
          <div key={element.title} className={style.filterCell}>
            <p className={style.filterHeader}>{element.title}</p>
            <div className={style.cellOptions}>
              {element.filters?.map((filter) => (
                <Fragment key={filter.name}>
                  <Checkbox
                    checked={contentState[camelize(element.title)].includes(filter.name)}
                    disabled={false}
                    icon={
                      <div
                        style={checkBoxStyle}
                      >
                        ✓
                      </div>
                    }
                    onChange={(value) => {
                      handleChange(false, element.title, filter.name, value);
                    }}
                    borderColor="gray"
                    borderWidth={1}
                    borderRadius={100}
                    style={{ overflow: 'hidden' }}
                    size={16}
                    label={filter.name}
                  />
                  <div className={style.colorContainer}>
                    {element.colors?.map((color) => (
                      <div
                        key={color}
                        className={style.box}
                        style={{ backgroundColor: color }}
                        onClick={(e) =>
                          handleChange(true, element.title, color)
                        }
                      >
                        {selectedColors.includes(color) && (
                          <span
                            style={
                              color === 'white'
                                ? { color: 'black' }
                                : { color: 'white' }
                            }
                            className={style.checkedColor}
                          >
                            ✓
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
      <label className={style.headers}>Content description</label>

      <div className={style.contentArea}>
        <textarea
          placeholder="Add description..."
          value={contentState.contentDescription}
          onChange={handleDescriptionChange}
        />
      </div>
    </div>
  );
}

export default InteractionArea;

InteractionArea.propTypes = {
  contentState: PropTypes.object,
  setContentState: PropTypes.func,
  onChangeData: PropTypes.func,
  folders: PropTypes.array,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  currentUserRole: PropTypes.string || PropTypes.any
}
