import styles from './Popup.module.scss';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
const Popup = ({ popupData, closePopup, children, transparent, bgColor, padding, extraStyles }) => {
  return (
    createPortal(
      <div className={styles.overlay} onClick={closePopup}>
      <div className={styles.popupContainer}>
        <div
          className={transparent ? `${styles.popupContent} ${styles.transparent}` : styles.popupContent}
          style={{ padding, ...extraStyles }}
          onClick={(e) => e.stopPropagation()}
        >
          {children || (
            <div className={styles.sliderContainer}>
            </div>
          )}
        </div>
      </div>
    </div>,
      document.getElementById('modal-root')
    )
  );
};

Popup.propTypes = {
  popupData: PropTypes.object,
  closePopup: PropTypes.func,
  children: PropTypes.node,
  transparent: PropTypes.bool,
  bgColor: PropTypes.string,
  padding: PropTypes.string,
  extraStyles: PropTypes.object
};
export default Popup;
