/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import styles from './FolderCard.module.scss';
import { ReactComponent as FolderIcon } from './Assets/icons/folder-ico.svg';
import { Link } from 'react-router-dom';

const FolderCard = ({ folder, setTargetElement, targetElement, triggerEdit }) => {
  let dragTimeout = null

  return (
    <div
      className={styles.folderCard}
      key={folder?.id}
      onDragOver={(e) => {
        dragTimeout && clearTimeout(dragTimeout);
        setTargetElement(folder?.id);
      }}
      onDragLeave={(e) => {
        if (targetElement === folder?.id) {
          dragTimeout = setTimeout(() => {
            setTargetElement(null);
          }
            , 10);
        }
      }}
    >
      <Link
        className={`${styles.folderContainer} ${targetElement === folder?.id ? styles.dragOver : ''}`}
        key={folder?.id}
        to={`/mpublisher/my-files/${folder.id}`}
        state={{ folder }}
      >
        <div className={styles.folderControlContainer}>
        <FolderIcon
          onDragEnter={(e) => { e.stopPropagation() }}
          onDragLeave={(e) => { e.stopPropagation() }}
          style={{ fill: folder.color ? folder.color : null }}
        />
        </div>
        <button className={styles.controlIcon} onClick={e => { e.preventDefault(); e.stopPropagation(); triggerEdit(folder) }}>...</button>
        <div
          className={styles.folderInfo}
          onDragEnter={(e) => { e.stopPropagation() }}
          onDragLeave={(e) => { e.stopPropagation() }}
        >
          <p className={styles.folderName}>
            {folder.name.length > 17
              ? folder.name.slice(0, 15) + '...'
              : folder.name}
            <span className={styles.folderCount}>
              {folder.count !== undefined
                ? ` (${folder.count})`
                : ' (...)'}
            </span>
          </p>
          <p className={styles.lastUpdated}>
            {folder.updatedAt
              ? `Last updated: ${new Date(
                folder.updatedAt
              )?.toLocaleDateString() || 'Undefined'
              }`
              : null}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default FolderCard;
