/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getCollectionsByFolderIdAPI, getCollectionFolderByIdAPI, getCollectionsSharedByMeAPI, getCollectionsSharedWithMeAPI } from 'api/collections';
import { getCatalogByCollectionIdAPI } from 'api/contentUploadAPI';
import Gallery from 'components/organisms/GalleryLayout/Gallery';
import SingleAssetPM from '../../../myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../../../myTemplatesCatalogs/components/PopupManger'
import { useSelector } from 'react-redux';
import SearchBar from '../../SearchBar/SearchBar';
import isMongoId from 'helpers/isMongo';
import NotFound from 'components/pages/NotFound/NotFound';
import UnavailableContent from 'components/pages/UnavailableContent/UnavailableContent';

const assetTypes = [
  { id: 1, name: 'All', value: 'all' },
  { id: 2, name: 'Images', value: 'images' },
  { id: 3, name: 'Videos', value: 'videos' },
  { id: 4, name: 'Audio', value: 'audio' },
  { id: 5, name: 'Documents', value: 'documents' }
]

const bannerStyle = {
  backgroundImage: 'var(--col-s-banner)',
  backgroundColor: 'var(--button-normal)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const SharedByMeCollections = () => {
  const { folders } = useSelector(state => state.folders);
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [assetType, setAssetType] = useState(null)
  const [sharedCollection, setSharedCollection] = useState({})
  const [catalogs, setCatalogs] = useState([])
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);
  const [isInvalidContent, setIsInvalidContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [searchInput, setSearchInput] = useState('');
  const { collectionId } = useParams()
  const isValidCollectionMongoId = isMongoId(collectionId) || collectionId === undefined
  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const getCollections = async () => {
    const response = await getCollectionsSharedByMeAPI()
    if (response?.data?.data && Array.isArray(response?.data?.data)) {
      return response?.data?.data.map(el => {
        return {
          ...el,
          id: el.collection?._id || el.collection?.id,
          pendingAssets: true,
          shareId: el._id || el.id
        }
      }).find(el => el?.id === collectionId)
    }
    return {}
  }

  const { permissions } = sharedCollection || {}

  const getACollectionCatalogs = async (id) => {
    try {
      const response = await getCatalogByCollectionIdAPI(id)
      const catalogsData = response?.data?.data?.result || null
      if (catalogsData && catalogsData.length) {
        const previewCatalogData = catalogsData.map((catalog, i) => {
          if (catalog?.assest_type === 'Templates') {
            return {
              ...catalog,
              index: i,
              id: catalog?._id || catalog?.id,
              src: catalog?.template_data?.templates[0],
              width: catalog?.template_data?.templates[0]?.width || 1920,
              height: catalog?.template_data?.templates[0]?.height || 1080
            }
          }
          return {
            ...catalog,
            index: i,
            id: catalog?._id || catalog?.id,
            src: catalog?.assest_urls[0],
            width: catalog?.image_size?.width || 16,
            height: catalog?.image_size?.height || 9
          }
        })
        return previewCatalogData
      }
    } catch {
      return []
    }
    return []
  }

  const catalogTypeFinder = (id) => {
    const folder = folders.find((folder) => folder.id === id)
    if (folder) {
      if (folder.is_global_folder) {
        return 'catalogs'
      } else {
        return 'my-catalogs'
      }
    }
    return null
  }

  const handleClickMulti = (state, catalog) => {
    if (state === 6) {
      const typeOfCats = catalogTypeFinder(catalog.folders[0])
      if (typeOfCats) {
        const link = `/mpublisher/${typeOfCats}/Templates/${catalog.folders[0]}/${catalog.id}`
        navigate(link, { state: { catalog } })
      }
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClickSingle = (state, catalog) => {
    if (state === 6) {
      navigate(`/mpublisher/my-catalogs/Templates/${catalog.folders[0]}/${catalog.id}`, { state: { catalog } })
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClick = (state, catalog) => {
    if (catalog.assest_type === 'Templates') {
      handleClickMulti(state, catalog)
    } else {
      handleClickSingle(state, catalog)
    }
  }

  useEffect(() => {
    setIsInvalidContent(false);
    setSearchInput('')

    // getting collections data either from location state or fetch if reloading

    if (params && isMongoId(collectionId)) {
      if (location.state?.collection) {
        const stateCollection = location.state?.collection
        setSharedCollection(stateCollection || {})
        setIsLoading(false)
      } else {
        // getAllData().then(() => { setIsLoading(false); })
        getCollections().then(res => {
          if (res) {
            setSharedCollection(res)
          } else {
            setIsInvalidContent(true)
          }
          setIsLoading(false)
        })
      }

      // getting catalogs data either from location state or fetch if reloading

      if (location.state && location.state?.catalogs) {
        setCatalogs(location.state?.catalogs)
      } else {
        // get data manually
        getACollectionCatalogs(collectionId).then(data => { setCatalogs(data) })
      }
    } else if (params && collectionId && !isMongoId(collectionId)) {
      collectionId !== undefined && setIsLoading(false)
    }
  }, [location, params, isValidCollectionMongoId]);

  const { collection } = sharedCollection || {}
  
  return (
    !isInvalidContent && isValidCollectionMongoId
      ? (
        <div className={styles.wrapper}>
          {
            !isLoading
              ? (
                <>
                  <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={catalogs} />
                  <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} />
                  <div className={styles.searchBar}>
                    <div className={styles.searchBarContainer} style={bannerStyle}>
                      <SearchBar
                        // onSelectChange={setSelectedAssetType}
                        // selectValue={selectedAssetType}
                        onTextChange={setSearchInput}
                        textValue={searchInput}
                      />
                    </div>
                    <div className={styles.optionSelector}>
                      {/* <button className={styles.assetSelectorBtn} onClick={() => setAssetsBtnSelector(prev => !prev)}>
            {assetType || 'Asset Type'}
            <ul className={assetsBtnSelector
              ? `${styles.assetSelectorContainer} ${styles.active}`
              : styles.assetSelectorContainer}
            >
              {
                assetTypes.map(asset => (
                  <li key={asset.id} onClick={() => setAssetType(asset.value)}>
                    {asset.name}
                  </li>
                ))
              }
            </ul>
          </button> */}
                      <p>{collection?.name || 'Undefined'} ({catalogs?.length || 0})</p>

                    </div>
                  </div>
                  <div className={styles.contentArea}>
                    <Gallery photos={catalogs.filter(catalog => catalog?.name?.toLowerCase().includes(searchInput.toLowerCase()))}
                      onClick={handleClick}
                      mixedContent={true}
                      margin={10}
                      isTouchScreen={isTouchScreen()}
                      OverRuledRoles={
                        ['SHARE', 'DOWNLOAD', 'CDELETE']
                      }
                    />
                  </div>
                </>
              )
              : null
          }
        </div>)
      : isInvalidContent && !isLoading
        ? <UnavailableContent />
        : !isLoading && <NotFound errMsg={'The link id you are trying to access is invalid'} />
  )
}

export default SharedByMeCollections
