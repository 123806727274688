/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import styles from './topArea.module.scss';
import ButtonWithIcon from './ButtonWithIcon';
import DownloadIcon from './svgs/Download';
// import FavouriteIcon from './svgs/Favourite';
// import ShareIcon from './svgs/Share';
import { downloadCatalogAPI, openDownloadCatalogAPI } from '../../../../../../api/contentUploadAPI';
import { errorToast } from 'toasts/Toasts';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom/dist';

export default function TopArea ({ id, type, shareMode, data }) {
  const { userdata } = useSelector((state) => state.userdata);
  const params = useParams();
  const { shareId } = params;
  const isProjectBased = data?.template_vid

  const sharingMode = shareMode && shareId;

  const isDling = useRef(false);
  // eslint-disable-next-line no-unused-vars
  const [dlProgress, setDlProgress] = useState({
    dl: 0,
    dlProject: 0
  });
  const onProgress = (progressEvent, isProject) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setDlProgress(prev => {
      if (isProject) {
        return {
          ...prev,
          dlProject: percentCompleted
        }
      }
      return {
        ...prev,
        dl: percentCompleted
      }
    });
  }
  const handledownloadCatalog = async (isProject) => {
    if (isDling.current) {
      return;
    }
    if (isProject && !isProjectBased) {
      errorToast('This is not a project');
      return;
    }
    isDling.current = true;

    try {
      let response, url;
      if (!sharingMode) {
        response = await downloadCatalogAPI(id, isProject);
        url = response?.data?.data?.downloadURL || '';
      } else if (sharingMode) {
        response = await openDownloadCatalogAPI(shareId, id, isProject);
        url = response?.data?.data?.downloadURL || '';
      }
      if (!url) {
        isDling.current = false;
        return;
      }
      const filename = url?.split('/')?.pop()?.split('?')?.shift()
      const blob = await axios.get(
        url,
        {
          responseType: 'blob',
          onDownloadProgress: (progress) => onProgress(progress, isProject)
        }
      ).then(r => {
        return new Blob([r.data], { type: r.headers['content-type'] });
      });
      if (!blob) {
        isDling.current = false;
        setDlProgress({
          dl: 0,
          dlProject: 0
        });
        errorToast('Something went wrong');
        return;
      }
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename || `file.${blob?.type?.split}`;
      link.click();
      isDling.current = false;
      setDlProgress({
        dl: 0,
        dlProject: 0
      });
    } catch {
      isDling.current = false;
      setDlProgress({
        dl: 0,
        dlProject: 0
      });
      errorToast('Something went wrong');
    }
  }

  const typeLowerCased = type?.toLowerCase() || null;
  const downloads = typeLowerCased && !sharingMode ? userdata?.downloads[typeLowerCased] : 0;
  const downloadLimit = typeLowerCased && !sharingMode ? userdata?.monthlyLimits?.downloads[typeLowerCased] : 0;
  const canDownload = (sharingMode || userdata?.userId === data?.userId) ? true : downloads?.length < downloadLimit || downloads.some(el => el?.toString() === id?.toString());

  return (
    <div className={styles.container}>
      <div className={styles.buttonGroup}>
        <ButtonWithIcon disabled={!canDownload} text={canDownload ? `Download ${dlProgress?.dl ? '(' + dlProgress?.dl + '%)' : ''}` : 'You have reached your downloads limit'} icon={canDownload && <DownloadIcon />} onClick={() => handledownloadCatalog(false)} />
        {
          isProjectBased && canDownload && <ButtonWithIcon disabled={!canDownload} text={canDownload ? `Download Project ${dlProgress?.dlProject ? '(' + dlProgress?.dlProject + '%)' : ''}` : 'You have reached your downloads limit'} icon={canDownload && <DownloadIcon />} onClick={() => handledownloadCatalog(true)} />
        }
      </div>
      {/* 
      <div className={styles.buttonGroup}>
        <ButtonWithIcon text="Favourite" icon={<FavouriteIcon />} />
      </div>

      <div className={styles.buttonGroup}>
        <ButtonWithIcon text="Share" icon={<ShareIcon />} />
      </div> */}
    </div>
  );
}
