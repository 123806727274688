/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import UploadHeader from './UploadHeader';
// import UploadMenuLabels from './UploadMenuLabels';
// import ProfileContent from './UserProfile/ProfileContent';
import UploadSelectResourceModal from './modals/UploadSelectResourceModal';
import UploadAddFiles from './modals/UploadAddFiles';
import UploadAddDetails from './modals/UploadAddDetails';
import styles from './css/uploadContainer.module.scss';

function UploadContent ({ data }) {
  const { selectedType, uploadContent, backFn, closeFn } = data;

  return (
    <Fragment>
      <div className={styles.uploadContentContainer}>
        <div className={styles.InfromationSection}>

          {/*
               <UploadHeader />
        */}
          {/*
            <UploadMenuLabels setAccordionItems={setAccordionItems} accordionItems={accordionItems} />
        */}
        </div>
        <div className={styles.interactiveSection}>
          {/*
            <ProfileContent data={data} />
        */}
        </div>
        <UploadSelectResourceModal data={data} cancel={closeFn} />
        <UploadAddFiles data={data} cancel={closeFn} />
        <UploadAddDetails data={data} uploadContent={uploadContent} selectedType={selectedType} cancel={closeFn} backFn={backFn} />
      </div>
    </Fragment>
  )
}

UploadContent.propTypes = {
  data: PropTypes.object
};
export default UploadContent
