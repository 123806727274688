/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as NavIcon } from 'assets/icons/go_to_arr.svg'
import { Link } from 'react-router-dom'

const index = ({ popupData, exitPopup, handleInteraction }) => {
    console.log(popupData, 'popupData')
    return (
        <div className={styles.shareContainer}>
            <h3 className={styles.shareHeader}>
                Select a collection
            </h3>
            <p className={styles.sharesub}>
                Many shares of the exact same collections were shared to you. Click the exact one you’d like to access from the list below
            </p>
            <div className={styles.shareContent}>

                <div className={styles.manageUlContainer}>
                    <ul className={styles.manageListContainer}>
                        <li className={styles.headingListItems}>
                            <div className={styles.collection_dataInfo}>
                                <p>{popupData?.withMe ? 'Sharee' : 'Recipients'}</p>
                            </div>
                            <div className={styles.collection_dataInfo}>
                                <p>Permission</p>
                            </div>
                            <div className={styles.collection_dataInfo}>
                            </div>
                        </li>
                        {
                            popupData?.length && popupData?.map((item) => (
                                <Link 
                                key={item._id}
                                // to={'collections/' + item._id} this will be changed after on backend to get correct one
                                to={'collections/' + item.collection._id}
                                state={{ catalogs: null, collection: item }}
                                className={styles.manageListItemsContainer}>
                                    <div className={styles.collection_dataInfo}>
                                        <p>{item.user.fullName}</p>
                                    </div>
                                    <div className={styles.collection_dataInfo}>
                                        <p>{item.permissions[0]}</p>
                                    </div>
                                    <div className={styles.btnInteractionContainer}>
                                        {/* <button className={styles.removeBtn}></button> */}
                                        <NavIcon className={styles.navIcon} />
                                    </div>
                                </Link>
                            ))
                        }
                    </ul>
                </div>
                <div className={styles.closeButtonContainer}>
                    <button
                        className={styles.closeButton}
                        onClick={exitPopup}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default index
