export const constants = [
  {
    title: 'People',
    filters: [
      {
        name: 'Closeup'
      },
      {
        name: 'Single person'
      },
      {
        name: 'Couple'
      },
      {
        name: 'Collaboration'
      },
      {
        name: 'Other'
      }
    ]
  },
  {
    title: 'Motive',
    filters: [
      {
        name: 'Technology'
      },
      {
        name: 'Corporate'
      },
      {
        name: 'Consumer'
      },
      {
        name: 'Artistic'
      },
      {
        name: 'Industrial'
      },
      {
        name: 'Other'
      }
    ]
  },
  {
    title: 'Background',
    filters: [
      {
        name: 'Black'
      },
      {
        name: 'Dark'
      },
      {
        name: 'Bright'
      },
      {
        name: 'Pastel'
      },
      {
        name: 'Vibrant'
      }
    ]
  },
  {
    title: 'Orientation',
    filters: [
      {
        name: '16:9'
      },
      {
        name: '1:1 Square'
      },
      {
        name: 'Panorama'
      },
      {
        name: 'Portrait'
      },
      {
        name: 'Round'
      }
    ]
  },
  {
    title: 'Style',
    filters: [
      {
        name: 'Classic'
      },
      {
        name: 'Creative'
      },
      {
        name: 'Organic'
      },
      {
        name: 'Squared'
      }
    ]
  },
  {
    title: 'Color',
    filters: [
      {
        name: 'Any color'
      }
    ],
    colors: ['white', 'black', 'purple', 'blue', 'red', '#ec7600', 'green']
  }
]
