/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './style.module.scss'
import UpperSegment from './UpperSegment/Index'
import InteractionArea from './InteractionArea/Index'
import PropTypes from 'prop-types'
import UploadOptions from '../UploadOptions';
import { addToUploadingQueue, createCatalog, updateCatalog, uploadingInitiated } from 'Redux/contentUpload/contentUploadSlice'
import { errorToast, successToast } from 'toasts/Toasts'
import ThumbnailGenerator from './ThumbnailGenerator';
import Compressor from 'compressorjs'
import { prepareCatalogsAPI } from 'api/contentUploadAPI'
import { hardDeleteCatalog } from 'api/trashAPI'

function MetaDataForm ({ arrayOfImages, setShowMetaDataForm, uploadContent, selectedType, editMode, cancel, backFn }) {
  const DEFAULT_CONTENT_STATE = {
    contributers: '',
    name: '',
    folder: {},
    isPublic: undefined,
    wordTags: [],
    orientation: [],
    people: [],
    motive: [],
    color: [],
    duration: [],
    contentDescription: '',
    assetType: selectedType
  }

  const isUploading = useRef(false)

  const [imgState, setImgState] = useState([])
  const [phaseState, setPhase] = useState(1)
  const [atLeastOneSelected, setAtLeastOneSelected] = useState(false)
  const [thumbnailsData, setThumbnails] = useState([])
  const [customThumbs, setCustomThumbnails] = useState([])
  const [contentState, setContentState] = useState(DEFAULT_CONTENT_STATE)
  let { folders } = useSelector((state) => state.folders);
  folders = folders?.filter((folder) => folder.parent?.toLowerCase() === selectedType.toLowerCase()) || [];
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth?.user);
  const [currentUserRole, setCurrentUserRole] = useState({});

  useEffect(() => {
    const role = user?.roles?.length > 0 ? user?.roles[0] : '';
    setCurrentUserRole(role);
  }, [user])

  const videoToThumbnails = useCallback(async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        const metadata = {
          duration: video.duration,
          width: video.videoWidth,
          height: video.videoHeight
        };

        // video height to 480p
        const aspectRatio = video.videoWidth / video.videoHeight;
        video.height = 360;
        video.width = 360 * aspectRatio;
        const thumbnails = [];
        const captureTimes = [0.25, 0.5, 0.75, 1].map(fraction => video.duration * fraction);

        const captureThumbnail = (time) => {
          return new Promise((resolve) => {
            video.currentTime = time;
            video.onseeked = () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.height * aspectRatio;
              canvas.height = video.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              canvas.toBlob(blob => {
                resolve(blob);
              });
            };
          });
        };

        const captureAllThumbnails = async () => {
          for (const time of captureTimes) {
            const thumbnailBlob = await captureThumbnail(time);
            thumbnails.push({ id: Math.random(), blob: thumbnailBlob, selected: false });
          }
          resolve({ metadata, thumbnails });
        };

        captureAllThumbnails();
      };

      video.onerror = (error) => {
        reject(error);
      };

      video.src = URL.createObjectURL(file);
    });
  }, []);

  const durationToStr = (durationInSeconds) => {
    if (durationInSeconds < 60) {
      return 'Short';
    } else if (durationInSeconds < 5 * 60) {
      return '1-5 minutes';
    } else if (durationInSeconds < 10 * 60) {
      return '5-10 minutes';
    } else {
      return 'More than 10 minutes';
    }
  }

  useEffect(() => {
    arrayOfImages.forEach(async (el) => {
      if (!el.video) return;
      const thumbnailList = await videoToThumbnails(el?.video);
      const { thumbnails, metadata } = thumbnailList;
      const { duration, width, height } = metadata;
      setThumbnails(prev => {
        const alreadyExist = prev.find(th => th?.id === el?.id);
        if (alreadyExist) {
          return prev
        }
        return [...prev,
        {
          id: el.id,
          metadata,
          thumbnails: [
            { id: Math.random(), blob: el.src, selected: true },
            ...thumbnails
          ],
          custom: null
        }
        ]
      })
      setCustomThumbnails(prev => {
        const alreadyExist = prev.find(th => th?.id === el?.id);
        if (alreadyExist) {
          return prev
        }
        return [...prev,
        {
          id: el.id,
          custom: null
        }
        ]
      })
      setImgState(prev => {
        return prev.map((img) => {
          if (img.id === el.id) {
            return {
              ...img,
              data: {
                ...img.data,
                duration: durationToStr(duration),
                orientation: width === height ? ['1:1 Square'] : width > height ? ['Horizontal'] : ['Vertical']
              },
              project: null
            }
          }
          return img
        })
      })
    })
  }, [])

  useEffect(() => {
    const NewArrOfImages = arrayOfImages.map(el => {
      return {
        id: el.id,
        uriData: el.src,
        data: { ...DEFAULT_CONTENT_STATE },
        active: false,
        errors: null,
        video: el.video || null
      }
    })
    setImgState(NewArrOfImages)
  }, [])

  useEffect(() => {
    if (imgState.length) {
      const activeOne = imgState.find(el => el.active)
      if (activeOne) {
        setAtLeastOneSelected(activeOne)
      } else {
        setAtLeastOneSelected(null)
      }
    }
  }, [imgState])

  const onChangeData = changes => {
    const filterSelection = { ...contentState }
    let newObj = {}
    const key = changes.category
    if (changes.selected) {
      const newArr = [...filterSelection[key], changes.value]
      newObj = { ...filterSelection, [key]: newArr }
    } else {
      const newArr = filterSelection[key].filter(
        item => item !== changes.value
      )
      newObj = { ...filterSelection, [key]: newArr }
    }
    setContentState(newObj)
  }

  const dataValidity = (data, index) => {
    const { name, folders, word_tags, files, contributor, assest_type, is_public } = data
    if (!name || !folders[0] || !contributor || !word_tags.length || !assest_type || is_public === undefined) {
      const prevImg = { ...imgState[index] } || null
      if (prevImg) {
        if (!name) {
          prevImg.errors = { ...prevImg.errors, name: true }
        }
        if (!folders[0]) {
          prevImg.errors = { ...prevImg.errors, folders: true }
        }
        if (!contributor) {
          prevImg.errors = { ...prevImg.errors, contributor: true }
        }
        if (!word_tags.length) {
          prevImg.errors = { ...prevImg.errors, word_tags: true }
        }
        if (!assest_type) {
          prevImg.errors = { ...prevImg.errors, assest_type: true }
        }
        if (is_public === undefined) {
          prevImg.errors = { ...prevImg.errors, is_public: true }
        }
        setImgState(prev => {
          prev[index] = prevImg
          return [...prev]
        })
      }
      return false
    }
    return true
  }

  const convertUsedThumbnailImage = (imgFile) => {
    return new Promise((resolve, reject) => {
      return new Compressor(imgFile, {
        quality: 0.5,
        success (result) {
          resolve(new File([result], imgFile.name, { type: imgFile.type }));
        },
        error () {
          reject(imgFile);
        }
      });
    }
    )
  }

  const submitData = async () => {
    // if (isSubmitting.current) return;
    let queue = [];
    for (let index = 0; index < imgState.length; index++) {
      const element = imgState[index];
      const submittableElement = element.active ? { ...element, data: { ...contentState }, active: false } : element
      const files = []
      const { video, uriData, id } = element
      const renamedVid = new File([video], id + '-' + video.name, { type: video.type })
      files.push(renamedVid)
      const reconstructedVidName = id + '-' + video.name.split('.').slice(0, -1).join('.') + '.jpg'
      const imgFile = new File([uriData], reconstructedVidName, { type: 'image/jpeg' })
      const compressedImg = await convertUsedThumbnailImage(imgFile)
      if (element?.project) {
        const zipProject = new File([element?.project], id + '-' + element?.project?.name, { type: element?.project?.type || 'application/zip' })
        if (zipProject) files.push(zipProject);
      }
      files.push(compressedImg)
      const {
        wordTags,
        name,
        contentDescription,
        contributers,
        orientation,
        folder,
        people,
        motive,
        duration,
        isPublic,
        assetType,
        color
      } = submittableElement.data
      try {
        const metadata = thumbnailsData?.find(el => el?.id === element?.id)?.metadata
        const data = !editMode
          ? {
            name,
            contributor: contributers,
            assest_type: assetType,
            orientation,
            word_tags: wordTags,
            content_description: contentDescription,
            folders: [folder.id],
            files,
            is_public: isPublic,
            color,
            people,
            motive,
            duration,
            vid_metadata: JSON?.stringify(metadata || {
              duration: 0,
              width: 0,
              heigh: 0
            }),
            tempId: element.id
          }
          : {
            name,
            contributor: contributers,
            assest_type: assetType,
            word_tags: wordTags.join(','),
            content_description: contentDescription,
            folders: [folder.id],
            is_public: isPublic,
            color: color.join(','),
            orientation: orientation.join(','),
            people: people.join(','),
            motive: motive.join(','),
            duration: duration.join(','),
            tempId: element.id
          }
        if (dataValidity(data, index)) {
          queue = [...queue, data]
        } else {
          console.log('lol', data, dataValidity(data, index))
          return null;
        }
      } catch {
        errorToast('Something went wrong')
      }
    }
    try {
      if (queue.length !== imgState.length) {
        console.log('queue', queue.length, imgState.length)
        errorToast('Please fill all the required fields')
        return
      }
      if (!editMode && !isUploading.current) {
        isUploading.current = true
        uploadContent(queue, () => {
          isUploading.current = false
        })
      } else {
        try {
          dispatch(updateCatalog(queue[0]))
          setShowMetaDataForm(false)
          successToast('Content updated successfully')
        } catch (error) {
          errorToast('Something went wrong')
        }
      }
    } catch {
      errorToast('Something went wrong')
    }
  }

  const goNextPhase = () => {
    setPhase(prev => prev + 1)
    if (phaseState === 2) {
      setImgState(prev => prev.map(el => {
        return el.active
          ? {
            ...el,
            data: {
              ...contentState,
              orientation: el.data.orientation.length ? el.data.orientation : ['Horizontal'],
              duration: el.data.duration.length ? el.data.duration : ['short']
            },
            active: false
          }
          : el
      }))
    }
  }

  return (
    <div className={style.wrapper}>
      <section className={editMode ? `${style.upperImgsSegment} ${style.inEditMode}` : style.upperImgsSegment}>
        <UpperSegment
          imgState={imgState}
          setImgState={setImgState}
          selectedN={atLeastOneSelected?.length}
          phaseState={phaseState}
          contentState={contentState}
          setContentState={setContentState}
          editMode={editMode}
        />
      </section>
      <section className={editMode ? `${style.interactionArea} ${style.inEditMode}` : style.interactionArea}>
        {phaseState !== 1
          ? (
            atLeastOneSelected
              ? (
                <InteractionArea
                  contentState={contentState}
                  setContentState={setContentState}
                  onChangeData={onChangeData}
                  phaseState={phaseState}
                  folders={folders}
                  setImgState={setImgState}
                  imgState={imgState}
                  currentUserRole={currentUserRole}
                />
              )
              : (
                <div className={style.noImgSelected}>
                  {
                    phaseState === 2
                      ? (
                        <p>
                          select item or multiple items <br />
                          to add details
                        </p>
                      )
                      : phaseState === 3 && (
                        <p>
                          You have added general details to each asset, in the previous step <br />
                          Now you can add specific details to each asset <br />
                          Once you have added details to all assets, you can submit
                        </p>
                      )
                  }
                </div>
              )
          )
          : atLeastOneSelected
            ? (
              <div className={style.noImgSelected}>
                <ThumbnailGenerator
                  atLeastOneSelected={atLeastOneSelected}
                  contentState={contentState}
                  setContentState={setContentState}
                  thumbnailsData={thumbnailsData}
                  setThumbnails={setThumbnails}
                  setImgState={setImgState}
                  customThumbs={customThumbs}
                  setCustomThumbnails={setCustomThumbnails}
                />
              </div>
            )
            : (
              <div className={style.noImgSelected}>
                <p>Please select a video to proceed to edit the thumbnail</p>
              </div>
            )
        }
      </section>
      <section className={style.uploadSection}>
        <div className={style.uploadBtn}>
          {
            !editMode && (
              <button
                type='button'
                onClick={() => phaseState === 1 ? backFn() : setPhase(prev => prev - 1)}
                className={`${style.cancel}`}
              // disabled={uploading}
              >
                Back
              </button>
            )
          }
          <button
            type='submit'
            className={
              atLeastOneSelected
                ? `${style.submitBtn}`
                : `${style.submitBtn} ${style.inactive}`
            }
            onClick={() => phaseState !== 3 ? goNextPhase() : submitData()}
          >
            {phaseState !== 3 ? 'Next' : editMode ? 'Finish' : 'Submit'}
          </button>
        </div>
        <div className={style.uploadBackAndSkip}>
          <button
            type='button'
            onClick={() => cancel ? cancel() : setShowMetaDataForm(false)}
            className={`${style.cancel}`}
          // disabled={uploading}
          >
            Cancel
          </button>
        </div>
      </section>
    </div>
  )
}

MetaDataForm.propTypes = {
  arrayOfImages: PropTypes.array,
  setShowMetaDataForm: PropTypes.func,
  uploadContent: PropTypes.func,
  selectedType: PropTypes.string,
  editMode: PropTypes.bool,
  cancel: PropTypes.func,
  backFn: PropTypes.func
}

export default MetaDataForm
