const getCurrentUser = (userWithProfile, catalog) => {
    const { user, profile } = userWithProfile
    const id = user?.id;
    const rolesArr = user?.roles;
    const role = rolesArr?.length > 0 ? rolesArr[0] : '';
    const userCompanyId = profile?.currentCompanyInfo?._id;
    const currentUrl = window.location.href;

    if (id === catalog.userId && catalog.companyId === userCompanyId) {
        return (currentUrl.includes('my-catalogs')) && role === 'ADMINISTRATOR' ? ['DELETE', 'EDIT', 'SHARE', 'DOWNLOAD', 'FEATURE'] : ['DELETE', 'EDIT', 'SHARE', 'DOWNLOAD'];
    } else if (id !== catalog.userId && catalog.companyId === userCompanyId) {
        switch (role) {
            case 'ADMINISTRATOR':
                return (currentUrl.includes('my-catalogs')) ? ['DELETE', 'EDIT', 'SHARE', 'DOWNLOAD', 'FEATURE'] : ['DELETE', 'EDIT', 'SHARE', 'DOWNLOAD'];
            case 'EDITOR':
                return ['EDIT', 'SHARE', 'DOWNLOAD'];
            default:
                return ['SHARE', 'DOWNLOAD'];
        }
    }
    return ['SHARE', 'DOWNLOAD'];
};

export default getCurrentUser;
