/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useLayoutEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as LeftArr } from '../assets/icon-arrow-left.svg';
import { ReactComponent as RightArr } from '../assets/icon-arrow-right.svg';
import { ReactComponent as ToggleFS } from '../assets/icon-full-screen.svg';
import { ReactComponent as CloseFs } from '../assets/icon-remove-fullscreen.svg';
import { ReactComponent as DblArrow } from 'assets/icons/dbarrow.svg';
import GridImagesLayout from '../../GridImagesLayout'

const index = ({ templateView, previewPopup, allCatalogs, setPreviewPopup }) => {
  const { state, data } = previewPopup || {}
  const [previewFullScreen, setPreviewFullScreen] = useState(false)
  const [previewLoadingNext, setPreviewLoadingNext] = useState(true)
  const [showcaseOnFullScreen, setShowcaseOnFullScreen] = useState(false)
  const [previewIndex, setPreviewIndex] = useState(0)
  const [templates, setTemplates] = useState([])

  const handleResize = () => {
    if (state === 1 && window.innerWidth < 800) {
      setPreviewPopup(prev => { return { ...prev, state: 5 } })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [state])

  useLayoutEffect(() => {
    if (data) {
      setPreviewIndex(templateView ? data.index : 0)
      setTemplates(data.template_data?.templates
        ? [...data.template_data.templates.map(el => {
          return {
            ...el,
            src: el
          }
        })]
        : allCatalogs
          ? [...allCatalogs.map(el => {
            return {
              ...el,
              src: el
            }
          })]
          : [])
    }
  }, [data])

  useEffect(() => {
    if (document?.exitFullscreen && previewFullScreen && !previewPopup) {
      document?.exitFullscreen()
    }
  }, [previewPopup, previewFullScreen])

  useEffect(() => {
    if (state === 1 || state === 5) {
      document.addEventListener('keydown', changePreviewPicture)
      setPreviewLoadingNext(true)
      return () => {
        document.removeEventListener('keydown', changePreviewPicture)
      }
    }
  }, [state, previewIndex])

  const changeToFullScreen = (value) => {
    if (value) {
      setPreviewFullScreen(true)
      const elem = document.getElementById('root')
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      }
    } else {
      setPreviewFullScreen(false)
      if (document?.exitFullscreen && previewFullScreen) {
        try {
          document?.exitFullscreen()
        } catch {
          return null
        }
      }
    }
  }

  const changePreviewPicture = (e) => {
    e.preventDefault()
    if (e.key === 'ArrowRight' && previewIndex < allCatalogs.length - 1) {
      setPreviewIndex(previewIndex + 1)
      setPreviewLoadingNext(true)
    } else if (e.key === 'ArrowLeft' && previewIndex > 0) {
      setPreviewIndex(previewIndex - 1)
      setPreviewLoadingNext(true)
    } else if (e.key === 'Escape') {
      e.preventDefault()
      setPreviewFullScreen(false)
      setPreviewPopup(null)
    }
  }

  const handleChangeOnFullScreen = (data) => {
    previewIndex !== data?.index && data?.index !== undefined && setPreviewIndex(data.index)
    setShowcaseOnFullScreen(false)
    previewIndex !== data?.index && data?.index !== undefined && setPreviewLoadingNext(true)
  }

  const tags = data?.word_tags?.split(',') || [];

  return (
    <>
      {
        state === 1
          ? (
            <>
              <div
                className={styles.miniPreviewContainer}
                style={{
                  maxHeight: '800px',
                  minHeight: '300px',
                  minWidth: previewLoadingNext ? '80%' : 'unset'
                }}
              >
                <div className={previewLoadingNext ? `${styles.popupPreviewImgContainer} ${styles.loadingImgActiveBg}` : styles.popupPreviewImgContainer}
                  style={{
                    maxHeight: '800px',
                    minHeight: '300px',
                    minWidth: previewLoadingNext ? '80%' : 'unset'
                  }}
                >
                  <img
                    className={previewLoadingNext ? `${styles.previewImg} ${styles.loadingImg}` : styles.previewImg}
                    src={templates[previewIndex]?.original}
                    onLoad={() => setPreviewLoadingNext(false)}
                    style={{
                      maxHeight: '800px',
                      minHeight: '300px',
                      width: '100%'
                    }}
                    alt="preview" />
                  {
                    previewLoadingNext && <div className={styles.loader} />
                  }
                  <div className={styles.popupPreviewImgContainer__controls}>
                    <button
                      className={styles.fullscreenBtn}
                      onClick={() => { changeToFullScreen(true); setPreviewPopup(prev => { return { ...prev, state: 5 } }) }}>
                      <ToggleFS />
                    </button>
                    <button
                      className={styles.fullscreenBtn}
                      onClick={() => setPreviewPopup(null)}>
                      <CloseIcon />
                    </button>
                  </div>
                  {
                    !templateView && (
                      <>
                        <div className={styles.popupPreviewImgContainer__info}>
                          <h3>{data.name}</h3>
                        </div>
                        <div className={styles.tagsContainer}>
                          <h2 className={styles.tagSpan}>Tags: </h2>
                          {tags?.map((tag, index) => (
                            <p className={styles.tag} key={index}>#{tag.trim()}</p>
                          ))}
                        </div>
                        <div className={styles.popupPreviewImgContainer__footer}>
                          <p>{data.content_description}</p>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
              <div className={styles.popupPreviewImgContainer__navigatorLeft}>
                <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex - 1) }} disabled={previewIndex === 0}>
                  <LeftArr />
                </button>
              </div>
              <div className={styles.popupPreviewImgContainer__navigatorRight}>
                <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex + 1) }} disabled={previewIndex === templates.length - 1}>
                  <RightArr />
                </button>
              </div>
            </>
          )
          : (
            <div
              className={`${styles.popupPreviewImgContainer} ${styles.fscreen}`}
            >
              {
                showcaseOnFullScreen &&
                <div className={styles.fullscreenShowcaseContainer}>
                  <div className={styles.fullscreenShowcaseContainer__interactiveTab}>
                    <button className={styles.closeShowCase} onClick={() => setShowcaseOnFullScreen(false)}>
                      <DblArrow className={styles.closeShowCaseImg} />
                    </button>
                  </div>
                  <div className={styles.catalogsWrapper}>
                    <GridImagesLayout
                      catalogs={templates}
                      innerProps={{ targetRowHeight: 200, noInteraction: true, margin: 10 }}
                      onClick={(value, data) => handleChangeOnFullScreen(data)}
                    />
                  </div>
                </div>
              }
              <div className={styles.backgroundOverlay} />
              {previewLoadingNext && <div className={styles.loadingImgActiveBgFs} />}
              <img
                className={previewLoadingNext ? `${styles.previewImg} ${styles.loadingImg}` : styles.previewImg}
                src={templates[previewIndex]?.original}
                onLoad={() => setPreviewLoadingNext(false)}
                style={{
                  maxHeight: templates[previewIndex]?.height > templates[previewIndex]?.width ? '100%' : 'unset',
                  width: templates[previewIndex]?.height > templates[previewIndex]?.width ? 'unset' : '100%',
                  maxWidth: templates[previewIndex]?.height > templates[previewIndex]?.width ? '100%' : 'unset'
                }}
                alt="preview" />
              <div className={styles.popupPreviewImgContainer__controls}>
                {
                  previewFullScreen &&
                  <button
                    className={styles.fullscreenBtn}
                    onClick={() => {
                      changeToFullScreen(false);
                      setPreviewPopup(prev => { return { ...prev, state: 1 } })
                    }}>
                    <CloseFs />
                  </button>
                }
                <button
                  className={styles.fullscreenBtn}
                  onClick={() => {
                    setPreviewPopup(null)
                    try {
                      if (document?.exitFullscreen && previewFullScreen) {
                        document?.exitFullscreen()
                      }
                    } catch {
                      return null
                    }
                  }}>
                  <CloseIcon />
                </button>
              </div>
              <div className={styles.popupPreviewImgContainer__navigatorLeft}>
                <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex - 1) }} disabled={previewIndex === 0}>
                  <LeftArr />
                </button>
              </div>
              <div className={styles.popupPreviewImgContainer__navigatorRight}>
                <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex + 1) }} disabled={previewIndex === templates.length - 1}>
                  <RightArr />
                </button>
              </div>
              <div className={styles.popupPreviewImgContainer__info}>
                <button className={styles.openShowCase} onClick={() => setShowcaseOnFullScreen(true)}>
                  <DblArrow className={styles.closeShowCaseImg} />
                </button>
                <h3>{data.name}</h3>
              </div>
              <div className={styles.popupPreviewImgContainer__footer}>
                <p>{data.content_description}</p>
              </div>
            </div>
          )
      }
    </>
  )
}

export default index
