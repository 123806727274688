/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import styles from './styles.module.scss'
import GridImagesLayout from '../GridImagesLayout'
import { getTemplate } from 'Redux/content/contentSlice';
import PopupManger from '../PopupManger';
import axiosInstance from '../../../../../axios/axiosInstance';
import JSZip from 'jszip';
import { getTemplateZipFile } from 'api/contentUploadAPI';
import { errorToast } from 'toasts/Toasts';
import { toast } from 'react-toastify';
import axios from 'axios';
import isMongoId from 'helpers/isMongo';
import NotFound from 'components/pages/NotFound/NotFound';
import UnavailableContent from 'components/pages/UnavailableContent/UnavailableContent';

export default function index () {
  const location = useLocation()
  const params = useParams()
  const { templateId, folderId } = params
  const dispatch = useDispatch()
  const { currentTemplate, loading, error } = useSelector(state => state.content);
  const [template, setTemplate] = useState([]);
  const [previewPopup, setPreviewPopup] = useState(null);
  const [showSamples, setShowSamples] = useState(true);
  const validFolderId = isMongoId(folderId?.split('&page=')[0]);
  const validTemplateId = isMongoId(templateId);

  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const SingularlinkGetter = async (zipFileLink, index) => {
    try {
      const response = await axios.get(zipFileLink, {
        responseType: 'arraybuffer'
      });
      const zip = new JSZip();
      const data = await zip.loadAsync(response.data);
      const files = Object.keys(data.files).map((key) => {
        return data.files[key]
      }
      )

      const file = await files[index].async('blob')
      const fileUrl = URL.createObjectURL(file)
      return { url: fileUrl, name: files[index].name }
    } catch (err) {
      errorToast(err.message)
      return null
    }
  }

  async function applyFilterAndGetLink (imageLink, filterStyle) {
    const image = new Image();
    image.crossOrigin = 'Anonymous';

    // Load the image
    await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
      image.src = imageLink;
    });

    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    // Apply the filter to the canvas
    const ctx = canvas.getContext('2d');
    ctx.filter = filterStyle?.webkitFilter;
    ctx.drawImage(image, 0, 0);

    // Convert the canvas to a data URL
    const dataURL = canvas.toDataURL('image/png');
    const blob = await (await fetch(dataURL)).blob();
    const url = URL.createObjectURL(blob);
    return url;
  }

  const getLink = async () => {
    const response = await getTemplateZipFile(templateId || currentTemplate?.id)
    if (response?.data?.data?.zipfileLink) {
      return response?.data?.data?.zipfileLink
    } else {
      return null
    }
  }

  const downloadSingleTemplate = (idx, customStyle) => {
    if (!currentTemplate) {
      return;
    }
    getLink().then(link => SingularlinkGetter(link, idx)).then((data) => {
      if (!data) {
        toast.error('Something went wrong, please try again later');
        return null;
      }
      const anchorLink = document.createElement('a');
      if (!customStyle) {
        anchorLink.href = data.url;
        anchorLink.target = '_blank';
        anchorLink.download = data?.name || 'template' + ' ' + idx;
        anchorLink.click();
      } else {
        applyFilterAndGetLink(data.url, customStyle).then((filteredUrl) => {
          anchorLink.href = filteredUrl;
          anchorLink.target = '_blank';
          anchorLink.download = data?.name || 'template' + ' ' + idx;
          anchorLink.click();
        })
      }
    })
  }

  const downloadAllTemplates = () => {
    if (!currentTemplate) {
      return;
    }
    getLink().then(link => {
      const anchorLink = document.createElement('a');
      anchorLink.href = link;
      anchorLink.target = '_blank';
      anchorLink.download = currentTemplate?.name || 'template';
      anchorLink.click();
    })
  }

  useEffect(() => {
    if (!location.state?.catalog?.template_data?.templates) {
      dispatch(getTemplate(templateId));
    }
  }, [location])

  useEffect(() => {
    let toBeAddedTemplates = []
    if (!location.state?.catalog?.template_data?.templates && currentTemplate) {
      toBeAddedTemplates = location.state?.catalog?.template_data?.templates || currentTemplate.template_data?.templates || []
    } else if (location.state?.catalog?.template_data?.templates) {
      toBeAddedTemplates = location.state?.catalog?.template_data?.templates || []
    }
    if (toBeAddedTemplates.length > 0) {
      setTemplate(toBeAddedTemplates.map((item) => {
        return {
          ...item,
          src: showSamples
            ? {
              original: item.thumbnail,
              thumbnail: item.original
            }
            : {
              original: item.original,
              thumbnail: item.thumbnail
            }
        }
      }))
    }
  }, [currentTemplate, showSamples, location])

  const handleClick = (state, catalog) => {
    setPreviewPopup({
      state,
      data: catalog
    })
  }

  return (
    folderId?.split('&page=')[0] && templateId && validTemplateId && validFolderId && !loading
      ? (
        <div className={styles.templateViewContainer}>
          {!error && currentTemplate && !loading
            ? <>
              <PopupManger previewPopup={previewPopup} setPreviewPopup={setPreviewPopup} downloadSingleTemplate={downloadSingleTemplate} allCatalogs={template} templateView={true} />
              <div className={styles.upperPreviewAndControlSection} style={{ backgroundImage: `url(${template[0]?.original})` }}>
              </div>
              <div className={styles.templatesContainer}>
                <div className={styles.templatesHeader}>
                  <h3 className={styles.headerTag}>{location.state?.catalog?.name || currentTemplate?.name || 'Untitled'}</h3>
                  <div className={styles.flipBtnContainer}>
                    <label htmlFor='flipbtn' className={styles.flipBtnLabel}>View sample</label>
                    <button id='flipbtn' className={showSamples ? `${styles.flipBtn} ${styles.active}` : styles.flipBtn} onClick={() => setShowSamples(prev => !prev)}>
                      <span className={styles.flipBtnInner} />
                    </button>
                  </div>
                </div>
                <div className={styles.templatesGridContainer}>
                  {
                    template.length > 0 && (
                      <GridImagesLayout catalogs={template} onClick={handleClick} innerProps={{
                        subCatalogView: true,
                        isTouchScreen: isTouchScreen()
                      }} />
                    )
                  }
                </div>
                <div className={styles.bottomInteractionSection}>
                  <button onClick={downloadAllTemplates} className={styles.downloadAllBtn}>Download All</button>
                </div>
              </div>
            </>
            : <UnavailableContent errMsg="Sorry, but unless you've got a way to travel through time, the template you're trying to access is unavailable" />
          }

        </div>
      )
      : !loading && folderId?.split('&page=')[0] && templateId
        ? <NotFound errMsg={
          validTemplateId
            ? 'Folder id link is not valid'
            : 'Template id link is not valid'
        } />
        : null
  )
}
