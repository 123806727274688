export const filtersData = [
  {
    title: 'People',
    dbTitle: 'people',
    filters: [
      {
        name: 'Closeup'
      },
      {
        name: 'Single person'
      },
      {
        name: 'Couple'
      },
      {
        name: 'Collaboration'
      },
      {
        name: 'Other'
      }
    ]
  },
  {
    title: 'Motive',
    dbTitle: 'motive',
    filters: [
      {
        name: 'Technology'
      },
      {
        name: 'Corporate'
      },
      {
        name: 'Consumer'
      },
      {
        name: 'Artistic'
      },
      {
        name: 'Industrial'
      },
      {
        name: 'Other'
      }
    ]
  },
  {
    title: 'Duration',
    dbTitle: 'duration',
    filters: [
      {
        name: 'Short'
      },
      {
        name: '1-5 minutes'
      },
      {
        name: '5-10 minutes'
      },
      {
        name: 'More than 10 minutes'
      }
    ]
  },
  {
    title: 'Orientation',
    dbTitle: 'orientation',
    filters: [
      {
        name: 'Vertical'
      },
      {
        name: 'Horizontal'
      },
      {
        name: '1:1 Square'
      }
    ]
  },
  {
    title: 'Quality',
    dbTitle: 'quality',
    filters: [
      {
        name: 'SD'
      },
      {
        name: 'HD'
      },
      {
        name: 'FHD'
      },
      {
        name: '4k'
      }
    ]
  },
  {
    title: 'Fps',
    dbTitle: 'fps',
    filters: [
      {
        name: '25-30'
      },
      {
        name: '30-50'
      },
      {
        name: '50-60'
      },
      {
        name: '60+'
      }
    ]
  },
  {
    title: 'Color',
    dbTitle: 'color',
    filters: [
      {
        name: ''
      }
    ],
    colors: ['white', 'black', 'purple', 'blue', 'red', '#ec7600', 'green']
  }
];
