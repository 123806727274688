import styles from './css/Creativity.module.scss';
import img1 from 'assets/images/img1.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Creativity = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.middleSection}>
            <div className={styles.content}>
                <div className={styles.desc}>
                    <h1 className={styles.title}>{t('A solution to make your content shine')}</h1>
                    <p className={styles.description}>{t('unleash-brilliance-text')}</p>
                </div>
                <div className={styles.images}>
                    <img src={img1} alt='image' />
                </div>
            </div>
        </div>
    );
};

export default Creativity;
