import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import Navigation from './routes/navigation'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
      </BrowserRouter>
      <ToastContainer theme='dark' />
    </div>
  )
}

export default App
