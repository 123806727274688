/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss'
import GridImagesLayout from './components/GridImagesLayout'
import { deleteCatalog, getCatalog } from 'Redux/content/contentSlice';
import { ReactComponent as LeftArr } from 'assets/icons/icon-arrow-left.svg';
import { ReactComponent as RightArr } from 'assets/icons/icon-arrow-right.svg';
// import useClosePop from '../../atoms/Popup/hooks/useClosePop';
import PopupManger from './components/PopupManger';
import { filtersData } from '../../../constants/filtersData';
import { Filter } from '../../organisms/Filter'
import '@master-wizr/wizr-filter/dist/index.css'
import { ReactComponent as GridIconOne } from '../../../assets/icons/grid1.svg';
import { ReactComponent as GridIconTwo } from '../../../assets/icons/grid2.svg';
import { ReactComponent as GridIconThree } from '../../../assets/icons/grid3.svg';
import Pagination from '../../molecules/Pagination';
import { errorToast } from 'toasts/Toasts'
import NotFound from '../NotFound/NotFound';
import isMongoId from 'helpers/isMongo';
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import { useTranslation } from 'react-i18next';
import { featureCatalog } from 'Redux/contentUpload/contentUploadSlice';

const IS_PACKAGE_OPTION = false;

const myImageCatalogs = ({ possiblePage }) => {
  const [previewPopup, setPreviewPopup] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const beCatalogs = useSelector(state => state.content.catalog);
  const { loading } = useSelector(state => state.content);
  const { pageDetails } = useSelector(state => state.content);
  const [catalogs, setCatalogs] = useState([]);
  const [viewTagsList, setViewTagslist] = useState([]);
  const [filterSelection, setFilterSelection] = useState({});
  const [scrollableTags, setScrollableTags] = useState(false);
  const [viewSize, setViewSize] = useState(Math.floor((20 * window.innerHeight) / 100));
  const params = useParams();
  const navigate = useNavigate();
  const validFolderId = isMongoId(params?.folderId?.split('&page=')[0]) || !params?.folderId;
  const { t } = useTranslation();
  let slideInterval;

  const isPrivate = location.pathname.includes('my-catalogs');

  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  useEffect(() => {
    if (pageDetails?.page) {
      if (pageDetails?.page?.number > pageDetails?.page?.totalPages) {
        navigate(`Images&page=${pageDetails?.page?.totalPages}`)
      } else if (pageDetails?.page?.number < 1) {
        navigate('Images')
      }
    }
  }, [pageDetails])

  useEffect(() => {
    const handleResize = () => {
      if (scrollableTags && window.innerWidth < 800) {
        setScrollableTags(false);
      } else if (!scrollableTags && window.innerWidth >= 800 && !isTouchScreen()) {
        setScrollableTags(true);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [scrollableTags])

  useEffect(() => {
    if (possiblePage && !params.folderId) {
      if (isNaN(possiblePage)) {
        navigate('Images')
      } else if (possiblePage && !isNaN(parseInt(possiblePage))) {
        dispatch(getCatalog({
          assetType: 'Images',
          page: parseInt(possiblePage),
          isPrivate
        }));
        return;
      }
    } else {
      if (params.folderId) {
        if (!params.folderId?.includes('&page=')) {
          dispatch(getCatalog({
            folderId: params.folderId,
            isPrivate
          }));
        } else {
          const page = params.folderId.split('&page=')[1]
          dispatch(getCatalog({
            folderId: params.folderId.split('&page=')[0],
            page: parseInt(page),
            isPrivate
          }));
        }
      } else {
        dispatch(getCatalog({
          assetType: 'Images',
          isPrivate
        }));
      }
    }
  }, [location])

  const onChangeData = (changes) => {
    const key = changes.category
    const value = changes.value?.toLowerCase()
    if (changes.category === 'searchText') {
      setFilterSelection({ ...filterSelection, name: [changes.value] })
    } else if (changes.category === 'assest_type') {
      setFilterSelection({ ...filterSelection, assest_type: [changes.value] })
    } else if (!filterSelection[key]) {
      const newData = {}
      newData[key] = [value]
      setFilterSelection({ ...filterSelection, ...newData })
    } else {
      if (changes.selected) {
        const newArr = [...filterSelection[key], value]
        setFilterSelection({ ...filterSelection, [key]: newArr })
      } else {
        const newArr = filterSelection[key].filter((item) => item !== value)
        newArr.length > 0 ? setFilterSelection({ ...filterSelection, [key]: newArr }) : setFilterSelection((prev) => { const newState = { ...prev }; delete newState[key]; return newState })
      }
    }
  }

  useEffect(() => {
    setViewTagslist([]);
    !loading && beCatalogs && Array.isArray(beCatalogs) && !beCatalogs[0]?.template_data && setCatalogs([...beCatalogs]
      .map(catalog => {
        setViewTagslist(prev => Array.from(new Set([...prev, ...catalog.word_tags.split(',')])))
        return {
          ...catalog,
          src: catalog.assest_urls[0],
          width: catalog.image_size?.width || 16,
          height: catalog.image_size?.height || 9
        }
      }));
  }, [beCatalogs, location, loading])

  useEffect(() => {
    if (viewTagsList) {
      const tagsContainer = document.querySelector(`.${styles.tagsContainer}`);
      if (tagsContainer?.scrollWidth > tagsContainer?.offsetWidth) {
        if (scrollableTags && window.innerWidth < 800) {
          setScrollableTags(false);
        } else if (!scrollableTags && window.innerWidth >= 800 && !isTouchScreen()) {
          setScrollableTags(true);
        }
      } else {
        setScrollableTags(false)
      }
    }
  }, [viewTagsList, location, scrollableTags])

  const handleClick = (state, catalog) => {
    if (state === 9) {
      const payload = {
        is_featured: !catalog.is_featured
      }
      dispatch(featureCatalog({ payload, catalog }))
      return;
    }
    setPreviewPopup({
      state,
      data: catalog
    })
  }

  const navigateToSearchPage = (propedFilterData) => {
    const filterData = propedFilterData || { ...filterSelection };
    const link = `/mpublisher/search/result&assest_type=images&${Object.keys(filterData).map((key) => `${key}=${filterData[key].join(',').toLowerCase()}`).join('&')}${!isPrivate ? '&use_global=true' : '&use_global=false'}`
    navigate(link, { state: { viewTagsList, typeOfSearch: !isPrivate ? '&use_global=true' : '&use_global=false' } })
  }

  const addToSearchTags = (tag) => {
    const lowTag = tag.toLowerCase();
    let newFilterSelection = { ...filterSelection };
    if (filterSelection.word_tags) {
      const newArr = filterSelection.word_tags?.includes(lowTag) ? [...filterSelection.word_tags].filter(tags => tags !== tag) : [...filterSelection.word_tags, lowTag]
      newFilterSelection = { ...filterSelection, word_tags: newArr }
      if (newArr.length === 0) delete newFilterSelection.word_tags
      setFilterSelection(newFilterSelection)
    } else {
      newFilterSelection = { ...filterSelection, word_tags: [lowTag] }
      setFilterSelection(newFilterSelection)
    }
    navigateToSearchPage(newFilterSelection)
  }

  const isNavigating = useRef(false);
  let timeoutRunner = null

  const slideHandler = (direction) => {
    const tagsContainer = document.querySelector(`.${styles.tagsContainer}`);
    if (direction === 1 && tagsContainer.scrollLeft > 0) {
      tagsContainer.scrollLeft -= 100;
    } else if (direction === 2 && tagsContainer.scrollLeft < tagsContainer.scrollWidth) {
      tagsContainer.scrollLeft += 100;
    }
    if (isNavigating.current) {
      setTimeout(() => {
        timeoutRunner = slideHandler(direction)
      }, 100)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', stopSlide);
    return () => {
      document.removeEventListener('mouseup', stopSlide);
    }
  }, [scrollableTags])

  const startSlide = (direction) => {
    isNavigating.current = true;
    slideHandler(direction)
  }

  const stopSlide = () => {
    isNavigating.current = false;
    clearTimeout(timeoutRunner)
  }

  return (
    validFolderId
      ? (
        <div className={styles.wrappingElement}>
          <PopupManger previewPopup={previewPopup} setPreviewPopup={setPreviewPopup} allCatalogs={catalogs} />
          <div className={styles.searchBarContainer}>
            <div className={styles.searchBar}>
              <Filter data={filtersData} onChangeData={onChangeData} onSearch={navigateToSearchPage} currentState={filterSelection} camelizedValues={true} type="wizrx" />
            </div>
            <h1>{t('Inspire Your Designs')} </h1>
          </div>
          {
            beCatalogs.length > 0 && (
              <div className={styles.contentSizing}>
                <div className={styles.contentAreaHeaderMiniFilter}>
                  <div className={styles.headerElement}>
                    <div className={styles.headerContainer}>
                      <h3 className={styles.headerElementTitle}>{t('Images')}</h3>
                      <span className={styles.headerElementTitle}>{pageDetails?.page?.totalElements
                        ? `${pageDetails?.page?.size * pageDetails?.page?.number >= pageDetails?.page?.totalElements ? pageDetails?.page?.totalElements : pageDetails?.page?.size * pageDetails?.page?.number}${` out of ${pageDetails?.page?.totalElements}`}`
                        : catalogs.length} {t('Images')}</span>
                    </div>
                    {
                      // Hide package option for now, can be shown later
                      IS_PACKAGE_OPTION &&
                      (<div className={styles.sizingContianer}>
                        <div className={styles.filterByRoleContainer}>
                          <div className={styles.filterByRoleSwingContainer}>
                            <span className={styles.roleSpan}>{t('All')}</span>
                            <span className={styles.roleSpan}>{t('Free')}</span>
                            <span className={styles.roleSpan}>{t('Premium')}</span>
                          </div>
                        </div>
                      </div>
                      )}
                  </div>
                </div>
                <div className={styles.miniFiltringAndView}>
                  <div className={styles.miniFilterContainer}>
                    {
                      scrollableTags && <button className={styles.l_arrowBtn} onMouseDown={() => startSlide(1)}>
                        <LeftArr />
                      </button>
                    }
                    <ul className={styles.tagsContainer}>
                      {
                        viewTagsList.map((tag, index) => {
                          return (
                            <li key={tag} className={filterSelection.word_tags?.includes(tag.toLowerCase()) ? `${styles.tagSpan} ${styles.active}` : styles.tagSpan} onClick={() => addToSearchTags(tag)}>#{tag}</li>
                          )
                        })
                      }
                    </ul>
                    {
                      scrollableTags && <button className={styles.r_arrowBtn} onMouseDown={() => startSlide(2)}>
                        <RightArr />
                      </button>
                    }
                  </div>

                  <div className={styles.viewsContainer}>
                    <button
                      className={Math.floor((30 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                      onClick={() => setViewSize(Math.floor((30 * window.innerHeight) / 100))}>
                      <GridIconOne />
                    </button>
                    <button
                      className={Math.floor((20 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                      onClick={() => setViewSize(Math.floor((20 * window.innerHeight) / 100))}
                    >
                      <GridIconTwo />
                    </button>
                    <button
                      className={Math.floor((15 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                      onClick={() => setViewSize(Math.floor((15 * window.innerHeight) / 100))}>
                      <GridIconThree />
                    </button>
                  </div>
                </div>
              </div>
            )
          }
          {
            !loading
              ? (
                <div className={styles.contentArea}>
                  {
                    beCatalogs?.length > 0 && catalogs.length > 0 && !loading
                      ? (
                        <>
                          <GridImagesLayout
                            catalogs={catalogs}
                            onClick={handleClick}
                            innerProps={{
                              targetRowHeight: viewSize,
                              margin: viewSize > 140 ? 10 : 15,
                              isTouchScreen: isTouchScreen()
                            }}
                            deleteACatalog={deleteCatalog}
                          />
                          {catalogs.length > 0 && (pageDetails?.page?.hasNextPage || pageDetails?.page?.number > 1) &&
                            <div className={styles.paginationContainer}>
                              <Pagination pageDetails={pageDetails} />
                            </div>
                          }
                        </>
                      )
                      : !loading && catalogs && Array.isArray(beCatalogs) && beCatalogs.length === 0
                        ? <div className={styles.noCatalogsContainer}>
                          <NotFound errMsg={t('no-catalogs-in-folder')} />)
                        </div>
                        : null
                  }
                </div>
              )
              : <ComponentLoaderTri />
          }
        </div>
      )
      : (
        <NotFound errMsg={'invalid-folder-id'} />
      )
  )
}

export default myImageCatalogs
