/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './style.module.scss'
import UpperSegment from '../../../organisms/UpperSegment/Index'
import InteractionArea from '../../../organisms/InteractionArea/Index'
import PropTypes from 'prop-types'
import { updateCatalog } from 'Redux/contentUpload/contentUploadSlice'
import { errorToast, successToast } from 'toasts/Toasts'

function MetaDataForm ({ arrayOfImages, setShowMetaDataForm, uploadContent, selectedType, editMode, cancel, backFn }) {
  const DEFAULT_CONTENT_STATE = {
    contributers: '',
    name: '',
    folder: {},
    isPublic: undefined,
    wordTags: [],
    orientation: [],
    people: [],
    motive: [],
    color: [],
    style: [],
    background: [],
    contentDescription: '',
    assetType: selectedType
  }

  const isUploading = useRef(false)

  const [imgState, setImgState] = useState([])
  const [phaseState, setPhase] = useState(1)
  const [atLeastOneSelected, setAtLeastOneSelected] = useState(false)
  const [contentState, setContentState] = useState(DEFAULT_CONTENT_STATE)
  const [errorsList, setErrorsList] = useState(false)
  const { uploading } = useSelector(state => state.contentUpload)
  let { folders } = useSelector((state) => state.folders);
  folders = folders.filter((folder) => folder.parent?.toLowerCase() === selectedType?.toLowerCase())
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth?.user);
  const [currentUserRole, setCurrentUserRole] = useState({});

  useEffect(() => {
    const role = user?.roles?.length > 0 ? user?.roles[0] : '';
    setCurrentUserRole(role);
  }, [user])

  const dataValidity = (data, index) => {
    const { name, folders, word_tags, files, contributor, assest_type, is_public } = data
    if (!name || !folders[0] || !contributor || !word_tags.length || !assest_type || is_public === undefined) {
      const prevImg = { ...imgState[index] } || null
      if (prevImg) {
        if (!name) {
          prevImg.errors = { ...prevImg.errors, name: true }
        }
        if (!folders[0]) {
          prevImg.errors = { ...prevImg.errors, folders: true }
        }
        if (!contributor) {
          prevImg.errors = { ...prevImg.errors, contributor: true }
        }
        if (!word_tags.length) {
          prevImg.errors = { ...prevImg.errors, word_tags: true }
        }
        if (!assest_type) {
          prevImg.errors = { ...prevImg.errors, assest_type: true }
        }
        if (is_public === undefined) {
          prevImg.errors = { ...prevImg.errors, is_public: true }
        }
        setImgState(prev => {
          prev[index] = prevImg
          return [...prev]
        })
      }
      return false
    }
    return true
  }

  useEffect(() => {
    if (!editMode) {
      const NewArrOfImages = arrayOfImages.map(el => {
        return {
          id: el.id,
          uriData: el.src,
          data: { ...DEFAULT_CONTENT_STATE },
          exifData: el.exifData,
          active: false,
          errors: null
        }
      })
      setImgState(NewArrOfImages)
    } else {
      const NewArrOfImages = arrayOfImages.map(el => {
        return {
          id: el.id || el._id,
          uriData: el.src?.thumbnail,
          data: {
            contributers: el.contributor || '',
            name: el.name || '',
            folder: folders?.find(f => f.id === el.folders[0]) || {},
            isPublic: el.is_public,
            wordTags: el.word_tags?.split(',') || [],
            style: el.style?.split(',') || [],
            orientation: el.orientation?.split(',') || [],
            background: el.background?.split(',') || [],
            motive: el.motive?.split(',') || [],
            color: el.color?.split(',') || [],
            people: el.people?.split(',') || [],
            contentDescription: el.content_description || '',
            assetType: el.assest_type || ''
          },
          active: true
        }
      })
      setImgState(NewArrOfImages)
      setPhase(2)
      setContentState(NewArrOfImages[0].data)
    }
  }, [])

  useEffect(() => {
    if (imgState.length) {
      let anyActive = 0
      imgState.forEach(el => {
        if (el.active) {
          anyActive += 1
        }
      })
      setAtLeastOneSelected(anyActive)
    }
  }, [imgState])

  const onChangeData = changes => {
    const filterSelection = { ...contentState }
    let newObj = {}
    const key = changes.category
    if (changes.selected) {
      const newArr = [...filterSelection[key], changes.value]
      newObj = { ...filterSelection, [key]: newArr }
    } else {
      const newArr = filterSelection[key].filter(
        item => item !== changes.value
      )
      newObj = { ...filterSelection, [key]: newArr }
    }
    setContentState(newObj)
  }

  const submitData = async () => {
    let queue = [];
    [...imgState].forEach((element, index) => {
      const submittableElement = element.active ? { ...element, data: { ...contentState }, active: false } : element
      const {
        wordTags,
        name,
        contentDescription,
        contributers,
        orientation,
        folder,
        people,
        motive,
        style,
        background,
        isPublic,
        assetType,
        color
      } = submittableElement.data
      try {
        const data = !editMode
          ? {
            name,
            contributor: contributers,
            assest_type: assetType,
            orientation,
            word_tags: wordTags,
            content_description: contentDescription,
            folders: [folder.id],
            files: [element.uriData],
            is_public: isPublic,
            background,
            color,
            people,
            motive,
            style,
            tempId: element.id
          }
          : {
            name,
            contributor: contributers,
            assest_type: assetType,
            word_tags: wordTags.join(','),
            content_description: contentDescription,
            folders: [folder.id],
            is_public: isPublic,
            color: color.join(','),
            orientation: orientation.join(','),
            people: people.join(','),
            motive: motive.join(','),
            style: style.join(','),
            background: background.join(','),
            tempId: element.id
          }
        if (dataValidity(data, index)) {
          queue = [...queue, data]
        } else {
          return null;
        }
      } catch {
        errorToast('Something went wrong')
      }
    })
    try {
      if (queue.length !== imgState.length) {
        errorToast('Please fill all the required fields')
        return
      }
      if (isUploading.current) {
        errorToast('Please wait while the content is being uploaded')
        return
      }
      if (!editMode && !isUploading.current) {
        isUploading.current = true
        uploadContent(queue, () => {
          isUploading.current = false
        })
      } else {
        try {
          dispatch(updateCatalog(queue[0]))
          setShowMetaDataForm(false)
          successToast('Content updated successfully')
        } catch (error) {
          errorToast('Something went wrong')
        }
      }
    } catch {
      errorToast('Something went wrong')
    }
  }

  function splitAndFlatten (arr) {
    return arr?.reduce((acc, str) => {
        return acc?.concat(str?.split(',')?.map(s => s?.trim()));
    }, []);
}

  const goNextPhase = () => {
    setPhase(prev => prev + 1)
    setImgState(prev => prev.map(el => {
      if (el?.usedExif) {
      return el.active ? { ...el, data: { ...contentState }, active: false } : el
      }
      const { exifData } = el;
      const { ObjectName, Keywords, Caption } = exifData || {};
      const possibleName = ObjectName?.trim()?.replace(/undefined/g, '') || null;
      const possibleTags = Keywords ? splitAndFlatten(Keywords)?.map(el => typeof el === 'string' ? el?.trim() : el) : [];
      let possibleDescription = Caption?.trim()?.replace(/undefined/g, '') || null;
      const arrayOfTags = possibleTags?.filter((tag, indx) => {
        // if composed of one word
        if (tag?.split(' ').length === 1) {
          return tag;
        } else {
          possibleDescription = possibleDescription ? (possibleDescription + ' ' + tag) : tag;
          return null;
        }
      });
      const prevName = (contentState?.name || null);
      const prevTags = contentState?.wordTags || [];
      const prevDescription = contentState?.contentDescription || null;
      const newName = prevName && possibleName ? (prevName + '-' + possibleName) : prevName || possibleName;
      const newTags = [...prevTags, ...arrayOfTags];
      const cleanTags = newTags.filter((tag, indx) => newTags.indexOf(tag) === indx && tag?.trim());
      const newDescription = prevDescription && possibleDescription ? (prevDescription + ' ' + possibleDescription) : prevDescription || possibleDescription;
      if (el.active) {
        return {
          ...el,
          data: {
            ...contentState,
            name: newName,
            wordTags: cleanTags,
            contentDescription: newDescription
          },
          active: false,
          usedExif: true
        }
      } else {
        return {
          ...el,
          data: {
            ...el?.data,
            name: newName,
            wordTags: cleanTags,
            contentDescription: newDescription
          },
          usedExif: true
        }
      }
    }))
  }

  return (
    <div className={style.wrapper}>
      <section className={editMode ? `${style.upperImgsSegment} ${style.inEditMode}` : style.upperImgsSegment}>
        <UpperSegment
          imgState={imgState}
          setImgState={setImgState}
          selectedN={atLeastOneSelected}
          phaseState={phaseState}
          contentState={contentState}
          setContentState={setContentState}
          editMode={editMode}
        />
      </section>
      <section className={editMode ? `${style.interactionArea} ${style.inEditMode}` : style.interactionArea}>
        {atLeastOneSelected
          ? (
            <InteractionArea
              contentState={contentState}
              setContentState={setContentState}
              onChangeData={onChangeData}
              phaseState={phaseState}
              folders={folders}
              setImgState={setImgState}
              imgState={imgState}
              currentUserRole={currentUserRole}
            />
          )
          : (
            <div className={style.noImgSelected}>
              {
                phaseState === 1
                  ? (
                    <p>
                      select item or multiple items <br />
                      to add details
                    </p>
                  )
                  : (
                    <p>
                      You have added general details to each asset, in the previous step <br />
                      Now you can add specific details to each asset <br />
                      Once you have added details to all assets, you can submit
                    </p>
                  )
              }
            </div>
          )}
      </section>
      <section className={style.uploadSection}>
        <div className={style.left_container}>
          <button
            type='button'
            onClick={() => cancel ? cancel() : setShowMetaDataForm(null)}
            className={`${style.cancel}`}
          >
            Cancel
          </button>
        </div>
        <div className={style.right_container}>
          <button
            type='submit'
            className={
              atLeastOneSelected
                ? `${style.submitBtn}`
                : `${style.submitBtn} ${style.inactive}`
            }
            onClick={() => phaseState === 1 ? goNextPhase() : submitData()}
          >
            {phaseState === 1 ? 'Next' : editMode ? 'Finish' : 'Submit'}
          </button>
          {
            !editMode &&
            <button
              type='button'
              onClick={() => (backFn && phaseState === 1) ? backFn() : (backFn && phaseState !== 1) ? setPhase(1) : setShowMetaDataForm(null)}
              className={`${style.cancel}`}
            >
              Back
            </button>
          }
        </div>
      </section>
    </div>
  )
}

MetaDataForm.propTypes = {
  arrayOfImages: PropTypes.array,
  setShowMetaDataForm: PropTypes.func,
  uploadContent: PropTypes.func,
  selectedType: PropTypes.string,
  editMode: PropTypes.bool,
  cancel: PropTypes.func,
  backFn: PropTypes.func
}

export default MetaDataForm
