import getMenuItem from '../../../pages/MySearchPage/functions/getMenuItem';
const activateMenuItem = (menuItem, refs, dispatch) => {
    const { catalogRef, newReleaseRef, popularRef, navigate } = refs;
    const catalog = catalogRef.current;
    const newRelease = newReleaseRef.current;
    const popular = popularRef.current;
    const menuItems = { catalog, newRelease, popular, navigate };
    const link = `/mpublisher/search/result&name=${menuItem}`;
    const menuData = { menuItems, dispatch, navigate, menuItem, link };
    return getMenuItem(menuData);
};

export default activateMenuItem;
