/* eslint-disable react/prop-types */
import React from 'react';
// import darkBackground from './assets/images/img1.png';
import styles from './middleArea.module.scss';

export default function MiddleArea ({
  img,
  templateView,
  filterStyle,
  lightEditing
}) {
  return (
    <div
      className={templateView ? styles.fullSize : styles.imgArea}
    // style={{ backgroundImage: `url(${img})` }}
    >
      <img
        src={img}
        alt="template-image"
        style={lightEditing ? filterStyle : {}}
      />
      <div className={styles.bottomLeftText}>
      </div>
    </div>
  );
}
