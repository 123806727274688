import axiosInstance from '../axios/axiosInstance'

export async function createCollectionsAPI (data) {
  const cleanCollectionsArray = [...data].map((collection) => {
    const noIdData = { ...collection }
    noIdData.id && delete noIdData.id
    noIdData.selected !== undefined && delete noIdData.selected
    noIdData.new !== undefined && delete noIdData.new
    return noIdData
  })
  const response = await axiosInstance.post(
    '/collection',
    cleanCollectionsArray,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function createCollectionsFromCatalogPopupAPI (data) {
  const id = data.catalog_id
  const newData = { ...data }
  delete newData.catalog_id
  const response = await axiosInstance.post(
    `/collection/${id}`,
    newData,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getAllCollectionsAPI () {
  const response = await axiosInstance.get(
    '/collection',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getCollectionByIdAPI (id) {
  const response = await axiosInstance.get(
    '/collection',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function updateCollectionsAPI (collection) {
  const data = { ...collection }
  data.userId !== undefined && delete data.userId
  data.id !== undefined && delete data.id
  data._id !== undefined && delete data._id
  data.createdAt !== undefined && delete data.createdAt
  data.updatedAt !== undefined && delete data.updatedAt
  data.__v !== undefined && delete data.__v 
  data.selected !== undefined && delete data.selected
  const response = await axiosInstance.patch(
    `/collection/${collection.id}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function deleteCollectionsAPI (id) {
  const responseOne = await axiosInstance.delete(
    `/collection/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  if (responseOne.status === 200 || responseOne.status === 201) {
    return responseOne
  }
  return null
}

// collections folders

export async function getAllCollectionFoldersAPI () {
  const response = await axiosInstance.get(
    '/collectionfolder',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getCollectionFolderByIdAPI (id) {
  const response = await axiosInstance.get(
    `/collectionfolder/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getCollectionFolderCountByIdAPI (id) {
  const response = await axiosInstance.get(
    `/collection/folder/${id}?only-length=true`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getCollectionsByFolderIdAPI (id) {
  const response = await axiosInstance.get(
    `/collection/folder/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function addNewCollectionFolderAPI (data) {
  const response = await axiosInstance.post(
    '/collectionfolder',
    data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function editCollectionFolderAPI (id, data) {
  const response = await axiosInstance.patch(
    `/collectionfolder/${id}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function deleteCollectionFolderAPI (id) {
  const response = await axiosInstance.delete(
    `/collectionfolder/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

// shared collections

export async function getCollectionsSharedByMeAPI () {
  const response = await axiosInstance.get(
    '/share-content/me',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getCollectionsSharedWithMeAPI () {
  const response = await axiosInstance.get(
    'share-content/with-me',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function shareCollectionAPI (data) {
  const response = await axiosInstance.post(
    '/share-content',
    data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function shareCollectionByLinkAPI (data) {
  const response = await axiosInstance.post(
    '/share-content/share-link',
    data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function deleteShareCollectionAPI (id) {
  const response = await axiosInstance.delete(
    `/share-content/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}
