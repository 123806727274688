/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import SinglePreviewPopup from '../../../myImageCatalogs/components/PopupManger/SinglePreviewPopup'

const index = ({ folderData, updateFolderData, exitPopup, popupData }) => {
  const allCatalogs = popupData?.collection?.catalogsData || []
  const [previewPopup, setPreviewPopup] = useState({
    state: 1,
    data: allCatalogs[0]
  })
  useEffect(() => {
    if (!previewPopup) {
      exitPopup()
    }
  }, [previewPopup])
  return (
    <>
      <SinglePreviewPopup previewPopup={previewPopup} allCatalogs={allCatalogs} setPreviewPopup={setPreviewPopup} />
    </>
  )
}

export default index
