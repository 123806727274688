import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  hardDeleteCatalog,
  restoreFile,
  softDeleteFile,
  getDeletedFiles,
  restoreAllFiles,
  bulkDeleteCatalogs
} from 'api/trashAPI'
import { errorToast, successToast } from 'toasts/Toasts'

export const getDeletedCatalog = createAsyncThunk(
  'catalog/getDeletedFiles',
  async () => {
    const response = await getDeletedFiles()
    return response.data
  }
)

export const softDeleteCatalog = createAsyncThunk(
  'catalog/softDeleteCatalog',
  async (id) => {
    const response = await softDeleteFile(id)
    return response.data
  }
)

export const hardDeleteCatalogThunk = createAsyncThunk(
  'catalog/hardDeleteCatalog',
  async id => {
    const response = await hardDeleteCatalog(id)
    return response.data
  }
)

export const restoreFileById = createAsyncThunk(
  'catalog/restoreFile',
  async id => {
    const response = await restoreFile(id)
    return response.data
  }
)

export const restoreAllFilesInTrash = createAsyncThunk(
  'catalog/restoreAllFiles',
  async () => {
    const response = await restoreAllFiles()
    return response.data
  }
)

export const bulkDeleteCatalogsInTrash = createAsyncThunk(
  'catalog/bulkDeleteCatalogs',
  async () => {
    const response = await bulkDeleteCatalogs()
    return response?.data
  }
)

const initialState = {
  data: [],
  loading: false,
  hardDeleteStatus: false,
  bulkDeleteCatalogsStatus: false,
  restoreFileStatus: false,
  error: null,
  allFilesRestorationStatus: false
}

export const trash = createSlice({
  name: 'trash',
  initialState,
  extraReducers (builder) {
    builder.addCase(softDeleteCatalog.pending, state => {
      state.loading = false
    })

    builder
      .addCase(getDeletedCatalog.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getDeletedCatalog.fulfilled, (state, action) => {
        state.data = action.payload.data.result
        state.loading = false
      })
      .addCase(getDeletedCatalog.rejected, (state, action) => {
        state.loading = false
      })

    builder
      .addCase(hardDeleteCatalogThunk.pending, state => {
        state.hardDeleteStatus = true
      })
      .addCase(hardDeleteCatalogThunk.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          item => item.id === action.payload.data.id
        )
        if (index !== -1) {
          state.data.splice(index, 1)
        }
        state.hardDeleteStatus = false
        successToast('Item deleted permanently')
      })
      .addCase(hardDeleteCatalogThunk.rejected, (state, action) => {
        state.hardDeleteStatus = false
        state.error = action.payload
        errorToast('Failed to delete item permanently')
      })

    builder
      .addCase(restoreFileById.pending, state => {
        state.restoreFileStatus = false
      })

      .addCase(restoreFileById.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          item => item.id === action.payload.data.id
        )
        if (index !== -1) {
          state.data.splice(index, 1)
        }
        state.restoreFileStatus = false
        successToast('File restored')
      })

      .addCase(restoreFileById.rejected, state => {
        state.restoreFileStatus = false
        errorToast('Failed to restore file')
      })

    builder
      .addCase(restoreAllFilesInTrash.pending, state => {
        // update loading state
        state.allFilesRestorationStatus = true
      })
      .addCase(restoreAllFilesInTrash.fulfilled, (state, action) => {
        state.allFilesRestorationStatus = false
        state.data = action.payload?.data?.result
        successToast('All files restored')
      })
      .addCase(restoreAllFilesInTrash.rejected, (state, action) => {
        state.allFilesRestorationStatus = false
        state.error = action.payload
        errorToast('Failed to restore all files')
      })

    builder
      .addCase(bulkDeleteCatalogsInTrash.pending, state => {
        state.bulkDeleteCatalogsStatus = true
      })
      .addCase(bulkDeleteCatalogsInTrash.rejected, (state, action) => {
        state.bulkDeleteCatalogsStatus = false
        state.error = action.payload
      })
      .addCase(bulkDeleteCatalogsInTrash.fulfilled, (state, action) => {
        state.bulkDeleteCatalogsStatus = false
        state.data = []
        successToast('Catalogs deleted')
      })
  }
})

export default trash.reducer
