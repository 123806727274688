import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCatalog, uploadingInitiated, addToUploadingQueue } from 'Redux/contentUpload/contentUploadSlice.js';
import { prepareCatalogsAPI } from 'api/contentUploadAPI';
import { errorToast } from 'toasts/Toasts';

function useContentUpload () {
    const [uploadModalVisible, setUploadModalVisibility] = useState(false);
    const [accordionItems, setAccordionItems] = useState('mycontent');
    const [images, setImages] = useState([])
    const [imageURL, setImageURL] = useState('');
    const [showUploadOptionsModal, setShowUploadOptionsModal] = useState(false);
    const [showMetaDataForm, setShowMetaDataForm] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const dispatch = useDispatch();

    const uploadContent = async (queue, done) => {
        try {
            const preparedCatsRes = await prepareCatalogsAPI(queue);
            const preparedCats = preparedCatsRes?.data?.data;
            if (!preparedCats || preparedCats?.length !== queue?.length) {
                errorToast('An error occurred, we could not upload your content')
                return;
            }
            dispatch(uploadingInitiated())
            setImageURL('')
            setImages([])
            queue.forEach((file, idx) => {
                dispatch(addToUploadingQueue({
                    id: file.tempId,
                    name: file.name,
                    type: file.assest_type,
                    uploading: true,
                    onCancel: null,
                    progress: 0
                }))
                dispatch(createCatalog({ ...file, id: preparedCats[idx]?.id }));
            })
        } catch (rejectedValueOrSerializedError) {
            errorToast('An error occurred, we could not upload your content')
        }
        setShowMetaDataForm(false);
        try {
            closeFn();
        } catch (e) {
            console.log(e);
        }
        done();
    }

    const closeFn = () => {
        setSelectedType(null);
        setUploadModalVisibility(false);
        setShowUploadOptionsModal(false);
        setShowMetaDataForm(false);
        setImageURL('')
        setImages([])
    }

    const backFn = () => {
        setShowUploadOptionsModal(true);
        setShowMetaDataForm(false);
    }
    
    const data = {
        setShowMetaDataForm,
        setShowUploadOptionsModal,
        imageURL,
        setImages,
        setUploadModalVisibility,
        showUploadOptionsModal,
        images,
        uploadModalVisible,
        showMetaDataForm,
        setImageURL,
        setSelectedType,
        accordionItems,
        setAccordionItems,
        selectedType,
        uploadContent,
        backFn,
        closeFn
    }
    return data;
}

export default useContentUpload;
