/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Plus } from '../../../../../assets/icons/plus.svg';
import CollectionCard from './CollectionCard';
import CollectionCardForSharedData from './CollectionCardForSharedData';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const index = ({ collections, openPopup, setInteractionPopups, noAdding, getACollectionCatalogs, searchInput, mainView, currentDraggedElement, handleDragEnd, collectionFolderId, isShared, allCollections }) => {
  const handleInteraction = (state, collection) => {
    setInteractionPopups({
      state,
      collection
    })
  }
  const { userdata } = useSelector((state) => state.userdata);
  const reduxcollections = useSelector((state) => state?.collections?.collections);
  const { t } = useTranslation();
  const collectionsCount = reduxcollections?.length || 0;
  const collectionLimit = userdata?.monthlyLimits?.collections || 0
  const canAddcollections = collectionsCount < collectionLimit
  return (
    <div className={styles.itemsWrapper} style={{ marginTop: '10px' }}>
      {
        (collections.length === 0)
          ? null
          : !isShared && collections.length
            ? collections.map((collection) => {
              return (
                <CollectionCard
                  key={collection.id}
                  searchInput={searchInput}
                  collection={collection}
                  getACollectionCatalogs={getACollectionCatalogs}
                  handleInteraction={handleInteraction}
                  mainView={mainView}
                  currentDraggedElement={currentDraggedElement}
                  handleDragEnd={handleDragEnd}
                  collectionFolderId={collectionFolderId}
                />
              )
            })
            : collections.length && collections.map((collection) => {
              return (
                <CollectionCardForSharedData
                  key={collection?.id || collection?._id}
                  searchInput={searchInput}
                  allSimilarCollections={allCollections.filter((el) => el.collectionId === collection.collectionId)}
                  collection={collection}
                  getACollectionCatalogs={getACollectionCatalogs}
                  handleInteraction={handleInteraction}
                  mainView={mainView}
                  currentDraggedElement={currentDraggedElement}
                  handleDragEnd={handleDragEnd}
                  collectionFolderId={collectionFolderId}
                />
              )
            })
      }
      {
        !noAdding && (
          <div className={`${styles.itemFolderContainer} ${styles.addBtnType} ${!canAddcollections ? styles.disabled : ''}`} onClick={() => canAddcollections && openPopup(true)}>
            <div className={styles.addNewCollectionBtnContainer} onClick={() => { return null /* should trigger popup */ }}>
              <button className={styles.addNewCollectionBtn}><span></span> <Plus /> {t('Create a new collection')}</button>
            </div>
          </div>
        )
      }
    </div>

  )
}

export default index
