import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true
});

// Add an interceptor to include the JWT in the request headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth-token');
    const companyId = localStorage.getItem('company-id');
    const tier = localStorage.getItem('tier');

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        companyId,
        tier
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response) {
      switch (error?.response?.status) {
        case 401:
          // if (
          //   error?.response?.data?.error.includes('Tier mismatch') ||
          //   error?.response?.data?.error.includes('profile is inactive') ||
          //   error?.response?.data?.error.includes('Subscription expired')
          // ) {
          //   window.location.replace(`${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${window.location}`)
          // }
          break;
        case 404:
          break;

        default:
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
