/* eslint-disable react/prop-types */
import React from 'react';
import styles from './bottomArea.module.scss';

export default function BottomArea ({ description, dimensions, fileType, contributor }) {
  return (
    <div className={styles.bottomAreaGrid}>
      <div className={styles.bottomAreaGridChild}>
        {description}
      </div>
      <div className={styles.bottomAreaGridChild}>
        <div>
          <p>Contributor</p> <br />
          <p className={styles.orangeText}>{contributor || 'User'}</p>
        </div>
      </div>
      <div className={styles.bottomAreaGridChild}>
        DIMENSIONS <br />
        {`${dimensions?.width}px`} x {`${dimensions?.height}px`}
      </div>
      <div className={styles.bottomAreaGridChild}>
        File Type <br />
        {fileType || 'JPEG'}
      </div>
    </div>
  );
}
