import image from '../../../../../assets/images/homeContent/recommendations/img_4.png';
import image1 from '../../../../../assets/images/homeContent/recommendations/img_2.png';
import image2 from '../../../../../assets/images/homeContent/recommendations/img_1.png';
import image3 from '../../../../../assets/images/homeContent/recommendations/img_5.png';
import image4 from '../../../../../assets/images/homeContent/recommendations/img_3.png';
import image5 from '../../../../../assets/images/homeContent/recommendations/img.png';

const getRecommendations = () => {
 return [
        {
            image,
            id: 1
        },
        {
            image: image1,
            id: 2
        },
        {
            image: image2,
            id: 3
        },
        {
            image: image3,
            id: 4
        },
         {
             image: image4,
             id: 5
         },
         {
             image: image5,
             id: 6
         }
    ];
};

export default getRecommendations;
