/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useLayoutEffect } from 'react'
import ImageLoader from './ImageLoader';
import FSImageLoader from './FSImageLoader';
import VideoLoader from './VideoLoader';

const index = ({ previewPopup, allCatalogs, setPreviewPopup }) => {
  const { state, data } = previewPopup || {}
  const [previewIndex, setPreviewIndex] = useState(0)
  const [previewFullScreen, setPreviewFullScreen] = useState(false)
  const [previewLoadingNext, setPreviewLoadingNext] = useState(true)
  const [showcaseOnFullScreen, setShowcaseOnFullScreen] = useState(false)

  const handleResize = () => {
    if (state === 1 && window.innerWidth < 800) {
      setPreviewPopup(prev => { return { ...prev, state: 5 } })
    }
  }

  const currentCatalog = allCatalogs[previewIndex] || allCatalogs[0]
  const catalogType = currentCatalog?.assest_type
  console.log('catalogType', catalogType)
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [state])

  useLayoutEffect(() => {
    if (data) {
      setPreviewIndex(data.index)
    }
  }, [data])

  useEffect(() => {
    if (document.exitFullscreen && previewFullScreen && !previewPopup) {
      document.exitFullscreen()
    }
  }, [previewPopup, previewFullScreen])

  useEffect(() => {
    if (state === 1 || state === 5) {
      document.addEventListener('keydown', changePreviewPicture)
      setPreviewLoadingNext(true)
      return () => {
        document.removeEventListener('keydown', changePreviewPicture)
      }
    }
  }, [state, previewIndex])

  const changeToFullScreen = (value) => {
    if (value) {
      setPreviewFullScreen(true)
      const elem = document.getElementById('root')
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      }
    } else {
      setPreviewFullScreen(false)
      if (document?.exitFullscreen && previewFullScreen) {
        try {
          document?.exitFullscreen()
        } catch {
          return null
        }
      }
    }
  }

  const changePreviewPicture = (e) => {
    e.preventDefault()
    if (e.key === 'ArrowRight' && previewIndex < allCatalogs.length - 1) {
      setPreviewIndex(previewIndex + 1)
      setPreviewLoadingNext(true)
    } else if (e.key === 'ArrowLeft' && previewIndex > 0) {
      setPreviewIndex(previewIndex - 1)
      setPreviewLoadingNext(true)
    } else if (e.key === 'Escape') {
      e.preventDefault()
      setPreviewFullScreen(false)
      setPreviewPopup(null)
    }
  }

  const handleChangeOnFullScreen = (data) => {
    setPreviewPopup(prev => { return { ...prev, data } })
    setShowcaseOnFullScreen(false)
    setPreviewLoadingNext(true)
  }

  const tags = currentCatalog?.word_tags?.split(',') || [];

  return (
    <>
      {
        state === 1
          ? (
            <>
              {(catalogType === 'Images' || catalogType === 'Templates')
                ? (
                  <ImageLoader
                    setPreviewLoadingNext={setPreviewLoadingNext}
                    previewLoadingNext={previewLoadingNext}
                    currentCatalog={currentCatalog}
                    tags={tags}
                    previewIndex={previewIndex}
                    setPreviewIndex={setPreviewIndex}
                    allCatalogs={allCatalogs}
                    setPreviewPopup={setPreviewPopup}
                    changeToFullScreen={changeToFullScreen}
                  />
                )
                : catalogType === 'Videos' && (
                  <VideoLoader
                    setPreviewLoadingNext={setPreviewLoadingNext}
                    previewLoadingNext={previewLoadingNext}
                    currentCatalog={currentCatalog}
                    tags={tags}
                    previewIndex={previewIndex}
                    setPreviewIndex={setPreviewIndex}
                    allCatalogs={allCatalogs}
                    setPreviewPopup={setPreviewPopup}
                    changeToFullScreen={changeToFullScreen}
                  />
                )}
            </>
          )
          : (
            <FSImageLoader
              setPreviewLoadingNext={setPreviewLoadingNext}
              previewLoadingNext={previewLoadingNext}
              currentCatalog={currentCatalog}
              previewIndex={previewIndex}
              setPreviewIndex={setPreviewIndex}
              allCatalogs={allCatalogs}
              setPreviewPopup={setPreviewPopup}
              changeToFullScreen={changeToFullScreen}
              showcaseOnFullScreen={showcaseOnFullScreen}
              setShowcaseOnFullScreen={setShowcaseOnFullScreen}
              previewFullScreen={previewFullScreen}
              handleChangeOnFullScreen={handleChangeOnFullScreen}
            />
          )
      }
    </>
  )
}

export default index
