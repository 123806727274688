/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import Popup from 'components/atoms/Popup/Popup';
import AddPopup from '../FilesContainer/AddPopup';
import DeletePopup from '../SharedPopups/DeletePopup'
import SharePopup from '../SharedPopups/SharePopup'
import ManageSharePopup from '../SharedPopups/ManageSharePopup'
import ManageSharePopupAccess from '../SharedPopups/ManageSharePopupAccess'
import CollectionPopup from '../SharedPopups/CollectionPopup'
import ViewPopup from '../SharedPopups/ViewPopup'
import ManageDeletePopup from '../SharedPopups/ManageDeletePopup'
import DeleteDraftPopup from '../SharedPopups/DeleteDraftPopup'

const MiniPopupManager = ({
  interactionPopups, addCollectionPopup, setAddCollectionPopup,
  setInteractionPopups, manualDeleteCollectionFromMain,
  addPopup, setAddPopup, onSubmitAddFolder, handleEditFolder, handleDeleteFolder, folderData, getAllData, AllDelete, deleteShare, canAddCollection
}) => {
  return (
    <>
      {(interactionPopups?.state === 3) && (
        <Popup
          closePopup={() => addCollectionPopup ? setAddCollectionPopup(false) : interactionPopups?.state ? setInteractionPopups(null) : null}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            maxWidth: '550px',
            height: '250px'
          }}
        >
          {
            AllDelete
              ? <DeletePopup folderData={folderData} updateFolderData={getAllData} popupData={interactionPopups} exitPopup={() => setInteractionPopups(null)} deleteShare={deleteShare} />
              : <DeletePopup mainView={true} folderData={{}} updateFolderData={manualDeleteCollectionFromMain} popupData={interactionPopups} exitPopup={() => setInteractionPopups(null)} />
          }
        </Popup>
      )}
      {(interactionPopups?.state === 2) && (
        <Popup
          closePopup={() => addCollectionPopup ? setAddCollectionPopup(false) : interactionPopups?.state ? setInteractionPopups(null) : null}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            height: 'auto',
            maxWidth: '900px'
          }}
        >
          <SharePopup popupData={interactionPopups} exitPopup={() => setInteractionPopups(null)} />
        </Popup>
      )}
      {((interactionPopups?.state === 4) || (interactionPopups?.state === 5)) && (
        <Popup
          closePopup={() => addCollectionPopup ? setAddCollectionPopup(false) : interactionPopups?.state ? setInteractionPopups(null) : null}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            height: 'auto',
            maxWidth: '900px'
          }}
        >
          <ManageSharePopup popupData={interactionPopups.collection} exitPopup={() => setInteractionPopups(null)} handleInteraction={(state, collection) => {
            setInteractionPopups({
              state,
              collection
            })
          }} />
        </Popup>
      )}
      {addCollectionPopup &&
        <Popup
          closePopup={() => addCollectionPopup ? setAddCollectionPopup(false) : interactionPopups?.state ? setInteractionPopups(null) : null}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            height: '60%',
            maxWidth: '900px',
            maxHeight: '900px'
          }}
        >
          {addCollectionPopup && !interactionPopups && <CollectionPopup createOnly={!canAddCollection} exitPopup={() => setAddCollectionPopup(false)} folderData={folderData} updateFolderData={getAllData} />}
        </Popup>
      }
      {addPopup && (
        <Popup
          closePopup={() => setAddPopup(null)}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            maxWidth: '600px'
          }}
        >
          <AddPopup
            onSubmit={onSubmitAddFolder}
            closePopup={() => setAddPopup(null)}
            popupData={addPopup}
            handleEditFolder={handleEditFolder}
            handleDeleteFolder={handleDeleteFolder}
          />
        </Popup>
      )}
      {interactionPopups?.state === 1 && (
        <Popup closePopup={() => { }} transparent={true} extraStyles={{
          width: '100%',
          height: '100%',
          background: 'transparent',
          userSelect: 'none'
        }}>
          <ViewPopup folderData={folderData} updateFolderData={getAllData} popupData={interactionPopups} exitPopup={() => setInteractionPopups(null)} />
        </Popup>
      )}
      {(interactionPopups?.state === 5) && (
        <Popup
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            maxWidth: '550px',
            height: '250px'
          }}
        >
          <ManageDeletePopup
            updateFolderData={manualDeleteCollectionFromMain}
            popupData={interactionPopups}
            setInteractionPopups={setInteractionPopups}
          />
        </Popup>
      )}
      {((interactionPopups?.state === 6)) && (
        <Popup
          closePopup={() => addCollectionPopup ? setAddCollectionPopup(false) : interactionPopups?.state ? setInteractionPopups(null) : null}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            height: 'auto',
            maxWidth: '900px'
          }}
        >
          <ManageSharePopupAccess popupData={interactionPopups.collection} exitPopup={() => setInteractionPopups(null)} handleInteraction={(state, collection) => {
            setInteractionPopups({
              state,
              collection
            })
          }} />
        </Popup>
      )}
      {((interactionPopups?.state === 7)) && (
        <Popup
          closePopup={() => addCollectionPopup ? setAddCollectionPopup(false) : interactionPopups?.state ? setInteractionPopups(null) : null}
          bgColor={'#232323'}
          extraStyles={{
            borderRadius: '16px',
            width: '90%',
            height: '250px',
            maxWidth: '600px'
          }}
        >
          <DeleteDraftPopup
          exitPopup={() => setInteractionPopups(null)}
          popupData={interactionPopups}
          />
        </Popup>
      )}
    </>
  );
};

export default MiniPopupManager;
