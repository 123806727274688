import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createCatalogAPI, featureCatalogAPI, updateCatalogAPI, updateCatalogCollectionAPI } from 'api/contentUploadAPI';
import { manualAddCatalog, manualCatalogUpdate } from '../content/contentSlice';
import axiosInstance from '../../axios/axiosInstance'
// import { getCatalog } from 'Redux/content/contentSlice';
import { getUserData } from 'Redux/userdata/userdataSlice';

// First, create the thunk
export const createCatalog = createAsyncThunk(
  'catalog/createCatalog',
  async (data, thunkAPI) => {
      const cancelToken = axiosInstance?.CancelToken?.source() || null;
    try {
      const response = await createCatalogAPI(
        data,
        (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded / total) * 100);
          thunkAPI.dispatch(
            updateUploadingQueueProgress({ id: data.tempId, progress, cancelToken: cancelToken?.token?.token })
          );
        },
        cancelToken?.token
      );
      if (response.data || response.status === 200 || response.status === 201) {
        thunkAPI.dispatch(
          updateUploadingQueueProgress({ id: data.tempId, finished: true })
        );
        thunkAPI.dispatch(
          manualAddCatalog(response.data.data)
        )
        thunkAPI.dispatch(getUserData());
      } else {
        thunkAPI.dispatch(
          updateUploadingQueueProgress({ id: data.tempId, error: 'failed to upload' })
        );
      }
      return response.data;
    } catch (e) {
      thunkAPI.dispatch(
        updateUploadingQueueProgress({ id: data.tempId, error: e.message || 'failed to upload' })
      );
    }
  }
);

export const updateCatalog = createAsyncThunk(
  'catalog/updateCatalog',
  async (data, thunkAPI) => {
    const response = await updateCatalogAPI(data);
    if ((response.status === 200 || response.status === 201) && response.data.data && !response.data.data.message) {
      const updatedCatalog = response.data.data;
      thunkAPI.dispatch(manualCatalogUpdate(updatedCatalog));
    }
  }
);

export const featureCatalog = createAsyncThunk(
  'catalog/featureCatalog',
  async (data, thunkAPI) => {
    const { payload, catalog } = data;
    const { id, _id } = catalog;
    const response = await featureCatalogAPI((id || _id), payload);
    if ((response.status === 200 || response.status === 201) && response.data.data && !response.data.data.message) {
      thunkAPI.dispatch(manualCatalogUpdate({ ...catalog, ...payload }));
    }
  }
);

export const updateCatalogCollection = createAsyncThunk(
  'catalog/updateCatalogCollection',
  async (data, thunkAPI) => {
    const response = await updateCatalogCollectionAPI(data);
    if ((response.status === 200 || response.status === 201) && response.data.data && !response.data.data.message) {
      const updatedCatalog = response.data.data;
      thunkAPI.dispatch(manualCatalogUpdate(updatedCatalog));
      thunkAPI.dispatch(getUserData());
    }
  }
);

const initialState = {
  uploading: false,
  data: null,
  catalog: [],
  uploadQueue: []
};

export const contentUploadSlice = createSlice({
  name: 'contentUpload',
  initialState,
  reducers: {
    uploadingInitiated: (state) => {
      state.uploading = true;
    },
    addToUploadingQueue: (state, action) => {
      state.uploadQueue = [...state.uploadQueue, action.payload];
    },
    updateUploadingQueueProgress: (state, action) => {
      const { id, progress, cancelToken, finished, error } = action.payload;
      const index = state.uploadQueue.findIndex((item) => item.id === id);
      if (progress) {
        state.uploadQueue[index].progress = progress;
      }

      if (progress && progress === 100) {
        state.uploadQueue[index].finalizing = true;
      }

      if (cancelToken) {
        state.uploadQueue[index].cancelToken = cancelToken;
      }

      if (finished) {
        state.uploadQueue[index].uploading = false;
        state.uploadQueue[index].finalizing = false;
      }
      if (error) {
        state.uploadQueue[index].uploading = false;
        state.uploadQueue[index].finalizing = false;
        state.uploadQueue[index].error = error;
      }
    },
    uploadingFulfilled: (state) => {
      state.uploading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createCatalog.fulfilled, (state, action) => {
      state.uploading = false;
    });
  }
});

export const {
  uploadingInitiated,
  uploadingFulfilled,
  addToUploadingQueue,
  updateUploadingQueueProgress
} = contentUploadSlice.actions;

export default contentUploadSlice.reducer;
