import React from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';

function Button ({ extraClass, clickEvent, type, disabled, text }) {
  return (
    <button
      className={`${styles.btn} ${styles[extraClass]}`}
      type={type}
      disabled={disabled}
      onClick={clickEvent}
    >
      {text}
    </button>
  );
}

Button.propTypes = {
    text: PropTypes.string,
    extraClass: PropTypes.string,
    clickEvent: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool
};

export default Button;
