import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import Photo from '../../organisms/GalleryLayout/Photo';
import { ReactComponent as LeftArr } from '../../pages/myImageCatalogs/components/PopupManger/assets/icon-arrow-left.svg';
import { ReactComponent as RightArr } from '../../pages/myImageCatalogs/components/PopupManger/assets/icon-arrow-right.svg';
import Video from 'components/organisms/GalleryLayout/Video';

export default function index ({ catalogs, currentUser, handleClick, header, linkForMore, directOtherProps, isTouchScreen }) {
  const [scrollableTags, setScrollableTags] = useState(!window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      if (scrollableTags && window.innerWidth < 800) {
        setScrollableTags(false);
      } else if (!scrollableTags && window.innerWidth >= 800) {
        setScrollableTags(true);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [catalogs, scrollableTags])

  const cardListRef = React.createRef();

  const checkArrows = () => {
    if (cardListRef && catalogs.length > 0) {
      const listContainer = cardListRef.current;
      console.log(listContainer?.scrollWidth, listContainer?.offsetWidth)
      if (listContainer?.scrollWidth > listContainer?.offsetWidth) {
        if (scrollableTags && window.innerWidth < 800) {
          setScrollableTags(false);
        } else if (!scrollableTags && window.innerWidth >= 800) {
          setScrollableTags(true);
        }
      } else {
        setScrollableTags(false)
      }
    }
  }

  useEffect(() => {
    checkArrows()
  }, [cardListRef, location, catalogs])

  const isNavigating = useRef(false);
  let timeoutRunner = null

  const slideHandler = (direction) => {
    const listContainer = cardListRef.current;
    if (!listContainer) return;
    if (direction === 1 && listContainer.scrollLeft > 0) {
      listContainer.scrollLeft -= 100;
    } else if (direction === 2 && listContainer.scrollLeft < listContainer.scrollWidth) {
      listContainer.scrollLeft += 100;
    }
    if (isNavigating.current) {
      setTimeout(() => {
        timeoutRunner = slideHandler(direction)
      }, 100)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', stopSlide);
    return () => {
      document.removeEventListener('mouseup', stopSlide);
    }
  }, [scrollableTags])

  const startSlide = (direction) => {
    isNavigating.current = true;
    slideHandler(direction)
  }

  const stopSlide = () => {
    isNavigating.current = false;
    clearTimeout(timeoutRunner)
  }

  return (
    <div className={styles.contentPerDataTypeContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>{header}</h2>
      </div>
      {
        scrollableTags && (<div className={styles.navigatorLeft}>
          <button className={styles.carouselInteractionBtn} onMouseDown={() => startSlide(1)} onMouseUp={stopSlide}>
            <LeftArr />
          </button>
        </div>)
      }
      <div className={styles.catalogsViewContainer}>
        <div className={styles.cardList} ref={cardListRef}>
          {
            catalogs.map((catalog, i) => (
              catalog.assest_type !== 'Videos'
                ? <Photo key={catalog?.id} isTouchScreen={isTouchScreen} index={i} currentUser={currentUser} assetUserId={catalog?.userId} onClick={handleClick} photo={catalog} catalog={catalog} allCatalogs={catalogs} margin={10} linearLine={true} height={200} {...directOtherProps} />
                : <Video key={catalog?.id} isTouchScreen={isTouchScreen} index={i} currentUser={currentUser} assetUserId={catalog?.userId} onClick={handleClick} photo={catalog} catalog={catalog} allCatalogs={catalogs} margin={10} linearLine={true} height={200} {...directOtherProps} />

            ))
          }
        </div>
      </div>
      {
        scrollableTags && (<div className={styles.navigatorRight}>
          <button className={styles.carouselInteractionBtn} onMouseDown={() => startSlide(2)} onMouseUp={stopSlide}>
            <RightArr />
          </button>
        </div>)
      }
      {
        linkForMore && (
          <div className={styles.seeMoreContainer}>
            <Link to={linkForMore} className={styles.seeMoreLink}>
              See More
            </Link>
          </div>
        )
      }
    </div>
  )
}
