import axiosInstance from '../axios/axiosInstance'

export async function getDraftsAPI () {
  const response = await axiosInstance.get(
    '/draft'
  )
  return response
}

export async function createDraftsAPI (data) {
  const response = await axiosInstance.post(
    '/draft',
    data
  )
  return response
}

export async function getDraftBydraftIdAPI (id) {
  const response = await axiosInstance.get(
    `/draft/draftid/${id}`
  )
  return response
}

export async function requestEditByTemplateIdAPI (id) {
  const response = await axiosInstance.get(
    `/catalog/request-edit-template/editor/${id}`
  )
  return response
}

export async function updateDraftBydraftIdAPI (id, data) {
  const response = await axiosInstance.patch(
    `/draft/draftid/${id}`,
    data
  )
  return response
}

export async function softDeleteDraftAPI (id) {
  const response = await axiosInstance.delete(
    `/draft/${id}`
  )
  return response
}

export async function hardDeleteDraftAPI (id) {
  const response = await axiosInstance.delete(
    `/draft/hard/${id}`
  )
  return response
}
