import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

export default function Button ({ text, onClick, extraClass, disabled }) {
  return <button disabled={disabled} className={`btn ${extraClass}`} onClick={onClick}>{text}</button>;
}

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  disabled: false
}
