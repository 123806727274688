/* eslint-disable react/react-in-jsx-scope */
import ProtectedLayout from '../components/templates/Layout/Layout';
import Home from '../components/pages/Home';
// import UploadContent from 'components/pages/uploadContent/ContentUpload';
import MyCollectionFolder from 'components/pages/files/MyCollectionFolder/MyCollectionFolder';
import SharedWithMe from 'components/pages/files/SharedWithMe/SharedWithMe';
import SharedByMe from 'components/pages/files/SharedByMe/SharedByMe';
import myImageCatalogs from '../components/pages/myImageCatalogs/myImageCatalogs';
import myTemplatesCatalogs from '../components/pages/myTemplatesCatalogs/myTemplatesCatalogs';
import CatalogMainView from '../components/pages/CatalogMainView/CatalogMainView';
// import MockupMain from '../components/pages/MockupMain/MockupMain';
import MySearchPage from '../components/pages/MySearchPage/MySearchPage';
import TemplateView from '../components/pages/myTemplatesCatalogs/components/TemplateView';
import ComingSoon from 'components/pages/ComingSoon/ComingSoon';
// import myImages from '../components/pages/myImages/myImages';
import FilesContainer from '../components/pages/files/FilesContainer/FilesContainer';
import Trash from 'components/pages/trash/Trash';
import UpgradeComponent from 'components/pages/UpgradeComponent/UpgradeComponent';
import ExtraNavigator from 'components/pages/ExtraNavigator';
import NotAvailable from 'components/pages/NotAvailable/NotAvailable';
import SharedByMeCollections from 'components/pages/files/SharedByMe/SharedByMeCollections/SharedByMeCollections';
import SharedWithMeCollections from 'components/pages/files/SharedWithMe/SharedWithMeCollections/SharedWithMeCollections';
import MyVideoCatalogs from 'components/pages/MyVideoCatalogs/MyVideoCatalogs';
import AllMyCatalogs from 'components/pages/AllMyCatalogs/AllMyCatalogs';

export const pages = [
  {
    children: [
      {
        path: '/',
        exact: true,
        layout: ProtectedLayout,
        element: Home
      },
      {
        path: '/upgrade',
        exact: true,
        layout: ProtectedLayout,
        element: UpgradeComponent
      },
      // {
      //   path: '/upload-content',
      //   exact: true,
      //   layout: ProtectedLayout,
      //   element: UploadContent
      // },
      {
        path: '/my-catalogs',
        exact: true,
        layout: ProtectedLayout,
        element: CatalogMainView
      },
      {
        path: '/catalogs',
        exact: true,
        layout: ProtectedLayout,
        element: CatalogMainView
      },
      {
        path: '/search/*',
        layout: ProtectedLayout,
        element: MySearchPage
      },
      {
        path: '/catalogs/*',
        layout: ProtectedLayout,
        element: ExtraNavigator
      },
      {
        path: '/catalogs/Images',
        exact: true,
        layout: ProtectedLayout,
        element: myImageCatalogs
      },
      {
        path: '/catalogs/Images/:folderId',
        exact: true,
        layout: ProtectedLayout,
        element: myImageCatalogs
      },
      {
        path: '/catalogs/Videos',
        exact: true,
        layout: ProtectedLayout,
        element: MyVideoCatalogs
      },
      {
        path: '/catalogs/Videos/:folderId',
        exact: true,
        layout: ProtectedLayout,
        element: MyVideoCatalogs
      },
      {
        path: '/catalogs/Templates/',
        exact: true,
        layout: ProtectedLayout,
        element: myTemplatesCatalogs
      },
      {
        path: '/catalogs/Templates/:folderId',
        exact: true,
        layout: ProtectedLayout,
        element: myTemplatesCatalogs
      },
      {
        path: '/catalogs/Templates/:folderId/:templateId',
        exact: true,
        layout: ProtectedLayout,
        element: TemplateView
      },
      {
        path: '/catalogs/Mockups',
        exact: true,
        layout: ProtectedLayout,
        element: ComingSoon
      },
      {
        path: '/my-catalogs/*',
        layout: ProtectedLayout,
        element: ExtraNavigator
      },
      {
        path: '/my-catalogs/Images',
        exact: true,
        layout: ProtectedLayout,
        element: myImageCatalogs
      },
      {
        path: '/my-catalogs/Images/:folderId',
        exact: true,
        layout: ProtectedLayout,
        element: myImageCatalogs
      },
      {
        path: '/my-catalogs/Videos',
        exact: true,
        layout: ProtectedLayout,
        element: MyVideoCatalogs
      },
      {
        path: '/my-catalogs/Videos/:folderId',
        exact: true,
        layout: ProtectedLayout,
        element: MyVideoCatalogs
      },
      {
        path: '/my-catalogs/Templates/',
        exact: true,
        layout: ProtectedLayout,
        element: myTemplatesCatalogs
      },
      {
        path: '/my-catalogs/Templates/:folderId',
        exact: true,
        layout: ProtectedLayout,
        element: myTemplatesCatalogs
      },
      {
        path: '/my-catalogs/Templates/:folderId/:templateId',
        exact: true,
        layout: ProtectedLayout,
        element: TemplateView
      },
      {
        path: '/my-catalogs/Mockups',
        exact: true,
        layout: ProtectedLayout,
        element: ComingSoon
      },
      {
        path: '/catalogs/Premium',
        exact: true,
        layout: ProtectedLayout,
        element: ComingSoon
      },
      {
        path: '/my-files',
        exact: true,
        layout: ProtectedLayout,
        element: FilesContainer
      },
      {
        path: '/my-files/:collectionFolderId',
        exact: true,
        layout: ProtectedLayout,
        element: MyCollectionFolder
      },
      {
        path: '/my-files/collections',
        exact: true,
        layout: ProtectedLayout,
        element: NotAvailable
      },
      {
        path: '/my-files/all-catalogs/*',
        exact: true,
        layout: ProtectedLayout,
        element: AllMyCatalogs
      },
      {
        path: '/my-files/:collectionFolderId/:collectionId',
        exact: true,
        layout: ProtectedLayout,
        element: MyCollectionFolder
      },
      {
        path: '/trash',
        exact: true,
        layout: ProtectedLayout,
        element: Trash
      },
      {
        path: '/coming-soon',
        exact: true,
        layout: ProtectedLayout,
        element: ComingSoon
      },
      {
        path: '/my-files/shared-with-me',
        exact: true,
        layout: ProtectedLayout,
        element: SharedWithMe
      },
      {
        path: '/my-files/shared-by-me',
        exact: true,
        layout: ProtectedLayout,
        element: SharedByMe
      },
      {
        path: '/my-files/shared-with-me/*',
        layout: ProtectedLayout,
        element: NotAvailable
      },
      {
        path: '/my-files/shared-by-me/*',
        layout: ProtectedLayout,
        element: NotAvailable
      },
      {
        path: '/my-files/shared-with-me/collections/:collectionId',
        exact: true,
        layout: ProtectedLayout,
        element: SharedWithMeCollections
      },
      {
        path: '/my-files/shared-by-me/collections/:collectionId',
        exact: true,
        layout: ProtectedLayout,
        element: SharedByMeCollections
      },
      {
        path: '*',
        layout: ProtectedLayout,
        element: NotAvailable
      }
    ]
  }
];
