import bgLight from 'assets/images/search_banners/Frame_41768_light.jpg';
import bgDark from 'assets/images/search_banners/Frame_41770_blk.jpg';
import bgGreen from 'assets/images/search_banners/Frame_41771_green.jpg';
import bgBlue from 'assets/images/search_banners/Frame_41770_blue.jpg';
import bgPurpl from 'assets/images/search_banners/Frame_41767_prup.jpg';
import bgHomeDark from 'assets/images/search_banners/13222-ai_dark.svg';
import bgHomeGreen from 'assets/images/search_banners/13222-ai_green.svg';
import bgHomeBlue from 'assets/images/search_banners/13222-ai_blue.svg';
import bgHomePurpl from 'assets/images/search_banners/13222-ai_purp.svg';

export const lightModeCSS = {
  '--main-color': '#563a4a',
  '--main-color-brighter': '#9d6183',
  '--mainBgColor': '#ebebeb',
  '--lighterbgColor': '#cbcbcb',
  '--mainBgColor-half-opacity': '#f3f3f3f0',
  '--mainBgColor-opacity-shadow': '#f3f3f33a',
  '--container-blocks': '#c9c9c9',
  '--barsColor': '#d8d8d8',
  '--button-normal': '#b7b7b7',
  '--primary-btn-color': '#b2b2b2',
  '--shadow-color': '#f5f6f5',

  '--text-main': '#0a0a0a',
  '--text-secondary': '#353535',
  '--text-third': '#626262',

  '--text-inverted': '#e4e4e4',
  '--bg-op-inverted': '#eaeaeb',

  '--sidebargradient': 'linear-gradient(180deg, #d8d8d8 0%, #eaeaea 50%, #d8d8d8 100%)',
  '--maincolorgradient': 'linear-gradient(90deg, #563a4a 0%, #8a5e77 50%, #412a37 100%)',
  '--filtercolorgradient': 'linear-gradient( 90deg, #563a4a90 0%, #8a5e7790 50%, #412a3790 100% )',
  '--col-s-banner': `url(${bgLight})`,
  '--banner-home': `url(${bgHomeDark})`
};

export const darkModeCSS = {
  '--main-color': '#563a4a',
  '--main-color-brighter': '#9d6183',
  '--sidebargradient': 'linear-gradient(180deg, #1b1b1b 0%, #272727 50%, #1b1b1b 100%)',
  '--maincolorgradient': 'linear-gradient(90deg, #563a4a 0%, #8a5e77 50%, #412a37 100%)',
  '--filtercolorgradient': 'linear-gradient(90deg, #563a4a90 0%, #8a5e7790 50%, #412a3790 100%)',
  '--mainBgColor': '#141414',
  '--lighterbgColor': '#343434',
  '--mainBgColor-half-opacity': '#0c0c0cf0',
  '--mainBgColor-opacity-shadow': '#0c0c0c3a',
  '--container-blocks': '#1a1a1a',
  '--barsColor': '#1b1b1b',
  '--button-normal': '#202020',
  '--primary-btn-color': '#272727',
  '--shadow-color': '#0a090a',
  '--text-main': '#ffffff',
  '--text-secondary': '#a3a3a3',
  '--text-third': '#626262',
  '--text-inverted': '#1b1b1b',
  '--bg-op-inverted': '#eaeaeb',
  '--col-s-banner': `url(${bgDark})`,
  '--banner-home': `url(${bgHomeDark})`

};

export const blueModeCSS = {
  '--main-color': '#15518a',
  '--main-color-brighter': '#15426c',
  '--sidebargradient': 'linear-gradient(180deg, #09233a 0%, #0b2842 50%, #09233a 100%)',
  '--maincolorgradient': 'linear-gradient(90deg, #061625 0%, #0b2842 50%, #061625 100%)',
  '--filtercolorgradient': 'linear-gradient(90deg, #06162590 0%, #0b284290 50%, #06162590 100%)',
  '--mainBgColor': '#061828',
  '--lighterbgColor': '#071c2e',
  '--mainBgColor-half-opacity': '#071e31f0',
  '--mainBgColor-opacity-shadow': '#071e313a',
  '--container-blocks': '#071e31',
  '--barsColor': '#09233a',
  '--button-normal': '#0c253f',
  '--primary-btn-color': '#0e2c4b',
  '--shadow-color': '#030b12',
  '--text-main': '#ffffff',
  '--text-secondary': '#a3a3a3',
  '--text-third': '#626262',
  '--text-inverted': '#ffffff',
  '--bg-op-inverted': '#eaeaeb',
  '--col-s-banner': `url(${bgBlue})`,
  '--banner-home': `url(${bgHomeBlue})`
};

export const greenModeCSS = {
  '--main-color': '#2c3e35',
  '--main-color-brighter': '#304f43',
  '--sidebargradient': 'linear-gradient(180deg, #131c19 0%, #1b2b25 50%, #131c19 100%)',
  '--maincolorgradient': 'linear-gradient(90deg, #131c19 0%, #1b2b25 50%, #131c19 100%)',
  '--filtercolorgradient': 'linear-gradient(90deg, #131c1990 0%, #1b2b2590 50%, #131c1990 100%)',
  '--mainBgColor': '#0e1512',
  '--lighterbgColor': '#1d2a25',
  '--mainBgColor-half-opacity': '#131d19f0',
  '--mainBgColor-opacity-shadow': '#131d193a',
  '--container-blocks': '#131d19',
  '--barsColor': '#131c19',
  '--button-normal': '#192822',
  '--primary-btn-color': '#192822',
  '--shadow-color': '#070b09',
  '--text-main': '#ffffff',
  '--text-secondary': '#a3a3a3',
  '--text-third': '#626262',
  '--text-inverted': '#0e1512',
  '--bg-op-inverted': '#eaeaeb',
  '--col-s-banner': `url(${bgGreen})`,
  '--banner-home': `url(${bgHomeGreen})`
}

export const purpleModeCSS = {
  '--main-color': '#313750',
  '--main-color-brighter': '#414c6a',
  '--sidebargradient': 'linear-gradient(180deg, #181b26 0%, #222736 50%, #181b26 100%)',
  '--maincolorgradient': 'linear-gradient(90deg, #181b26 0%, #222736 50%, #181b26 100%)',
  '--filtercolorgradient': 'linear-gradient(90deg, #181b2690 0%, #22273690 50%, #181b2690 100%)',
  '--mainBgColor': '#12141d',
  '--lighterbgColor': '#262a3c',
  '--mainBgColor-half-opacity': '#12141df0',
  '--mainBgColor-opacity-shadow': '#12141d3a',
  '--container-blocks': '#1d1f2d',
  '--barsColor': '#181b26',
  '--button-normal': '#282d3f',
  '--primary-btn-color': '#282d3f',
  '--shadow-color': '#0b0b11',
  '--text-main': '#ffffff',
  '--text-secondary': '#a3a3a3',
  '--text-third': '#626262',
  '--text-inverted': '#12141d',
  '--bg-op-inverted': '#eaeaeb',
  '--col-s-banner': `url(${bgPurpl})`,
  '--banner-home': `url(${bgHomePurpl})`
}
