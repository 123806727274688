import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCollectionsAPI,
  getAllCollectionsAPI,
  getCollectionByIdAPI,
  deleteCollectionsAPI,
  getAllCollectionFoldersAPI,
  getCollectionsByFolderIdAPI,
  addNewCollectionFolderAPI,
  updateCollectionsAPI,
  getCollectionsSharedByMeAPI,
  getCollectionsSharedWithMeAPI
    // createCollectionsFromCatalogPopupAPI
} from 'api/collections';
import { updateCatalogCollection } from 'Redux/contentUpload/contentUploadSlice';
import { getUserData } from 'Redux/userdata/userdataSlice';

// First, create the thunk
export const createCollections = createAsyncThunk(
  'collection/createCollections',
  async (data, thunkAPI) => {
    const response = await createCollectionsAPI(data);
    thunkAPI.dispatch(getUserData());
    return response.data;
  }
);

export const createCollectionsFromCatalogPopup = createAsyncThunk(
  'collection/createCollectionsFromCatalogPopup',
  async (data, thunkAPI) => {
    const { collections, catalogData, callback } = data || {};
      thunkAPI.dispatch(updateCatalogCollection({ tempId: catalogData.id, collections }));
      thunkAPI.dispatch(getUserData());
      callback && callback();
  }
);

export const createCollectionsFromFolderPopup = createAsyncThunk(
  'collection/createCollectionsFromFolderPopup',
  async (data, thunkAPI) => {
    const { newCollections, oldCollections } = data || {};
        const response = await createCollectionsAPI(newCollections);
        if (response.data.data) {
          response.data.data.forEach((collection, idx) => {
          thunkAPI.dispatch(manualCollectionUpdate(collection || newCollections[idx]));
          });
        }
      oldCollections.forEach(async (collection) => {
        const response = await updateCollectionsAPI(collection);
        if (response.data.data) {
          thunkAPI.dispatch(manualCollectionUpdate(collection));
        }
      });
      thunkAPI.dispatch(getUserData());
  }
);

export const deleteCollections = createAsyncThunk(
  'collection/deleteCollections',
  async (id, thunkAPI) => {
    const response = await deleteCollectionsAPI(id);
    thunkAPI.dispatch(getUserData());
    return response.data;
  }
);

export const getAllCollections = createAsyncThunk(
  'collection/getAllCollections',
  async (id) => {
    const response = await getAllCollectionsAPI(id);
    return response.data;
  }
);

export const getSharedWithMeCollections = createAsyncThunk(
  'collection/getSharedWithMeCollections',
  async () => {
    const response = await getCollectionsSharedWithMeAPI()
    if (response?.data?.statusCode === 200 && response?.data?.data && Array.isArray(response?.data?.data)) {
      return response?.data?.data.map(el => {
        return {
          ...el,
          id: el.collection?._id || el.collection?.id,
          pendingAssets: true,
          shareId: el._id || el.id,
          withMe: true
        }
      }).filter((collection) => collection?.id && collection?.collection)
    }
    return []
  }
);

export const getSharedByMeCollections = createAsyncThunk(
  'collection/getSharedByMeCollections',
  async () => {
    const response = await getCollectionsSharedByMeAPI()
    if (response?.data?.statusCode === 200 && response?.data?.data && Array.isArray(response?.data?.data)) {
      return response?.data?.data.map(el => {
        return {
          ...el,
          id: el.collection?._id || el.collection?.id,
          pendingAssets: true,
          shareId: el._id || el.id
        }
      }).filter((collection) => collection?.id && collection?.collection)
    }
    return []
  }
);

export const getAllCollectionFolders = createAsyncThunk(
  'collection/getAllCollectionFolders',
  async () => {
    const response = await getAllCollectionFoldersAPI();
    return response.data;
  }
);

export const getCollectionsByFolderId = createAsyncThunk(
  'collection/getCollectionsByFolderId',
  async (id) => {
    const response = await getCollectionsByFolderIdAPI(id);
    return response.data;
  }
);

export const addNewCollectionFolder = createAsyncThunk(
  'collection/addNewCollectionFolder',
  async (data, thunkAPI) => {
    const response = await addNewCollectionFolderAPI(data);
    thunkAPI.dispatch(getUserData());
    return response.data;
  }
);

export const getCollectionById = createAsyncThunk(
  'collection/getCollectionById',
  async (id) => {
    const response = await getCollectionByIdAPI(id);
    return response.data;
  }
);

const initialState = {
  collections: [],
  collectionFolders: [],
  sharedWithMeCollections: [],
  sharedByMeCollections: [],
  loading: true,
  error: null
};

export const collections = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    manualCollectionUpdate: (state, action) => {
      const upddatedCollection = action.payload;
      const index = state.collections?.findIndex(
        (item) => item.id === upddatedCollection.id
      );
      if (index !== -1) {
      state.collections[index] = upddatedCollection;
      } else {
        state.collections = [...state.collections, upddatedCollection];
      }
    },
    manualCollectionDelete: (state, action) => {
      const deletedCollection = action.payload;
      const index = state.collections?.findIndex(
        (item) => item.id === deletedCollection.id
      );
      if (index !== -1) {
      state.collections.splice(index, 1);
      }
    },
    manualSharedCollectionUpdate: (state, action) => {
      const { newCollections, type } = action.payload;
      if (type === 1) {
        state.sharedWithMeCollections = newCollections;
      } else if (type === 2) {
        state.sharedByMeCollections = newCollections;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSharedWithMeCollections.pending, (state, action) => {
      state.sharedWithMeCollections = [];
      state.loading = true;
    }
    );

    builder.addCase(getSharedByMeCollections.pending, (state, action) => {
      state.sharedByMeCollections = action.payload;
      state.loading = true;
    }
    );

    builder.addCase(getAllCollections.fulfilled, (state, action) => {
      state.collections = action.payload.data.result;
      state.loading = false;
    });

    builder.addCase(getSharedWithMeCollections.fulfilled, (state, action) => {
      console.log('action.payload', action.payload)
      state.sharedWithMeCollections = action.payload;
      state.loading = false;
    }
    );

    builder.addCase(getSharedByMeCollections.fulfilled, (state, action) => {
      state.sharedByMeCollections = action.payload;
      state.loading = false;
    }
    );

    builder.addCase(createCollections.fulfilled, (state, action) => {
      state.collections = [...state.collections, ...action.payload.data];
      state.loading = false;
    });

    builder.addCase(deleteCollections.fulfilled, (state, action) => {
      state.collections = action.payload.data.result
        ? action.payload.data.result
        : state.collections;
      state.loading = false;
    });

    builder.addCase(getAllCollectionFolders.fulfilled, (state, action) => {
      state.collectionFolders = action?.payload?.data?.result ? action.payload.data.result : [];
      state.loading = false;
    });
  }

});

export const {
  manualCollectionUpdate,
  manualCollectionDelete,
  manualSharedCollectionUpdate
} = collections.actions;

export default collections.reducer;
