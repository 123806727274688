/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as LeftArr } from '../assets/icon-arrow-left.svg';
import { ReactComponent as RightArr } from '../assets/icon-arrow-right.svg';
import { ReactComponent as DblArrow } from 'assets/icons/dbarrow.svg';
import { ReactComponent as CloseFs } from '../assets/icon-remove-fullscreen.svg';
import GridImagesLayout from '../../GridImagesLayout'

export default function FSImageLoader ({
  setPreviewLoadingNext,
  previewLoadingNext,
  currentCatalog,
  previewIndex,
  setPreviewIndex,
  allCatalogs,
  setPreviewPopup,
  changeToFullScreen,
  showcaseOnFullScreen,
  setShowcaseOnFullScreen,
  previewFullScreen,
  handleChangeOnFullScreen
}) {
  useEffect(() => {
    if (currentCatalog?.assest_type === 'Videos') {
      changeToFullScreen(false);
      setPreviewPopup(prev => { return { ...prev, state: 1 } })
    }
  }, [currentCatalog])
  return (
    <div
    className={`${styles.popupPreviewImgContainer} ${styles.fscreen}`}
  >
    {
      showcaseOnFullScreen &&
      <div className={styles.fullscreenShowcaseContainer}>
        <div className={styles.fullscreenShowcaseContainer__interactiveTab}>
          <button className={styles.closeShowCase} onClick={() => setShowcaseOnFullScreen(false)}>
            <DblArrow className={styles.closeShowCaseImg} />
          </button>
        </div>
        <div className={styles.catalogsWrapper}>
          <GridImagesLayout
            catalogs={allCatalogs}
            innerProps={{ targetRowHeight: 200, noInteraction: true, margin: 10 }}
            onClick={(value, data) => handleChangeOnFullScreen(data)}
          />
        </div>
      </div>
    }
    <div className={styles.backgroundOverlay} />
    {previewLoadingNext && <div className={styles.loadingImgActiveBgFs} />}
    <img
      className={previewLoadingNext ? `${styles.previewImg} ${styles.loadingImg}` : styles.previewImg}
      src={currentCatalog?.src?.original}
      onLoad={() => setPreviewLoadingNext(false)}
      style={{
        maxHeight: currentCatalog?.height > currentCatalog?.width ? '100%' : 'unset',
        width: currentCatalog?.height > currentCatalog?.width ? 'unset' : '100%',
        maxWidth: currentCatalog?.height > currentCatalog?.width ? '100%' : 'unset'
      }}
      alt="preview" />
    <div className={styles.popupPreviewImgContainer__controls}>
      {
        previewFullScreen &&
        <button
          className={styles.fullscreenBtn}
          onClick={() => {
            changeToFullScreen(false);
            setPreviewPopup(prev => { return { ...prev, state: 1 } })
          }}>
          <CloseFs />
        </button>
      }
      <button
        className={styles.fullscreenBtn}
        onClick={() => {
          setPreviewPopup(null)
          try {
            if (document?.exitFullscreen && previewFullScreen) {
              document?.exitFullscreen()
            }
          } catch {
            return null
          }
        }}>
        <CloseIcon />
      </button>
    </div>
    <div className={styles.popupPreviewImgContainer__navigatorLeft}>
      <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex - 1) }} disabled={previewIndex === 0}>
        <LeftArr />
      </button>
    </div>
    <div className={styles.popupPreviewImgContainer__navigatorRight}>
      <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex + 1) }} disabled={previewIndex === allCatalogs.length - 1}>
        <RightArr />
      </button>
    </div>
    <div className={styles.popupPreviewImgContainer__info}>
      <button className={styles.openShowCase} onClick={() => setShowcaseOnFullScreen(true)}>
        <DblArrow className={styles.closeShowCaseImg} />
      </button>
      <h3>{currentCatalog?.name}</h3>
    </div>
    <div className={styles.popupPreviewImgContainer__footer}>
      <p>{currentCatalog?.content_description}</p>
    </div>
  </div>
  )
}
