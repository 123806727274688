/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import Editor from '@master-wizr/editor';
import '@master-wizr/editor/dist/style.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getOpenEditorTemplateShareAPI
} from 'api/sharing';
// import { errorToast } from 'toasts/Toasts';

function shareId () {
  const navigate = useNavigate();
  const { shareId } = useParams();
  const [currentdraft, setCurrentDraft] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [errorOnDraft, setError] = useState(false);

  const goBackFn = () => {
    window.location.replace('/mpublisher/my-files');
  };

  if (window.innerWidth < 1024) {
    navigate('/mpublisher');
  }

  useEffect(() => {
    if (!shareId) {
      setError({
        code: 404,
        message: 'No share id found'
      });
      return;
    }
    setLoadingDraft(true);
    getOpenEditorTemplateShareAPI(shareId).then((response) => {
      const data = response?.data?.data;
      if (data) {
        const { type } = data;
        if (type === 'template') {
          setCurrentTemplate(data.template);
        } else if (type === 'draft') {
          setCurrentDraft(data.template);
        } else {
          setError({
            code: 404,
            message: 'Corrupted data'
          });
        }
      } else {
        setError({
          code: 404,
          message: 'No data found'
        });
      }
      setLoadingDraft(false);
    });
  }, [shareId]);

  return (
    !errorOnDraft
      ? (
        <div className={styles.container}>
          {loadingDraft && (
            <div className={styles.loading}>
              <div className={styles.spinner} />
            </div>
          )}
          {(currentdraft || currentTemplate) && (
            <Editor
              goBackFn={goBackFn}
              isEditing={!!currentTemplate}
              documentFile={currentdraft || currentTemplate}
              inheritThemes={false}
              viewOnly={true}
              openRoute={true}
            />
          )}
        </div>
      )
      : (
        <div className={styles.error_container}>
          <div className={styles.error}>
            <h1>{errorOnDraft?.code}</h1>
            <h2>{errorOnDraft?.message}</h2>
            <p>
              The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            </p>
            <button
              type="button"
              className={styles.error_button}
              onClick={() => navigate('/mpublisher/my-files')}
            >
              Go Back
            </button>
          </div>
        </div>
      )
  );
}
export default shareId;
