/* eslint-disable react/prop-types */
import styles from './css/FilterSection.module.scss';
import React, { useState } from 'react';
import { Filter } from '../../organisms/Filter';
import '@master-wizr/wizr-filter/dist/index.css';
import { filtersData } from 'constants/filtersData';
import { filtersData as templatesFD } from '../../../constants/templatesFilterData';
import { filtersData as videosFD } from '../../../constants/vidFilterData';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function FilterSection () {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterSelection, setFilterSelection] = useState({});

  const onChangeData = (changes) => {
    const key = changes.category
    const value = changes.value?.toLowerCase()
    if (changes.category === 'searchText') {
      setFilterSelection({ ...filterSelection, name: [changes.value] })
    } else if (changes.category === 'assest_type') {
      setFilterSelection({ name: [filterSelection.name], assest_type: [changes.value] })
    } else if (!filterSelection[key]) {
      const newData = {}
      newData[key] = [value]
      setFilterSelection({ ...filterSelection, ...newData })
    } else {
      if (changes.selected) {
        const newArr = [...filterSelection[key], value]
        setFilterSelection({ ...filterSelection, [key]: newArr })
      } else {
        const newArr = filterSelection[key].filter((item) => item !== value)
        newArr.length > 0 ? setFilterSelection({ ...filterSelection, [key]: newArr }) : setFilterSelection((prev) => { const newState = { ...prev }; delete newState[key]; return newState })
      }
    }
  }

  const navigateToSearchPage = () => {
    const link = `/mpublisher/search/result&${Object.keys(filterSelection).map((key) => `${key}=${filterSelection[key].join(',').toLowerCase()}`).join('&')}&use_global=all`
    navigate(link, { state: { typeOfSearch: '&use_global=all' } })
  }
  const getMenu = () => {
    const currentAsset = filterSelection?.assest_type ? filterSelection.assest_type[0] : null;
    if (currentAsset?.includes('images') || currentAsset?.includes('Images')) {
      return filtersData;
    } else if (currentAsset === 'templates' || currentAsset?.includes('Templates')) {
      return templatesFD;
    } else if (currentAsset === 'videos' || currentAsset?.includes('Videos')) {
      return videosFD;
    } else {
      return [...filtersData.filter(el => el.dbTitle !== 'color' && el.dbTitle !== 'style'), ...templatesFD]; // like this for now
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <h1>{t('Spice up your work')}</h1>
        <Filter
          data={getMenu()}
          onChangeData={onChangeData}
          onSearch={navigateToSearchPage}
          currentState={filterSelection}
          camelizedValues={true}
          withAssetBtn={true}
          type="wizrx"
        />
      </div>
    </div>
  );
}

export default FilterSection;
