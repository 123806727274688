/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import './style.scss';

export default function DragDropFile ({ setSampleImage, sampleImage }) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSampleImage({
        file: e.dataTransfer.files[0],
        preview: URL.createObjectURL(e.dataTransfer.files[0])
      })
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSampleImage({
        file: e.target.files[0],
        preview: URL.createObjectURL(e.target.files[0])
      })
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} style={{ backgroundImage: `url(${sampleImage?.preview})` }}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
        <div>
          {!sampleImage &&
            <>
              {dragActive ? <p>Drop your file here</p> : <button className="upload-button" onClick={onButtonClick}>Add a background sample<br /><span className="recommended-text"></span></button>}
            </>
          }
        </div>
      </label>
      {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
    </form>
  );
};
