import styles from './css/EasyUpload.module.scss';
import React from 'react';
import image from '../../../../assets/images/easyUpload/img.jpg';
import RoundedButton from '../../../atoms/RoundedButton/RoundedButton';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EasyUpload = ({ setUploadModalVisibility, currentUserRole }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.easy__upload__container}>
        <div>
          <figure className={styles.imgFigure}>
            <img src={image} alt="Easy Upload" />
          </figure>
          <div className={styles.text_container}>
            <h1>{t('Easy way to upload your content')}</h1>
            <small>
              {t('unleash-imagination-text')}
            </small>
            <RoundedButton
              onClick={() => setUploadModalVisibility(true)}
              extraClass={styles.easy__upload__container__button}
            >
              {t('Upload content')}
            </RoundedButton>
          </div>

        </div>
      </div>
    </section>
  );
};

export default EasyUpload;

EasyUpload.propTypes = {
  setUploadModalVisibility: PropTypes.function || PropTypes.any,
  currentUserRole: PropTypes.any
};
