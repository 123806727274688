import React from 'react';

const HubLogoTrigger = (props) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C5 2.49316 4.84961 2.02246 4.59082 1.62891C4.14355 0.949219 3.37402 0.5 2.5 0.5C1.11914 0.5 0 1.61914 0 3C0 4.38086 1.11914 5.5 2.5 5.5C3.88086 5.5 5 4.38086 5 3ZM11 3C11 1.61914 9.88086 0.5 8.5 0.5C7.11914 0.5 6 1.61914 6 3C6 4.38086 7.11914 5.5 8.5 5.5C9.19336 5.5 9.82129 5.21777 10.2744 4.76172C10.7227 4.30957 11 3.6875 11 3ZM14.5 0.5C15.8809 0.5 17 1.61914 17 3C17 4.38086 15.8809 5.5 14.5 5.5C13.1191 5.5 12 4.38086 12 3C12 1.61914 13.1191 0.5 14.5 0.5ZM5 10C5 8.61914 3.88086 7.5 2.5 7.5C1.11914 7.5 0 8.61914 0 10C0 11.3809 1.11914 12.5 2.5 12.5C3.88086 12.5 5 11.3809 5 10ZM8.5 7.5C9.88086 7.5 11 8.61914 11 10C11 11.3809 9.88086 12.5 8.5 12.5C7.7168 12.5 7.01758 12.1396 6.55957 11.5762C6.20996 11.1465 6 10.5977 6 10C6 8.61914 7.11914 7.5 8.5 7.5ZM17 10C17 8.61914 15.8809 7.5 14.5 7.5C13.1191 7.5 12 8.61914 12 10C12 11.3809 13.1191 12.5 14.5 12.5C15.1963 12.5 15.8262 12.2158 16.2793 11.7568C16.4785 11.5547 16.6436 11.3184 16.7656 11.0576C16.916 10.7363 17 10.3779 17 10ZM2.5 14.5C3.88086 14.5 5 15.6191 5 17C5 18.3809 3.88086 19.5 2.5 19.5C1.11914 19.5 0 18.3809 0 17C0 15.6191 1.11914 14.5 2.5 14.5ZM11 17C11 15.6191 9.88086 14.5 8.5 14.5C7.78613 14.5 7.14258 14.7988 6.6875 15.2783C6.26172 15.7266 6 16.333 6 17C6 18.3809 7.11914 19.5 8.5 19.5C9.88086 19.5 11 18.3809 11 17ZM14.5 14.5C15.8809 14.5 17 15.6191 17 17C17 18.3809 15.8809 19.5 14.5 19.5C13.1191 19.5 12 18.3809 12 17C12 15.6191 13.1191 14.5 14.5 14.5Z"
      fill="url(#paint0_linear_299_840)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_299_840"
        x1="8.5"
        y1="2.5"
        x2="9"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--main-color)" />
        <stop offset="0.963542" stopColor="var(--main-color-brighter)" stopOpacity="0.31" />
      </linearGradient>
    </defs>
  </svg>
);

export default HubLogoTrigger;
