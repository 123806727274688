/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { updateCatalog } from 'Redux/contentUpload/contentUploadSlice'
import { createCollectionsFromFolderPopup } from 'Redux/collection/collectionSlice'
import { errorToast, successToast } from 'toasts/Toasts'
import { usersAutoComplete } from 'api/users'
import { shareCollectionAPI, shareCollectionByLinkAPI } from 'api/collections'
import { useLocation } from 'react-router-dom'

const permissions = [
  { id: 1, value: 'View', label: 'View' },
  { id: 3, value: 'Download', label: 'Download' },
  { id: 2, value: 'Edit', label: 'Edit' }
]

const index = ({ exitPopup, popupData }) => {
  const [dropDownMenuPermission, setDropDownMenuPermission] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [arrayOfAutoComplete, setArrayOfAutoComplete] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const location = useLocation()
  const bgStyle = {
    backgroundImage: 'url(https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/Group_40538_hogamw.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }

  const { collection } = popupData

  function debounce (func, timeout = 2000) {
    let timer;
    return (...args) =>
      new Promise((resolve) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          resolve(func.apply(this, args));
        }, timeout);
      });
  }

  const fetchBackendData = async (text) => {
    const response = await usersAutoComplete(text)
    if (response.status === 200 && response.data?.data.length > 0) {
      return response.data?.data
    }
    return []
  }

  const debouncedFetch = debounce(fetchBackendData, 1000);

  function handleInputChange (event) {
    setArrayOfAutoComplete([])
    const text = event.target.value;
    if (!text || text.length <= 2) return null
    debouncedFetch(text).then((data) => {
      setArrayOfAutoComplete(data)
    });
  }

  const submitShare = () => {
    selectedUsers.forEach(async (user) => {
      const data = {
        userId: user._id,
        permissions: [selectedPermission.label],
        collectionId: collection.id || collection._id
      }
      try {
        const response = await shareCollectionAPI(data)
        if (response.data && (response.data.statusCode === 200 || response.data.statusCode === 201)) {
          successToast('Shared successfully')
          exitPopup()
        }
      } catch {
        errorToast('Something went wrong')
      }
    })
  }

  const getShareLink = async () => {
    if (!selectedPermission) {
      errorToast('Please select permission')
      return
    }
    if (selectedPermission?.label === 'Edit') {
      errorToast('Edit permission is not allowed for share link')
      return
    }
    const data = {
      permissions: [selectedPermission.label],
      collectionId: Array.isArray(collection) && collection?.length > 0 ? collection[0]?._id : collection.id || collection._id
    }
    try {
      const response = await shareCollectionByLinkAPI(data)
      const link = response.data?.data.shareUrl
      const shareText = window.location.host + '/share/' + link.split('/collection/c/')[1]
      if (navigator.clipboard) {
        navigator.clipboard.writeText(shareText)
        successToast('Link copied to clipboard')
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = shareText;
        textArea.style.position = 'fixed'; // Make it hidden from view
        textArea.style.left = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          successToast('Link copied to clipboard')
        } catch (err) {
          errorToast(err.message || 'Something went wrong')
        }
      }
    } catch (e) {
      errorToast(e.message || 'Something went wrong')
    }
  }

  return (
    <div className={styles.shareContainer}>
      <div className={styles.shareUpperBackground} style={bgStyle} />
      <div className={styles.shareContent}>
        <h3 className={styles.shareHeader}>
          Sharing
        </h3>
        <div className={styles.shareRecipientInfo}>
          <div className={styles.containerHeader}>
            <h4 className={styles.containerHeaderText}>
              Choose Recepients
            </h4>
            <div className={styles.recipientInputs}>
              <div className={styles.upperInputs}>
                <div className={styles.inputWrapper}>
                  {
                    selectedUsers.length > 0 &&
                    <ul className={styles.inputList}>
                      {selectedUsers.map((user) => (
                        <li key={user._id} className={styles.inputItem}>
                          {user.fullName}  <span>x</span>
                        </li>
                      ))}
                    </ul>
                  }
                  <div className={styles.inputContainer}>
                    <input className={styles.input} placeholder="Type username..."
                      onChange={handleInputChange}
                    />
                    {
                      arrayOfAutoComplete && arrayOfAutoComplete.length > 0 &&
                      <ul className={styles.autoCompleteList}>
                        {arrayOfAutoComplete.map((user) => (
                          <li
                            key={user._id}
                            className={styles.autoCompleteItem}
                            onClick={() => {
                              setSelectedUsers(prev => {
                                if (prev.some((item) => item._id === user._id)) {
                                  return prev
                                }
                                return [...prev, user]
                              })
                              setArrayOfAutoComplete(prev => {
                                return prev.filter((item) => item._id !== user._id)
                              })
                            }}
                          >
                            {user.fullName}
                          </li>
                        ))}
                      </ul>
                    }
                  </div>
                </div>
                <div className={styles.selectorContainer} style={{ zIndex: 1 }}>
                  <div className={styles.selector}
                    //  style={errors.folder ? errorStyle : {}}
                    onClick={() => setDropDownMenuPermission(prev => {
                      return {
                        ...prev,
                        active: !prev.active
                      }
                    })}>
                    <span className={!dropDownMenuPermission.value ? styles.label : ''}>{selectedPermission?.label || 'Permissions'}</span>
                    <ul className={dropDownMenuPermission.active ? `${styles.dropDownMenu} ${styles.active}` : styles.dropDownMenu}>
                      {permissions && permissions.map((permission) => (
                        <li key={permission.id}
                          onClick={(e) => { setSelectedPermission(permission) }}
                        >{permission.label}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.shareButtonContainer}>
          <button
            className={`${styles.shareButton} ${styles.shareLinkButton}`}
            onClick={() => getShareLink()}
          >
            Copy share link
          </button>
          <button
            className={styles.shareButton}
            disabled={!(selectedUsers.length > 0 && selectedPermission)}
            onClick={() => selectedUsers.length > 0 && selectedPermission && submitShare()}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  )
}

export default index
