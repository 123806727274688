/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './topArea.module.scss';
import ButtonWithIcon from './ButtonWithIcon';
import DownloadIcon from './svgs/Download';
// import FavouriteIcon from './svgs/Favourite';
// import ShareIcon from './svgs/Share';
import { downloadCatalogAPI, openDownloadCatalogAPI } from '../../../../../../api/contentUploadAPI';
import { useSelector } from 'react-redux';
import { errorToast } from 'toasts/Toasts';
import { useParams } from 'react-router-dom/dist';

export default function TopArea ({ id, downloadST, index, dlAll, shareMode }) {
  const [isDownloading, setIsDownloading] = useState(false)
  const { userdata } = useSelector((state) => state.userdata);
  const params = useParams();
  const { shareId } = params;

  const sharingMode = shareMode && shareId;

  const handledownloadCatalog = async () => {
    try {
    let response, url;
    if (!sharingMode) {
      response = await downloadCatalogAPI(id);
      url = response?.data?.data?.downloadURL || '';
    } else if (sharingMode) {
      response = await openDownloadCatalogAPI(shareId, id);
      url = response?.data?.data?.downloadURL || response?.data?.data?.zipfileLink || '';
    }
    if (!url) {
      return;
    }
    const link = document.createElement('a');
    link.href = url;
    link.click();
  } catch {
    errorToast('Something went wrong');
  }
}

  const initiateDownload = () => {
    if (!isDownloading) {
      setIsDownloading(true);
      !dlAll ? downloadST(index) : dlAll(id)
      setIsDownloading(false);
    }
  }
  const downloads = !sharingMode ? userdata?.downloads.templates : 0;
  const downloadLimit = !sharingMode ? userdata?.monthlyLimits?.downloads?.templates : 0;
  const canDownload = sharingMode ? true : downloads?.length < downloadLimit || downloads.some(el => el?.toString() === id?.toString());

  return (
    <div className={styles.container}>
      <div className={styles.buttonGroup} disabled={isDownloading}>
        <ButtonWithIcon disabled={!canDownload} text={canDownload ? 'Download' : 'You have reached your downloads limit'} icon={canDownload && <DownloadIcon />} onClick={(downloadST || dlAll) ? initiateDownload : handledownloadCatalog} />
      </div>
{/* 
      <div className={styles.buttonGroup}>
        <ButtonWithIcon text="Favourite" icon={<FavouriteIcon />} />
      </div>

      <div className={styles.buttonGroup}>
        <ButtonWithIcon text="Share" icon={<ShareIcon />} />
      </div> */}
    </div>
  );
}
