/* eslint-disable react/prop-types */

import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
// Rendering individual images
const Image = ({ image, deleteImage, errored }) => {
  const sliderHandler = (e) => {
    const container = document.getElementById('file-section-scroll')
    e.stopPropagation()
    if (e.deltaY > 0) {
      container.scrollLeft += 40
    } else {
      container.scrollLeft -= 40
    }
  }
  return (
    <div className='file-item'>
      <button
        onClick={() => deleteImage(image.id)}
        className='delete-image'
      >
        <span>x</span>
      </button>
      <img alt={`img - ${image.id}`} onWheel={sliderHandler} src={URL.createObjectURL(image.src)} style={{ objectFit: 'cover' }} className={`file-img ${errored ? 'error-tag' : ''}`} />
    </div>
  )
}

// ImageList Component//
const ImageGrid = ({ images, deleteImage, remainingUploads, isMultiUpload }) => {
  useEffect(() => {
    if (!isMultiUpload) return;
    const errored = document.getElementsByClassName('error-tag')
    if (errored.length > 0) {
      errored[errored.length - 1].scrollIntoView({ behavior: 'smooth', block: 'center' })
      const toastId = 'maxReachedErr'
      const toastMsg = 'You need to remove ' + errored.length + ' images to proceed.'
      const toastExists = toast.isActive(toastId)
      if (toastExists) {
        toast.update(toastId, {
          render: toastMsg
        })
      } else {
        toast.error(
          toastMsg,
          { 
            toastId
          }
        )
      }
    }
  }, [images, isMultiUpload])
  // render each image by calling Image component
  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        key={`${image.id}-image`}
        deleteImage={deleteImage}
        errored={remainingUploads < index + 1 && isMultiUpload}
      />
    )
  }
  // Return the list of files//
  return <section id='file-section-scroll' className='file-list'>{images.map(renderImage)}</section>
}

export default ImageGrid
