/* eslint-disable camelcase */
import axiosInstance from '../axios/axiosInstance';
import { objectToFormData } from 'helpers/objectToFormData';
import processSearchResultData from '../components/molecules/HorizontalMenu/functions/processSearchResultData';

export const prepareCatalogsAPI = async (catalogs) => {
  const usedData = catalogs?.map(data => {
    const { name, folders, contributor, assest_type } = data;
    return {
      name,
      folders,
      contributor,
      assest_type
    }
  })
  const response = await axiosInstance.post(
    '/catalog',
    usedData
  );
  return response;
};

export const createCatalogAPI = async (data, onProgress, cancelToken) => {
  const { id } = data;
  const response = await axiosInstance.post(
    `/catalog/step/${id}`,
    objectToFormData(data),
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onProgress,
      cancelToken: cancelToken || null
    }
  );
  return response;
};

export const createCatalgoAPIFromEditor = async (data, onProgress, cancelToken) => {
  const response = await axiosInstance.post(
    '/catalog/editor',
    objectToFormData(data),
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onProgress,
      cancelToken: cancelToken || null
    }
  );
  return response;
};

export const updateCatalogAPI = async (data) => {
 const noIdData = { ...data };
  delete noIdData.tempId;
  const response = await axiosInstance.patch(
    `/catalog/${data.tempId}`,
    JSON.stringify(noIdData),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
};

export const updateEditorTemplateAPI = async (data, onProgress) => {
  const response = await axiosInstance.patch(
    `catalog/editor-update-template/${data._id || data.id}`,
    objectToFormData(data),
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onProgress
    }
  );
  return response;
 };

export const featureCatalogAPI = async (id, data) => {
   const response = await axiosInstance.patch(
     `/catalog/featuring/${id}`,
     data,
     {
       headers: {
         'Content-Type': 'application/json'
       }
     }
   );
   return response;
 };

export const updateCatalogCollectionAPI = async (data) => {
  const noIdData = { ...data };
   delete noIdData.tempId;
   const response = await axiosInstance.patch(
     `/catalog/add-collection/${data.tempId}`,
     JSON.stringify(noIdData),
     {
       headers: {
         'Content-Type': 'application/json'
       }
     }
   );
   return response;
 };

export async function getSearchCatsAPI (params) {
  const response = await axiosInstance.get(`/catalog/search?${params}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getCatalogAPI (isPrivate) {
  const response = await axiosInstance.get(`/catalog?size=60&use_global=${!isPrivate ? 'true' : 'false'}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getFeaturedCatalogAPI (isPrivate) {
  const response = await axiosInstance.get(`/catalog/featured?size=60&use_global=${!isPrivate ? 'true' : 'false'}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getFeaturedCatalogByAssetTypeAPI (type, isPrivate) {
  const response = await axiosInstance.get(`/catalog/featured?size=60&assettype=${type}&use_global=${!isPrivate ? 'true' : 'false'}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getCatalogByFolderIdAPI (id, page, isPrivate) {
  const link = `/catalog/folder/${id}?size=60${page ? `&page=${page}` : ''}&use_global=${!isPrivate ? 'true' : 'false'}`;
  const response = await axiosInstance.get(link, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getCatalogByLinkShare (id) {
  const response = await axiosInstance.get(`/share-content/collection/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getCatalogByAssetTypeAPI (assetType, page, isPrivate) {
  const link = `/catalog/asset/${assetType}?size=60${page ? `&page=${page}` : ''}&use_global=${!isPrivate ? 'true' : 'false'}`;
  const response = await axiosInstance.get(link, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function getCatalogByCollectionIdAPI (id) {
  const response = await axiosInstance.get(`/catalog/collection/${id}?size=60`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function deleteCatalogAPI (id) {
  const response = await axiosInstance.delete(`/catalog/soft-delete/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (response.status === 200 || response.status === 201) {
    const newCatalogs = await getCatalogAPI();
    return newCatalogs;
  }
  return null;
}

export async function getCatalogByIdAPI (id) {
  const response = await axiosInstance.get(`/catalog/get-catalog/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
    return response;
}

export async function getAllMyCatalogsAPI () {
  const response = await axiosInstance.get('/catalog/all-my-catalogs', {
    headers: {
      'Content-Type': 'application/json'
    }
  });
    return response;
}

export async function downloadCatalogAPI (id, isProject) {
  const response = await axiosInstance.get(`/catalog/download/${id}?project=${isProject}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function openDownloadCatalogAPI (shareId, id, isProject) {
  const response = await axiosInstance.get(`/catalog/open-download/${shareId}/${id}?project=${isProject}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function uploadFileAPI (file, onProgress) {
  const response = await axiosInstance.post(
    '/catalog/editor-upload-file',
    file,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted);
      }
    }
  );
  return response;
}

export async function getTemplateZipFile (id) {
  const response = await axiosInstance.get(`/catalog/download-template/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
    return response;
}

export async function getCatalogFile (id, fromEditor) {
  const link = fromEditor
    ? `/catalog/download-template/${id}`
    : `/catalog/download-template/editor/${id}`;
  const response = await axiosInstance.get(link, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response;
}

export async function searchForNewRelease () {
  const url = '/catalog/newReleases';
  const { data } = await axiosInstance.get(`${url}`, {
    headers: { 'Content-Type': 'application/json' }
  })
  return processSearchResultData(data);
}

export const getLink = async (id) => {
  const response = await getTemplateZipFile(id)
    if (response?.data?.data?.zipfileLink) {
      return response?.data?.data?.zipfileLink
    } else {
      return null
    }
}

export async function searchForPopular () {
  const url = '/catalog/popular';
  const { data } = await axiosInstance.get(`${url}`, {
    headers: { 'Content-Type': 'application/json' }
  })
  return processSearchResultData(data);
}
