/* eslint-disable no-unused-vars */
/* eslint-disable no-new */
import React, { useCallback, useState, useEffect } from 'react'
import exifr from 'exifr'
import Dropzone from './DropZone'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import './main.css'
import Compressor from 'compressorjs';
import styles from './styles.module.scss';
import { errorToast } from 'toasts/Toasts'

const UploadOptions = ({ images, setImages, imageURL, setImageURL, selectedType, setSelectedType, remainingUploads }) => {
  const [loadingImages, setLoadingImages] = useState(false)

  const imageAccept = {
    'image/*': ['.jpeg', '.jpg', '.png']
  };
  const videoAccept = {
    'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm']
  }

  const deleteImage = imageId => {
    const newimages = images.filter(image => image.id !== imageId)
    setImages(newimages)
  }

  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif']
  const allowedVideoTypes = ['video/mp4', 'video/webm']

  async function generateImgFromVideoFile (videoFile) {
    return new Promise((resolve, reject) => {
      // Create a video element
      const video = document.createElement('video');
      video.src = URL.createObjectURL(videoFile);
      video.crossOrigin = 'Anonymous'; // Handle CORS if necessary

      // Ensure the video loads metadata (such as duration and dimensions)
      video.addEventListener('loadedmetadata', () => {
        // Seek to the first frame
        video.currentTime = 0;
      });

      // Once the first frame is loaded, draw it to a canvas
      video.addEventListener('seeked', () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');

        // Draw the video frame to the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a Blob (PNG)
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      });

      video.addEventListener('error', (e) => {
        reject(e);
      });
    });
  }

  const onDrop = useCallback(acceptedFiles => {
    const usedAllowedTypes = selectedType === 'Videos' ? allowedVideoTypes : allowedTypes
    acceptedFiles.map(async (file, index) => {
      if (!usedAllowedTypes.includes(file.type)) {
        errorToast(`Only ${selectedType} are allowed`)
        return
      }
      const possibleExifData = await exifr.parse(file, true)
      setLoadingImages(true)
      const compressedFile = file.type === 'image/jpeg'
        ? await new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.75,
            success (result) {
              resolve(new File([result], file.name, { type: file.type }));
            },
            error () {
              reject(file);
            }
          });
        })
        : file
      setImages(prevState => [...prevState, { id: uuidv4(), src: compressedFile, exifData: possibleExifData }])
      if (index === acceptedFiles.length - 1) {
        setLoadingImages(false)
      }
      return compressedFile
    })
  }, [selectedType])

  const onDropVideo = useCallback(acceptedFiles => {
    const usedAllowedTypes = allowedVideoTypes
    setLoadingImages(true)
    acceptedFiles.forEach(async (file, index) => {
      try {
      if (!usedAllowedTypes.includes(file.type)) {
        errorToast(`Only ${selectedType} are allowed`)
        return
      }
      const vidImg = await generateImgFromVideoFile(file)
      setImages(prevState => [...prevState, { id: uuidv4(), src: vidImg, video: file }])
      if (index === acceptedFiles.length - 1) {
        setLoadingImages(false)
      }
      return vidImg
    } catch (error) {
      errorToast('An error occured while uploading video' + error)
      setLoadingImages(false)
    }
    })
  }, [selectedType])

  return (
    <div className='drop-upload-container'>
      <h4 className='upload-title'>Upload Content to MPublisher</h4>
      <div className='upload-subtitle'>
        <div>
          <p className='subtext'>
            You can upload up to 100 images, we will review them and get back to
            you
          </p>
        </div>
        <div className='radio-container'>
          <input type='radio' id="radio-input-image" value={'Images'} checked={selectedType === 'Images'} onChange={() => setSelectedType('Images')} />
          <label htmlFor="radio-input-image">Images</label>

          {/* <input type='radio' id="radio-input-mocckup" value={'Mockups'} checked={selectedType === 'Mockups'} onChange={() => setSelectedType('Mockups')} />
          <label htmlFor="radio-input-mocckup">Mockups</label> */}

          <input type='radio' id="radio-input-template" value={'Templates'} checked={selectedType === 'Templates'} onChange={() => setSelectedType('Templates')} />
          <label htmlFor="radio-input-template">Templates</label>

          <input type='radio' id="radio-input-video" value={'Videos'} checked={selectedType === 'Videos'} onChange={() => setSelectedType('Videos')} />
          <label htmlFor="radio-input-video">Videos</label>
        </div>
      </div>

      <Dropzone
        onDrop={selectedType === 'Videos' ? onDropVideo : onDrop}
        accept={selectedType === 'Videos' ? videoAccept : imageAccept}
        images={images}
        deleteImage={deleteImage}
        loadingImages={loadingImages}
        remainingUploads={remainingUploads}
        isMultiUpload={selectedType === 'Images'}
      />
    </div>
  )
}

UploadOptions.propTypes = {
  images: PropTypes.array,
  setImages: PropTypes.func,
  imageURL: PropTypes.string,
  setImageURL: PropTypes.func,
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
  remainingUploads: PropTypes.number
}
export default UploadOptions
