/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import './photo.scss';
import getCurrentUser from './utils/getCurrentUser';
import noImage from 'assets/images/noimage.png';
import { ReactComponent as Trash } from 'assets/icons/trashicon.svg';
import { ReactComponent as EditIco } from 'assets/icons/editicon.svg';
import { ReactComponent as Download } from 'assets/icons/downloadicon.svg';
import { ReactComponent as Share } from 'assets/icons/shareicon.svg';
import { ReactComponent as Fullscreen } from 'assets/icons/fullscreen.svg';
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger
} from '../../molecules/ContextMenuWrapper';
import { ReactComponent as FtStar } from 'assets/icons/ft-star.svg';
import { createPortal } from 'react-dom';
const imgWithClick = { cursor: 'pointer' };

const Photo = ({
  assetUserId,
  currentUser,
  index,
  onClick,
  catalog,
  photo,
  linearLine,
  margin,
  direction,
  top,
  left,
  noInteraction,
  templates,
  subCatalogView,
  reverseThumbnail,
  allCatalogs,
  mixedContent,
  height,
  useOriginal,
  isTouchScreen,
  OverRuledRoles,
  homePage,
  navigateToEditor
}) => {
  const placeholderImage = noImage;
  // const { userRole: { roles } } = useSelector(state => state.auth);
  const imgStyle = {
    margin,
    display: 'block',
    maxHeight: allCatalogs.length <= 4 && !homePage ? '350px' : 'unset',
    minHeight: allCatalogs.length <= 4 && !homePage ? '250px' : 'unset'
  };

  const imgContainerRef = React.createRef(null);
  const imgRef = React.createRef(null);
  const diaporamaTimer = React.createRef(null);
  const diaporamaProgress = React.createRef(null);
  const diaporamaContainer = React.createRef(null);
  const progressBar = React.createRef(null);
  const canFeatureNow = React.createRef(true);

  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleInteraction = (state) => {
    onClick(state, { ...catalog, index });
  };

  const sizeReducer = () => {
    const { height, width } = photo;
    if (allCatalogs.length <= 4 && !homePage) {
      const ratio = width / height;
      if (height >= 350) {
        return 350 * ratio;
      } else if (height <= 200) {
        return 200 * ratio;
      }
    }
    return width;
  };

  const startDiaporama = (e, index = 0) => {
    const imgEl = imgRef?.current;
    const currentIndex = index;
    const arrOfTemps = catalog?.template_data?.templates;
    if (
      !imgEl ||
      arrOfTemps?.length <= 1 ||
      !diaporamaContainer ||
      !progressBar
    ) {
      return;
    }
    const currentTemplate = arrOfTemps[currentIndex];
    diaporamaProgress.current = {
      cIndex: currentIndex + 1,
      size: arrOfTemps.length
    };
    diaporamaContainer.current.style.display = 'block';
    progressBar.current.style.width = `${
      (currentIndex * 100) / arrOfTemps.length
    }%`;
    imgEl.src = reverseThumbnail || (mixedContent && photo?.assest_type === 'Templates')
      ? currentTemplate?.mixedSampleThumbnail || currentTemplate?.original
      : currentTemplate?.thumbnail;
    diaporamaTimer.current = setTimeout(() => {
      if (currentIndex < arrOfTemps.length - 1) {
        startDiaporama(e, currentIndex + 1);
      } else {
        startDiaporama(e, 0);
      }
    }, 1000);
  };

  const stopDiaporama = () => {
    clearTimeout(diaporamaTimer.current);
    const imgEl = imgRef?.current;
    imgEl.src = useOriginal
      ? photo?.src?.original
      : reverseThumbnail || (mixedContent && photo?.assest_type === 'Templates')
      ? photo?.src?.mixedSampleThumbnail || photo?.src?.original
      : photo?.src?.thumbnail;
    diaporamaProgress.current = null;
    diaporamaContainer.current.style.display = 'none';
    progressBar.current.style.width = '0%';
  };

  const featureCatalog = (e) => {
    e.stopPropagation();
    const ftBtn = document.getElementById(
      `feature-btn-${photo?.id || photo?._id}`
    );
    ftBtn.setAttribute('disabled', 'true');
    ftBtn.classList.add('disabled');
    handleInteraction(9);
    setTimeout(() => {
      ftBtn.classList.remove('disabled');
      ftBtn.removeAttribute('disabled');
    }, 2000);
  };

  const defaultInteractionOfCurrentUser = Array.isArray(OverRuledRoles)
    ? OverRuledRoles
    : getCurrentUser(currentUser, catalog) || ['SHARE', 'DOWNLOAD'];
  if (catalog?.assest_type === 'Templates' && (currentUser?.user?.id === catalog?.userId)) {
    defaultInteractionOfCurrentUser.push('EDIT-TEMPLATE');
  }

  // 1: view, 2: share, 3: download, 4: edit, 7: delete, 6: template, 8: delete from collection
  const potentialUseIcons = [
    {
      type: 'EDIT-TEMPLATE',
      text: 'Editor',
      jsx: (
        <>
          <EditIco onClick={() => navigateToEditor && navigateToEditor(photo)} />
          <span className="tooltipInfo">Editor</span>
        </>
      )
    },
    {
      type: 'EDIT',
      text: 'Edit',
      jsx: (
        <>
          <EditIco onClick={onClick ? () => handleInteraction(4) : null} />
          <span className="tooltipInfo">Edit</span>
        </>
      )
    },
    {
      type: 'DELETE',
      text: 'Delete',
      jsx: (
        <>
          <Trash onClick={onClick ? () => handleInteraction(7) : null} />
          <span className="tooltipInfo">Delete</span>
        </>
      )
    },
    {
      type: 'CDELETE',
      text: 'Delete from collection',
      jsx: (
        <>
          <Trash onClick={onClick ? () => handleInteraction(8) : null} />
          <span className="tooltipInfo">Delete</span>
        </>
      )
    },
    {
      type: 'SHARE',
      text: 'Share',
      jsx: (
        <>
          <Share onClick={onClick ? () => handleInteraction(2) : null} />
          <span className="tooltipInfo">Add to collection</span>
        </>
      )
    },
    {
      type: 'DOWNLOAD',
      text: 'Download',
      jsx: (
        <>
          <Download onClick={onClick ? () => handleInteraction(3) : null} />
          <span className="tooltipInfo">Download</span>
        </>
      )
    }
  ].reverse();

  const potentialUseContextMenuEntries = [
    {
      type: 'DOWNLOAD',
      text: 'Download',
      jsx: <MenuItem onClick={() => handleInteraction(3)}>Download</MenuItem>
    },
    {
      type: 'SHARE',
      text: 'Share',
      jsx: <MenuItem onClick={() => handleInteraction(2)}>Share</MenuItem>
    },
    {
      type: 'DELETE',
      text: 'Delete',
      jsx: <MenuItem onClick={() => handleInteraction(7)}>Delete</MenuItem>
    },
    {
      type: 'CDELETE',
      text: 'Delete',
      jsx: <MenuItem onClick={() => handleInteraction(8)}>Delete</MenuItem>
    },
    {
      type: 'EDIT',
      text: 'Edit',
      jsx: <MenuItem onClick={() => handleInteraction(4)}>Edit</MenuItem>
    },
    {
      type: 'FEATURE',
      text: 'Feature',
      jsx: (
        <MenuItem
          onClick={featureCatalog}
        >
          Feature
        </MenuItem>
      )
    }
  ];

  return (
    <>
      <ContextMenuTrigger id={catalog?.id || catalog?._id}>
        <div
          className="photo__img-container"
          onMouseEnter={
            photo?.assest_type === 'Templates' ? () => startDiaporama() : null
          }
          onMouseLeave={
            photo?.assest_type === 'Templates' ? () => stopDiaporama() : null
          }
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          ref={imgContainerRef}
        >
          <img
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            ref={imgRef}
            src={
              useOriginal
                ? photo?.src?.original
                : reverseThumbnail ||
                  (mixedContent && photo?.assest_type === 'Templates')
                ? photo?.src?.mixedSampleThumbnail || photo?.src?.original
                : photo?.src?.thumbnail
            }
            // on start loading
            // any react attrib for on
            onLoad={(e) => e.target.classList.remove('skeliton')}
            onError={(e) => {
              e.target.src = placeholderImage;
            }}
            alt={photo?.alt}
            width={linearLine ? 'unset' : sizeReducer()}
            height={linearLine || height ? `${height}px` : photo?.height}
            loading="lazy"
            onClick={
              onClick
                ? () =>
                    handleInteraction(
                      templates || photo?.assest_type === 'Templates'
                        ? 6
                        : window.innerWidth > 800
                        ? 1
                        : 5
                    )
                : null
            }
            className="photo__img skeliton"
          />
          {!noInteraction && (
            <>
              <div
                className="photo__overlay"
                onClick={(e) => {
                  e.stopPropagation();
                  handleInteraction(
                    templates || photo?.assest_type === 'Templates'
                      ? 6
                      : window.innerWidth > 800
                      ? 1
                      : 5
                  );
                }}
                style={{
                  top: `${homePage ? margin : 0}px`,
                  left: `${margin}px`,
                  right: `${margin}px`
                }}
              >
                <div className="photo__title">
                  {!subCatalogView && (photo?.name || 'Untitled')}
                </div>
                <div
                  className={
                    photo?.width / photo?.height > 1.3
                      ? 'photo__icon-container'
                      : 'photo__icon-container column'
                  }
                  style={
                    photo?.width / photo?.height < 1.3
                      ? { height: photo?.height }
                      : {}
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {!isTouchScreen && currentUser && (
                    <>
                      <div
                        className="photo__circle allways-visible"
                        onClick={
                          onClick
                            ? () =>
                                handleInteraction(
                                  window.innerWidth > 800 ? 1 : 5
                                )
                            : null
                        }
                      >
                        <Fullscreen />
                        <span className="tooltipInfo">View</span>
                      </div>
                      {potentialUseIcons.map((data, index) => {
                        if (
                          defaultInteractionOfCurrentUser?.includes(data.type)
                        ) {
                          return (
                            <div
                              key={data.type || index}
                              className="photo__circle"
                            >
                              {data.jsx}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  )}
                </div>
              </div>
              <div ref={diaporamaContainer} className="diaporama-progress">
                {/* <span ref={progressText} className="diaporama-progress__text" /> */}
                <div ref={progressBar} className="diaporama-progress__bar" />
              </div>
              {defaultInteractionOfCurrentUser?.includes('FEATURE') && (
                <div className="feautiring-container">
                  <button
                    id={`feature-btn-${photo?.id || photo?._id}`}
                    className="feautiring-container__button"
                    onClick={featureCatalog}
                  >
                    <FtStar
                      id={`featured-star-${photo?.id || photo?._id}`}
                      className={catalog?.is_featured ? 'ft-ico active' : 'ft-ico'}
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </ContextMenuTrigger>
      {document.getElementById('context-root') &&
        createPortal(
          <ContextMenu id={catalog?.id || catalog?._id}>
            <MenuItem
              onClick={() => handleInteraction(window.innerWidth > 800 ? 1 : 5)}
            >
              View
            </MenuItem>
            {potentialUseContextMenuEntries.map((data, index) => {
              if (defaultInteractionOfCurrentUser?.includes(data.type)) {
                return data.jsx;
              }
              return null;
            })}
          </ContextMenu>,
          document.getElementById('context-root')
        )}
    </>
  );
};

export const photoPropType = PropTypes.shape({
  src: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  srcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string
});

Photo.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  photo: photoPropType.isRequired,
  margin: PropTypes.number,
  top: (props) => {
    if (props.direction === 'column' && typeof props.top !== 'number') {
      return new Error(
        'top is a required number when direction is set to `column`'
      );
    }
  },
  left: (props) => {
    if (props.direction === 'column' && typeof props.left !== 'number') {
      return new Error(
        'left is a required number when direction is set to `column`'
      );
    }
  },
  direction: PropTypes.string,
  catalogs: PropTypes.arrayOf(PropTypes.object),
  catalog: PropTypes.object,
  noInteraction: PropTypes.bool,
  templates: PropTypes.bool,
  subCatalogView: PropTypes.bool,
  reverseThumbnail: PropTypes.bool,
  allCatalogs: PropTypes.arrayOf(PropTypes.object),
  noWrap: PropTypes.bool
};

export default Photo;
