/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react'
import styles from './styles.module.scss'
import '@master-wizr/wizr-filter/dist/index.css'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SingleAssetPM from '../myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../myTemplatesCatalogs/components/PopupManger'
import GridImagesLayout from '../myImageCatalogs/components/GridImagesLayout'
import { ReactComponent as GridIconOne } from '../../../assets/icons/grid1.svg';
import { ReactComponent as GridIconTwo } from '../../../assets/icons/grid2.svg';
import { ReactComponent as GridIconThree } from '../../../assets/icons/grid3.svg';
import { getLink } from 'api/contentUploadAPI'
import { toast } from 'react-toastify'
import Pagination from '../../molecules/Pagination';
import { useTranslation } from 'react-i18next';
import { getAllMyCatalogs } from 'Redux/content/contentSlice'
import SearchBar from '../files/SearchBar/SearchBar'

const assetList = [
  'Images',
  'Videos',
  'Templates'
]

const AllMyCatalogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [searchInput, setSearchInput] = useState('');
  const { folders } = useSelector(state => state.folders);
  const { loading } = useSelector(state => state.content);
  const { user } = useSelector((state) => state.auth);
  const { currentCompanyInfo } = useSelector(
    (state) => state.profile
  );

  const { id } = user;
  const companyId = currentCompanyInfo?._id || currentCompanyInfo?.id;

  const beCatalogs = useSelector(state => state.content.myCatalogs.result);
  const [catalogs, setCatalogs] = useState([]);
  const [viewSize, setViewSize] = useState((20 * window.innerHeight) / 90);
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);

  const assetSearch = searchParams?.get('asset');
  const assetArr = assetSearch ? assetSearch.split(',') : [];
  const typeSearch = searchParams?.get('dl_type');
  const typeArr = typeSearch ? typeSearch.split(',') : [];
  const pageParams = searchParams?.get('page') || 1;

  const filterSelection = {
    assets: assetArr,
    types: typeArr
  }

  const CONTENT_COUNT = 20;

  let filteredCatalogs = [...catalogs]

  filteredCatalogs = catalogs.filter((catalog) => {
    if (filterSelection.assets.length > 0) {
      if (!filterSelection.assets.includes(catalog.assest_type)) {
        return false;
      }
    }
    if (filterSelection.types.length > 0) {
      if (filterSelection.types.includes('Uploads')) {
        return catalog.userId === id && catalog.companyId === companyId;
      } else if (filterSelection.types.includes('Downloads')) {
        return catalog.userId !== id || catalog.companyId !== companyId;
      }
    }
    return true;
  });

  if (searchInput) {
    filteredCatalogs = [...filteredCatalogs].filter(catalog => {
      if (searchInput) {
        return catalog?.name?.toLowerCase()?.includes(searchInput?.toLowerCase());
      }
        return true;
    })
  }

  const totalElements = filteredCatalogs?.length;
  const totalPages = Math.ceil((filteredCatalogs?.length) / CONTENT_COUNT);
  const hasNextPage = pageParams < totalPages;

  const localPageDetails = {
    page: {
      // size: 1000,
      totalElements,
      totalPages,
      number: parseInt(pageParams) || 1,
      hasNextPage
    }
  }

  const { page } = localPageDetails;

  const paginatedCatalogs = filteredCatalogs.slice((page.number - 1) * CONTENT_COUNT, page.number * CONTENT_COUNT);

  const { t } = useTranslation();

  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  useLayoutEffect(() => {
    if (beCatalogs?.length) {
      return;
    }
    dispatch(getAllMyCatalogs());
  }, [location])

  useEffect(() => {
    if (beCatalogs) {
      const catArr = beCatalogs.map((catalog) => {
        if (catalog.template_data) {
          return {
            ...catalog,
            id: catalog?._id || catalog?.id,
            src: catalog?.template_data?.templates[0],
            width: catalog?.template_data?.templates[0]?.width || 1920,
            height: catalog?.template_data?.templates[0]?.height || 1080
          }
        } else if (catalog.assest_type === 'Videos') {
          return {
            ...catalog,
            src: catalog.video_urls,
            width: catalog?.vid_metadata?.width || 16,
            height: catalog?.vid_metadata?.height || 9
          }
        }
        return {
          ...catalog,
          id: catalog?._id || catalog?.id,
          src: catalog?.assest_urls[0],
          width: catalog?.image_size?.width || 16,
          height: catalog?.image_size?.height || 9
        }
      })
      setCatalogs(catArr);
    }
  }, [beCatalogs])

  const catalogTypeFinder = (id) => {
    const folder = folders.find((folder) => folder.id === id)
    if (folder) {
      if (folder.is_global_folder) {
        return 'catalogs'
      } else {
        return 'my-catalogs'
      }
    }
    return null
  }

  const handleClickSingle = (state, catalog) => {
    if (state === 7) {
      setSinglePreviewPopup({
        state,
        data: catalog
      })
    }
    if (state === 6) {
      const typeOfCats = catalogTypeFinder(catalog.folders[0])
      if (typeOfCats) {
        const link = `/mpublisher/${typeOfCats}/Templates/${catalog.folders[0]}/${catalog.id}`
        navigate(link, { state: { catalog } })
      }
    } else if (catalog.assest_type === 'Images' || catalog.assest_type === 'Videos') {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      }
    } else if (catalog.assest_type === 'Templates') {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const downloadTemplate = async (id) => {
    getLink(id).then((res) => {
      if (res) {
        const link = document.createElement('a');
        link.href = res;
        link.click();
      } else {
        toast.error('Something went wrong, please try again later');
      }
    })
  }

  const addDeleteAssetType = (asset) => {
    if (assetArr.includes(asset)) {
      const newAssetArr = assetArr.filter((item) => item !== asset);
      if (newAssetArr?.length > 0) {
        searchParams?.set('asset', newAssetArr.join(','));
      } else {
        searchParams?.delete('asset');
      }
    } else {
      searchParams?.set('asset', [...assetArr, asset].join(','));
    }
    searchParams.sort();
    const currentUrl = location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ',');
    navigate(currentUrl);
  }

  const addDeleteType = (type) => {
    if (type === 'All') {
      searchParams?.delete('dl_type');
    } else {
      if (typeArr.includes(type)) {
        return;
      } else {
        searchParams?.set('dl_type', type);
      }
    }
    searchParams.sort();
    const currentUrl = location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ',');
    navigate(currentUrl);
  }

  const handleLocalPageNav = (type) => {
    if ((type === 'left' && page?.number === 1) || (type === 'right' && !page?.hasNextPage)) {
      return;
    }
    const addedValue = type === 'left' ? -1 : 1;
    if (pageParams) {
      searchParams?.set('page', page?.number + addedValue);
    } else {
      searchParams?.append('page', page?.number + addedValue);
    }
    searchParams?.sort(
      // page always last
      (a, b) => {
        if (a[0] === 'p') return 1;
        if (b[0] === 'p') return -1;
        return 0;
      }
    );
    const currentUrl = location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ',');
    navigate(currentUrl);
  }

  const localNavToPage = (pageN) => {
    if ((pageN === page?.number) || pageN < 1 || pageN > page?.totalPages || isNaN(pageN) || !pageN?.trim()) {
      return;
    }
    if (pageParams) {
      searchParams?.set('page', pageN);
    } else {
      searchParams?.append('page', pageN);
    }
    searchParams?.sort(
      // page always last
      (a, b) => {
        if (a[0] === 'p') return 1;
        if (b[0] === 'p') return -1;
        return 0;
      }
    );
    const currentUrl = location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ',');
    navigate(currentUrl);
  }

  const getNextLink = () => {
    if (searchParams?.size === 0) {
      return location.pathname + '?page=2';
    } else {
      if (searchParams?.has('page')) {
        const url = location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ',').split('page')[0];
        return url + `page=${page?.number + 1}`;
      } else {
        return location.pathname + '?' + searchParams?.toString()?.replace(/%2C/g, ',') + `&page=${page?.number + 1}`;
      }
    }
  }

  const localNxtLink = page?.hasNextPage ? getNextLink() : null;

  useLayoutEffect(() => {
    if (page?.number > page?.totalPages) {
      localNavToPage(page?.totalPages?.toString() || '1');
    }
  }, [location, localPageDetails])

  return (
    <div className={styles.wrappingElement}>
      <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={catalogs} />
      <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} dlAll={downloadTemplate} />
      <div className={styles.searchBarContainer}>
        <SearchBar
          // onSelectChange={setSelectedAssetType}
          // selectValue={selectedAssetType}
          onTextChange={setSearchInput}
          textValue={searchInput}
        />
        {/* <h1>{t('My catalogs')}</h1> */}
      </div>
      <div className={styles.contentSizing}>
        <div className={styles.contentAreaHeaderMiniFilter}>
          <div className={styles.headerElement}>
            <div className={styles.headerContainer}>
              <span className={styles.headerElementTitle}><strong>My catalogs: </strong>{catalogs.length} Usable catalogs</span>
            </div>
            <div className={styles.sizingContianer}>
              <div className={styles.filterByRoleContainer}>
                <div className={styles.filterByRoleSwingContainer}>
                  <span className={`${styles.roleSpan} ${!searchParams?.has('dl_type') ? styles.active_role : ''}`} onClick={() => addDeleteType('All')}>{t('All')}</span>
                  <span className={`${styles.roleSpan} ${typeArr?.includes('Uploads') ? styles.active_role : ''}`} onClick={() => addDeleteType('Uploads')}>{t('Uploads')}</span>
                  <span className={`${styles.roleSpan} ${typeArr?.includes('Downloads') ? styles.active_role : ''}`} onClick={() => addDeleteType('Downloads')}>{t('Downloads')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentFilterFull}>
          <div className={styles.miniFiltringAndView}>
            <div className={styles.miniFilterContainer}>
              <ul className={styles.tagsContainer}>
                {
                  assetList.map((asset) => {
                    return (
                      <li key={asset} className={filterSelection?.assets?.includes(asset) ? `${styles.tagSpan} ${styles.active}` : styles.tagSpan} onClick={() => addDeleteAssetType(asset)}>{asset}</li>
                    )
                  })
                }
              </ul>
            </div>

            <div className={styles.viewsContainer}>
              <button
                className={Math.floor((30 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                onClick={() => setViewSize(Math.floor((30 * window.innerHeight) / 100))}>
                <GridIconOne />
              </button>
              <button
                className={Math.floor((20 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                onClick={() => setViewSize(Math.floor((20 * window.innerHeight) / 100))}
              >
                <GridIconTwo />
              </button>
              <button
                className={Math.floor((15 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                onClick={() => setViewSize(Math.floor((15 * window.innerHeight) / 100))}>
                <GridIconThree />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contentArea}>
        {
          loading && Array.isArray(beCatalogs) && beCatalogs.length === 0
            ? <div className={styles.noResultsContainer}>
              <h3 className={styles.noResultsTitle}>You have not added catalogs yet</h3>
            </div>
            : (
              <>
                <GridImagesLayout
                  catalogs={paginatedCatalogs}
                  onClick={handleClickSingle}
                  innerProps={{
                    targetRowHeight: viewSize,
                    margin: viewSize > 140 ? 10 : 15,
                    isTouchScreen: isTouchScreen(),
                    mixedContent: true
                  }} />
                {paginatedCatalogs.length > 0 && (localPageDetails?.page?.hasNextPage || localPageDetails?.page?.number > 1) &&
                  <div className={styles.paginationContainer}>
                    <Pagination
                      pageDetails={localPageDetails}
                      handleLocalPageNav={handleLocalPageNav}
                      localNavToPage={localNavToPage}
                      localNxtLink={localNxtLink}
                    />
                  </div>
                }
              </>
            )
        }

      </div>
    </div>
  )
}

export default AllMyCatalogs
