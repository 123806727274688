/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getCollectionsByFolderIdAPI, getCollectionFolderByIdAPI, getCollectionsSharedByMeAPI, getCollectionsSharedWithMeAPI } from 'api/collections';
import { getCatalogByCollectionIdAPI } from 'api/contentUploadAPI';
import Gallery from 'components/organisms/GalleryLayout/Gallery';
import SingleAssetPM from '../../myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../../myTemplatesCatalogs/components/PopupManger'
import ItemsComponent from '../components/ItemsComponent'
import { useSelector } from 'react-redux';
import SearchBar from '../SearchBar/SearchBar';
import MiniPopupManager from '../components/MiniPopupManager';
import isMongoId from 'helpers/isMongo';
import NotFound from 'components/pages/NotFound/NotFound';
import UnavailableContent from 'components/pages/UnavailableContent/UnavailableContent';
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import NoCatalogsCollection from '../NoCatalogsCollection/NoCatalogsCollection';

const assetTypes = [
  { id: 1, name: 'All', value: 'all' },
  { id: 2, name: 'Images', value: 'images' },
  { id: 3, name: 'Videos', value: 'videos' },
  { id: 4, name: 'Audio', value: 'audio' },
  { id: 5, name: 'Documents', value: 'documents' }
]

const bannerStyle = {
  backgroundImage: 'var(--col-s-banner)',
  backgroundColor: 'var(--button-normal)',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
};

const MyCollectionFolder = () => {
  const allCollections = useSelector((state) => state.collections?.collections)
  const loadingCollections = useSelector((state) => state.collections?.loading)
  const { folders } = useSelector(state => state.folders);
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [assetType, setAssetType] = useState(null)
  const [collections, setCollections] = useState([])
  const [collection, setCollection] = useState({})
  const [catalogs, setCatalogs] = useState([])
  const [folderData, setFolderData] = useState([])
  const [assetsBtnSelector, setAssetsBtnSelector] = useState(false)
  const [addCollectionPopup, setAddCollectionPopup] = useState(false)
  const [interactionPopups, setInteractionPopups] = useState(null)
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);
  const [isInvalidContent, setIsInvalidContent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [searchInput, setSearchInput] = useState('');
  const { collectionFolderId, collectionId } = useParams()
  const isValidFolderMongoId = isMongoId(collectionFolderId) || (collectionFolderId === 'shared-with-me' || collectionFolderId === 'shared-by-me' || collectionFolderId === 'collections' || collectionFolderId === undefined)
  const isValidCollectionMongoId = isMongoId(collectionId) || collectionId === undefined
  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const getCollections = async () => {
    const response = await getCollectionsByFolderIdAPI(collectionFolderId)
    if (response?.data?.statusCode === 200 && response?.data?.data?.result && Array.isArray(response?.data?.data?.result)) {
      return response?.data?.data?.result.map(el => {
        return {
          ...el,
          id: el._id || el.id,
          pendingAssets: true
        }
      })
    }
    return []
  }

  const getFolderData = async () => {
    const response = await getCollectionFolderByIdAPI(collectionFolderId)
    try {
      return response.data.data
    } catch {
      return []
    }
  }

  const getACollectionCatalogs = async (id) => {
    try {
      const response = await getCatalogByCollectionIdAPI(id)
      const catalogsData = response?.data?.data?.result || null
      if (catalogsData && catalogsData.length) {
        const previewCatalogData = catalogsData.map((catalog, i) => {
          if (catalog?.assest_type === 'Templates') {
            return {
              ...catalog,
              index: i,
              id: catalog?._id || catalog?.id,
              src: catalog?.template_data?.templates[0],
              width: catalog?.template_data?.templates[0]?.width || 1920,
              height: catalog?.template_data?.templates[0]?.height || 1080
            }
          } else if (catalog.assest_type === 'Videos') {
            return {
              ...catalog,
              src: catalog.video_urls,
              width: catalog?.vid_metadata?.width || 16,
              height: catalog?.vid_metadata?.height || 9
            }
          }
          return {
            ...catalog,
            index: i,
            id: catalog?._id || catalog?.id,
            src: catalog?.assest_urls[0],
            width: catalog?.image_size?.width || 16,
            height: catalog?.image_size?.height || 9
          }
        })
        return previewCatalogData
      }
    } catch {
      return []
    }
    return []
  }

  const getAllData = async () => {
    if (!location.state?.folder) { // can receive from state navigate
      getFolderData().then(data => {
        if (data?.message && data?.message?.includes('not found')) {
          setIsInvalidContent(true)
          return;
        }
        setFolderData(data)
      })
    } else {
      setFolderData(location.state.folder)
    }
    getCollections().then(data => { setCollections(data); return data })
  }

  const catalogTypeFinder = (id) => {
    const folder = folders.find((folder) => folder.id === id)
    if (folder) {
      if (folder.is_global_folder) {
        return 'catalogs'
      } else {
        return 'my-catalogs'
      }
    }
    return null
  }

  const handleClickMulti = (state, catalog) => {
    if (state === 6) {
      const typeOfCats = catalogTypeFinder(catalog.folders[0])
      if (typeOfCats) {
        const link = `/mpublisher/${typeOfCats}/Templates/${catalog.folders[0]}/${catalog.id}`
        navigate(link, { state: { catalog } })
      }
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else if (state === 8) {
        setMultiPreviewPopup({
          state,
          data: catalog,
          setterDelFn: setCatalogs
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClickSingle = (state, catalog) => {
    if (state === 6) {
      navigate(`/mpublisher/my-catalogs/Templates/${catalog.folders[0]}/${catalog.id}`, { state: { catalog } })
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else if (state === 8) {
        setSinglePreviewPopup({
          state,
          data: catalog,
          setterDelFn: setCatalogs
        })
      } else {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClick = (state, catalog) => {
    if (catalog.assest_type === 'Templates') {
      handleClickMulti(state, catalog)
    } else {
      handleClickSingle(state, catalog)
    }
  }

  useEffect(() => {
    setIsInvalidContent(false);
    if (!isValidFolderMongoId) {
      setIsInvalidContent(true)
      setIsLoading(false)
      return
    }
    setSearchInput('')
    if (params && collectionFolderId && !collectionId) {
      getAllData().then(() => { setIsLoading(false); })
    } else if (params && collectionId && isMongoId(collectionId)) {
      if (location.state?.collection) {
        setCollection(location.state?.collection)
        setIsLoading(false)
      } else {
        const cfdata = allCollections?.find(el => el.id === collectionId || el._id === collectionId)
        if (cfdata) {
          setCollection(cfdata)
          setIsLoading(false)
        } else {
          !loadingCollections && setIsInvalidContent(true)
          setIsLoading(loadingCollections)
        }
      }
      if (location.state && location.state?.catalogs?.length) {
        setCatalogs(location.state?.catalogs)
      } else {
        // get data manually
        getACollectionCatalogs(collectionId).then(data => { setCatalogs(data) })
      }
    } else if (params && collectionId && !isMongoId(collectionId)) {
      collectionId !== undefined && setIsLoading(false)
    }
  }, [location, allCollections, isValidFolderMongoId, params]);

  const generateOverRules = () => {
    // here we'll handle role cases
    return ['SHARE', 'DOWNLOAD', 'CDELETE'];
  }

  return (
    isValidFolderMongoId && !isInvalidContent && isValidCollectionMongoId
      ? (
        <div className={styles.wrapper}>
          {
            !isLoading
              ? (
                <>
                  {
                    !collectionId
                      ? (
                        <>
                          <MiniPopupManager
                            interactionPopups={interactionPopups}
                            addCollectionPopup={addCollectionPopup}
                            setAddCollectionPopup={setAddCollectionPopup}
                            setInteractionPopups={setInteractionPopups}
                            AllDelete={true}
                            folderData={folderData}
                            getAllData={getAllData}
                            deleteShare={collectionFolderId.includes('shared')}
                            canAddCollection={true}
                          />
                        </>
                      )
                      : (
                        <>
                          <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={catalogs} />
                          <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} />
                        </>
                      )
                  }

                  <div className={styles.searchBar}>
                    <div className={styles.searchBarContainer} style={bannerStyle}>
                      <SearchBar
                        // onSelectChange={setSelectedAssetType}
                        // selectValue={selectedAssetType}
                        onTextChange={setSearchInput}
                        textValue={searchInput}
                      />
                    </div>
                    <div className={styles.optionSelector}>
                      {/* <button className={styles.assetSelectorBtn} onClick={() => setAssetsBtnSelector(prev => !prev)}>
            {assetType || 'Asset Type'}
            <ul className={assetsBtnSelector
              ? `${styles.assetSelectorContainer} ${styles.active}`
              : styles.assetSelectorContainer}
            >
              {
                assetTypes.map(asset => (
                  <li key={asset.id} onClick={() => setAssetType(asset.value)}>
                    {asset.name}
                  </li>
                ))
              }
            </ul>
          </button> */}
                      {
                        !collectionId
                          ? (
                            <p>{folderData?.name || 'Undefined Folder'} ({collections.length || Object.keys(collections).length || 0})</p>
                          )
                          : (
                            catalogs?.length ? <p>{collection?.name || 'Undefined'} ({catalogs?.length || 0})</p> : null
                          )
                      }
                    </div>
                  </div>
                  <div className={styles.contentArea}>
                    {
                      !collectionId
                        ? (<ItemsComponent
                          collections={collections}
                          searchInput={searchInput}
                          setCollections={setCollections}
                          openPopup={setAddCollectionPopup}
                          setInteractionPopups={setInteractionPopups}
                          noAdding={collectionFolderId.includes('shared')}
                          getACollectionCatalogs={getACollectionCatalogs}
                          collectionFolderId={collectionFolderId}
                        />)
                        : (
                          catalogs.length > 0
                            ? (
                              <Gallery photos={catalogs.filter(catalog => catalog?.name?.toLowerCase().includes(searchInput.toLowerCase()))}
                                onClick={handleClick}
                                mixedContent={true}
                                margin={10}
                                isTouchScreen={isTouchScreen()}
                                OverRuledRoles={generateOverRules()}
                              />)
                            : (
                              <NoCatalogsCollection collectionName={collection?.name} />
                            )
                        )
                    }
                  </div>
                </>
              )
              : <ComponentLoaderTri />
          }
        </div>)
      : isValidFolderMongoId && isInvalidContent && !isLoading
        ? <UnavailableContent />
        : !isLoading && <NotFound errMsg={'The link id you are trying to access is invalid'} />
  )
}

export default MyCollectionFolder
