import { configureStore } from '@reduxjs/toolkit'
import searchSlice from '../components/molecules/FilterSection/searchSlice';
import homePageSlice from './homePage/reducer/homePageSlice';
import logger from 'redux-logger'
import auth from './auth/authSlice'
import profile from './auth/profileSlice'
import contentUpload from './contentUpload/contentUploadSlice';
import content from './content/contentSlice';
import uniMenuState from './universalMenu/uniSlice';
import folders from './folder/folderSlice';
import userdata from './userdata/userdataSlice';
import drafts from './draft/draftSlice';
import collections from './collection/collectionSlice';
import profileAdministration from './profileAdministration/profileAdministrationSlice';
import trash from './trash/trashSlice';

export const store = configureStore({
  reducer: {
    search: searchSlice,
    homePage: homePageSlice,
    auth,
    profile,
    uniMenuState,
    contentUpload,
    content,
    userdata,
    drafts,
    folders,
    collections,
    profileAdministration,
    trash
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})
