import img from '../../../../../assets/images/assetLinks/img.png';
import img1 from '../../../../../assets/images/assetLinks/img_1.png';
import img2 from '../../../../../assets/images/assetLinks/img_2.png';
const getAssets = () => {
  return [
    {
      title: 'assetTitle1',
      subTitle: 'assetSubTitle1',
      link: '/mpublisher/catalogs',
      label: 'assetLabel1',
      image: img
    },
    {
      title: 'assetTitle2',
      subTitle: 'assetSubTitle2',
      link: '/mpublisher/catalogs/Templates',
      label: 'assetLabel2',
      image: img1
    },
    {
      title: 'assetTitle3',
      subTitle: 'assetSubTitle3',
      link: '/mpublisher/create',
      label: 'assetLabel3',
      image: img2
    }
  ];
};

export default getAssets;
