import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  searchString: '',
  loading: false,
  status: 'idle',
  error: false,
  errorMessage: '',
  searchResult: []
}

export const searchCatalog = createAsyncThunk('catalog/search', async (_, { getState }) => {
  const { search: { searchString } } = getState();
    const url = `${process.env.REACT_APP_SERVER_URL}/catalog/search?search-term=${searchString}`;
    const { data } = await axios.get(url);
    return data;
});

const searchSlice = createSlice({
  name: 'search',
  initialState: INITIAL_STATE,
  reducers: {
    // Reducers
    setSearchString (state, action) {
      const { searchText } = action.payload;
      state.searchString = searchText;
    },
    setStatus (state, acton) {
      state.status = acton.payload;
    }
  },
  extraReducers (builder) {
    builder
      .addCase(searchCatalog.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(searchCatalog.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.searchResult = action.payload.data;
      })
      .addCase(searchCatalog.rejected, (state, action) => {
        state.status = 'failed'
        state.errorMessage = action.error.message
        state.searchResult = []
      })
  }
});

const { actions, reducer: searchReducer } = searchSlice;
export const { setSearchString, setStatus } = actions;
export default searchReducer;
