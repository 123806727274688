import axiosInstance from '../axios/axiosInstance'

export async function usersAutoComplete (text) {
  const response = await axiosInstance.get(
    `auth/auto-complete?search-term=${text}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
};
