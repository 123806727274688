import React from 'react'
import styles from './styles.module.scss'
import './custom-grid.scss'
import Gallery from '../../../../organisms/GalleryLayout/Gallery';

const index = ({ catalogs, onClick, innerProps }) => {
  return (
    <div className={styles.itemsWrapper}>
      <Gallery photos={catalogs} onClick={onClick} margin={10} {...innerProps} />
    </div>

  )
}

export default index
