import getMenuLabel from './getMenuLabel';
import styles from '../css/HorizontalMenu.module.scss';

const updateLabel = (ref, others) => {
    const { first, second } = others;
    const label = getMenuLabel(ref);
    const firstLabel = getMenuLabel(first);
    const secondLabel = getMenuLabel(second)
    firstLabel.classList.add(styles.horizontal__menu__container__deactivate);
    secondLabel.classList.add(styles.horizontal__menu__container__deactivate);
    return label.classList.remove(styles.horizontal__menu__container__deactivate);
};

export default updateLabel;
