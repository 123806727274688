/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import CompanyLogo from 'assets/icons/CompanyLogo';
import UserLogo from 'assets/icons/UserLogo';
import styles from './TopNavigation.module.scss';
import { logout } from '../../../Redux/auth/authSlice';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ProgressBar = ({ progress }) => {
  const percentageProgress = Math?.round(progress * 100);
  const percentageColorGenerator = (percentage) => {
    if (percentage === 0) {
      return 'transparent';
    }
    if (percentage === 100) {
      return 'red';
    }

    if (percentage < 50) {
      return 'linear-gradient(90deg, #68AE56 0%, #68AE5633 100%)';
    } else if (percentage < 80) {
      return 'linear-gradient(90deg, #FFB800 0%, #FFB80033 100%)';
    }
    return 'linear-gradient(90deg, #AE6656 0%, #AE665633 100%)';
  }
  return (
    <div className={styles.progress_container}>
      <div className={styles.progress} style={{ width: `${percentageProgress}%`, background: percentageColorGenerator(percentageProgress) }} />
    </div>
  );
};

export default function Usermenu ({ showMenu, setShowMenu }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [selectedDataInput, setSelectedDataInput] = useState('Uploads');

  const { userdata } = useSelector((state) => state.userdata);

  const HUB_URL = `${process.env.REACT_APP_MASTER_WIZR_LOGIN}/hub/home`;

  const { userData, currentCompanyInfo } = useSelector(
    (state) => state.profile
  );
  const { profiles } = userData || {};
  const handleLogout = async () => {
    await dispatch(logout());
  };

  const inputData =
    userdata && userdata[selectedDataInput?.toLowerCase()]
      ? userdata[selectedDataInput?.toLowerCase()]
      : {
          images: [],
          templates: [],
          videos: []
        };
  const inputLimits =
    userdata &&
    userdata?.monthlyLimits &&
    userdata?.monthlyLimits[selectedDataInput?.toLowerCase()]
      ? userdata?.monthlyLimits[selectedDataInput?.toLowerCase()]
      : {
          images: 0,
          templates: 0,
          videos: 0
        };

  const changeProfile = (profileId) => {
    const activeProfile = Cookies.get('activeProfile');
    const hostname = window.location.hostname;
    const mainDomain = hostname.match(/[^.]+\.(.+\..+)/);
    let usedDomain = hostname;
    if (mainDomain) {
      usedDomain = mainDomain[1] ? `.${mainDomain[1]}` : hostname;
    }
    if (activeProfile !== profileId) {
      setShowMenu(false);
      Cookies.set('activeProfile', profileId, {
        domain: usedDomain
      });
      console.log('new profile id', profileId);
      localStorage.setItem('company-id', profileId);
      window.location.reload();
    } else {
      return;
    }
  };

  const mongoDatetoString = (date) => {
    const dateObj = new Date(date);
    // return in this format dd/mm/yyyy depend on user's locale
    return dateObj.toLocaleDateString();
  };

  return (
    <div
      className={`${styles.menu} ${showMenu ? styles.active : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={`${styles.info_container}`}>
        <div className={styles.icon}>
          <UserLogo />
        </div>
        <div className={styles.detail_container}>
          <span className={styles.header}>{t('My Account')}</span>
          <span className={styles.detail}>{t('my-account-info')}</span>
        </div>
      </div>
      <div className={styles.account_selection_container}>
        <div className={styles.detail_container}>
          <span className={styles.detail}>
            <strong>{user?.fullName}</strong>
          </span>
          <span className={styles.detail}>
            Plan: <strong>{userdata?.tier}</strong>
          </span>
          <div className={styles.button_up_dl_selector}>
            <button
              className={`${styles.sel_btn} ${
                selectedDataInput === 'Uploads' && styles.active
              }`}
              onClick={() => setSelectedDataInput('Uploads')}
            >
              {t('Uploads')}
            </button>
            <button
              className={`${styles.sel_btn} ${
                selectedDataInput === 'Downloads' && styles.active
              }`}
              onClick={() => setSelectedDataInput('Downloads')}
            >
              {t('Downloads')}
            </button>
          </div>
          <div className={`${styles.detail} ${styles.counters}`}>
          <span>{t('Images')}: 
              {' ' + inputData?.images?.length + '/' + inputLimits?.images}
            </span>
            <ProgressBar progress={inputData?.images?.length / inputLimits?.images}/>
          </div>
          <div className={`${styles.detail} ${styles.counters}`}>
            <span>{t('Templates')}: 
              {' ' + inputData?.templates?.length + '/' + inputLimits?.templates}
            </span>
            <ProgressBar progress={inputData?.templates?.length / inputLimits?.templates}/>
          </div>
          <div className={`${styles.detail} ${styles.counters}`}>
            <span>{t('Videos')}: 
              {' ' + inputData?.videos?.length + '/' + inputLimits?.videos}
            </span>
            <ProgressBar progress={inputData?.videos?.length / inputLimits?.videos}/>
          </div>
          <div className={`${styles.detail} ${styles.counters}`}>
            <span>{t('Drafts')}: 
              {' ' + userdata?.drafts?.length + '/' + (userdata?.monthlyLimits?.drafts || 'N/A')}
            </span>
            <ProgressBar progress={userdata?.drafts?.length / userdata?.monthlyLimits?.drafts}/>
          </div>
          <div className={`${styles.detail} ${styles.counters}`}>
            <span>{t('Collections')}: 
              {' ' + userdata?.collections?.length + '/' + (userdata?.monthlyLimits?.collections || 'N/A')}
            </span>
            <ProgressBar progress={userdata?.collections?.length / userdata?.monthlyLimits?.collections}/>
          </div>
          <div className={`${styles.detail} ${styles.counters}`}>
            <span>{t('Folders')}: 
              {' ' + userdata?.folders?.length + '/' + (userdata?.monthlyLimits?.folders || 'N/A')}
            </span>
            <ProgressBar progress={userdata?.folders?.length / userdata?.monthlyLimits?.folders}/>
          </div>
          <div className={styles.validation}>{t('Valid until')}: {mongoDatetoString(userdata?.monthlyLimits?.monthEnd)}</div>
        </div>
      </div>

      {/* control account */}
      <div className={`${styles.info_container}`}>
        <div className={styles.icon}>
          <CompanyLogo />
        </div>
        <div className={styles.detail_container}>
          <span className={styles.header}>{t('Switch accounts')}</span>
          <span className={styles.detail}>{t('switch-manage-accounts')}</span>
        </div>
      </div>
      <div className={styles.account_selection_container}>
        {profiles?.length > 0 &&
          profiles?.map((profile, index) => (
            <div
              key={profile?.accountId || profile?._id}
              className={styles.account_container}
              onClick={() => changeProfile(profile?.accountId || profile?._id)}
            >
              <div className={styles.comp_info}>
                {profile?.accountInfo?.logoUrl
? (
                  <img
                    className={styles.company_icon}
                    src={`https://d39s7usso569ei.cloudfront.net/${profile?.accountInfo?.logoUrl}`}
                    alt="logo"
                  />
                )
: (
                  <span className={styles.ico_notfound}>N/A</span>
                )}

                <p className={styles.company_name}>
                  {profile?.accountInfo?.name}
                </p>
              </div>
              <div className={styles.checkbox_container}>
                <div
                  className={`${styles.checkbox} ${
                    (profile?.accountId === currentCompanyInfo?._id || profile?.accountId === localStorage.getItem('company-id'))
                      ? styles.active
                      : ''
                  }`}
                />
              </div>
            </div>
          ))}
      </div>

      {/* logout */}
      <div className={styles.buttons_container}>
        <a href={HUB_URL} className={styles.lgo_btn}>
          Master Hub
        </a>
        <button className={styles.lgo_btn} onClick={handleLogout}>
          {t('Logout')}
        </button>
      </div>
    </div>
  );
}
