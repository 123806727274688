export const constants = [
  {
    title: 'Format',
    filters: [
      {
        name: 'Cover Page'
      },
      {
        name: 'Presentations'
      },
      {
        name: 'Premium Presentations'
      },
      {
        name: 'Reports'
      },
      {
        name: 'Others'
      }
    ]
  },
  {
    title: 'Style',
    filters: [
      {
        name: 'Simple'
      },
      {
        name: 'Modern'
      },
      {
        name: 'Conservative'
      },
      {
        name: 'Creative'
      },
      {
        name: 'Others'
      }
    ]
  },
  {
    title: 'Size',
    filters: [
      {
        name: '16:9 portrait'
      },
      {
        name: '1:1 square'
      },
      {
        name: 'Standing A4'
      },
      {
        name: 'Social media'
      }
    ]
  },
  {
    title: 'Series',
    filters: [
      {
        name: 'Single visuals'
      },
      {
        name: 'Duo'
      },
      {
        name: '5 or less'
      },
      {
        name: '5 to 10'
      },
      {
        name: 'More than 10'
      }
    ]
  },
  {
    title: 'Files type',
    filters: [
      {
        name: 'Jpeg'
      },
      {
        name: 'PNG'
      },
      {
        name: 'Vector'
      },
      {
        name: 'Multimedia'
      },
      {
        name: 'Others'
      }
    ]
  },
  {
    title: 'Color',
    filters: [
      {
        name: 'Any'
      }
    ],
    colors: ['white', 'black', 'purple', 'blue', 'red', '#ec7600', 'green']
  }
]
