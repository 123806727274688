/* eslint-disable no-unused-vars */
import styles from './CheckAuthLoadingPage.module.scss';
import React from 'react';
import HubLogo from 'assets/icons/HubLogo';

function CheckAuthLoadingPage () {
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <HubLogo />
      </div>
    </div>
  );
}
export default CheckAuthLoadingPage;
