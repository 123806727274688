/* eslint-disable no-unused-vars */
import React from 'react'
import styles from './styles.module.scss'
const index = ({ popupData, exitPopup, handleInteraction }) => {
    return (
        <div className={styles.shareContainer}>
            <h3 className={styles.shareHeader}>
                Manage Share
            </h3>
            <div className={styles.shareContent}>

                <div className={styles.manageUlContainer}>
                    <ul className={styles.manageListContainer}>
                        <li className={styles.headingListItems}>
                            <div className={styles.collection_dataInfo}>
                                <p>{popupData?.withMe ? 'Sharee' : 'Recipients'}</p>
                            </div>
                            <div className={styles.collection_dataInfo}>
                                <p>Permission</p>
                            </div>
                            <div className={styles.collection_dataInfo}>

                            </div>

                        </li>
                        {
                            popupData?.length && popupData.map((item) => (
                                <li key={item._id} className={styles.manageListItemsContainer}>
                                    <div className={styles.collection_dataInfo}>
                                        <p>{item.user.fullName}</p>
                                    </div>
                                    <div className={styles.collection_dataInfo}>
                                        <p>{item.permissions[0]}</p>
                                    </div>
                                    <div className={styles.btnInteractionContainer}>
                                        <button className={styles.removeBtn} onClick={() => handleInteraction(5, item)}>X</button>
                                    </div>

                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className={styles.closeButtonContainer}>
                    <button
                        className={styles.closeButton}
                        onClick={exitPopup}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default index
