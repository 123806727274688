/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import styles from './styles.module.scss'
import { Filter } from '../../organisms/Filter'
import '@master-wizr/wizr-filter/dist/index.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { filtersData } from '../../../constants/filtersData';
import { filtersData as templatesFD } from '../../../constants/templatesFilterData';
import { useDispatch, useSelector } from 'react-redux'
import SingleAssetPM from '../myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../myTemplatesCatalogs/components/PopupManger'
import GridImagesLayout from '../myImageCatalogs/components/GridImagesLayout'
import { ReactComponent as GridIconOne } from '../../../assets/icons/grid1.svg';
import { ReactComponent as GridIconTwo } from '../../../assets/icons/grid2.svg';
import { ReactComponent as GridIconThree } from '../../../assets/icons/grid3.svg';
import { ReactComponent as LeftArr } from 'assets/icons/icon-arrow-left.svg';
import { ReactComponent as RightArr } from 'assets/icons/icon-arrow-right.svg';
import getSearchResult from './functions/getSearchResult';
import { getLink } from 'api/contentUploadAPI'
import { toast } from 'react-toastify'
import Pagination from '../../molecules/Pagination';
import { ClearFilter } from '../../organisms/Filter/ClearFilter';
import { useTranslation } from 'react-i18next';
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri'

const IS_PACKAGE_OPTION = false;

const MySearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const beCatalogs = useSelector(state => state.content.searchResult.result);
  const { loading } = useSelector(state => state.content);
  const [catalogs, setCatalogs] = useState([]);
  const { folders } = useSelector(state => state.folders);
  const [filterSelection, setFilterSelection] = useState({});
  const [memoedFilterSelection, setMemoedFilterSelection] = useState({});
  const [viewSize, setViewSize] = useState((20 * window.innerHeight) / 90);
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);
  const [viewTagsList, setViewTagslist] = useState(location?.state?.viewTagsList || []);
  const [noResults, setNoResults] = useState(true);
  const [scrollableTags, setScrollableTags] = useState(false);
  const [initialSearchKey, setInitialSearchKey] = useState(true);
  const { pageDetails } = useSelector(state => state.content);
  const { t } = useTranslation();
  const allParams = location.pathname?.split('/mpublisher/search/result&')[1];

  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  useEffect(() => {
    if (pageDetails?.page) {
      if (pageDetails?.page?.number > pageDetails?.page?.totalPages) {
        navigate(`/mpublisher/search/result&${allParams.split('&page')[0]}&page=${pageDetails?.page?.totalPages}`)
      } else if (pageDetails?.page?.number < 1) {
        navigate(`/mpublisher/search/result&${allParams.split('&page')[0]}&page=1`)
      }
    }
  }, [pageDetails])

  useLayoutEffect(() => {
    if (allParams) {
      const paramsLink = allParams.split('&page=')[0];
      getSearchResult(allParams, dispatch);
      const stateFromParams = paramsLink ? paramsLink?.split('&') : null;
      const state = {};
      if (stateFromParams) {
        stateFromParams.forEach((item) => {
          const [key, value] = item.split('=');
          state[key] = value.split(',').map(item => item.replace(/%20/g, ' ')).filter(item => item);
        }
        )
        setInitialSearchKey(state.name);
        setFilterSelection(state);
        setMemoedFilterSelection(state);
        setNoResults(false);
      } else {
        setNoResults(true);
      }
    }
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      if (scrollableTags && window.innerWidth < 800) {
        setScrollableTags(false);
      } else if (!scrollableTags && window.innerWidth >= 800 && !isTouchScreen()) {
        setScrollableTags(true);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [scrollableTags])

  useEffect(() => {
    if (viewTagsList) {
      const tagsContainer = document.querySelector(`.${styles.tagsContainer}`);
      if (tagsContainer.scrollWidth > tagsContainer.offsetWidth) {
        if (scrollableTags && window.innerWidth < 800) {
          setScrollableTags(false);
        } else if (!scrollableTags && window.innerWidth >= 800 && !isTouchScreen()) {
          setScrollableTags(true);
        }
      } else {
        setScrollableTags(false)
      }
    }
  }, [viewTagsList, location])

  const onChangeData = (changes) => {
    const key = changes.category
    const value = changes.value?.toLowerCase()
    let newFilterSelection = { ...filterSelection }
    if (changes.category === 'searchText') {
      newFilterSelection = { ...filterSelection, name: [changes.value] }
      setFilterSelection(newFilterSelection)
    } else if (changes.category === 'assest_type') {
      newFilterSelection = { name: filterSelection.name, assest_type: [changes.value] }
      setFilterSelection(newFilterSelection)
    } else if (!filterSelection[key]) {
      const newData = {}
      newData[key] = [value]
      newFilterSelection = { ...filterSelection, ...newData }
      setFilterSelection(newFilterSelection)
    } else {
      if (changes.selected) {
        const newArr = [...filterSelection[key], value]
        newFilterSelection = { ...filterSelection, [key]: newArr }
        setFilterSelection(newFilterSelection)
      } else {
        const newArr = filterSelection[key].filter((item) => item !== value)
        if (newArr.length > 0) {
          newFilterSelection = { ...filterSelection, [key]: newArr }
          setFilterSelection(newFilterSelection)
        } else {
          delete newFilterSelection[key]
          setFilterSelection(newFilterSelection)
        }
      }
    }
    return newFilterSelection;
  }
  useEffect(() => {
    if (beCatalogs || Array.isArray(beCatalogs)) {
      const catArr = beCatalogs.map((catalog) => {
        setViewTagslist(prev => {
          try {
            return Array.from(new Set([...prev, ...catalog?.word_tags?.split(',')]))
          } catch {
            return prev || []
          }
        })
        if (catalog.template_data) {
          return {
            ...catalog,
            id: catalog?._id || catalog?.id,
            src: catalog?.template_data?.templates[0],
            width: catalog?.template_data?.templates[0]?.width || 1920,
            height: catalog?.template_data?.templates[0]?.height || 1080
          }
        } else if (catalog.assest_type === 'Videos') {
          return {
            ...catalog,
            src: catalog.video_urls,
            width: catalog?.vid_metadata?.width || 16,
            height: catalog?.vid_metadata?.height || 9
          }
        }
        return {
          ...catalog,
          id: catalog?._id || catalog?.id,
          src: catalog?.assest_urls[0],
          width: catalog?.image_size?.width || 16,
          height: catalog?.image_size?.height || 9
        }
      })
      setCatalogs(catArr);
    }
  }, [beCatalogs])

  const catalogTypeFinder = (id) => {
    const folder = folders.find((folder) => folder.id === id)
    if (folder) {
      if (folder.is_global_folder) {
        return 'catalogs'
      } else {
        return 'my-catalogs'
      }
    }
    return null
  }

  const handleClickSingle = (state, catalog) => {
    if (state === 7) {
      setSinglePreviewPopup({
        state,
        data: catalog
      })
    }
    if (state === 6) {
      const typeOfCats = catalogTypeFinder(catalog.folders[0])
      if (typeOfCats) {
        const link = `/mpublisher/${typeOfCats}/Templates/${catalog.folders[0]}/${catalog.id}`
        navigate(link, { state: { catalog } })
      }
    } else if (catalog.assest_type === 'Images' || catalog.assest_type === 'Videos') {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      }
    } else if (catalog.assest_type === 'Templates') {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const downloadTemplate = async (id) => {
    getLink(id).then((res) => {
      if (res) {
        const link = document.createElement('a');
        link.href = res;
        link.click();
      } else {
        toast.error('Something went wrong, please try again later');
      }
    })
  }

  const navigateToSearchPage = (propedFilterData) => {
    const filterData = propedFilterData || { ...filterSelection };
    const link = `/mpublisher/search/result&${Object.keys(filterData).map((key) => key !== 'name'
      ? `${key}=${filterData[key].join(',').toLowerCase()}`
      : `${key}=${filterData[key].join(',')}`)
      .join('&')}`
    navigate(link, { state: { viewTagsList } });
  }

  const addToSearchTags = (tag) => {
    const lowTag = tag.toLowerCase();
    let newFilterSelection = { ...filterSelection };
    if (filterSelection.word_tags) {
      const newArr = filterSelection.word_tags?.includes(lowTag) ? [...newFilterSelection.word_tags].filter(tags => tags !== lowTag) : [...newFilterSelection.word_tags, lowTag]
      newFilterSelection = { ...newFilterSelection, word_tags: newArr }
      if (newArr.length === 0) delete newFilterSelection.word_tags
      setFilterSelection(newFilterSelection)
    } else {
      newFilterSelection = { ...filterSelection, word_tags: [lowTag] }
      setFilterSelection(newFilterSelection)
    }
    navigateToSearchPage(newFilterSelection)
  }

  const isNavigating = useRef(false);
  let timeoutRunner = null

  const slideHandler = (direction) => {
    const tagsContainer = document.querySelector(`.${styles.tagsContainer}`);
    if (direction === 1 && tagsContainer.scrollLeft > 0) {
      tagsContainer.scrollLeft -= 100;
    } else if (direction === 2 && tagsContainer.scrollLeft < tagsContainer.scrollWidth) {
      tagsContainer.scrollLeft += 100;
    }
    if (isNavigating.current) {
      setTimeout(() => {
        timeoutRunner = slideHandler(direction)
      }, 100)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', stopSlide);
    return () => {
      document.removeEventListener('mouseup', stopSlide);
    }
  }, [scrollableTags])

  const startSlide = (direction) => {
    isNavigating.current = true;
    slideHandler(direction)
  }

  const stopSlide = () => {
    isNavigating.current = false;
    clearTimeout(timeoutRunner)
  }

  const getMenu = () => {
    const currentAsset = filterSelection?.assest_type ? filterSelection.assest_type[0] : null;
    if (currentAsset?.includes('images') || currentAsset?.includes('Images')) {
      return filtersData;
    } else if (currentAsset === 'templates' || currentAsset?.includes('Templates')) {
      return templatesFD;
    } else {
      return [...filtersData.filter(el => el.dbTitle !== 'color' && el.dbTitle !== 'style'), ...templatesFD]; // like this for now
    }
  }

  const removeFilterTags = (value, tag) => {
    const dataObj = {
      category: value,
      value: tag,
      selected: false
    }
    const newFilterSelection = onChangeData(dataObj);
    navigateToSearchPage(newFilterSelection);
  }

  const getClearedLink = (newFilterSelection) => {
    const { use_global, assest_type } = newFilterSelection
    if (use_global && use_global[0]) {
      const globalsideLink = use_global[0] && use_global[0] === 'true' ? 'catalogs' : 'my-catalogs'
      const link = `/mpublisher/${globalsideLink}/${assest_type ? assest_type[0] : ''}`;
      return link;
    }
    return '/mpublisher'
  }

  const handleClearAll = () => {
    const newFilterSelection = { ...filterSelection };
    // deleting only other items and leaving the asset type incase we need to use for redirecting to asset link
    Object.keys(newFilterSelection).forEach(item => {
      if (item !== 'use_global' && item !== 'assest_type' && item !== 'name' && item !== 'restrictive_tagging') {
        delete newFilterSelection[item];
      }
    })
    if (!newFilterSelection.name) {
      // safe precaution but won't go here ever by definition
      navigate(getClearedLink(newFilterSelection));
    } else {
      navigateToSearchPage(newFilterSelection);
    }
  }

  const changeRestriction = () => {
    const newFilterSelection = { ...filterSelection };
    if (newFilterSelection.restrictive_tagging) {
      newFilterSelection.restrictive_tagging = [newFilterSelection.restrictive_tagging[0] === 'true' ? 'false' : 'true']
    } else {
      newFilterSelection.restrictive_tagging = ['true']
    }
    navigateToSearchPage(newFilterSelection);
  }

  return (
    <div className={styles.wrappingElement}>
      <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={catalogs} />
      <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} dlAll={downloadTemplate} />
      <div className={styles.searchBarContainer}>
        <div className={styles.searchBar}>
          <Filter withAssetBtn={true} data={getMenu()} onChangeData={onChangeData} onSearch={(option) => navigateToSearchPage(option)} currentState={filterSelection} filterOn={false} camelizedValues={true} type="wizrx" />
        </div>
        <h1>{t('Inspire Your Designs')}</h1>
      </div>
      <div className={styles.contentSizing}>
        <div className={styles.contentAreaHeaderMiniFilter}>
          <div className={styles.headerElement}>
            <div className={styles.headerContainer}>
              <h3 className={styles.headerElementTitle}>{initialSearchKey ? 'Search for : ' + initialSearchKey : 'Search using tags'}</h3>
              <span className={styles.headerElementTitle}>{catalogs.length} found</span>
            </div>
            {
              // Hide package option for now, can be shown later
              IS_PACKAGE_OPTION &&
              (<div className={styles.sizingContianer}>
                <div className={styles.filterByRoleContainer}>
                  <div className={styles.filterByRoleSwingContainer}>
                    <span className={styles.roleSpan}>{t('All')}</span>
                    <span className={styles.roleSpan}>{t('Free')}</span>
                    <span className={styles.roleSpan}>{t('Premium')}</span>
                  </div>
                </div>
              </div>)}
          </div>
        </div>
        <div className={styles.contentFilterFull}>
          <div className={styles.flipBtnContainer}>
            <label htmlFor='flipbtn' className={styles.flipBtnLabel}>Additive tagging</label>
            <button id='flipbtn' className={!(filterSelection?.restrictive_tagging?.length && filterSelection?.restrictive_tagging[0] === 'true') ? `${styles.flipBtn} ${styles.active}` : styles.flipBtn} onClick={() => changeRestriction()}>
              <span className={styles.flipBtnInner} />
            </button>
          </div>
          <ClearFilter onRemove={removeFilterTags} filterSelection={memoedFilterSelection} onClearAll={handleClearAll} />
          <div className={styles.miniFiltringAndView}>
            <div className={styles.miniFilterContainer}>
              {
                scrollableTags && <button className={styles.l_arrowBtn} onMouseDown={() => startSlide(1)} onMouseUp={stopSlide}>
                  <LeftArr />
                </button>
              }
              <ul className={styles.tagsContainer}>
                {
                  viewTagsList.map((tag, index) => {
                    return (
                      <li key={tag} className={filterSelection.word_tags?.includes(tag.toLowerCase()) ? `${styles.tagSpan} ${styles.active}` : styles.tagSpan} onClick={() => addToSearchTags(tag)}>#{tag}</li>
                    )
                  })
                }
              </ul>
              {
                scrollableTags && <button className={styles.r_arrowBtn} onMouseDown={() => startSlide(2)} onMouseUp={stopSlide}>
                  <RightArr />
                </button>
              }
            </div>

            <div className={styles.viewsContainer}>
              <button
                className={Math.floor((30 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                onClick={() => setViewSize(Math.floor((30 * window.innerHeight) / 100))}>
                <GridIconOne />
              </button>
              <button
                className={Math.floor((20 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                onClick={() => setViewSize(Math.floor((20 * window.innerHeight) / 100))}
              >
                <GridIconTwo />
              </button>
              <button
                className={Math.floor((15 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                onClick={() => setViewSize(Math.floor((15 * window.innerHeight) / 100))}>
                <GridIconThree />
              </button>
            </div>
          </div>
        </div>
      </div>
      {!loading
        ? <div className={styles.contentArea}>
          <GridImagesLayout
            catalogs={catalogs}
            onClick={handleClickSingle}
            innerProps={{
              targetRowHeight: viewSize,
              margin: viewSize > 140 ? 10 : 15,
              isTouchScreen: isTouchScreen(),
              mixedContent: true
            }} />
          {catalogs.length > 0 && (pageDetails?.page?.hasNextPage || pageDetails?.page?.number > 1) &&
            <div className={styles.paginationContainer}>
              <Pagination pageDetails={pageDetails} />
            </div>
          }
        </div>
        : (
          <ComponentLoaderTri />
        )
      }
    </div>
  )
}

export default MySearchPage
