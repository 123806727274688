/* eslint-disable react/prop-types */
import React from 'react';
// import darkBackground from './assets/images/img1.png';
import styles from './middleArea.module.scss';

export default function MiddleArea ({ img }) {
  return (
    <div
      className={styles.imgArea}
      // style={{ backgroundImage: `url(${img})` }}
    >
      <img src={img} alt="poster"/>
    </div>
  );
}
