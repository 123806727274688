/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import style from './style.module.scss';
import Checkbox from 'react-custom-checkbox';

function UpperSegment ({ imgState, setImgState, editMode, sampleImage, phaseState, atLeastOneSelected }) {
  const deleteImg = (id) => {
    setImgState((prev) => prev.filter((el) => el.id !== id));
  };

  const setRadioImgactive = (img) => {
    setImgState((prev) => prev.map((el) => {
      if (el.id === img.id) {
        return {
          ...el,
          active: true
        };
      }
      return { ...el, active: false };
    }));
  }

  const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: '0.6rem',
    backgroundColor: '#1d1d1d'
  }

  return (
    <>
      {
        !editMode && (
          <>
            <h4 className={style.header}>
              {
                phaseState === 1
                  ? 'Add view options to your templates'
                  : 'Add information to your content'
              }
            </h4>
            <div className={style.descriptionAndBtnContainer}>
              <div className={style.descriptionContainer}>
                <p className={style.descriptionPargrph}>
                  {
                    phaseState === 1
                      ? 'Select an element and add a sample image. The element you select will be the default view for your template.'
                      : 'Fill in the information for your content. You can add or edit more information later.'
                  }
                </p>
                <p className={style.descriptionPargrph}>
                  {
                    phaseState === 1
                      ? 'The sample image is just a placeholder to make your template more vivid. You cannot change it later.'
                      : 'The information you add narrows the search and would potentially make your asset more reachable to others.'
                  }
                </p>
              </div>
              {/* <button className={style.addImgsBtn}>
                <img className={style.addImgsBtnIcon} src='https://i.imgur.com/TkalPb8.png' alt='add' />
                <span>Add images</span>
              </button> */}
            </div>
          </>
        )
      }
      <div className={style.ImgsContainer}>
        {imgState.length
          ? (
            imgState.map((img) => {
              return (
                <div key={img.id} className={style.singleImgContainer}>
                  <img
                    className={editMode ? style.inEditMode : (img.active || atLeastOneSelected.id === img.id) ? `${style.active}` : ''}
                    style={{
                      backgroundImage: sampleImage
                        ? `url(${sampleImage.preview})`
                        : 'url(https://img.freepik.com/premium-vector/small-size-white-gray-square-background-elements-png-background_533741-46.jpg)'
                    }}
                    src={typeof img.uriData === 'string' ? img.uriData : URL.createObjectURL(img.uriData)}
                    alt="img"
                    onClick={() => setRadioImgactive(img)}
                  />
                  <div className={style.imgChckbox}>
                    <Checkbox
                      checked={img.active}
                      icon={
                        <div
                          style={checkboxStyle}
                        >
                          ✓
                        </div>
                      }
                      onChange={() => setRadioImgactive(img)}
                      borderColor="#1d1d1d"
                      borderWidth={1}
                      borderRadius={5}
                      style={{ overflow: 'hidden' }}
                      size={20}
                    />
                  </div>
                  {
                    atLeastOneSelected?.id !== img.id && (
                      <div className={style.deleteimgBtn}>
                        <button onClick={() => deleteImg(img.id)}>×</button>
                      </div>
                    )
                  }
                </div>
              )
            })
          )
          : (
            <div className={style.noImgs}>No images added</div>
          )}
      </div>
    </>
  );
}

export default UpperSegment;
