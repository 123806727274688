import styles from './css/Recommendations.module.scss';
import React from 'react';
import getRecommendations from './functions/getRecommendations';
import { useTranslation } from 'react-i18next';

const Recommendations = () => {
    const recommendations = getRecommendations();
    const { t } = useTranslation();

    const list = recommendations.map((recommendation) => {
        return (
            <div key={recommendation.id}><img src={recommendation.image} alt="recommendations"/></div>
        )
    });

    return (
        <section className={styles.container}>
            <div className={styles.recommendations__container}>
                <h1>{t('Recommended')}</h1>
                <div>{ list }</div>
            </div>
        </section>
    );
};

export default Recommendations;
