import styles from './css/CreativeSpaec.module.scss';
import React from 'react';
import RoundedButton from '../../../atoms/RoundedButton/RoundedButton';
import editorImage from '../../../../assets/images/creativeSpace/img_2.jpg';
import { useNavigate } from 'react-router-dom';
import FilledLogo from '../../../../assets/images/creativeSpace/FilledLogo';
import LinedLogo from 'assets/images/creativeSpace/LinedLogo';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

const CreativeSpace = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <section className={styles.container}
    style={{
        backgroundImage: 
        `
          url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<FilledLogo />))}"),
          url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<LinedLogo />))}")
        `
      }}    
    >
      <div
        className={styles.creative__space__container}>
        <div>
          <p>{t('Just start and create your')}</p>
          <p>{t('own creative space')}</p>
          <RoundedButton
            onClick={() => navigate('/mpublisher/create')}
            extraClass={styles.creative__space__container__button}
          >
            Open Editor
          </RoundedButton>
        </div>
        <figure>
          <img src={editorImage} alt="Editor" />
        </figure>
      </div>
    </section>
  );
};

export default CreativeSpace;
