/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss'
import GridImagesLayout from './components/GridImagesLayout'
import { getCatalog } from 'Redux/content/contentSlice';
import PopupManger from './components/PopupManger';
import { filtersData } from '../../../constants/templatesFilterData';
import { Filter } from '../../organisms/Filter';
import '@master-wizr/wizr-filter/dist/index.css';
import { ReactComponent as LeftArr } from './components/PopupManger/assets/icon-arrow-left.svg';
import { ReactComponent as RightArr } from './components/PopupManger/assets/icon-arrow-right.svg';
import Gallery from '../../organisms/GalleryLayout/Gallery';
import { getLink } from 'api/contentUploadAPI';
import { toast } from 'react-toastify';
import Pagination from '../../molecules/Pagination';
import { ReactComponent as GridIconOne } from '../../../assets/icons/grid1.svg';
import { ReactComponent as GridIconTwo } from '../../../assets/icons/grid2.svg';
import { ReactComponent as GridIconThree } from '../../../assets/icons/grid3.svg';
import DataCardShowSlide from 'components/molecules/DataCardShowSlide'
import isMongoId from 'helpers/isMongo';
import NotFound from '../NotFound/NotFound';
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import { useTranslation } from 'react-i18next';
import { featureCatalog } from 'Redux/contentUpload/contentUploadSlice';

const IS_PACKAGE_OPTION = false;

const myTemplatesCatalogs = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [previewPopup, setPreviewPopup] = useState(null);
  const [showNavigationBtns, setShowNavigationBtns] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const beCatalogs = useSelector(state => state.content.catalog);
  const { pageDetails } = useSelector(state => state.content);
  const { loading } = useSelector(state => state.content);
  const { folders } = useSelector(state => state.folders);
  const [catalogs, setCatalogs] = useState([]);
  const [foldersData, setFoldersData] = useState([]);
  const [filterSelection, setFilterSelection] = useState({});
  const [viewSize, setViewSize] = useState(Math.floor((20 * window.innerHeight) / 100));
  const [scrollableTags, setScrollableTags] = useState(false);
  const [viewTagsList, setViewTagslist] = useState([]);
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth?.user);
  const profile = useSelector((state) => state.profile);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const userWithProfile = {};
    userWithProfile.user = user;
    userWithProfile.profile = profile;
    setCurrentUser(userWithProfile);
  }, [user, profile])

  console.log('folderId', params?.folderId)
  const validFolderId = isMongoId(params?.folderId?.split('&page=')[0]) || !params?.folderId;

  const usedFolders = location.pathname.includes('my-catalogs')
    ? folders.filter(folder => !folder?.is_global_folder)
    : folders.filter(folder => folder?.is_global_folder)

  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const isPrivate = location.pathname.includes('my-catalogs');

  useEffect(() => {
    if (pageDetails?.page && params?.folderId) {
      if (pageDetails?.page?.number > pageDetails?.page?.totalPages) {
        navigate(`Templates/${params?.folderId?.split('&page=')[0]}&page=${pageDetails?.page?.totalPages}`)
      } else if (pageDetails?.page?.number < 1) {
        navigate('Templates')
      }
    }
  }, [pageDetails])

  useEffect(() => {
    const handleResize = () => {
      setViewSize(Math.floor((20 * window.innerHeight) / 100));
      if (scrollableTags && window.innerWidth < 800) {
        setScrollableTags(false);
      } else if (!scrollableTags && window.innerWidth >= 800 && !isTouchScreen()) {
        setScrollableTags(true);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [scrollableTags])

  useEffect(() => {
    if (params.folderId && validFolderId) {
      if (!params.folderId?.includes('&page=')) {
        dispatch(getCatalog({
          folderId: params.folderId,
          isPrivate
        }));
      } else {
        const page = params.folderId.split('&page=')[1]
        dispatch(getCatalog({
          folderId: params.folderId.split('&page=')[0],
          page: parseInt(page),
          isPrivate
        }));
      }
    }
  }, [location, params.folderId])

  useEffect(() => {
    if (!params.folderId) {
      dispatch(getCatalog({
        assetType: 'Templates',
        templates: true,
        isPrivate
      }));
    }
  }, [params.folderId, location])

  const onChangeData = (changes) => {
    const key = changes.category
    const value = changes.value?.toLowerCase()
    if (changes.category === 'searchText') {
      setFilterSelection({ ...filterSelection, name: [changes.value] })
    } else if (changes.category === 'assest_type') {
      setFilterSelection({ ...filterSelection, assest_type: [changes.value] })
    } else if (!filterSelection[key]) {
      const newData = {}
      newData[key] = [value]
      setFilterSelection({ ...filterSelection, ...newData })
    } else {
      if (changes.selected) {
        const newArr = [...filterSelection[key], value]
        setFilterSelection({ ...filterSelection, [key]: newArr })
      } else {
        const newArr = filterSelection[key].filter((item) => item !== value)
        newArr.length > 0 ? setFilterSelection({ ...filterSelection, [key]: newArr }) : setFilterSelection((prev) => { const newState = { ...prev }; delete newState[key]; return newState })
      }
    }
  }

  useEffect(() => {
    if (folders.length && usedFolders.length) {
      const foldersData = usedFolders?.filter(folder => folder.parent === 'Templates').map(folder => {
        return {
          id: folder?.id,
          label: folder?.name
        }
      })
      setFoldersData(foldersData)
    }
  }, [folders, location])

  useEffect(() => {
    if (params.folderId && beCatalogs && Array.isArray(beCatalogs) && !loading) {
      !beCatalogs[0]?.assest_urls?.length && setCatalogs([...beCatalogs]
        .map(catalog => {
          setViewTagslist(prev => Array.from(new Set([...prev, ...catalog.word_tags.split(',')])))
          return {
            ...catalog,
            src: catalog?.template_data?.templates[0],
            width: catalog?.template_data?.templates[0]?.width || 1920,
            height: catalog?.template_data?.templates[0]?.height || 1080
          }
        }));
    }
  }, [beCatalogs, loading])

  useEffect(() => {
    if (viewTagsList) {
      const tagsContainer = document.querySelector(`.${styles.tagsContainer}`);
      if (tagsContainer?.scrollWidth > tagsContainer?.offsetWidth) {
        if (scrollableTags && window.innerWidth < 800) {
          setScrollableTags(false);
        } else if (!scrollableTags && window.innerWidth >= 800 && !isTouchScreen()) {
          setScrollableTags(true);
        }
      } else {
        setScrollableTags(false)
      }
    }
  }, [viewTagsList, location])

  const handleClick = (state, catalog) => {
    if (state === 9) {
      const payload = {
        is_featured: !catalog.is_featured
      }
      dispatch(featureCatalog({ payload, catalog }))
      return;
    }
    if (state === 6) {
      if (params.folderId) {
        navigate(`${location.pathname}/${catalog.id}`, { state: { catalog } })
      } else {
        navigate(`${location.pathname}/${catalog.folders[0]}/${catalog.id}`, { state: { catalog } })
      }
    } else {
      if (state === 1) {
        setPreviewPopup({
          state,
          data: catalog
        })
      } else {
        setPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const seeMoreNavigation = (catalog) => { }

  const navigateToSearchPage = (propedFilterData) => {
    const filterData = propedFilterData || { ...filterSelection };
    const link = `/mpublisher/search/result&assest_type=templates&${Object.keys(filterData).map((key) => `${key}=${filterData[key].join(',').toLowerCase()}`).join('&')}${!isPrivate ? '&use_global=true' : '&use_global=false'}`
    navigate(link, { state: { viewTagsList, typeOfSearch: !isPrivate ? '&use_global=true' : '&use_global=false' } })
  }

  const downloadTemplate = async (id) => {
    getLink(id).then((res) => {
      if (res) {
        const link = document.createElement('a');
        link.href = res;
        link.click();
      } else {
        toast.error('Something went wrong, please try again later');
      }
    })
  }

  const addToSearchTags = (tag) => {
    const lowTag = tag.toLowerCase();
    let newFilterSelection = { ...filterSelection };
    if (filterSelection.word_tags) {
      const newArr = filterSelection.word_tags?.includes(lowTag) ? [...filterSelection.word_tags].filter(tags => tags !== tag) : [...filterSelection.word_tags, lowTag]
      newFilterSelection = { ...filterSelection, word_tags: newArr }
      if (newArr.length === 0) delete newFilterSelection.word_tags
      setFilterSelection(newFilterSelection)
    } else {
      newFilterSelection = { ...filterSelection, word_tags: [lowTag] }
      setFilterSelection(newFilterSelection)
    }
    navigateToSearchPage(newFilterSelection)
  }

  const isNavigating = useRef(false);
  let timeoutRunner = null

  const slideHandler = (direction) => {
    const tagsContainer = document.querySelector(`.${styles.tagsContainer}`);
    if (direction === 1 && tagsContainer.scrollLeft > 0) {
      tagsContainer.scrollLeft -= 100;
    } else if (direction === 2 && tagsContainer.scrollLeft < tagsContainer.scrollWidth) {
      tagsContainer.scrollLeft += 100;
    }
    if (isNavigating.current) {
      setTimeout(() => {
        timeoutRunner = slideHandler(direction)
      }, 100)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', stopSlide);
    return () => {
      document.removeEventListener('mouseup', stopSlide);
    }
  }, [scrollableTags])

  const startSlide = (direction) => {
    isNavigating.current = true;
    slideHandler(direction)
  }

  const stopSlide = () => {
    isNavigating.current = false;
    clearTimeout(timeoutRunner)
  }

  return (
    validFolderId
      ? (
        <div className={styles.wrappingElement}>
          <PopupManger previewPopup={previewPopup} setPreviewPopup={setPreviewPopup} dlAll={downloadTemplate} />
          <div className={styles.searchBarContainer}>
            <div className={styles.searchBar}>
              <Filter data={filtersData} onChangeData={onChangeData} onSearch={navigateToSearchPage} currentState={filterSelection} camelizedValues={true} type="wizrx" />
            </div>
            <h1>{t('Inspire Your Designs')}</h1>
          </div>
          {
            !loading
              ? (
                <>
                  {!params.folderId
                    ? (<div className={styles.contentSizingMain}>
                      <ul className={styles.linksContainer}>
                        <li className={styles.linkItem}>
                          <Link to="" className={`${styles.link} ${styles.active}`}>
                            All Templates
                          </Link>
                        </li>
                        {
                          foldersData.map(folder => (
                            <li key={folder.id} className={styles.linkItem}>
                              <Link to={`${folder.id}`} className={`${styles.link}`}>
                                {folder.label}
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                      <span className={styles.tempsAmount}>12.311 {t('templates')}</span>
                    </div>)
                    : beCatalogs.length > 0
                      ? (
                        <div className={styles.contentSizingByFolder}>
                          <div className={styles.contentAreaHeaderMiniFilter}>
                            <div className={styles.headerElement}>
                              <div className={styles.headerContainer}>
                                <h3 className={styles.headerElementTitle}>{t('Templates')}</h3>
                                <span className={styles.headerElementTitle}>{pageDetails?.page?.totalElements
                                  ? `${pageDetails?.page?.size * pageDetails?.page?.number >= pageDetails?.page?.totalElements ? pageDetails?.page?.totalElements : pageDetails?.page?.size * pageDetails?.page?.number}${` out of ${pageDetails?.page?.totalElements}`}`
                                  : catalogs.length} {t('Templates')}</span>
                              </div>
                              {
                                // Hide package option for now, can be shown later
                                IS_PACKAGE_OPTION &&
                                (<div className={styles.sizingContianer}>
                                  <div className={styles.filterByRoleContainer}>
                                    <div className={styles.filterByRoleSwingContainer}>
                                      <span className={styles.roleSpan}>{t('All')}</span>
                                      <span className={styles.roleSpan}>{t('Free')}</span>
                                      <span className={styles.roleSpan}>{t('Premium')}</span>
                                    </div>
                                  </div>
                                </div>
                                )}
                            </div>
                          </div>
                          <div className={styles.miniFiltringAndView}>
                            <div className={styles.miniFilterContainer}>
                              {
                                scrollableTags && <button className={styles.l_arrowBtn} onMouseDown={() => startSlide(1)} onMouseUp={stopSlide}>
                                  <LeftArr />
                                </button>
                              }
                              <ul className={styles.tagsContainer}>
                                {
                                  viewTagsList.map((tag, index) => {
                                    return (
                                      <li key={tag} className={filterSelection.word_tags?.includes(tag.toLowerCase()) ? `${styles.tagSpan} ${styles.active}` : styles.tagSpan} onClick={() => addToSearchTags(tag)}>#{tag}</li>
                                    )
                                  })
                                }
                              </ul>
                              {
                                scrollableTags && <button className={styles.r_arrowBtn} onMouseDown={() => startSlide(2)} onMouseUp={stopSlide}>
                                  <RightArr />
                                </button>
                              }
                            </div>

                            <div className={styles.viewsContainer}>
                              <button
                                className={Math.floor((30 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                                onClick={() => setViewSize(Math.floor((30 * window.innerHeight) / 100))}>
                                <GridIconOne />
                              </button>
                              <button
                                className={Math.floor((20 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                                onClick={() => setViewSize(Math.floor((20 * window.innerHeight) / 100))}
                              >
                                <GridIconTwo />
                              </button>
                              <button
                                className={Math.floor((15 * window.innerHeight) / 100) === viewSize ? `${styles.sizingBtn} ${styles.active}` : styles.sizingBtn}
                                onClick={() => setViewSize(Math.floor((15 * window.innerHeight) / 100))}>
                                <GridIconThree />
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                      : null
                  }
                  <div className={params.folderId ? styles.contentArea : `${styles.contentArea} ${styles.mainView}`}>
                    {
                      params?.folderId && !loading && (
                        <>
                          {
                            beCatalogs?.length > 0 && catalogs.length > 0
                              ? (
                                <>
                                  <GridImagesLayout catalogs={catalogs} onClick={handleClick} innerProps={{
                                    templates: true,
                                    reverseThumbnail: true,
                                    targetRowHeight: viewSize,
                                    isTouchScreen: isTouchScreen(),
                                    margin: viewSize > 140 ? 10 : 15
                                  }} />
                                  {catalogs.length > 0 && (pageDetails?.page?.hasNextPage || pageDetails?.page?.number > 1) &&
                                    <div className={styles.paginationContainer}>
                                      <Pagination pageDetails={pageDetails} />
                                    </div>
                                  }
                                </>
                              )
                              : (
                                <div className={styles.noCatalogsContainer}>
                                  <NotFound errMsg={t('no-catalogs-in-folder')} />)
                                </div>)
                          }

                        </>
                      )
                    }
                    {
                      // this will change to be a seperate component
                      beCatalogs?.length > 0 && !params.folderId && foldersData.length > 0
                        ? foldersData.map((folder) => {
                          const folderCatalogs = [];
                          beCatalogs?.forEach(catalog => {
                            if (catalog.folders[0] === folder.id) {
                              folderCatalogs.push({
                                ...catalog,
                                id: catalog._id || catalog.id,
                                src: catalog?.template_data?.templates[0],
                                width: catalog?.template_data?.templates[0]?.width || 1920,
                                height: catalog?.template_data?.templates[0]?.height || 1080
                              });
                            }
                          });
                          const filteredCatalogs = folderCatalogs.filter(el => el?.is_featured);
                          return (
                            filteredCatalogs.length > 0 && (
                              <DataCardShowSlide
                                key={folder.id}
                                catalogs={filteredCatalogs.slice(0, 8) || []}
                                currentUser={currentUser}
                                handleClick={handleClick}
                                header={folder.label}
                                isTouchScreen={isTouchScreen()}
                                linkForMore={`${folder.id}`}
                                directOtherProps={
                                  {
                                    templates: true,
                                    reverseThumbnail: true
                                  }
                                }
                              />
                            )
                          );
                        })
                        : !params.folderId && !loading && (
                          <div className={styles.noCatalogs}>
                            <NotFound errMsg={t('no-featured-templates')} />
                          </div>
                        )
                    }
                  </div>
                </>
              )
              : <ComponentLoaderTri />
          }
        </div>)
      : <NotFound errMsg={t('invalid-folder-id')} />
  )
}

export default myTemplatesCatalogs
