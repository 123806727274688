import styles from '../css/HorizontalMenu.module.scss';
import updateLabel from './updateLabel';
import updateHorizontalLines from './updateHorizontalLines';

const activatecatalog = (menuItems) => {
    const { catalog, newRelease, popular } = menuItems;
    const others = { first: newRelease, second: popular };
    catalog.classList.add(styles.horizontal__menu__container__display);
    updateHorizontalLines(others);
    return updateLabel(catalog, others);
};

export default activatecatalog;
