import axiosInstance from '../../axios/axiosInstance'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jwt from 'jsonwebtoken'
import Cookies from 'js-cookie'

const token = localStorage.getItem('token')
const roleToken = Cookies.get('mpublisher-auth-role')

const initialState = {
  isAuthenticated: false,
  user: token !== null && token !== undefined ? jwt.decode(token) : {},
  loading: true,
  error: null,
  status: '',
  userRole: roleToken !== null && roleToken !== undefined ? jwt.decode(roleToken) : {}
}

const deleteAuthData = () => {
  // Set old expiry to invalide cookie
  document.cookie = `authCode=;domain=.${process.env.REACT_APP_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  document.cookie = `activeProfile=;domain=.${process.env.REACT_APP_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  // Clear all items from local storage
  localStorage.clear();
}

export const authorizeUser = createAsyncThunk('auth/authorize', async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/authorize`;
  const { data } = await axiosInstance.get(url);
  return data;
});

export const logoutUser = createAsyncThunk('auth/logout', async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/logout`;
  const { data } = await axiosInstance.get(url);
  return data;
});

export const getPublisherUser = createAsyncThunk('auth/user', async () => {
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/user`;
  const { data } = await axiosInstance.get(url);
  return data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const authCode = Cookies.get('authCode');
      state.loading = true
      if (authCode) {
        try {
          const decoded = jwt.decode(authCode)
          if (!decoded || !decoded.exp) {
            state.isAuthenticated = false;
            return;
          }
          const now = new Date();
          const nowInSec = Math.floor(now.getTime() * 0.001);
          state.isAuthenticated = decoded.exp > nowInSec;
          if (decoded.exp > nowInSec) localStorage.setItem('auth-token', authCode);
        } catch (error) {
          console.log('Invalid token, login to continue');
          deleteAuthData();
          state.isAuthenticated = false;
        }
      } else {
        state.isAuthenticated = false;
      }
      state.loading = false
    },
    logout: (state, action) => {
      deleteAuthData();
      state.isAuthenticated = false;
    }

  },
  extraReducers (builder) {
    builder
      .addCase(authorizeUser.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.isAuthenticated = true
      })
      .addCase(authorizeUser.rejected, (state, action) => {
        state.status = 'failed'
        state.errorMessage = action.error.message
        state.isAuthenticated = false
      })

      builder.addCase(logoutUser.pending, (state, action) => {
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isAuthenticated = true
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action?.error?.message
      })
      builder.addCase(getPublisherUser.pending, (state, action) => {
      })
      .addCase(getPublisherUser.fulfilled, (state, action) => {
        state.userRole = action.payload
        state.user = action.payload
      })
      .addCase(getPublisherUser.rejected, (state, action) => {
        state.error = action?.error?.message
      })
  }
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer
