/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as RestoreIcon } from 'assets/icons/restore.svg';
import { errorToast } from 'toasts/Toasts';
import { restoreFolder } from 'Redux/folder/folderSlice';

const ExcludedFolders = () => {
  const dispatch = useDispatch();
  const { excludedFolders } = useSelector((state) => state.folders);
  const nonGlobalFoldersOnly = excludedFolders?.filter(folder => !folder.is_global_folder && folder.static);
  const managementArray = [
    {
      title: 'Images',
      folders: nonGlobalFoldersOnly.filter(folder => folder.parent === 'Images')
    },
    {
      title: 'Videos',
      folders: nonGlobalFoldersOnly.filter(folder => folder.parent === 'Videos')
    },
    {
      title: 'Templates',
      folders: nonGlobalFoldersOnly.filter(folder => folder.parent === 'Templates')
    }
  ]

  const restoreFolderAdmin = (folder) => {
    dispatch(restoreFolder(folder.id || folder._id));
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Suspended Folders
      </h2>
      <div className={styles.management_container}>
        {managementArray.map((management) => (
          <div key={management.title} className={styles.management}>
            <div className={styles.management_header}>
              <h3 className={styles.management_title}>{management.title}</h3>
            </div>
            <ul className={styles.folder_list}>
              {management?.folders?.map((folder) => (
                <li key={folder?.name} className={styles.folder_item}>
                  <span>
                    {folder?.name}
                  </span>
                  <RestoreIcon className={styles.svg_icon} onClick={() => restoreFolderAdmin(folder)} />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExcludedFolders;
