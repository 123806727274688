/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import styles from './homeContent.module.scss'
import Gap from 'components/atoms/Gap/Index'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFeaturedCatalogs } from 'Redux/content/contentSlice'
import SingleAssetPM from '../../pages/myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../../pages/myTemplatesCatalogs/components/PopupManger'
import Templates from './Templates/Templates';
import Reports from './Reports/Reports';
import { homepageUser } from 'Redux/homePage/reducer/templates'
import Recommendations from './Recommendations/Recommendations';
import FAQ from './FAQ/FAQ';
import Creativity from './Creativity/Creativity';
import { profile } from 'assets/images/profiletop.webp';

function HomeContent () {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const beCatalogs = useSelector(state => state.content.catalog);
  const { folders } = useSelector(state => state.folders);
  const [imageCatalogs, setImageCatalogs] = useState([]);
  const [templateCatalogs, setTemplateCatalogs] = useState([]);
  const [filterSelection, setFilterSelection] = useState({});
  const [viewSize, setViewSize] = useState((20 * window.innerHeight) / 90);
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);
  const { templates, reports } = useSelector((state) => state.homePage);

  const user = useSelector((state) => state.auth?.user);
  const profile = useSelector((state) => state.profile);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const userWithProfile = {};
    // Update user to user from state after we change the home page reports to get data from the APInot dummy data
    userWithProfile.user = homepageUser;

    userWithProfile.profile = profile;
    setCurrentUser(userWithProfile);
  }, [])

  const templateArray =
  templates?.map((template) => {
    return {
      ...template,
      id: template._id || template.id,
      src: template?.template_data?.templates[0],
      width: template?.template_data?.templates[0]?.width || 1920,
      height: template?.template_data?.templates[0]?.height || 1080
    };
  }) || [];

const reportsArray =
  reports?.map((template) => {
    return {
      ...template,
      id: template._id || template.id,
      src: template?.template_data?.templates[0],
      width: template?.template_data?.templates[0]?.width || 1920,
      height: template?.template_data?.templates[0]?.height || 1080
    };
  }) || [];

  useLayoutEffect(() => {
    dispatch(getFeaturedCatalogs());
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      setViewSize((20 * window.innerHeight) / 60);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
    if (beCatalogs) {
      if (beCatalogs.templates) {
        const templateArray = beCatalogs.templates.map((template) => {
          return {
            ...template,
            id: template._id || template.id,
            src: template?.template_data?.templates[0],
            width: template?.template_data?.templates[0]?.width || 1920,
            height: template?.template_data?.templates[0]?.height || 1080
          }
        })
        setTemplateCatalogs(templateArray);
      }
      if (beCatalogs.images) {
        const imagesArr = beCatalogs.images.map((image) => {
          return {
            ...image,
            id: image._id || image.id,
            src: image.assest_urls[0],
            width: image.image_size?.width || 16,
            height: image.image_size?.height || 9
          }
        })
        setImageCatalogs(imagesArr);
      }
    }
  }, [beCatalogs])

  const catalogTypeFinder = (id) => {
    const folder = folders.find((folder) => folder.id === id)
    if (folder) {
      if (folder.is_global_folder) {
        return 'catalogs'
      } else {
        return 'my-catalogs'
      }
    }
    return null 
  }

  const handleClickMulti = (state, catalog) => {
    // Put this back when after we change the home page reports to get data from the API not dummy data

    // if (state === 6) {
    //   const typeOfCats = catalogTypeFinder(catalog.folders[0])
    //   if (typeOfCats) {
    //     const link = `/mpublisher/${typeOfCats}/Templates/${catalog.folders[0]}/${catalog.id}`
    //     navigate(link, { state: { catalog } })
    //   }
    // } else {
      if (state === 1) {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    // }
  }

  return (
    <div className={styles.container}>
      <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={imageCatalogs.slice(0, 10)} />
      <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} />
      <Templates handleClick={handleClickMulti} templateArray={templateArray} reportsArray={reportsArray} currentUser={currentUser} />
      <Creativity />
      <Reports reportsArray={reportsArray} handleClick={handleClickMulti} currentUser={currentUser} />
      <Recommendations />
      <FAQ />
      <Gap />
    </div>
  )
}

export default HomeContent
