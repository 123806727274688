import removeUndefined from './removeUndefined'

export const objectToFormData = data => {
  const formData = new FormData()
  const object = removeUndefined(data)
  for (const key in object) {
    if (key !== 'files') {
      formData.append(key, object[key])
    } else if (key === 'files') {
      for (let i = 0; i < object[key].length; i++) {
        formData.append('files', object[key][i])
      }
    }
  }
  return formData;
}

// export const objectToFormDataWithoutFiles = data => {
//   // remove all empty values and empty arrays and empty objects
//   const cleanObject = { ...data }
//   for (const key in cleanObject) {
//     if (cleanObject[key] === '' || cleanObject[key] === undefined || cleanObject[key] === null) {
//       delete cleanObject[key]
//     }
//   }
// }
