// Delete user after we change the home page reports to get data from the API
export const homepageUser = {
  createdAt: '2023-07-31T12:07:14.943Z',
  email: 'mohamed@mwizr.com',
  favouriteCatalogs: [],
  fullName: 'Mohamed Amine Hajltaief',
  id: '64c7a3f2fff475d91754720b',
  roles: ['VIEWER'],
  telephone: '+21653377818',
  theme: 'DARK',
  updatedAt: '2023-12-01T13:18:30.838Z',
  wizr_user_id: '64c2652168a5ea573525f912',
  __v: 0
}
export const arrayOfTemplates = [
  {
    _id: '65184dbc1223438ea2151a59',
    userId: '649184bfe63420189ff98875',
    name: 'Creative cover page',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'creative,shapes,cover,page,professional,purple,vibrant',
    template_data: {
      zipfileLink: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091579736-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091579147-selected-file-Cover%20page%20%2813%29.png.jpg',
          thumbnail: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091579147-selected-file-Cover%20page%20%2813%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091579147-mixed-selected-file-Cover%20page%20%2813%29.png.jpg',
          width: 1920,
          height: 1080
        }
      ]
    },
    content_description: 'Creative cover page',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['64d94ee73a21a27fc30f8c4f'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    visited_count: 2
  },
  {
    _id: '65184d1c1223438ea2151a51',
    userId: '649184bfe63420189ff98875',
    name: 'Vibrant cover page',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'vibrant,cover,page,artistic,professional,violet,purple',
    template_data: {
      zipfileLink: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091419823-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091419276-selected-file-Cover%20page%20%2812%29.png.jpg',
          thumbnail: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091419276-selected-file-Cover%20page%20%2812%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1696091419276-mixed-selected-file-Cover%20page%20%2812%29.png.jpg',
          width: 1920,
          height: 1080
        }
      ]
    },
    content_description: 'Vibrant cover page',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['64d94ee73a21a27fc30f8c4f'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    visited_count: 5
  },
  {
    _id: '64d12754877d3da875a25525',
    userId: '649184bfe63420189ff98875',
    name: 'Presentation template',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'artistic,template,presentation,pr,agency,trending,pink,purple,shade,3d',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428691832-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428682353-selected-file-T-serie259-Cover.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428682353-selected-file-T-serie259-Cover.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428682353-mixed-selected-file-T-serie259-Cover.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428683828-T-Serie259-Body1.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428683828-T-Serie259-Body1.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428683828-mixed-T-Serie259-Body1.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428685401-t-serie259-Body2.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428685401-t-serie259-Body2.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428685401-mixed-t-serie259-Body2.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428687126-T-serie259-Body3.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428687126-T-serie259-Body3.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428687126-mixed-T-serie259-Body3.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428688701-T-serie259-Body4.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428688701-T-serie259-Body4.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428688701-mixed-T-serie259-Body4.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428690204-T-Serie259-Body5.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428690204-T-Serie259-Body5.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428690204-mixed-T-Serie259-Body5.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428691561-t-sERIE259-bODY6.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428691561-t-sERIE259-bODY6.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428691561-mixed-t-sERIE259-bODY6.png.jpg',
          width: 1920,
          height: 1080
        }
      ]
    },
    content_description: 'Creative presentation template',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b945c74b8fe18cfeb50b4'],
    collections: [
      {
        $oid: '6509e6f05736c5ba7049c3e8'
      }
    ],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    visited_count: 14,
    is_featured: true
  },
  {
    _id: '64b69e6f20bc8b10f2a4abd7',
    userId: '6426bbf9593ac8ac34407332',
    name: 'cover page',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'green,cover',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689711334-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689688422-selected-file-T-serie7-Cover.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689688422-selected-file-T-serie7-Cover.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689688422-selected-file-T-serie7-Cover.png.jpg'
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689692294-T-serie7-Body8.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689692294-T-serie7-Body8.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689692294-T-serie7-Body8.png.jpg'
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689695977-T-serie7-Body4.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689695977-T-serie7-Body4.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689695977-T-serie7-Body4.png.jpg'
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689699428-T-serie7-Body5.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689699428-T-serie7-Body5.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689699428-T-serie7-Body5.png.jpg'
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689703383-T-serie7-Body3.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689703383-T-serie7-Body3.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689703383-T-serie7-Body3.png.jpg'
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689707145-T-serie7-Body7.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689707145-T-serie7-Body7.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689707145-T-serie7-Body7.png.jpg'
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689711144-T-serie7-Body2.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1689689711144-T-serie7-Body2.png',
          width: 1920,
          height: 1080,
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mixed-1689689711144-T-serie7-Body2.png.jpg'
        }
      ]
    },
    content_description: '',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b945c74b8fe18cfeb50b4'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null
  },
  {
    _id: '64d12651877d3da875a2551b',
    userId: '649184bfe63420189ff98875',
    name: 'Presentation template',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'presentation,artistic,green,pr,agency,modeling,creative,dark,details',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428433372-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428339339-selected-file-T-Serie260-Cover.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428339339-selected-file-T-Serie260-Cover.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428339339-mixed-selected-file-T-Serie260-Cover.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428354571-T-Serie260-Body1.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428354571-T-Serie260-Body1.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428354571-mixed-T-Serie260-Body1.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428370088-T-Serie260-Body2.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428370088-T-Serie260-Body2.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428370088-mixed-T-Serie260-Body2.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428386078-T-Serie260-Body3.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428386078-T-Serie260-Body3.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428386078-mixed-T-Serie260-Body3.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428401568-T-Serie260-Body4.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428401568-T-Serie260-Body4.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428401568-mixed-T-Serie260-Body4.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428417051-T-Serie260-Body5.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428417051-T-Serie260-Body5.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428417051-mixed-T-Serie260-Body5.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428433106-T-Serie260-Body6.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428433106-T-Serie260-Body6.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691428433106-mixed-T-Serie260-Body6.png.jpg',
          width: 1920,
          height: 1080
        }
      ]
    },
    content_description: 'Creative presentation template',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b945c74b8fe18cfeb50b4'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    visited_count: 0
  },
  {
    _id: '64f0b40d0ba0c4dacad07d74',
    userId: '64c8c4e2fff475d917046516',
    name: 'Presentation template',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'Gray orange presentation template',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496333024-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496007426-selected-file-T-Series+358-Cover.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496007426-selected-file-T-Series%20358-Cover.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496007426-mixed-selected-file-T-Series%20358-Cover.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496044034-T-Series%20358-Body9.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496044034-T-Series%20358-Body9.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496044034-mixed-T-Series%20358-Body9.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496080433-T-Series%20358-Body1.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496080433-T-Series%20358-Body1.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496080433-mixed-T-Series%20358-Body1.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496117306-T-Series%20358-Body2.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496117306-T-Series%20358-Body2.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496117306-mixed-T-Series%20358-Body2.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496153449-T-Series%20358-Body3.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496153449-T-Series%20358-Body3.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496153449-mixed-T-Series%20358-Body3.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496188693-T-Series%20358-Body4.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496188693-T-Series%20358-Body4.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496188693-mixed-T-Series%20358-Body4.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496225227-T-Series%20358-Body5.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496225227-T-Series%20358-Body5.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496225227-mixed-T-Series%20358-Body5.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496260832-T-Series%20358-Body6.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496260832-T-Series%20358-Body6.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496260832-mixed-T-Series%20358-Body6.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496296352-T-Series%20358-Body7.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496296352-T-Series%20358-Body7.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496296352-mixed-T-Series%20358-Body7.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496332796-T-Series%20358-Body8.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496332796-T-Series%20358-Body8.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693496332796-mixed-T-Series%20358-Body8.png.jpg',
          width: 1920,
          height: 1080
        }
      ]
    },
    content_description: 'Gray orange presentation template',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b945c74b8fe18cfeb50b4'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    series: '5 to 10',
    visited_count: 0
  },
  {
    _id: '64d12a31877d3da875a2553a',
    userId: '649184bfe63420189ff98875',
    name: 'Presentation template',
    assest_type: 'Templates',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'corporate,professional,work,green,team,talking,person,people,working,dark,business',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429425186-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429370693-selected-file-t-serie247-cover%20%281%29.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429370693-selected-file-t-serie247-cover%20%281%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429370693-mixed-selected-file-t-serie247-cover%20%281%29.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429384658-T-serie247-cover-dark.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429384658-T-serie247-cover-dark.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429384658-mixed-T-serie247-cover-dark.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429397857-t-serie247-body2%20%281%29.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429397857-t-serie247-body2%20%281%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429397857-mixed-t-serie247-body2%20%281%29.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429411072-T-serie247-body3%20%281%29.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429411072-T-serie247-body3%20%281%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429411072-mixed-T-serie247-body3%20%281%29.png.jpg',
          width: 1920,
          height: 1080
        },
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429424848-t-serie247-body1%20%281%29.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429424848-t-serie247-body1%20%281%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1691429424848-mixed-t-serie247-body1%20%281%29.png.jpg',
          width: 1920,
          height: 1080
        }
      ]
    },
    content_description: 'Presentation template for professional content',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b945c74b8fe18cfeb50b4'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    series: '',
    visited_count: 1
  }
]

export const arrayOfReports = [
  {
    _id: '651211ad354227b0a07f4d00',
    assest_type: 'Templates',
    assest_urls: [],
    collections: [],
    color: '',
    content_description: 'Creative report cover',
    contributor: 'MW',
    deletedAt: null,
    files_type: '',
    folders: ['642b949074b8fe18cfeb50b5'],
    image_size: {
      width: 1920,
      height: 1080
    },
    is_deleted: false,
    is_public: true,
    name: 'Report ',
    restoredAt: null,
    reviews: [],
    series: '',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1695682988907-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1695682988690-selected-file-1.4%20%282%29.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1695682988690-selected-file-1.4%20%282%29.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1695682988690-mixed-selected-file-1.4%20%282%29.png.jpg',
          width: 4960,
          height: 7016
        }
      ]
    },
    userId: '649184bfe63420189ff98875',
    visited_count: 0,
    word_tags: 'report,cover,orange,pastel,creative,shape'
  },
  {
    _id: '64fb654291e966c17eacebf7',
    userId: '649184bfe63420189ff98875',
    name: 'Report 127',
    contributor: 'MW',
    assest_type: 'Templates',
    files_type: '',
    color: '',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'report,cover,creative,purple,dark',
    template_data: {
      zipfileLink: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1694197057872-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1694197056899-selected-file-A4%20-%20183.png.jpg',
          thumbnail: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1694197056899-selected-file-A4%20-%20183.png',
          mixedSampleThumbnail: 'https://publisher-frontend-prod-data.s3.us-west-1.amazonaws.com/mpublisher/1694197056899-mixed-selected-file-A4%20-%20183.png.jpg',
          width: 2480,
          height: 3508
        }
      ]
    },
    content_description: 'Creative report cover',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b949074b8fe18cfeb50b5'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    series: '',
    visited_count: 1
  },
  {
    _id: '64f0aecd0ba0c4dacad07d1f',
    userId: '649184bfe63420189ff98875',
    name: 'Report 102',
    contributor: 'MW',
    assest_type: 'Templates',
    files_type: '',
    color: '',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'report,cover,professional,work,green,cyan,financial',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693494989446-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693494989233-selected-file-A4%20-%20139.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693494989233-selected-file-A4%20-%20139.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1693494989233-mixed-selected-file-A4%20-%20139.png.jpg',
          width: 2480,
          height: 3508
        }
      ]
    },
    content_description: 'Professional report cover',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b949074b8fe18cfeb50b5'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    series: '',
    visited_count: 0
  },
  {
    _id: '64dd2ccd7499423216fba071',
    userId: '64ca24eca8e12e8a25ab5b4c',
    name: 'Report 85',
    contributor: 'Winnie',
    assest_type: 'Templates',
    files_type: 'PNG',
    color: 'purple',
    image_size: {
      width: 1920,
      height: 1080
    },
    word_tags: 'Corporate,✕,Sustainability,Renewable,Energy,Investments,Green,Business,Strategies,Social,Responsibility,(CSR),Sustainable,Supply,Chains,Adoption,Transition,Procurement,Carbon,Footprint,Reduction,Management,Partnerships,ESG,(Environmental,Governance),Performance,Certificates,(RECs),Climate,Targets,Financing,Integration,Certifications,Efficiency,Offsets,Intensive,Industries,Project,Planning,Execution,Monitoring,Control,Scope,Schedule,Cost,Quality,Risk,Communication,Stakeholder,Agile,Waterfall,Scrum,Methodology,Kanban,Critical,Path,Analysis,Gantt,Charts,Work,Breakdown,Structure,(WBS),Lifecycle,Tools,Team,Collaboration,Change,Resource,Allocation',
    template_data: {
      zipfileLink: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1692216525762-zipfile.zip',
      templates: [
        {
          original: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1692216525435-selected-file-A4%20-%20471.png.jpg',
          thumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1692216525435-selected-file-A4%20-%20471.png',
          mixedSampleThumbnail: 'https://publisher-frontend-staging-data.s3.amazonaws.com/mpublisher/1692216525435-mixed-selected-file-A4%20-%20471.png.jpg',
          width: 2480,
          height: 3508
        }
      ]
    },
    content_description: '',
    is_public: true,
    reviews: [],
    assest_urls: [],
    folders: ['642b949074b8fe18cfeb50b5'],
    collections: [],
    is_deleted: false,
    deletedAt: null,
    restoredAt: null,
    visited_count: 0
  }
]
