/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as LeftArr } from 'assets/icons/icon-arrow-left.svg';
import { ReactComponent as RightArr } from 'assets/icons/icon-arrow-right.svg';

const index = ({ pageDetails, handleLocalPageNav, localNavToPage, localNxtLink }) => {
  const navigate = useNavigate();
  const { page } = pageDetails;
  const [number, setNumber] = useState(page?.number || 1);
  const location = useLocation();

  useEffect(() => {
    setNumber(page?.number || 1);
  }, [page, location]);
  
  const handlePageNavigation = (type) => {
    if (handleLocalPageNav) {
      handleLocalPageNav(type);
      return;
    }
    if (type === 'left') {
      if (page?.number === 1) {
        return;
      }
      navigate(!location.pathname?.includes('&page=') ? location.pathname + `&page=${page?.number - 1}` : location.pathname.split('&page=')[0] + `&page=${page?.number - 1}`);
    }
    if (type === 'right') {
      if (page?.hasNextPage) {
        navigate(!location.pathname?.includes('&page=') ? location.pathname + `&page=${page?.number + 1}` : location.pathname.split('&page=')[0] + `&page=${page?.number + 1}`);
      }
    }
  }

  const navigateToPage = (pageN) => {
    if (localNavToPage) {
      localNavToPage(pageN);
      return;
    }
    if ((pageN === page?.number) || pageN < 1 || pageN > page?.totalPages || isNaN(pageN) || !pageN?.trim()) {
      return;
    }
    navigate(!location.pathname?.includes('&page=') ? location.pathname + `&page=${pageN}` : location.pathname.split('&page=')[0] + `&page=${pageN}`);
  }

  return (
    <div className={styles.wraper}>
      <div className={styles.interactions}>
        <div className={`${styles.arrows} ${page?.number <= 1 ? styles.disabled : ''}` } disabled={page?.number <= 1} onClick={() => handlePageNavigation('left')}>
          <LeftArr className={styles.leftArrow} />
        </div>
        <input type="number" value={number} style={{ width: `${35 + ((number).toString().length * 10)}px` }} className={styles.pageinput} onChange={e => setNumber(e.target.value)} onKeyDown={e => e.key === 'Enter' && navigateToPage(e.target.value)} />
        <div className={`${styles.arrows} ${!page?.hasNextPage ? styles.disabled : ''}` } disabled={!page?.hasNextPage} onClick={() => handlePageNavigation('right')}>
          <RightArr className={styles.rightArrow} />
        </div>
        <span className={styles.pageTotal}>of {page?.totalPages}</span>
      </div>
      {
        page?.hasNextPage && (
          <div className={styles.nextBtnContainer}>
            <Link to={localNxtLink || (!location.pathname?.includes('&page=') ? location.pathname + `&page=${page?.number + 1}` : location.pathname.split('&page=')[0] + `&page=${page?.number + 1}`)} className={styles.nxt_button}>Next</Link>
          </div>
        )
      }
      <div className={styles.possibleLogoWhoknows} />
    </div>
  );
};

export default index;
