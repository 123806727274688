/* eslint-disable react/prop-types */
import React from 'react'
import styles from './styles.module.scss'

export const ClearFilter = ({ filterSelection, onRemove, onClearAll }) => {
    const ValsToshow = Object.keys(filterSelection).filter(el => el !== 'name' && el !== 'assest_type' && el !== 'use_global' && el !== 'restrictive_tagging');
    const isNotEmpty = ValsToshow.length > 0;
    return (
        isNotEmpty &&
        <div className={styles.mainContainer}>
            <ul className={styles.filterContainer}>
                <span className={styles.clearAll} onClick={() => onClearAll()}>Clear all <span>X</span></span>
                {
                    ValsToshow.map((value) => {
                        return filterSelection[value].map(item => {
                            return (
                                <li key={item} className={styles.clearFilter} onClick={() => onRemove(value, item)}>
                                    {item} <span>X</span></li>
                            )
                        })
                    })
                }
            </ul>
        </div>
    )
}
