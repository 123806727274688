import React, { useState, useEffect } from 'react';
import './Countdown.scss'; 
import PropTypes from 'prop-types';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

const Countdown = ({ launchDate }) => {
  const templaunchDate = new Date('2023-12-31T00:00:00'); 
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  const { t } = useTranslation();

  function getTimeRemaining () {
    const now = new Date();
    const timeDiff = launchDate ? launchDate - now : templaunchDate - now;

    return {
      days: Math.floor(timeDiff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeDiff % (1000 * 60)) / 1000)
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="image-div">
        <Loader />
      </div>
      <div className="countdown">
        <div className="time-box">
          <div className="time">{timeRemaining.days}</div>
          <div className="time-label">{t('Days')}</div>
        </div>
        <div className="time-box">
          <div className="time">{timeRemaining.hours}</div>
          <div className="time-label">{t('Hours')}</div>
        </div>
        <div className="time-box">
          <div className="time">{timeRemaining.minutes}</div>
          <div className="time-label">{t('Minutes')}</div>
        </div>
        <div className="time-box">
          <div className="time">{timeRemaining.seconds}</div>
          <div className="time-label">{t('Seconds')}</div>
        </div>
      </div>
    </>
  );
};

Countdown.propTypes = {
  launchDate: PropTypes.func
};

export default Countdown;
