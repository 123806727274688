/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Dots } from '../../../../../assets/icons/dots.svg';
import { ReactComponent as Empty } from 'assets/icons/nothing.svg';
import { useLocation, Link } from 'react-router-dom'
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import { useSelector } from 'react-redux';

const CollectionCardForSharedData = ({ collection, getACollectionCatalogs, handleInteraction, searchInput, mainView, currentDraggedElement, handleDragEnd, allSimilarCollections }) => {
  const [currentCollection, setCurrentCollection] = useState(collection || {})
  const { sharedByMeCollections, sharedWithMeCollections } = useSelector(
    (state) => state.collections
  );
  const [collectionMenu, setCollectionMenu] = useState(null)
  const location = useLocation()
  const allSharedCollections = !collection.withMe ? sharedByMeCollections : sharedWithMeCollections

  const toggleCollectionMenu = (id) => {
    if (collectionMenu === id) {
      setCollectionMenu(null)
    } else {
      setCollectionMenu(id)
    }
  }

  const collectionMenuRef = React.useRef(null);

  const handleClickOutside = (e) => {
    if (collectionMenuRef.current && !collectionMenuRef.current.contains(e.target)) {
      setCollectionMenu(null);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [])

  useEffect(() => {
    collection && !currentCollection && setCurrentCollection(collection)
  }, [collection])

  useEffect(() => {
    if (currentCollection && currentCollection.catalogsData) return
    getACollectionCatalogs(collection.id).then((res) => {
      setCurrentCollection((prev) => {
        return {
          ...prev,
          catalogsData: res,
          loaded: true
        }
      })
    })
  }, [currentCollection])
  const sharedCollectioninfo = currentCollection?.collection || {}
  const collectionsWithSameId = allSharedCollections?.filter(el => el.collectionId === sharedCollectioninfo?.id || el.collectionId === sharedCollectioninfo?._id)
  const catalogContainerReturn = () => {
    return currentCollection?.catalogsData?.length
      ? (
        currentCollection?.catalogsData?.slice(0, 1).map((file) => (
          <p key={file.id}>
            <img
              className={styles.folderImg}
              src={
                file.assest_type === 'Images'
                  ? file.assest_urls[0]?.thumbnail
                  : file.template_data?.templates[0]?.original
              }
              alt={file.name}
            />
          </p>
        ))
      )
      : currentCollection?.loaded
        ? (
          <div className={styles.no_cats_cont}>
            <Empty className={styles.no_cats_svg} />
            <span className={styles.header_nocats}>No catalogs in here</span>
          </div>
        )
        : (
          <div className={styles.no_cats_cont}>
            <div className={styles.dotLoader} />
          </div>
        )
  }

  return (
    (sharedCollectioninfo?.name?.toLowerCase().includes(searchInput.toLowerCase()))
      ? (
        <div key={collection?.id} className={styles.itemFolderContainer}>
          <div className={styles.imgsContainer}
            onDragStart={(e) => {
              if (mainView) {
                setCollectionMenu(null);
                currentDraggedElement.current = collection;
              }
            }}
            onDragEnd={(e) => {
              handleDragEnd();
            }}
            draggable={mainView && collectionMenu !== (collection.id)}
          >
            {
              collectionsWithSameId?.length === 1
                ? (
                  <Link
                    to={location.pathname && location.pathname[location.pathname.length - 1] === '/'
                      ? `${location.pathname}${!mainView ? (collection.id || collection._id) : `collections/${collection.id || collection._id}`}`
                      : `${location.pathname}/${!mainView ? (collection.id || collection._id) : `collections/${collection.id || collection._id}`}`}
                    state={{ catalogs: currentCollection?.catalogsData || null, collection }}
                    draggable={false}
                    style={{
                      textDecoration: 'none'
                    }}
                  >
                    {catalogContainerReturn()}
                  </Link>
                )
                : collectionsWithSameId?.length > 1 && (
                  <button className={styles.btn_naving}
                    draggable={false}
                    onClick={() => handleInteraction(6, allSimilarCollections)}
                  >
                    {catalogContainerReturn()}
                  </button>
                )
            }

            <div className={styles.interactionContainer}>
              <span className={styles.counter}> {currentCollection.catalogsData?.length || 0} visuals </span>
              <p className={styles.collectionName} title={(sharedCollectioninfo?.name?.length > 30) ? (sharedCollectioninfo?.name) : undefined}>
                {(sharedCollectioninfo?.name?.length > 30) ? `${(sharedCollectioninfo.name.substring(0, 25))}...` : sharedCollectioninfo.name}
              </p>
              <button className={styles.btn} onClick={() => {
                toggleCollectionMenu(sharedCollectioninfo?.id || sharedCollectioninfo?._id);
              }}>
                <Dots className={collectionMenu === (collection.id) ? styles.active : ''} />
                <ul
                  ref={collectionMenuRef}
                  className={collectionMenu === (sharedCollectioninfo?.id || sharedCollectioninfo?._id) ? `${styles.collectionMenu} ${styles.active}` : styles.collectionMenu}>
                  {
                    currentCollection?.catalogsData?.length > 0 && (
                      <li onClick={() => handleInteraction(1, currentCollection)}>View</li>
                    )
                  }
                  <li className={!(currentCollection.catalogsData?.length > 0) ? styles.noCollectionShare : ''}
                    onClick={(e) => {
                      if (!(currentCollection.catalogsData?.length > 0)) {
                        e.stopPropagation();
                        return;
                      }
                      handleInteraction(2, collection)
                    }}
                    disabled={(currentCollection.catalogsData?.length > 0)}
                  >
                    Share
                    {!(currentCollection.catalogsData?.length > 0) && <span className={styles.tooltipInfo} >Cannot share empty collection</span>}
                  </li>
                  <li onClick={() => handleInteraction(4, allSimilarCollections)}>Manage</li>
                </ul>
              </button>
            </div>
          </div>
        </div>
      )
      : null
  )
}

export default CollectionCardForSharedData
