import axiosInstance from '../axios/axiosInstance'

export async function createEditorTemplateShareAPI ({ type, id }) {
  const response = await axiosInstance.post(
    `/share/${type}/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getOpenEditorTemplateShareAPI (id) {
  const response = await axiosInstance.get(
    `/share/share-uuid/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}
