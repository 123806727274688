import React from 'react';
import PropTypes from 'prop-types';
import styles from './Layout.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateUniState } from 'Redux/universalMenu/uniSlice';
import UniMenuLogo from 'assets/icons/UniMenuLogo';
// import TopNavigation from '../../organisms/TopNavigation/TopNavigation'
// import Sidebar from '../../organisms/Sidebar/Sidebar'
function TemplatesContainer ({ children }) {
  const dispatch = useDispatch();
  const { view, expandState } = useSelector((state) => state.uniMenuState);
  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const openMenu = () => {
    dispatch(updateUniState(true));
  };

  const easyCloseMenu = (e) => {
    if (window.innerWidth < 500 && isTouchScreen() && view) {
      dispatch(updateUniState(false));
    }
  }

  return (
    <div
      className={`${styles.content} ${styles.closedMenu} ${view && styles[expandState]}`}
      onClick={easyCloseMenu}
    >
      {!view && (
        <button className="menu_logo_btn" onClick={openMenu}>
          <UniMenuLogo />
        </button>
      )}
      {children}
    </div>
  );
}
export default TemplatesContainer;

TemplatesContainer.propTypes = {
  children: PropTypes.any.isRequired
};
