import React from 'react'
import styles from './card.module.scss'
import PropTypes from 'prop-types'

export default function SmallImageCard (props) {
  const {
    containerStyles,
    imageSource,
    bottomLeftText,
    topLeftText,
    topRightText,
    bottomRightText,
    centeredText,
    bottomCenterText,
    height,
    width,
    onClick,
    selected,
    opacityEffect,
    cursorPointer
  } = props
  const container = {
    position: 'relative',
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    height: '100%',
    width: 'auto',
    borderRadius: '40px',
    zIndex: 1
  }
  return (
    <div
      className={styles.imageHolder}
      onClick={onClick && onClick}
      style={{
        ...containerStyles,
        ...container,
        borderRadius: '10px',
        boxShadow: selected ? '0 8px 20px #0000008a' : '',
        cursor: cursorPointer ? 'pointer' : 'not-allowed',
        opacity: opacityEffect ? 0.4 : 1
      }}
    >
      {imageSource && (
        <img
          src={imageSource}
          alt='poster'
          style={{
            width: width || '100%',
            height: height || '100%'
          }}
        />
      )}
      <div className={styles['bottom-left']}>{bottomLeftText}</div>
      <div className={styles['top-left']}>{topLeftText}</div>
      <div className={styles['top-right']}>{topRightText}</div>
      <div className={styles['bottom-right']}>{bottomRightText}</div>
      <div className={styles.bottomCenter}>{bottomCenterText}</div>
      <div className={styles.centered}>{centeredText}</div>
    </div>
  )
}

SmallImageCard.propTypes = {
  containerStyles: PropTypes.object,
  imageSource: PropTypes.string,
  bottomLeftText: PropTypes.string,
  topLeftText: PropTypes.string,
  topRightText: PropTypes.string,
  bottomRightText: PropTypes.string,
  centeredText: PropTypes.string,
  bottomCenterText: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  opacityEffect: PropTypes.bool,
  cursorPointer: PropTypes.bool
}
