/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import './photo.scss';
import getCurrentUser from './utils/getCurrentUser';
import noImage from 'assets/images/noimage.png';
import { ReactComponent as Trash } from 'assets/icons/trashicon.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video_icon.svg';
import { ReactComponent as EditIco } from 'assets/icons/editicon.svg';
import { ReactComponent as Download } from 'assets/icons/downloadicon.svg';
import { ReactComponent as Share } from 'assets/icons/shareicon.svg';
import { ReactComponent as Fullscreen } from 'assets/icons/fullscreen.svg';
import { ReactComponent as Project } from 'assets/icons/project_cut.svg';

import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger
} from '../../molecules/ContextMenuWrapper';
import { ReactComponent as FtStar } from 'assets/icons/ft-star.svg';
import { createPortal } from 'react-dom';
const imgWithClick = { cursor: 'pointer' };

const Video = ({
  assetUserId,
  currentUser,
  index,
  onClick,
  catalog,
  photo,
  linearLine,
  margin,
  direction,
  top,
  left,
  noInteraction,
  templates,
  subCatalogView,
  allCatalogs,
  isTouchScreen,
  OverRuledRoles,
  height,
  homePage
}) => {
  const placeholderImage = noImage;
  const isProcecssing = photo?.src?.processing;

  // const { userRole: { roles } } = useSelector(state => state.auth);
  const imgStyle = {
    margin,
    display: 'block',
    maxHeight: allCatalogs.length <= 4 && !homePage ? '350px' : 'unset',
    minHeight: allCatalogs.length <= 4 && !homePage ? '250px' : 'unset'
  };

  const metadata = catalog?.vid_metadata || {};
  const duration = metadata?.duration || 0;
  const videoHeight = metadata?.height || 0;

  const generatedQuality =
    videoHeight > 1081
      ? '4K'
      : videoHeight > 721
      ? '1080p'
      : videoHeight > 481
      ? '720p'
      : videoHeight > 361
      ? '480p'
      : videoHeight > 241
      ? '360p'
      : videoHeight > 181
      ? '240p'
      : videoHeight > 121
      ? '180p'
      : videoHeight > 91
      ? '120p'
      : '90p';

  const durationInString = // duration to mm:ss
    `${Math.floor(duration / 60)}`.padStart(2, '0') +
    ':' +
    `${Math.floor(duration % 60)}`.padStart(2, '0');

  const imgContainerRef = React.createRef(null);
  const imgRef = React.createRef(null);

  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleInteraction = (state) => {
    !isProcecssing && onClick(state, { ...catalog, index });
  };

  const sizeReducer = () => {
    const { height, width } = photo;
    if (allCatalogs.length <= 4 && !homePage) {
      const ratio = width / height;
      if (height >= 350) {
        return 350 * ratio;
      } else if (height <= 200) {
        return 200 * ratio;
      }
    }
    return width;
  };

  const featureCatalog = (e) => {
    e.stopPropagation();
    const ftBtn = document.getElementById(
      `feature-btn-${photo?.id || photo?._id}`
    );
    ftBtn.setAttribute('disabled', 'true');
    ftBtn.classList.add('disabled');
    handleInteraction(9);
    setTimeout(() => {
      ftBtn.classList.remove('disabled');
      ftBtn.removeAttribute('disabled');
    }, 2000);
  };

  const defaultInteractionOfCurrentUser = Array.isArray(OverRuledRoles)
    ? OverRuledRoles
    : getCurrentUser(currentUser, catalog) || ['SHARE', 'DOWNLOAD'];

  // 1: view, 2: share, 3: download, 4: edit, 7: delete, 6: template, 8: delete from collection
  const potentialUseIcons = [
    {
      type: 'EDIT',
      text: 'Edit',
      jsx: (
        <>
          <EditIco onClick={onClick ? () => handleInteraction(4) : null} />
          <span className="tooltipInfo">Edit</span>
        </>
      )
    },
    {
      type: 'DELETE',
      text: 'Delete',
      jsx: (
        <>
          <Trash onClick={onClick ? () => handleInteraction(7) : null} />
          <span className="tooltipInfo">Delete</span>
        </>
      )
    },
    {
      type: 'CDELETE',
      text: 'Delete from collection',
      jsx: (
        <>
          <Trash onClick={onClick ? () => handleInteraction(8) : null} />
          <span className="tooltipInfo">Delete</span>
        </>
      )
    },
    {
      type: 'SHARE',
      text: 'Share',
      jsx: (
        <>
          <Share onClick={onClick ? () => handleInteraction(2) : null} />
          <span className="tooltipInfo">Add to collection</span>
        </>
      )
    },
    {
      type: 'DOWNLOAD',
      text: 'Download',
      jsx: (
        <>
          <Download onClick={onClick ? () => handleInteraction(3) : null} />
          <span className="tooltipInfo">Download</span>
        </>
      )
    }
  ].reverse();

  const potentialUseContextMenuEntries = [
    {
      type: 'DOWNLOAD',
      text: 'Download',
      jsx: <MenuItem onClick={() => handleInteraction(3)}>Download</MenuItem>
    },
    {
      type: 'SHARE',
      text: 'Share',
      jsx: <MenuItem onClick={() => handleInteraction(2)}>Share</MenuItem>
    },
    {
      type: 'DELETE',
      text: 'Delete',
      jsx: <MenuItem onClick={() => handleInteraction(7)}>Delete</MenuItem>
    },
    {
      type: 'CDELETE',
      text: 'Delete',
      jsx: <MenuItem onClick={() => handleInteraction(8)}>Delete</MenuItem>
    },
    {
      type: 'EDIT',
      text: 'Edit',
      jsx: <MenuItem onClick={() => handleInteraction(4)}>Edit</MenuItem>
    },
    {
      type: 'FEATURE',
      text: 'Feature',
      jsx: <MenuItem onClick={featureCatalog}>Feature</MenuItem>
    }
  ];

  const fps = photo?.fps || catalog?.fps || 0;

  const isProjectBased = photo?.template_vid || catalog?.template_vid

  return (
    <>
      <ContextMenuTrigger id={catalog?.id || catalog?._id}>
        <div
          className={'photo__img-container vid'}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          ref={imgContainerRef}
          onMouseEnter={() => {
            if (isProcecssing) {
              return;
            }
            const { current } = imgRef;
            current.classList.remove('skeliton');
            if (current && current.tagName !== 'IMG') {
              try {
                current.play();
              } catch (e) {
                console.error(e);
              }
            }
          }}
          onMouseLeave={() => {
            if (isProcecssing) {
              return;
            }
            const { current } = imgRef;
            if (current && current.tagName !== 'IMG') {
              try {
                current.pause();
                const prevSrc = current.src;
                current.src = prevSrc;
                current.currentTime = 0;
              } catch {
                console.error('error');
              }
            }
          }}
        >
          {isProcecssing && (
            <div className="processing_vid">
              Video is unavailable and is currently being processed... <br />
              It may take a few minutes to be available.
            </div>
          )}
          {photo?.src?.reduced
? (
            <video
              className="photo__img skeliton"
              style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
              ref={imgRef}
              poster={photo?.src?.thumbnail}
              width={linearLine ? 'unset' : sizeReducer()}
              height={linearLine || height ? `${height}px` : photo?.height}
              src={photo?.src?.reduced}
              muted
              onError={(e) => {}}
            />
          )
: (
            <img
              className="photo__img skeliton"
              style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
              ref={imgRef}
              src={photo?.src?.thumbnail}
              width={linearLine ? 'unset' : sizeReducer()}
              height={linearLine || height ? `${height}px` : photo?.height}
            />
          )}
          {!noInteraction && (
            <>
              <div
                className="photo__overlay"
                onClick={(e) => {
                  e.stopPropagation();
                  handleInteraction(
                    templates || photo?.assest_type === 'Templates'
                      ? 6
                      : window.innerWidth > 800
                      ? 1
                      : 5
                  );
                }}
                style={{
                  top: `${homePage ? margin : 0}px`,
                  left: `${margin}px`,
                  right: `${margin}px`
                }}
              >
                <div className="photo__title">
                  {!subCatalogView && (photo?.name || 'Untitled')}
                </div>
                <div
                  className={
                    photo?.width / photo?.height > 1.3
                      ? 'photo__icon-container'
                      : 'photo__icon-container column'
                  }
                  style={
                    photo?.width / photo?.height < 1.3
                      ? { height: photo?.height }
                      : {}
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {!isTouchScreen && currentUser && (
                    <>
                      <div
                        className="photo__circle allways-visible"
                        onClick={
                          onClick
                            ? () =>
                                handleInteraction(
                                  window.innerWidth > 800 ? 1 : 5
                                )
                            : null
                        }
                      >
                        <Fullscreen />
                        <span className="tooltipInfo">View</span>
                      </div>
                      {potentialUseIcons.map((data, index) => {
                        if (
                          defaultInteractionOfCurrentUser?.includes(data.type)
                        ) {
                          return (
                            <div
                              key={data.type || index}
                              className="photo__circle"
                            >
                              {data.jsx}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  )}
                </div>
              </div>
                <div className="feautiring-container">
              {defaultInteractionOfCurrentUser?.includes('FEATURE') && (
                  <button
                    id={`feature-btn-${photo?.id || photo?._id}`}
                    className="feautiring-container__button"
                    onClick={featureCatalog}
                  >
                    <FtStar
                      id={`featured-star-${photo?.id || photo?._id}`}
                      className={
                        catalog?.is_featured ? 'ft-ico active' : 'ft-ico'
                      }
                    />
                  </button>
              )}
                </div>
            </>
          )}
          <div className="hide-hover">
            {metadata
? (
              <>
                <span className="info-indicator qaulity">
                  {generatedQuality}
                </span>
                <span className="info-indicator time">{durationInString}</span>
                <span className="info-indicator time">{fps} FPS</span>
                {isProjectBased && 
                  <span className="info-indicator p_icon"><Project /></span>
                }
              </>
            )
: (
              <VideoIcon />
            )}
          </div>
        </div>
      </ContextMenuTrigger>
      {document.getElementById('context-root') &&
        createPortal(
          <ContextMenu id={catalog?.id || catalog?._id}>
            <MenuItem
              onClick={() => handleInteraction(window.innerWidth > 800 ? 1 : 5)}
            >
              View
            </MenuItem>
            {potentialUseContextMenuEntries.map((data, index) => {
              if (defaultInteractionOfCurrentUser?.includes(data.type)) {
                return data.jsx;
              }
              return null;
            })}
          </ContextMenu>,
          document.getElementById('context-root')
        )}
    </>
  );
};

export const photoPropType = PropTypes.shape({
  src: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  srcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string
});

Video.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  photo: photoPropType.isRequired,
  margin: PropTypes.number,
  top: (props) => {
    if (props.direction === 'column' && typeof props.top !== 'number') {
      return new Error(
        'top is a required number when direction is set to `column`'
      );
    }
  },
  left: (props) => {
    if (props.direction === 'column' && typeof props.left !== 'number') {
      return new Error(
        'left is a required number when direction is set to `column`'
      );
    }
  },
  direction: PropTypes.string,
  catalogs: PropTypes.arrayOf(PropTypes.object),
  catalog: PropTypes.object,
  noInteraction: PropTypes.bool,
  templates: PropTypes.bool,
  subCatalogView: PropTypes.bool,
  reverseThumbnail: PropTypes.bool,
  allCatalogs: PropTypes.arrayOf(PropTypes.object),
  noWrap: PropTypes.bool
};

export default Video;
