import React, { useEffect, useLayoutEffect, useState, Fragment, useMemo } from 'react';
import style from './style.module.scss';
import './custom-tags.scss';
import Checkbox from 'react-custom-checkbox';
import { constants as data } from './constants';
import camelize from './camelize';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TagsInput } from 'react-tag-input-component';
import { errorToast } from 'toasts/Toasts';
import Jszip from 'jszip';

const options = [
  {
    name: 'Public',
    value: true
  },
  {
    name: 'Private',
    value: false
  }
];

const INITIAL_SELECT_STATE = {
  value: null,
  active: false,
  data
}

const camelizedValues = true;

function InteractionArea ({
  contentState,
  setContentState,
  onChangeData,
  folders,
  setImgState,
  imgState,
  currentUserRole,
  phaseState
}) {
  const [selectedColors, setSelectedColors] = useState([]);
  const [dropDownMenuPublic, setDropDownMenuPublic] = useState(INITIAL_SELECT_STATE);
  const [dropDownMenuFolder, setDropDownMenuFolder] = useState(INITIAL_SELECT_STATE);
  const [folderType, setFolderType] = useState(contentState.folder?.is_global_folder ? 1 : !(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR') ? 1 : 2);
  const { t } = useTranslation();

  const isTouchScreen = useMemo(() => {
    return ('ontouchstart' in document.documentElement);
  }, []);

  useLayoutEffect(() => {
    const inputTag = document.querySelector('.rti--input')
    if (inputTag) {
      inputTag.autocomplete = 'off'
    }
  }, []);

  const currentImage = imgState.find(el => el.active) || null

  const hasErrors = {
    border: '1px solid red'
  }

  useEffect(() => {
    setDropDownMenuFolder(() => {
      if (contentState.folder.id) {
        return {
          value: contentState.folder.name,
          active: false,
          data: contentState.folder
        }
      } else return INITIAL_SELECT_STATE
    });
    setDropDownMenuPublic(() => {
      if (contentState.isPublic !== undefined) {
        return {
          value: contentState.isPublic ? 'Public' : 'Private',
          active: false
        }
      } else return INITIAL_SELECT_STATE
    });
  }, [contentState]);

  useEffect(() => {
    contentState.color && setSelectedColors(contentState.color);
  }, [contentState]);

  const handleChange = (isColor, itemTitle, value, checked) => {
    let obj = {};
    if (itemTitle === 'Duration' || itemTitle === 'Orientation') {
      return;
    }
    const categoryTitle = camelizedValues ? camelize(itemTitle) : itemTitle;
    if (!isColor) {
      obj = {
        category: categoryTitle,
        value,
        selected: checked
      };
      setContentState(prev => ({ ...prev, [obj.category]: obj.value }))
    } else {
      if (selectedColors.includes(value)) {
        setSelectedColors(selectedColors.filter((color) => color !== value));
        obj = {
          category: categoryTitle,
          value,
          selected: false
        };
      } else {
        setSelectedColors([...selectedColors, value]);
        obj = {
          category: categoryTitle,
          value,
          selected: true
        };
      }
    }
    console.log('smehow changing')
    onChangeData(obj);
  };

  const handleRemoveError = () => {
    setImgState((prev) => {
      return prev.map(img => {
        if (img.active) {
          return {
            ...img,
            errors: null
          }
        }
        return img
      })
    })
  }

  const handleDescriptionChange = (event) => {
    setContentState((prev) => {
      return {
        ...prev,
        contentDescription: event.target.value
      };
    });
  };

  const selectFolder = (folder) => {
    setContentState((prev) => ({
      ...prev,
      folder
    }))
    handleRemoveError()
  }

  const selectPriOption = (option) => {
    setContentState((prev) => ({
      ...prev,
      isPublic: option.value
    }))
    handleRemoveError()
  }

  const handleAddingTags = (newTags) => {
    setContentState((prev) => {
      return {
        ...prev,
        wordTags: newTags
      };
    });
    if (newTags.length > 0) {
      handleRemoveError()
    }
  }

  const handlePastingTags = (event) => {
    event.preventDefault()
    try {
      const tagsArr = event.clipboardData.getData('Text').split((/[,.\s]/)).filter((tag) => tag !== '' && !contentState.wordTags.includes(tag)).map(txt => txt.replace('-', ' '));
      setContentState((prev) => {
        return {
          ...prev,
          wordTags: [...new Set([...prev.wordTags, ...tagsArr])]
        };
      });
      handleRemoveError()
    } catch {
      return null;
    }
  }

  const handleSomeSpecialKeysForTagging = (event) => {
    if (event.key === ' ' && event.target.value !== '' && isTouchScreen) {
      event.preventDefault();
      const { value } = event.target || '';
      if (value && value?.trim() !== '' && !contentState.wordTags.includes(value)) {
        setContentState((prev) => {
          return {
            ...prev,
            wordTags: [...new Set([...prev.wordTags, value?.trim()])]
          };
        });
        handleRemoveError()
        event.target.value = ''
      }
    }
  }

  const chckBoxStyle = (isDisabled) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      fontSize: '0.6rem',
      backgroundColor: isDisabled ? 'gray' : 'var(--main-color)',
      alignSelf: 'stretch',
      color: 'white',
      cursor: 'pointer',
      opacity: isDisabled ? 0.8 : 1
    }
  }

  const handleFolderSelection = (e, type) => {
    e.stopPropagation()
    if (type === 2 && !(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR')) {
      return
    }
    setFolderType(type)
    if (type !== folderType) {
      setDropDownMenuFolder(prev => {
        return {
          ...prev,
          value: null,
          active: true
        }
      })
    }
  }

  const acceptProjectFiles = (e) => {
    const file = e.target.files[0];
    if (file.type !== 'application/zip') {
      errorToast('Please upload a zip file')
      return;
    }
    // using jszip check if the file is password protected
    const zip = new Jszip();
    zip.loadAsync(file).then(() => {
      setImgState((prev) => {
        return prev.map(img => {
          if (img.active) {
            return {
              ...img,
              project: file
            }
          }
          return img
        })
      });
    }).catch(() => {
      errorToast('Please upload a zip file that is not password protected')
    }
    )
  }

  return (
    <div className={style.formContainer}>
      {
        phaseState === 3 && (
          <div className={style.projectInputs}>
            <div className={style.inputWrapper}>
              <label>Project</label>
              <label
                htmlFor='vid-project-upload'
                className={style.customup}>{
                  currentImage?.project
                    ? (
                      <>
                        {currentImage?.project?.name || ''}
                        <button 
                        className={style.removeProject}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setImgState((prev) => {
                            return prev.map(img => {
                              if (img.active) {
                                return {
                                  ...img,
                                  project: null
                                }
                              }
                              return img
                            })
                          })
                        }
                        }>✖</button>
                        </>

                    )
                    : 'Upload project in zip format (should not be password protected)'
                }</label>
              <input type='file'
                id='vid-project-upload'
                className={style.input}
                accept='application/zip'
                multiple={false}
                onChange={acceptProjectFiles}
              />
            </div>
          </div>
        )
      }
      <div className={style.upperInputs}>
        <div className={style.inputWrapper}>
          <label>Name</label>
          <input className={style.input} style={currentImage?.errors?.name ? hasErrors : {}} value={contentState.name} placeholder="Type name..."
            onChange={(e) => {
              setContentState((prev) => ({
                ...prev,
                name: e.target.value
              }))
              handleRemoveError()
            }} />
        </div>
        <div className={style.selectorContainer} style={{ zIndex: 2 }}>
          <label>Privacy options</label>
          <div className={style.selector} style={currentImage?.errors?.is_public ? hasErrors : {}} onClick={() => setDropDownMenuPublic(prev => {
            return {
              ...prev,
              active: !prev.active
            }
          })}>
            <span>{dropDownMenuPublic.value || 'Choose option'}</span>
            <ul className={dropDownMenuPublic.active ? `${style.dropDownMenu} ${style.active}` : style.dropDownMenu}>
              {options.map((option) => (
                <li key={option.name} onClick={(e) => { e.stopPropagation(); selectPriOption(option) }}>{option.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={style.upperInputs}>
        <div className={style.inputWrapper}>
          <label>Contributor</label>
          <input className={style.input} value={contentState.contributers} style={currentImage?.errors?.contributor ? hasErrors : {}} placeholder="Type contributor..."
            onChange={(e) => {
              setContentState((prev) => ({
                ...prev,
                contributers: e.target.value
              }))
              handleRemoveError()
            }} />
        </div>
        <div className={style.selectorContainer} style={{ zIndex: 1 }}>
          <div className={style.labling_type_container}>
            <label>Folder</label>
            <div className={style.folder_type_sect}>
              <button className={`${style.folder_type_btn} ${folderType === 1 ? style.active : ''}`} onClick={(e) => handleFolderSelection(e, 1)}><strong>General upload</strong></button>
              <button className={`${style.folder_type_btn} ${folderType === 2 ? style.active : ''}`} disabled={!(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR')} onClick={(e) => handleFolderSelection(e, 2)}><strong>Company upload</strong>
                <span className={style.disableMsg}>You are not an administrator</span>
              </button>
            </div>
          </div>
          <div className={style.selector} style={currentImage?.errors?.folders ? hasErrors : {}} onClick={() => setDropDownMenuFolder(prev => {
            return {
              ...prev,
              active: !prev.active
            }
          })}>
            <span>{dropDownMenuFolder.value || 'Select a folder'}</span>
            <div className={dropDownMenuFolder.active ? `${style.dropDownMenu} ${style.active} ${style.folder_selector}` : `${style.dropDownMenu} ${style.folder_selector}`}>
              <ul className={style.folder_list}>
                {folders && folders.filter(folder => {
                  if (folderType === 1) {
                    return folder?.is_global_folder
                  } else {
                    return !folder?.is_global_folder
                  }
                }).map((folder) => (
                  <li key={folder.id} onClick={(e) => { e.stopPropagation(); selectFolder(folder) }}>{folder.name}</li>
                ))}
              </ul>
              {
                dropDownMenuFolder.active && (
                  <div className={style.mobile_folder_type}>
                    <button className={`${style.folder_type_btn_mob} ${folderType === 1 ? style.active : ''}`} onClick={(e) => handleFolderSelection(e, 1)}><strong>General upload</strong></button>
                    <button className={`${style.folder_type_btn_mob} ${folderType === 2 ? style.active : ''}`} disabled={!(currentUserRole === 'EDITOR' || currentUserRole === 'ADMINISTRATOR')} onClick={(e) => handleFolderSelection(e, 2)}><strong>Company upload</strong></button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <label className={style.headers}>Word Tags</label>
      <div className={style.tagsContainer} style={currentImage?.errors?.word_tags ? hasErrors : {}} onPaste={handlePastingTags}>
        <TagsInput
          value={contentState.wordTags}
          onChange={handleAddingTags}
          // onPaste={handleAddingTags}
          name="tags"
          placeHolder="Add new tags..."
          onKeyUp={e =>
            handleSomeSpecialKeysForTagging(e)
          }
        />
      </div>
      <div className={style.filterContainer}>
        {data.map((element) => (
          <div key={element.title} className={`${style.filterCell} ${(element.title === 'Duration' || element.title === 'Orientation') && `${style.grayed}`}`}>
            <p className={style.filterHeader}>{element.title}</p>
            <div className={style.cellOptions}>
              {element.filters?.map((filter) => (
                <Fragment key={filter.name}>
                  <Checkbox
                    checked={contentState[camelize(element.title)].includes(filter.name)}
                    disabled={element.title === 'Orientation' || element.title === 'Duration'}
                    icon={
                      <div
                        style={chckBoxStyle(filter.name !== 'Orientation' && filter.name !== 'Duration')}
                      >
                        ✓
                      </div>
                    }
                    onChange={(value) => {
                      element.title !== 'Orientation' && element.title !== 'Duration' &&
                        handleChange(false, element.title, filter.name, value);
                    }}
                    borderColor="gray"
                    borderWidth={1}
                    borderRadius={100}
                    style={{ overflow: 'hidden' }}
                    size={16}
                    label={t(filter.name)}
                  />
                  <div className={style.colorContainer}>
                    {element.colors?.map((color) => (
                      <div
                        key={color}
                        className={style.box}
                        style={{ backgroundColor: color }}
                        onClick={(e) =>
                          handleChange(true, element.title, color)
                        }
                      >
                        {selectedColors.includes(color) && (
                          <span
                            style={
                              color === 'white'
                                ? { color: 'black' }
                                : { color: 'white' }
                            }
                            className={style.checkedColor}
                          >
                            ✓
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
      <label className={style.headers}>{t('Content description')}</label>
      <div className={style.contentArea}>
        <textarea
          placeholder={t('Add description') + '...'}
          value={contentState.contentDescription}
          onChange={handleDescriptionChange}
        />
      </div>
    </div>
  );
}

export default InteractionArea;

InteractionArea.propTypes = {
  contentState: PropTypes.object,
  setContentState: PropTypes.func,
  onChangeData: PropTypes.func,
  folders: PropTypes.array,
  setImgState: PropTypes.func,
  imgState: PropTypes.object,
  currentUserRole: PropTypes.string || PropTypes.any,
  phaseState: PropTypes.number
}
