import React, { useState, useEffect } from 'react';
import styles from './CircularImage.module.scss'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';

const getInitials = (name) => {
  const names = name ? name.split(' ') : [];
  return names.map(name => name[0]).join('').toUpperCase();
}
export default function CircularImage ({ image, width, height, extraClass }) {
  const user = useSelector((state) => state.auth.user);
  const { selectedProfile } = useSelector(
    (state) => state.profile
  );

  console.log('selectedprofile', selectedProfile)
    const [initials, setInitials] = useState('');

    useEffect(() => {
        if (user) {
            setInitials(getInitials(user?.fullName))
        }
    }, [user])
    
  return (
    <div className={`${styles.container} ${styles[extraClass]}`}>
      <div
        className={styles.profileImage}
        // Add back when we are using real images
        // style={{ backgroundImage: `url(${image})`, width, height }}
      >
        {
          selectedProfile?.profilePhoto

            ? (
            <img
              className={styles.user_icon}
              src={`https://d39s7usso569ei.cloudfront.net/${selectedProfile?.profilePhoto}`}
              alt="logo"
            />
          )
          : (
            initials
          )
        }
      </div>
    </div>
  )
}
CircularImage.propTypes = {
  image: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  extraClass: PropTypes.string
}
