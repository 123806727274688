/* eslint-disable react/prop-types */
import styles from './css/Reports.module.scss';
import React, { useEffect, useState } from 'react';
import Photo from 'components/organisms/GalleryLayout/Photo';
import { useTranslation } from 'react-i18next';

const Reports = ({ handleClick, reportsArray, currentUser }) => {
  const [viewPort, setviewPort] = useState(300);
  const { t } = useTranslation();

  const handleResize = () => {
    if (window.innerWidth > 1100) {
       viewPort !== 350 && setviewPort(350);
    } else if (window.innerWidth < 1100 && window.innerWidth > 700) {
       setviewPort(290);
    } else {
       setviewPort(window.innerWidth / 2);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [viewPort]);

  const list = reportsArray.map((template) => {
    return (
      <Photo
        key={template?.id}
        index={0}
        currentUser={currentUser}
        assetUserId={template?.userId}
        onClick={handleClick}
        photo={template}
        catalog={template}
        allCatalogs={reportsArray}
        margin={0}
        height={viewPort}
        linearLine={true}
        templates={true}
        useOriginal={true}
        mixedContent={true}
        homePage={true}
        OverRuledRoles={[]}
      />
    );
  });

  return (
    <section className={styles.container}>
      <div className={styles.reports__container}>
        <h1>{t('Reports')}</h1>
        <small>{t('insights-text')}</small>
        <div className={styles.reports__listing}>{list}</div>
      </div>
    </section>
  );
};

export default Reports;
