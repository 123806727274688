import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getProfileAdministrationAPI } from 'api/profileadministration'

// First, create the thunk

export const getProfileAdministration = createAsyncThunk(
  'profileAdministration/getprofileAdministration',
  async () => {
    const response = await getProfileAdministrationAPI()
    return response?.data?.data || null
  }
)

const initialState = {
  profileAdministration: null,
  role: '',
  loadingPA: false
}

export const profileAdministration = createSlice({
  name: 'profileAdministration',
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getProfileAdministration.pending, (state) => {
      state.loadingPA = true
    })
    builder.addCase(getProfileAdministration.fulfilled, (state, action) => {
      state.profileAdministration = action.payload
      state.loadingPA = false
    })
    builder.addCase(getProfileAdministration.rejected, () => {
      // window.location.replace(`${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${window.location}`);
    })
  }
})

// export const { fetchingCatolog } = folders.actions

export default profileAdministration.reducer
