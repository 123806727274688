/* eslint-disable react/prop-types */
//* Dropzone.js*//

import RoundedButton from 'components/atoms/RoundedButton/RoundedButton'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import ImageGrid from './ImageGrid'

function Dropzone ({ onDrop, accept, open, images, deleteImage, loadingImages, remainingUploads, isMultiUpload }) {
  const { getRootProps, getInputProps, isDragActive /* acceptedFiles */ } =
    useDropzone({
      accept: { ...accept },
      onDrop
    })

  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ))

  return (
    <div className='drop-zone-container' disabled={loadingImages}>
      <div
        {...getRootProps({ className: 'dropzone' })}
        style={
          isDragActive
            ? { border: '2px dashed grey', borderRadius: '20px' }
            : {}
        }
      >
        <input className='input-zone' {...getInputProps()} />
        <div className='text-center'>
          {isDragActive
            ? (
              <p className='dropzone-content'>Release to drop the files here</p>
            )
            : (
              <>
                <p className='lead-txt'>
                  {loadingImages ? 'Loading and optimizing your assets' : <>Drag and drop your content <br /> here to start uploading</>}
                </p>

                {loadingImages ? null : <p className='center-text'>or</p>}
              </>
            )}
          <div className='center-pos'>
            {
              !loadingImages && (
                <RoundedButton onClick={open} extraClass='browse-btn'>
                  Browse files
                </RoundedButton>
              )
            }

          </div>
        </div>
      </div>
      {images.length
        ? (
          <ImageGrid images={images} deleteImage={deleteImage} remainingUploads={remainingUploads} isMultiUpload={isMultiUpload} />
        )
        : (
          <div className='terms-container'>
            <div className='required-photo-terms'>
              <small className='instruction-span'> <span className='dots'>•</span> High quality photos (at least 3MP)</small>
              <small className='instruction-span'><span className='dots'>•</span> Photos are clear and original</small>
            </div>

            <div className='required-photo-terms'>
              <small className='instruction-span'><span className='dots'>•</span> Only upload photos you own the right to</small>
              <small className='instruction-span'><span className='dots'>•</span> Zero tolerance for nudity, violence or hate</small>
            </div>

            <div className='required-photo-terms'>
              <small className='instruction-span'><span className='dots'>•</span> Read the <a href='#' style={{ textDecoration: 'underline', color: '#fff' }}>MPublisher terms</a></small>
              <small className='instruction-span'><span className='dots'>•</span> Respect the intellectual property of others</small>
            </div>

          </div>
        )}
    </div>
  )
}

export default Dropzone
