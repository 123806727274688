/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import styles from './DraftCard.module.scss';
import { Link } from 'react-router-dom';
import noImage from 'assets/images/noimage.png';

const DraftCard = ({ card, setInteractionPopups }) => {
  const { documentName, thumbnail } = card?.document?.documentInfo || {
    documentName: 'Untitled'
  };

  return (
    <div
      className={styles.folderCard}
      key={card?.id}
    >
      <Link
        className={`${styles.folderContainer}`}
        key={card?.id}
        to={`/mpublisher/create/${card?.draftId}`}
        state={card}
      >
        <div className={styles.folderControlContainer}>
          <img
            src={thumbnail || noImage}
            alt="thumbnail"
            loading='lazy'
            className={styles.folderThumbnail}
          />
        </div>
        <button className={styles.controlIcon} onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setInteractionPopups({
            state: 7,
            data: card
          });
        }}>x</button>
        <div
          className={styles.folderInfo}
        >
          <p className={styles.folderName}>
            {documentName.length > 17
              ? documentName.slice(0, 15) + '...'
              : documentName}
          </p>
          <p className={styles.lastUpdated}>
            {card?.updatedAt
              ? `Last updated: ${new Date(
                card?.updatedAt
              )?.toLocaleDateString() || 'Undefined'
              }`
              : null}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default DraftCard;
