import styles from './css/AssetLinks.module.scss';
import React from 'react';
import getAssetTitles from './functions/getAssets';
import RoundedButton from '../../../atoms/RoundedButton/RoundedButton';
import { useNavigate } from 'react-router-dom';
import goToAssets from './functions/goToAssets';
import { useTranslation } from 'react-i18next';

const AssetLinks = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const assetTitles = getAssetTitles();
    const assets = assetTitles.map((asset) => {
        return (
            <div className={`${styles.listing_card}`} key={asset.title}>
                <img src={asset.image} alt={asset.title}/>
                <p>{t(asset.title)}</p>
                <small>{t(asset.subTitle)}</small>
                {asset.link && <RoundedButton extraClass={styles.asset__links__button} onClick={() => goToAssets(navigate, asset.link)}>{t(asset.label)}</RoundedButton>}
            </div>
        )
    });

    return (
        <section className={styles.container}>
            <div className={`${styles.asset__links__container}`}>
                <h1>{t('Features that you will love')}</h1>
                <div className={`${styles.asset__links__listing}`}>{ assets }</div>
            </div>
        </section>
    );
};

export default AssetLinks;
