import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  view: window.innerWidth > 1270,
  expandState: 'show'
};

export const uniMenuState = createSlice({
  name: 'uniMenuState',
  initialState,
  reducers: {
    updateUniState: (state, action) => {
      state.view = action.payload;
    },
    updateExpandState: (state, action) => {
      state.expandState = action.payload;
    }
  }
});

export const {
  updateUniState,
  updateExpandState
} = uniMenuState.actions;

export default uniMenuState.reducer;
