/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import MiddleArea from './MiddleArea';
import TopArea from './TopArea';
import BottomArea from './BottomArea';
import LightEditor from 'components/molecules/LightEditor/LightEditor';
import { ReactComponent as ColorPalette } from 'assets/icons/color-palet.svg'

const index = ({ data, templateView, downloadST, dlAll, shareMode }) => {
  const [lightEditing, setLightEditing] = useState(false)
  const [brightness, setBrightness] = useState(false)
  const [contrast, setContrast] = useState(false)
  const [saturation, setSaturation] = useState(false)
  const [hue, setHue] = useState(false)

  const { src, content_description, image_size, assest_type, id, index, contributor } = data

  const generateFilterStr = (brightness, contrast, saturation, hue) => {
    let filterData = '';
    if (brightness !== false && brightness !== undefined) {
      filterData += `brightness(${brightness}%)`;
    }
    if (contrast !== false && contrast !== undefined) {
      filterData += `contrast(${contrast}%)`;
    }
    if (saturation !== false && saturation !== undefined) {
      filterData += `saturate(${saturation}%)`;
    }
    if (hue !== false && hue !== undefined) {
      filterData += `hue-rotate(${hue}deg)`;
    }
    return filterData;
  };

  const filterStyle = {
    webkitFilter: generateFilterStr(brightness, contrast, saturation, hue)
  };

  return (
    <div className={styles.popUpContainer}>
        <TopArea id={id} downloadST={downloadST} dlAll={dlAll} index={index} shareMode={shareMode}/>
        <MiddleArea 
        img={!lightEditing ? src.thumbnail : src.original}
        templateView={true}
        lightEditing={lightEditing}
        filterStyle={filterStyle}
        />
        {
          !templateView && (
            <BottomArea description={content_description} dimensions={image_size} fileType={assest_type} contributor={contributor}/>
          )
        }
        {
          templateView && !dlAll && !shareMode && (
            <div className={styles.lightEditingContainer}>
              <div className={styles.toggleBtnContainer}>
                <button className={lightEditing ? `${styles.editorBtn} ${styles.active}` : styles.editorBtn} onClick={() => setLightEditing(prev => !prev)}>
                  <ColorPalette />
                </button>
              </div>
              <div className={lightEditing ? `${styles.editorContainer} ${styles.active}` : styles.editorContainer}>
                <LightEditor
                  setBrightness={setBrightness}
                  setContrast={setContrast}
                  setSaturation={setSaturation}
                  setHue={setHue}
                  brightness={brightness}
                  contrast={contrast}
                  saturation={saturation}
                  hue={hue}
                  download={() => downloadST(index, filterStyle)}
                />
              </div>
            </div>
          )
        }
    </div>
  )
}

export default index
