import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './private';
import LinkSharePage from 'components/pages/LinkSharePage/LinkSharePage';
import OpenEditorContainer from 'components/pages/OpenEditorContainer/OpenEditorContainer';
import NotAvailable from 'components/pages/NotAvailable/NotAvailable';

export default function Navigation () {
  return (
    <Routes>
      <Route index exact path={'/'} element={<Navigate to="/mpublisher" />} />
      <Route index exact path={'/home'} element={<Navigate to="/mpublisher" />} />
      <Route index exact path={'/dashboard'} element={<Navigate to="/mpublisher" />} />
      <Route path="/share/:shareId" exact element={<LinkSharePage />} />
      <Route path="/share-template/:shareId" exact element={<OpenEditorContainer />} />
      <Route index path="/mpublisher/*" element={<PrivateRoutes />} />
      <Route index path="*" element={<NotAvailable />} />
    </Routes>
  );
}
