import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserDataAPI } from 'api/userdata'

// First, create the thunk

export const getUserData = createAsyncThunk(
  'userdata/getUserData',
  async () => {
    const response = await getUserDataAPI()
    return response.data
  }
)

const initialState = {
  userdata: {},
  loadingUD: false
}

export const userdata = createSlice({
  name: 'userdata',
  initialState,
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUserData.pending, (state) => {
      state.loadingUD = true
    })
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userdata = action.payload
      state.loadingUD = false
    })
    builder.addCase(getUserData.rejected, () => {
      // window.location.replace(`${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${window.location}`);
    })
  }
})

// export const { fetchingCatolog } = folders.actions

export default userdata.reducer
