/* eslint-disable no-unused-vars */
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import styles from './SharedByMe.module.scss';
import SearhBar from '../SearchBar/SearchBar';
import Tags from '../Tags/Tags';
import {
  getCollectionsSharedByMeAPI
} from 'api/collections';
import { Link, useLocation } from 'react-router-dom';
import ItemsComponent from '../components/ItemsComponent';
import { getCatalogByCollectionIdAPI } from 'api/contentUploadAPI';
import MiniPopupManager from '../components/MiniPopupManager';
import { useDispatch, useSelector } from 'react-redux';
import { getSharedByMeCollections } from 'Redux/collection/collectionSlice';
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import { useTranslation } from 'react-i18next';

const arrOfTags = [
  { name: 'All', active: true },
  { name: 'Recent', active: false },
  { name: 'Folders', active: false },
  { name: 'Designs', active: false }
];

const SharedByMe = () => {
  const dispatch = useDispatch();
  const { sharedByMeCollections, loading } = useSelector(
    (state) => state.collections
  );
  const { t } = useTranslation();
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [addCollectionPopup, setAddCollectionPopup] = useState(false);
  const [interactionPopups, setInteractionPopups] = useState(null);
  const [grpdCollections, setGrpdCollections] = useState([]);

  const bannerStyle = {
    backgroundImage: 'var(--col-s-banner)',
    backgroundColor: 'var(--button-normal)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  };

  const currentDraggedElement = useRef(null);

  useEffect(() => {
    dispatch(getSharedByMeCollections())
  }, []);

  const getACollectionCatalogs = async (id) => {
    try {
      const response = await getCatalogByCollectionIdAPI(id);
      const catalogsData = response?.data?.data?.result || null;
      if (catalogsData && catalogsData.length) {
        const previewCatalogData = catalogsData.map((catalog, i) => {
          if (catalog?.assest_type === 'Templates') {
            return {
              ...catalog,
              index: i,
              id: catalog?._id || catalog?.id,
              src: catalog?.template_data?.templates[0],
              width: catalog?.template_data?.templates[0]?.width || 1920,
              height: catalog?.template_data?.templates[0]?.height || 1080
            };
          }
          return {
            ...catalog,
            index: i,
            id: catalog?._id || catalog?.id,
            src: catalog?.assest_urls[0],
            width: catalog?.image_size?.width || 16,
            height: catalog?.image_size?.height || 9
          };
        });
        return previewCatalogData;
      }
      return [];
    } catch {
      return [];
    }
  };

  function groupByCollectionId (objectsArray) {
    const groupArray = []
    objectsArray.forEach((el) => {
      if (!groupArray.find((groupedEls) => groupedEls.collectionId === el.collectionId)) {
        groupArray.push(el)
      }
    })
    return groupArray
  }

  useEffect(() => {
    if (Array.isArray(sharedByMeCollections)) {
      // group elements in sharedByMeCollections array by collectionId and push them to groupedArray
      setGrpdCollections(groupByCollectionId(sharedByMeCollections));
    }
  }, [sharedByMeCollections]);

  return (
    <div className={styles.files__container}>
      <MiniPopupManager
        interactionPopups={interactionPopups}
        addCollectionPopup={addCollectionPopup}
        setAddCollectionPopup={setAddCollectionPopup}
        setInteractionPopups={setInteractionPopups}
      />
      <div className={styles.search_container} style={bannerStyle}>
        <SearhBar
          onSelectChange={setSelectedAssetType}
          selectValue={selectedAssetType}
          onTextChange={setSearchInput}
          textValue={searchInput}
        />
      </div>
      {/* <div className={styles.tags_container}>
        <Tags setTags={setTags} selectedTags={selectedTags} />
      </div> */}
      <div className={styles.content_container}>
        <h2 className={styles.content_container__title}>{t('Shared by me')}</h2>
        <p className={styles.content_container__count}>{grpdCollections?.length || 0} {t('collections were shared by you')}</p>

        {
          !loading
            ? (
              <ItemsComponent
                collections={grpdCollections || []}
                allCollections={sharedByMeCollections || []}
                searchInput={searchInput}
                openPopup={setAddCollectionPopup}
                setInteractionPopups={setInteractionPopups}
                getACollectionCatalogs={getACollectionCatalogs}
                mainView={true}
                currentDraggedElement={currentDraggedElement}
                handleDragEnd={() => null}
                noAdding={true}
                isShared={true}
              />
            )
            : <ComponentLoaderTri />
        }
      </div>
    </div>
  );
};

export default SharedByMe;
