import React from 'react';
import './buttonWithIcon.scss';

// eslint-disable-next-line react/prop-types
export default function ButtonWithIcon ({ text, icon, onClick, disabled }) {
  return (
    <button disabled={disabled} className="btn" onClick={() => onClick && onClick()}>
      <i>{icon}</i>
      {text}
    </button>
  );
}
