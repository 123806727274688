import React from 'react';

const CompanyLogo = (props) => (
  <svg
    stroke="var(--main-color)"
    fill="var(--main-color)"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 19H23V21H1V19H3V4C3 3.44772 3.44772 3 4 3H14C14.5523 3 15 3.44772 15 4V19H17V9H20C20.5523 9 21 9.44772 21 10V19ZM7 11V13H11V11H7ZM7 7V9H11V7H7Z"></path>
  </svg>
);

export default CompanyLogo;
