/* eslint-disable react/prop-types */
import React from 'react';

const BackHighlightEffect = ({ mainColor, fillColor, opacity }) => (
<svg width="2216" height="2371" viewBox="0 0 2216 2371" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_230_550)">
<path d="M892.5 501L501 1526.5H530L694.5 1478L970 1381.5L859 1623L1086 1870L1715 1429.5L892.5 501Z" fill={fillColor} fillOpacity={opacity}/>
<path d="M892.5 501L501 1526.5H530L694.5 1478L970 1381.5L859 1623L1086 1870L1715 1429.5L892.5 501Z"/>
</g>
<defs>
<filter id="filter0_f_230_550" x="0.273438" y="0.0508423" width="2215.47" height="2370.61" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_230_550"/>
</filter>
</defs>
</svg>

)

export default BackHighlightEffect;
