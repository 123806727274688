import React from 'react'
import roles from '../constants/roles'
import PropTypes from 'prop-types'

export const FeatureFlags = React.createContext({})

export const FeatureFlagsProvider = ({ children }) => {
  return (
    <FeatureFlags.Provider value={{ roles }}>
      {children}
    </FeatureFlags.Provider>
  )
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}
