import React from 'react';
import styles from './styles.module.scss';
import { ReactComponent as Smiley } from 'assets/icons/smiley.svg';
import PropTypes from 'prop-types';

export default function NotFound ({ errMsg }) {
  return (
    <div className={styles.container}>
      <Smiley />
      <p className={styles.paragraph}>{errMsg || 'No items found'}</p>
    </div>
  );
}

NotFound.propTypes = {
  errMsg: PropTypes.string
}
