/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import './universalmenu_custom.scss';
// import { UniversalMenu } from '@master-wizr/universal-menu';
import { UniversalMenu } from '@master-wizr/universal-menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateUniState, updateExpandState } from 'Redux/universalMenu/uniSlice';
import { lightModeCSS, darkModeCSS, blueModeCSS, greenModeCSS, purpleModeCSS } from './colormodes';
import { createDraft } from 'Redux/draft/draftSlice';
import Popup from 'components/atoms/Popup/Popup';
import { errorToast } from 'toasts/Toasts';
import SettingsPopup from './SettingsPopup';
// import SidebarRoutes from '../Sidebar/SidebarRoutes';

const initialValues = {
  mode: 'dark'
};

const GlobalMenu = () => {
  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const menuValue = useRef('show');

  const location = useLocation()
  const dispatch = useDispatch();
  const { folders } = useSelector((state) => state.folders);
  const { view } = useSelector((state) => state.uniMenuState);
  const { user } = useSelector((state) => state.auth);
  const { roles } = user || {
    roles: []
  };
  const { selectedProfile, currentCompanyInfo } = useSelector((state) => state.profile);
  const uniMenuRef = React.createRef();
  const easyCloseMenu = (e) => {
    if (window.innerWidth < 500 && isTouchScreen()) {
      dispatch(updateUniState(false));
    }
  }

  const [props, setProps] = useState(initialValues);
  const [folderSettingsPopup, setFolderSettingsPopup] = useState(false);
  const [creatingPopup, setCreatingPopup] = useState(false);
  const navigate = useNavigate();
  const navigation = (url) => {
    navigate(url);
    easyCloseMenu();
  }

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      displayValue: view ? '100' : '0'
    }));
  }, [view]);

  useLayoutEffect(() => {
    if (selectedProfile?.accountId) {
      switch (localStorage.getItem(`${selectedProfile?.accountId}`)) {
        case 'light':
          toggleLight()
          break;
        case 'dark':
          toggleDark()
          break;
        case 'blue':
          toggleBlue()
          break;
        case 'green':
          toggleGreen()
          break;
        case 'purple':
          togglePurple()
          break;
        default:
          toggleDark()
          break;
      }
    }
  }, [selectedProfile]);

  const handleResize = () => {
    if (window.innerWidth > 1270 && (location.pathname !== '/mpublisher/upgrade' && location.pathname !== '/mpublisher/upgrade/')) {
      !view && dispatch(updateUniState(true));
    } else {
        view && dispatch(updateUniState(false));
    }
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [view])

  // const location = useLocation();
  // const [currentLocation, setCurrentLocation] = useState(location.pathname);
  //
  // useEffect(() => {
  //     setCurrentLocation(location.pathname);
  // }, [location]);
  const saveToLocalStorage = (theme) => {
    localStorage.setItem(`${selectedProfile?.accountId}`, theme);
  }
  const convertObjCssToDom = (obj) => {
    for (const variable in obj) {
      const root = document.documentElement;
      root.style.setProperty(variable, obj[variable]);
    }
  }
  const toggleLight = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: 'light'
    }));
    convertObjCssToDom(lightModeCSS);
    saveToLocalStorage('light');
  };
  const toggleDark = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: 'dark'
    }));
    convertObjCssToDom(darkModeCSS);
    saveToLocalStorage('dark');
  };

  const toggleBlue = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: 'blue'
    }));
    convertObjCssToDom(blueModeCSS);
    saveToLocalStorage('blue');
  };

  const toggleGreen = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: 'green'
    }));
    convertObjCssToDom(greenModeCSS);
    saveToLocalStorage('green');
  };

  const togglePurple = () => {
    setProps((prevProps) => ({
      ...prevProps,
      mode: 'purple'
    }));
    convertObjCssToDom(purpleModeCSS);
    saveToLocalStorage('purple');
  };

  const closeMenu = () => {
    dispatch(updateUniState(false));
  };

  const logout = () => {
    alert('This is in dev mode, You will have to add a logout functionality');
  };

  const onExpand = (value) => {
    dispatch(updateExpandState(value));
  }

  const createBtnMpub = async () => {
    setCreatingPopup(true);
    const draft = {
      type: 'presentation',
      is_public: true
    }
    dispatch(createDraft({
      data: draft,
      callback: (response) => {
        setCreatingPopup(false);
        navigate(`/mpublisher/create/${response.draftId}`, { state: response });
      },
      errorCallback: (error) => {
        setCreatingPopup(false);
        errorToast('You have reached your monthly limits', error?.message || 'An error occurred')
      }
    }));
  };

  useEffect(() => {
    if (location.pathname === '/mpublisher/upgrade' || location === '/mpublisher/upgrade/') {
      closeMenu()
    }
  }, [location])

  const capitalize = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1) || null;
  }

  const mpubFoldersettingsFn = () => {
    setFolderSettingsPopup(true);
  }

  return (
    <>
      {
        folderSettingsPopup && <SettingsPopup closePopup={() => setFolderSettingsPopup(null)}/>
      }
      <div
        ref={uniMenuRef}
      >
        <UniversalMenu
          {...{
            ...props,
            profile: selectedProfile,
            logout,
            toggleLight,
            toggleDark,
            toggleBlue,
            toggleGreen,
            togglePurple,
            toggleMenu: closeMenu,
            _redirect: navigation, // uses react router dom useNavigate hook to handle route navigation
            handleCreateShowroom: () => null,
            handleCreateCoverPages: () => null,
            isMpublisher: true,
            createBtnMpub,
            // MPUBLISHER_URL: process.env.REACT_APP_DEV_STATE ? process.env.REACT_APP_URL : process.env.REACT_APP_LIVE_URL,
            redirectURL: 'mpublisher',
            hiddenIcons: 'wizrup',
            mpubFolders: folders?.filter((folder) => folder?.is_global_folder),
            mpubFoldersPrivate: folders?.filter((folder) => !folder?.is_global_folder),
            mainActiveColor: 'var(--main-color)',
            orgName: currentCompanyInfo?.name ? capitalize(currentCompanyInfo?.name) : undefined,
            onExpand,
            mpubFoldersettingsFn,
            // isNotAllowedEditor: 'The editor is not part of your subscription'
            isNotAllowedEditor: // if smaller than 1024px,
              window.innerWidth < 1024
                ? 'Cannot access editor on mobile devices'
                : undefined
          }}
        />
        {
          creatingPopup && <Popup
            closePopup={() => setCreatingPopup(false)}
          >
            <div className={'popup__container_create'}>
              <p className='cr-text'>Creating document for you</p>
              <div className='loader' />
            </div>
          </Popup>
        }
      </div>
    </>
  );
};

export default GlobalMenu;
