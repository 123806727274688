/* eslint-disable react/prop-types */
import {
  createCatalgoAPIFromEditor,
  downloadCatalogAPI,
  getCatalogByCollectionIdAPI,
  getCatalogByFolderIdAPI,
  getCatalogFile,
  getSearchCatsAPI,
  getCatalogByAssetTypeAPI,
  uploadFileAPI,
  updateEditorTemplateAPI
} from 'api/contentUploadAPI';
import styles from './EditorContainer.module.scss';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Editor from '@master-wizr/editor';
import '@master-wizr/editor/dist/style.css';
import { getAllCollectionsAPI } from 'api/collections';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  getDraftBydraftIdAPI,
  updateDraftBydraftIdAPI,
  requestEditByTemplateIdAPI
} from 'api/draft';
import { errorToast } from 'toasts/Toasts';
import { getUserData } from 'Redux/userdata/userdataSlice';
import Popup from 'components/atoms/Popup/Popup';
import SharingPopup from './SharingPopup';

function EditorContainer ({ currentUserRole, editing }) {
  const { folders } = useSelector((state) => state.folders);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { draftId, templateId } = useParams();
  const { state, pathname } = useLocation();
  const [currentdraft, setCurrentDraft] = useState(state);
  const [currentTemplate, setCurrentTemplate] = useState(state);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [isSharing, setIsharing] = useState(false);

  const goBackFn = () => {
    dispatch(getUserData());
    navigate('/mpublisher/my-files');
  };

  if (window.innerWidth < 1024) {
    navigate('/mpublisher');
  }

  useEffect(() => {
    if (editing) {
      return;
    }
    if (!draftId) {
      navigate('/mpublisher');
    }
    if (!state && draftId) {
      try {
        setLoadingDraft(true);
        getDraftBydraftIdAPI(draftId).then((response) => {
          const { data } = response?.data;
          if (!data || data.message) {
            // navigate('/mpublisher/my-files');
            errorToast('Draft not found');
            throw new Error('Draft not found');
          }
          setCurrentDraft(data);
          setLoadingDraft(false);
        });
      } catch (error) {
        navigate('/mpublisher');
      }
    }
  }, [draftId, state, editing]);

  useEffect(() => {
    if (editing && templateId) {
      try {
        setLoadingDraft(true);
        requestEditByTemplateIdAPI(templateId)
          .then((response) => {
            const { data } = response?.data;
            if (!data || data.message) {
              navigate('/mpublisher/my-files');
              errorToast('Template not found');
              throw new Error('Template not found');
            }
            setLoadingDraft(false);
            setCurrentTemplate(data);
          })
          .catch((e) => {
            errorToast('Template not found');
            navigate('/mpublisher');
            setLoadingDraft(false);
          });
      } catch {
        errorToast('Something went wrong');
        navigate('/mpublisher');
        setLoadingDraft(false);
      }
    }
  }, [templateId, editing]);

  const saveFn = async (data) => {
    const { documentId } = data?.documentInfo;
    if (documentId && data) {
      const response = await updateDraftBydraftIdAPI(draftId, data);
      return response;
    }
  };

  const updateEditorTemplate = async (data, onProgress) => {
    const newData = { ...data, id: templateId };
    delete newData.draftSource;
    const response = await updateEditorTemplateAPI(newData, onProgress);
    return response;
  };

  // triggerSharing,

  const switchToViewCallback = () => {
    navigate(`${pathname}/view`);
  };

  const switchToEditModeCallback = () => {
    navigate(pathname.replace('/view', ''));
  };

  const triggerSharing = () => {
    setIsharing(currentTemplate || currentdraft);
  };

  return (
    <>
      <div id="modal-root" />
      <div className={styles.container}>
        {loadingDraft && (
          <div className={styles.loading}>
            <div className={styles.spinner} />
          </div>
        )}
        {(currentdraft || currentTemplate) && (
          <Editor
            goBackFn={goBackFn}
            uploadFile={uploadFileAPI}
            documentFile={!editing ? currentdraft : currentTemplate}
            isEditing={editing}
            getCatalogImageFile={downloadCatalogAPI}
            getCatalogFile={getCatalogFile}
            createCatalogAPI={
              !editing ? createCatalgoAPIFromEditor : updateEditorTemplate
            }
            getCollectionsAPI={getAllCollectionsAPI}
            getCatalogByCollectionId={getCatalogByCollectionIdAPI}
            getCatalogByFolderId={getCatalogByFolderIdAPI}
            getCatalogByAssetType={getCatalogByAssetTypeAPI}
            onSearchFn={getSearchCatsAPI}
            folders={folders || ['']}
            currentUserRole={currentUserRole}
            inheritThemes={true}
            saveDocFunction={!editing ? saveFn : () => {}}
            viewOnly={pathname.includes('/view')}
            switchToViewCallback={switchToViewCallback}
            switchToEditModeCallback={switchToEditModeCallback}
            triggerSharing={triggerSharing}
          />
        )}
      </div>
      {
        isSharing && (
          <Popup
            closePopup={() => setIsharing(false)}
            bgColor={'#232323'}
            extraStyles={{
              borderRadius: '15px',
              width: '90%',
              maxWidth: '550px',
              height: '250px'
            }}
          >
            <SharingPopup data={{
              type: currentdraft ? 'draft' : 'template',
              id: (currentdraft?.id || currentdraft?._id) || templateId
            }} />
          </Popup>
        )
      }
    </>
  );
}
export default EditorContainer;
