/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import Carousel from 'react-spring-3d-carousel';
import { useDropzone } from 'react-dropzone'
import './style.scss';
import { errorToast } from 'toasts/Toasts';

export default function DragDropFile ({ atLeastOneSelected, setCustomThumbnails, customThumbs, thumbnailsData, setThumbnails, setImgState }) {
  const usedThumbnails = thumbnailsData?.find(th => th?.id === atLeastOneSelected?.id)
  const currentCustom = customThumbs?.find(th => th?.id === atLeastOneSelected?.id)?.custom
  const { thumbnails, custom } = usedThumbnails || {}
  const [usedIndex, setUsedIndex] = useState(0)
  // const [isDragging, setIsDragging] = useState(false)

  const backgroundOfCustomStyle = useMemo(() => {
    return {
      backgroundImage: currentCustom ? `url(${URL.createObjectURL(currentCustom)})` : '',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }
  }, [currentCustom])

  const selectThumbnail = (thumbnail) => {
    setImgState(prev => prev.map((el) => {
      if (el.id === atLeastOneSelected?.id || el.selected) {
        el.uriData = thumbnail?.blob
      }
      return el
    })
    )
    setThumbnails(thumbnailsData.map(th => {
      if (th?.id === atLeastOneSelected?.id) { // correct video
        th.thumbnails = th?.thumbnails?.map(t => {
          t.selected = t?.id === thumbnail?.id
          return t
        })
      }
      return th
    }))
  }

  const isFileJpg = (file) => {
    return file.type === 'image/jpeg' || file.type === 'image/jpg'
  }

  const onDropFn = (acceptedFiles) => {
    const firstFile = acceptedFiles[0]
    if (firstFile && isFileJpg(firstFile)) {
      setImgState(prev => prev.map((el) => {
        if (el.id === atLeastOneSelected?.id || el.selected) {
          el.uriData = firstFile
        }
        return el
      })
      )
      setCustomThumbnails(prev => {
        const newCustomThumbs = prev.map(th => {
          if (th.id === atLeastOneSelected?.id) {
            th.custom = firstFile
          }
          return th
        })
        return newCustomThumbs
      })
      makeCustom(true)
    } else {
      errorToast('Only jpeg files are allowed')
    }
  }
  const { getRootProps, getInputProps, isDragActive /* acceptedFiles */ } =
    useDropzone({
      accept: { 'image/jpeg': 'image/jpeg' },
      onDrop: onDropFn
    })

  useEffect(() => {
    const index = thumbnails?.findIndex(th => th.selected)
    setUsedIndex(index || 0)
  }, [thumbnailsData])

  useEffect(() => {
    if (custom) {
      const currentCustom = customThumbs?.find(th => th?.id === atLeastOneSelected?.id)?.custom
      currentCustom && setImgState(prev => prev.map((el) => {
        if (el.id === atLeastOneSelected?.id || el.selected) {
          el.uriData = currentCustom
        }
        return el
      })
      )
    } else {
      usedThumbnails?.thumbnails?.[usedIndex || 0]?.blob && setImgState(prev => prev.map((el) => {
        if (el.id === atLeastOneSelected?.id || el.selected) {
          el.uriData = usedThumbnails?.thumbnails?.[usedIndex || 0]?.blob
        }
        return el
      })
      )
    }
  }, [custom])

  const slides = thumbnails?.map((thumbnail, index) => {
    return {
      key: thumbnail?.id,
      content: <img src={URL.createObjectURL(thumbnail?.blob)} alt='thumbnail' style={{ height: '60%' }} onClick={() => selectThumbnail(thumbnail)} />
    }
  })

  const makeCustom = (custom) => {
    setThumbnails(thumbnailsData.map(th => {
      if (th?.id === atLeastOneSelected?.id) { // correct video
        th.custom = custom
      }
      return th
    }))
  }

  const handleWheelMvt = (e) => {
    if (!thumbnails) return
    if (e.deltaY > 0) {
      const nextThumbnail = thumbnails?.[usedIndex + 1] || thumbnails?.[0]
      if (nextThumbnail) selectThumbnail(nextThumbnail)
    } else {
      const prevThumbnail = thumbnails?.[usedIndex - 1] || thumbnails?.[thumbnails.length - 1]
      if (prevThumbnail) selectThumbnail(prevThumbnail)
    }
  }

  const carousel = useMemo(() => {
    return slides?.length > 0
? (
      <Carousel
        slides={slides}
        goToSlide={usedIndex}
        showNavigation={false}
        offsetRadius={2}
        animationConfig={{ tension: 120, friction: 14 }}
      />
    )
: (
      <div className='no-thumbnails' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>Loading thumbnails...</div>
    )
  }, [atLeastOneSelected, usedIndex, slides])

  return (
    <div id="form-file-upload">
      <div className='thumbnail-container-radio'>
        <input id='select-auto-thumnb' type='radio' checked={!custom} onChange={() => makeCustom(false)} />
        <label htmlFor="select-auto-thumnb">Select an auto generated thumbnail</label>
      </div>
      <div className={`auto-thumbnail-vid ${(custom || isDragActive) ? 'disabled' : ''}`} onWheel={(e) => !(custom || isDragActive) && handleWheelMvt(e)}>
        {carousel}
      </div>
      <div className='thumbnail-container-radio'>
        <input id='select-custom-thumnb' type='radio' checked={custom} onChange={() => makeCustom(true)} />
        <label htmlFor="select-custom-thumnb">Use a custom thumbnail</label>
      </div>
      <div className={`custom-thumbnail-vid ${!(custom || isDragActive) ? 'disabled' : ''}`} {...getRootProps()}>
        <input {...getInputProps()} id="input-file-upload" multiple={false} />
        <label
          id="label-file-upload"
          style={
            isDragActive
              ? { border: '2px dashed grey', borderRadius: '20px', ...backgroundOfCustomStyle }
              : { ...backgroundOfCustomStyle }
          }
        >
          {!currentCustom && (
            <>
              Upload a custom thumbnail
              <span className='note'>Only jpeg*</span>
            </>
          )}
        </label>
      </div>
    </div>
  );
};
