import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createDraftsAPI, getDraftsAPI, hardDeleteDraftAPI, softDeleteDraftAPI } from 'api/draft'
import { getUserData } from 'Redux/userdata/userdataSlice'

// First, create the thunk

export const getDrafts = createAsyncThunk(
  'draft/getDrafts',
  async () => {
    const response = await getDraftsAPI()
    return response?.data?.data || 
    {
      result: []
    }
  }
)

export const createDraft = createAsyncThunk(
  'draft/createDraft',
  async ({ data, callback, errorCallback }, thunkAPI) => {
    try {
      const response = await createDraftsAPI(data)
      if (response.data.data) {
        callback(response.data.data)
      }
      thunkAPI.dispatch(getUserData());
      return response?.data?.data || null
    } catch (error) {
      errorCallback(error)
    }
  }
)

export const softDeleteDraft = createAsyncThunk(
  'draft/softDeleteDraft',
  async (id, thunkAPI) => {
    const response = await softDeleteDraftAPI(id)
    thunkAPI.dispatch(getUserData());
    return response?.data?.data
  }
)

export const hardDeleteDraft = createAsyncThunk(
  'draft/hardDeleteDraft',
  async (id, thunkAPI) => {
    const response = await hardDeleteDraftAPI(id)
    thunkAPI.dispatch(getUserData());
    return response?.data?.data
  }
)
const initialState = {
  drafts: [],
  loading: false
}

export const drafts = createSlice({
  name: 'drafts',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDrafts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDrafts.fulfilled, (state, action) => {
      state.drafts = action.payload.result
      state.loading = false
    })
    builder.addCase(createDraft.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createDraft.fulfilled, (state, action) => {
      if (action.payload) {
        state.drafts = [...state.drafts, action.payload]
      }
      state.loading = false
    })
    builder.addCase(softDeleteDraft.fulfilled, (state, action) => {
      state.drafts = state.drafts.filter((draft) => draft.id !== action.payload.id)
      state.loading = false
    })
    builder.addCase(hardDeleteDraft.fulfilled, (state, action) => {
      state.drafts = state.drafts.filter((draft) => draft.id !== action.payload.id)
      state.loading = false
    })
  }
})

// export const { fetchingCatolog } = folders.actions

export default drafts.reducer
