/* eslint-disable react/prop-types */
import React from 'react'

function Search ({ onClick }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.191'
      height='16.77'
      viewBox='0 0 16.191 16.77'
      onClick={onClick}
    >
    </svg>
  );
}
export default Search;
