export const constants = [
  {
    title: 'People',
    filters: [
      {
        name: 'Closeup'
      },
      {
        name: 'Single person'
      },
      {
        name: 'Couple'
      },
      {
        name: 'Collaboration'
      },
      {
        name: 'Other'
      }
    ]
  },
  {
    title: 'Motive',
    filters: [
      {
        name: 'Technology'
      },
      {
        name: 'Corporate'
      },
      {
        name: 'Consumer'
      },
      {
        name: 'Artistic'
      },
      {
        name: 'Industrial'
      },
      {
        name: 'Other'
      }
    ]
  },
  {
    title: 'Duration',
    filters: [
      {
        name: 'Short'
      },
      {
        name: '1-5 minutes'
      },
      {
        name: '5-10 minutes'
      },
      {
        name: 'More than 10 minutes'
      }
    ]
  },
  {
    title: 'Orientation',
    filters: [
      {
        name: 'Vertical'
      },
      {
        name: 'Horizontal'
      },
      {
        name: '1:1 Square'
      }
    ]
  },
  {
    title: 'Color',
    filters: [
      {
        name: 'Any color'
      }
    ],
    colors: ['white', 'black', 'purple', 'blue', 'red', '#ec7600', 'green']
  }
]
