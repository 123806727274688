import { createSlice } from '@reduxjs/toolkit';
import { arrayOfTemplates, arrayOfReports } from './templates';

const INITIAL_STATE = {
    error: false,
    errorMessage: '',
    loading: false,
    templates: arrayOfTemplates || [],
    reports: arrayOfReports || []
}

const homePageSlice = createSlice({
    name: 'Home Page',
    initialState: INITIAL_STATE,
    reducers: {
        getTemplates (state, action) {
            state.error = false;
            state.errorMessage = '';
            state.templates = action.payload;
            state.loading = false;
        },
        getTemplatesError (state, action) {
            state.error = true;
            state.errorMessage = action.payload;
            state.templates = [];
            state.loading = false;
        },
        getReports (state, action) {
            state.error = false;
            state.errorMessage = '';
            state.reports = action.payload;
            state.loading = false;
        },
        getReportsError (state, action) {
            state.error = true;
            state.errorMessage = action.payload;
            state.reports = [];
            state.loading = false;
        }
    }
});

const { actions, reducer: homePageReducer } = homePageSlice;
export const { getReportsError, getReports, getTemplates, getTemplatesError } = actions;
export default homePageReducer;
