/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Button from '../Button'
import styles from './styles.module.scss'
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { updateCatalogCollectionAPI } from 'api/contentUploadAPI';

const FromCollectionDeletePopup = ({ onCancel, data, setterDelFn }) => {
  const { collectionId } = useParams()
  const [deleteStatus, setDeleteStatus] = useState(false)
  const handleDeleteCatFromCol = async () => {
    const collections = data?.collections?.filter(el => el !== collectionId)
    try {
      await updateCatalogCollectionAPI({
        tempId: data?.id,
        collections
      })
      setterDelFn(prev => prev.filter(el => el.id !== data?.id))
    } catch {
      console.log('error')
    }
  }
  return (
    <div className={styles.container}>
      <Trash />
      <h3>Are you sure you want to delete?</h3>
      <div className={styles.twoButtons}>
        <Button clickEvent={onCancel} text='No' extraClass='grayBtn' disabled={deleteStatus} />
        <Button
          clickEvent={async () => {
            setDeleteStatus(true)
            await handleDeleteCatFromCol()
            onCancel()
          }}
          text={deleteStatus ? 'Deleting...' : 'Delete'}
          disabled={deleteStatus}
        />
      </div>
    </div>
  )
}

FromCollectionDeletePopup.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.object
}

export default FromCollectionDeletePopup
