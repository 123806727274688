/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Dots } from '../../../../../assets/icons/dots.svg';
import { ReactComponent as Empty } from 'assets/icons/nothing.svg';
import { useLocation, Link } from 'react-router-dom'
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';

const CollectionCard = ({ collection, getACollectionCatalogs, handleInteraction, searchInput, mainView, currentDraggedElement, handleDragEnd, collectionFolderId, useShareManage }) => {
  const [currentCollection, setCurrentCollection] = useState(collection || {})
  const [collectionMenu, setCollectionMenu] = useState(null)
  const location = useLocation()

  const toggleCollectionMenu = (id) => {
    if (collectionMenu === id) {
      setCollectionMenu(null)
    } else {
      setCollectionMenu(id)
    }
  }

  const collectionMenuRef = React.useRef(null);

  const handleClickOutside = (e) => {
    if (collectionMenuRef.current && !collectionMenuRef.current.contains(e.target)) {
      setCollectionMenu(null);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [])

  useEffect(() => {
    collection && !currentCollection && setCurrentCollection(collection)
  }, [collection])

  useEffect(() => {
    if (currentCollection && currentCollection.catalogsData) return
    getACollectionCatalogs(collection.id).then((res) => {
      setCurrentCollection((prev) => {
        return {
          ...prev,
          catalogsData: res,
          loaded: true
        }
      })
    })
  }, [currentCollection])
  return (
    (currentCollection?.name?.toLowerCase().includes(searchInput.toLowerCase()))
      ? (
        <div key={collection?.id} className={styles.itemFolderContainer}>
          <div className={styles.imgsContainer}
            onDragStart={(e) => {
              if (mainView) {
                setCollectionMenu(null);
                currentDraggedElement.current = collection;
              }
            }}
            onDragEnd={(e) => {
              handleDragEnd();
            }}
            draggable={mainView && collectionMenu !== (collection.id)}
          >
            <Link
              to={location.pathname && location.pathname[location.pathname.length - 1] === '/'
                ? `${location.pathname}${!mainView ? (collection.id || collection._id) : `collections/${collection.id || collection._id}`}`
                : `${location.pathname}/${!mainView ? (collection.id || collection._id) : `collections/${collection.id || collection._id}`}`}
              state={{ catalogs: collection.catalogsData || null, collection }}
              draggable={false}
              style={{
                textDecoration: 'none'
              }}
            >
              {
                currentCollection?.catalogsData?.length
                  ? (
                    currentCollection?.catalogsData?.slice(0, 1).map((file) => (
                      <p key={file.id}>
                        <img
                          className={styles.folderImg}
                          src={
                            file.assest_type === 'Images'
                              ? file.assest_urls[0]?.thumbnail
                              : file.assest_type === 'Videos'
                              ? file.video_urls?.thumbnail
                              : file.template_data?.templates[0]?.original
                          }
                          alt={file.name}
                        />
                      </p>
                    ))
                  )
                  : currentCollection?.loaded
                    ? (
                      <div className={styles.no_cats_cont}>
                        <Empty className={styles.no_cats_svg} />
                        <span className={styles.header_nocats}>No catalogs in here</span>
                      </div>
                    )
                    : (
                      <div className={styles.no_cats_cont}>
                        <div className={styles.dotLoader} />
                      </div>
                    )
              }
            </Link>
            <div className={styles.interactionContainer}>
              <span className={styles.counter}> {currentCollection.catalogsData?.length || 0} visuals </span>
              <p className={styles.collectionName} title={(collection?.name?.length > 30 || collection[0]?.collection?.name?.length > 30) ? (collection?.name || collection[0]?.collection?.name) : undefined}>
                {(collection?.name?.length > 30 || collection[0]?.collection?.name?.length > 30) ? `${(collection.name.substring(0, 25) || collection[0].collection.name.substring(0, 25))}...` : (collection.name || collection[0].collection.name)}
              </p>
              <button className={styles.btn} onClick={() => {
                toggleCollectionMenu(collection.id);
              }}>
                <Dots className={collectionMenu === (collection.id) ? styles.active : ''} />
                <ul
                  ref={collectionMenuRef}
                  className={collectionMenu === (collection.id) ? `${styles.collectionMenu} ${styles.active}` : styles.collectionMenu}>
                  {
                    currentCollection?.catalogsData?.length > 0 && (
                      <li onClick={() => handleInteraction(1, currentCollection)}>View</li>
                    )
                  }
                  <li className={!(currentCollection.catalogsData?.length > 0) ? styles.noCollectionShare : ''}
                    onClick={(e) => {
                      if (!(currentCollection.catalogsData?.length > 0)) {
                        e.stopPropagation();
                        return;
                      }
                      handleInteraction(2, collection)
                    }}
                    disabled={(currentCollection.catalogsData?.length > 0)}
                  >
                    Share
                    {!(currentCollection.catalogsData?.length > 0) && <span className={styles.tooltipInfo} >Cannot share empty collection</span>}
                  </li>
                  {!useShareManage && <li onClick={() => handleInteraction(3, collection)}>Delete</li>}
                  {useShareManage && <li onClick={() => handleInteraction(4, collection)}>Manage</li>}
                </ul>
              </button>
            </div>
          </div>
        </div>
      )
      : null
  )
}

export default CollectionCard
