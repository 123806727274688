import { getNewRelease } from '../../../../Redux/content/contentSlice';
import activatePopular from '../../../molecules/HorizontalMenu/functions/activatePopular';

const displayPopular = (popularData) => {
    const { dispatch, link, menuItems, navigate } = popularData;
    activatePopular(menuItems);
    dispatch(getNewRelease());
    return navigate(link);
};

export default displayPopular;
