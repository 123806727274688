import React from 'react';
import PropTypes from 'prop-types';
import { Oval } from 'react-loader-spinner';
import styles from './Loader.module.scss';
import HubLogo from 'assets/icons/HubLogo';

function Loader ({
  size = 4,
  atStart,
  fullpage,
  section
}) {
  return (
    <section
      className={styles.spinner} 
      style={{
        height: fullpage ? '80vh' : section ? '250px' : 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Oval
        height={`${size * 15}px`}
        width={`${size * 15}px`}
        color="var(--main-color-brighter)"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#8668B6"
        strokeWidth={2}
        strokeWidthSecondary={2}
      ></Oval>
      <div className={styles.load}><HubLogo /></div>
    </section>
  );
}

Loader.propTypes = {
  size: PropTypes.number,
  atStart: PropTypes.bool,
  fullpage: PropTypes.bool,
  section: PropTypes.bool 
};

export default Loader;
