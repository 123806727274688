import React, { useState } from 'react';
import styles from './css/Faq.module.scss';
import { ReactComponent as PlusButton } from 'assets/icons/faq/PlusButton.svg';
import { ReactComponent as MinusButton } from 'assets/icons/faq/MinusButton.svg';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
    const { t } = useTranslation();
    const questionsAndAnswers = [
        {
            question: t('What inspired MPublisher'),
            answer: (
                <p className={styles.desc}>{t('answer1')}</p>
            )
        },
        {
            question: t('How do I edit templates?'),
            answer: (
                <>
                <ol>
                    <li>
                        <b>{t('Access the Design Editor')}:</b> {t('answer2')}
                    </li>
                    <li>
                        <b>{t('Select a Template')}:</b> {t('answer3')}
                    </li>
                    <li>
                        <b>{t('Customize Your Design')}:</b> {t('answer4')}
                    </li>
                    <li>
                        <b>{t('Save Your Changes')}:</b> {t('answer5')}
                    </li>
                    <li>
                        <b>{t('Preview and Finalize')}:</b> {t('answer6')}
                    </li>
                    <li>
                        <b>{t('Donwload or Share')}:</b> {t('answer7')}
                    </li>
                </ol>
                <br/>
                <p className={styles.desc}>{t('mpublisher-text')}</p>
                </>
            )

        },
        {
            question: t('How do I download content?'),
            answer: (
                <>
                <p className={styles.desc}>{t('download-content-text')}:</p>
                <ol>
                    <li>
                        <b>{t('Browse the Catalog')}:</b> {t('answer8')}
                    </li>
                    <li>
                        <b>{t('Select Content Type')}:</b> {t('answer9')}
                    </li>
                    <li>
                        <b>{t('Browse and Choose')}:</b> {t('answer10')}
                    </li>
                    <li>
                        <b>{t('Download')}:</b> {t('answer11')}
                    </li>
                </ol>
                <br/>
                <p className={styles.desc}></p> {t('mpublisher-text-2')}
                </>
            )

        },
        {
            question: t('How do I arrange content?'),
            answer: (
                <>
                <p className={styles.desc}>{t('faq-desc')}:</p>
                <ol>
                    <li>
                        <b>{t('access-my-files')}:</b> {t('answer12')}
                    </li>
                    <li>
                        <b>{t('Create Folders')}:</b> {t('answer13')}
                    </li>
                    <li>
                        <b>{t('Share Content')}:</b> {t('answer14')}
                    </li>
                    <li>
                        <b>{t('Access Shared Content')}:</b> {t('answer15')}
                    </li>
                </ol>
                <br/>
                <p className={styles.desc}>{t('mpublisher-text-3')}</p>
                </>
            )

        },
        {
            question: t('Where can I find Design content?'),
            answer: (
                <>
                <p className={styles.desc}>{t('find-desisgn-faq')}:</p>
                <ol>
                    <li>
                        <b>{t('Main Menu and Catalog Dropdown')}:</b> 
                        <ul>
                            <li>{t('option1')}</li>
                            <li>{t('option2')}</li>
                            <li>{t('option3')}</li>
                            <li>{t('option4')}</li>
                        </ul>
                        </li>
                    <li>
                        <b>{t('Search Bar')}:</b>
                        <ul>
                            <li>{t('option5')}</li>
                            <li>{t('option6')}</li>
                            <li>{t('option7')}</li>
                            <li>{t('option8')}</li>
                        </ul>
                    </li>
                </ol>
                <br/>
                <p className={styles.desc}>{t('mpublisher-text-4')}</p>
                </>
            )

        }
    ];

    const [openQuestion, setOpenQuestion] = useState(null);

    const toggleAnswer = (index) => {
        if (openQuestion === index) {
            setOpenQuestion(null);
        } else {
            setOpenQuestion(index);
        }
    };

    const faqList = questionsAndAnswers.map((qa, index) => (
        <div key={index} className={`${styles.faq__container__question__wrapper} ${openQuestion === index ? 'open' : ''}`}>
            <div className={styles.q_contianer}>
                <p>{qa.question}</p>
                {openQuestion !== index ? <PlusButton onClick={() => toggleAnswer(index)}/> : <MinusButton onClick={() => toggleAnswer(index)}/>}
            </div>
            {openQuestion === index && <div >{qa.answer}</div>}
        </div>
    ));

    return (
        <section className={styles.container}>
            <div className={styles.faq__container}>
                <div>
                    <div>
                        <h1>{t('FAQ')}</h1>
                        <h2>{t('In case you missed anything')}</h2>
                    </div>
                    <div className={styles.faqList}>{faqList}</div>
                </div>
            </div>
        </section>
    );
};

export default FAQ;
