import axiosInstance from '../axios/axiosInstance'

export async function createFoldersAPI (data) {
  const { name, parent } = data
  const response = await axiosInstance.post(
    `/folder/create/${parent}`,
    {
      name
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  console.log('Create Folder', response)
  return response
}

export async function getAllFoldersAPI () {
  const response = await axiosInstance.get(
    '/folder?size=1000',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function getFolderByIdAPI (id) {
  const response = await axiosInstance.get(
    '/folder',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function suspendFoldersAPI (id) {
  const response = await axiosInstance.patch(
    `profileAdministration/folder/suspend-folder/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function restoreFoldersAPI (id) {
  const response = await axiosInstance.patch(
    `profileAdministration/folder/restore-folder/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function deleteFoldersAPI (id) {
  const response = await axiosInstance.delete(
    `/folder/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}

export async function restoreDeleteFoldersAPI (id) {
  const response = await axiosInstance.patch(
    `/folder/restore/${id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response
}
