/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { manualCollectionDelete, manualCollectionUpdate } from 'Redux/collection/collectionSlice'
import { errorToast, successToast } from 'toasts/Toasts'
import { deleteCollectionsAPI, updateCollectionsAPI, deleteShareCollectionAPI } from 'api/collections'

const index = ({ folderData, updateFolderData, exitPopup, popupData, deleteShare, mainView }) => {
  const dispatch = useDispatch()
  const { collection } = popupData || {}
  const [collectionState, setCollections] = useState([])
  const [interactedWithCollections, setInteractedWithCollections] = useState([])

  const handleDeleteForever = async () => {
    try {
      const response = await deleteCollectionsAPI(collection.id)
      if (response) {
        successToast('Collection deleted forever successfully')
        // update might change to manual update later
        updateFolderData(mainView ? collection : null)
        dispatch(manualCollectionDelete(collection))
        exitPopup()
      } else {
        throw new Error('Something went wrong')
      }
    } catch {
      errorToast('Something went wrong')
    }
  }

  const handleShareDelete = async () => {
    const shareId = collection?.shareId || null
    try {
      const response = await deleteShareCollectionAPI(shareId)
      if (response) {
        successToast('Shared collection deleted successfully')
        updateFolderData()
        exitPopup()
      } else {
        throw new Error('Something went wrong')
      }
    } catch {
      errorToast('Something went wrong')
    }
  }

  const handleNormalDelete = async () => {
    try {
      const newCollectionFolderArray = collection.folders?.filter(el => el !== folderData.id)
      const newCollection = {
        ...collection,
        folders: newCollectionFolderArray
      }
      newCollection.pendingAssets !== undefined && delete newCollection.pendingAssets
      newCollection.catalogsData !== undefined && delete newCollection.catalogsData
      const response = await updateCollectionsAPI(newCollection)
      if (response) {
        updateFolderData()
        successToast('Collection deleted successfully')
        dispatch(manualCollectionUpdate(newCollection))
        exitPopup()
      } else {
        throw new Error('Something went wrong')
      }
    } catch {
      errorToast('Something went wrong')
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Are you sure you would like<br /> to delete this collection ?</h3>
      </div>
      <div className={styles.buttonsArea}>
        {/* <p className={styles.title}>Would you like to delete this collection</p> */}
        <div className={styles.cancelContainerLeft}>
          <button className={styles.cancelBtn} onClick={exitPopup}>Cancel</button>
        </div>
        <div className={styles.deleteContainerRight}>
          {
            !deleteShare && (
              <button className={styles.deleteBtn} onClick={handleDeleteForever}>{mainView ? 'Delete' : 'Delete forever'}</button>
            )
          }
          {
            !mainView && <button className={styles.deleteBtn} onClick={deleteShare ? handleShareDelete : handleNormalDelete}>Delete</button>
          }
        </div>
      </div>
    </div>
  )
}

export default index
