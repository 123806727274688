import React from 'react';

const UserLogo = (props) => (
  <svg
    viewBox="0 0 24 24"
    height="22"
    width="22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 13V9.5M20 7.00999V7M20 21V19C20 16.7909 18.2091 15 16 15H12M4 21V19C4 16.7909 5.79086 15 8 15M12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11C14.2091 11 16 9.20914 16 7C16 6.29687 15.8186 5.63612 15.5 5.062"
      stroke="var(--main-color)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserLogo;
