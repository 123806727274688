/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import MyImageCatalogs from '../myImageCatalogs/myImageCatalogs';
import MyVideoCatalogs from '../MyVideoCatalogs/MyVideoCatalogs';
import NotAvailable from '../NotAvailable/NotAvailable';

export default function index () {
  const location = useLocation();
  const page = location.pathname.split('page=')[1] || null;
  return (
    location.pathname.includes('page=') && location.pathname?.includes('Images') && (location.pathname?.includes('mpublisher/my-catalogs/') || location.pathname?.includes('mpublisher/catalogs/'))
      ? <MyImageCatalogs possiblePage={page} />
      : location.pathname.includes('page=') && location.pathname?.includes('Videos') && (location.pathname?.includes('mpublisher/my-catalogs/') || location.pathname?.includes('mpublisher/catalogs/'))
      ? <MyVideoCatalogs possiblePage={page} />
       : <NotAvailable />
  )
}
