/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  restoreFileById,
  getDeletedCatalog,
  restoreAllFilesInTrash,
  bulkDeleteCatalogsInTrash,
  hardDeleteCatalogThunk
} from '../../../Redux/trash/trashSlice'
// The edit mode is not doing anything currently so we can put back the check box when it is used
// import Checkbox from 'react-custom-checkbox'
import Header from './header/Header'
import styles from './styles.module.scss'
import Search from './search/Search'
import Button from './Button'
// import GridIcon from './icons/GridIcon'
import CircularLoader from '../../atoms/loaders/CircularLoader'
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import { useTranslation } from 'react-i18next';

export default function Trash () {
  const {
    data,
    loading,
    allFilesRestorationStatus,
    restoreFileStatus,
    hardDeleteStatus
  } = useSelector(state => state.trash)
  const { t } = useTranslation();

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDeletedCatalog())
  }, [])

  const [editMode] = React.useState(false)

  const [showMenu, setShowMenu] = useState(Array(data.length).fill(false))
  const [searchInput, setSearchInput] = useState('');

  const filteredData = data.filter((item) => {
    return item.name.toLowerCase().includes(searchInput.toLowerCase())
  })

  const handleClickOutside = e => {
    if (e.target.className !== styles.ellipsis) {
      setShowMenu(Array(data.length).fill(false))
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showMenu])

  // const [prevIndex, setPrevIndex] = useState(-1)

  const toggleMenu = index => {
    if (!Array.isArray(showMenu)) {
      return
    }
    const updatedMenuState = showMenu.map((item, i) =>
      i === index ? !item : false
    )
    setShowMenu(updatedMenuState)
  }

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.upperControlcontainer}>
        <Header />
        <Search onChange={setSearchInput} />

        <div className={styles.actionsContainer}>
          <div><p className={styles.items}>{t('Items')}: {filteredData ? filteredData.length : null}</p></div>
          <div className={styles.actionButtons}>
            <div className={styles.actionButtonsSegmentOne}>
              <div className={styles.btnContainer}>
                <div>
                  <Button
                    text={t('Restore All')}
                    onClick={
                      filteredData.length
                        ? () => dispatch(restoreAllFilesInTrash())
                        : () => { }
                    }
                  />
                </div>

                <div>
                  <Button
                    text={t('Empty Trash')}
                    onClick={() => dispatch(bulkDeleteCatalogsInTrash())}
                  />
                </div>
              </div>
            </div>
            {/* <div className={styles.actionButtonsSegmentTwo}>
                    <div className={styles.iconOne}>
                      <GridIcon />
                    </div>
                    <div>
                      <GridIcon color='#b6b6b6' />
                    </div>
                  </div> */}
          </div>
        </div>
      </div>
      {!loading
        ? (
          <div className={styles.contentArea}>
            <div>
              {filteredData && filteredData.length > 0
                ? (
                  <div className={styles.ImgsContainer}>
                    {filteredData.map((element, index) => (
                      <div key={index} className={styles.singleImgContainer}>
                        <img
                          className={
                            editMode
                              ? styles.inEditMode
                              : element.active
                                ? `${styles.active}`
                                : ''
                          }
                          src={element?.assest_urls[0]?.thumbnail || element?.template_data?.templates[0]?.thumbnail || element?.video_urls?.thumbnail}
                          alt='img'
                        />
                        <div className={styles.ellipsisContainer} onClick={(e) => { e.stopPropagation(); toggleMenu(index) }}>
                          <div
                            className={styles.ellipsis}
                          >
                            &#8942;
                          </div>
                          <div className={`${styles.menu} ${showMenu[index] ? styles.active : ''}`}>
                            <ul>
                              <li
                                onClick={() => {
                                  dispatch(restoreFileById(element.id))
                                  if (restoreFileStatus === false) {
                                    showMenu[index] = false
                                  }
                                }}
                                disabled={restoreFileStatus}
                              >
                                {restoreFileStatus ? t('Restoring') : t('Restore')}
                              </li>
                              <li
                                disabled={hardDeleteStatus}
                                onClick={() => {
                                  dispatch(hardDeleteCatalogThunk(element.id))
                                  if (hardDeleteStatus === false) {
                                    showMenu[index] = false
                                  }
                                }}
                              >
                                {hardDeleteStatus
                                  ? t('Deleting...')
                                  : t('Delete Forever')}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
                : (
                  <div className={styles.contentContainer}>
                    <p className={`${styles.trashCaption} ${styles.bold}`}>
                      {t('Trash bin is Empty')}
                    </p>
                    <p className={`${styles.trashCaption} ${styles.grayText}`}>
                      {t('Any work you delete will be stored here.')} <br /> {t('30-days-notice')}
                    </p>
                  </div>
                )}
            </div>
          </div>
        )
        : (
            <ComponentLoaderTri />
        )}
    </div>
  )
}
