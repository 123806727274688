import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createFoldersAPI, getAllFoldersAPI, getFolderByIdAPI, deleteFoldersAPI, suspendFoldersAPI, restoreFoldersAPI, restoreDeleteFoldersAPI } from 'api/folders'

// First, create the thunk
export const createFolders = createAsyncThunk(
  'folder/createFolders',
  async (data) => {
    const response = await createFoldersAPI(data)
    return response?.data?.data
  }
)

export const deleteFolders = createAsyncThunk(
  'folder/deleteFolder',
  async (id) => {
    const response = await deleteFoldersAPI(id)
    return response?.data?.data
  }
)

export const restoreDeletedFolders = createAsyncThunk(
  'folder/restoreDeletedFolder',
  async (id) => {
    const response = await restoreDeleteFoldersAPI(id)
    return response?.data?.data
  }
)

export const getAllFolders = createAsyncThunk(
  'folder/getAllFolders',
  async (exFolders) => {
    const response = await getAllFoldersAPI()
    const folderList = response?.data?.data?.result
    const folderObj = {
      folders: [],
      allFolders: folderList,
      excludedFolders: [],
      deletedFolders: []
    }
    folderList?.forEach(folder => {
      if (!exFolders?.includes(folder._id || folder.id)) {
        folderObj.folders.push(folder)
      } else if (exFolders?.includes(folder._id || folder.id)) {
        folderObj.excludedFolders.push(folder)
      } else if (!folder.is_global_folder && !folder.static && folder.deleted) {
        folderObj.deletedFolders.push(folder)
      }
    })
    return folderObj
  }
)

export const getFolderById = createAsyncThunk(
  'folder/getFolderById',
  async (id) => {
    const response = await getFolderByIdAPI(id)
    return response.data
  }
)

export const suspendFolders = createAsyncThunk(
  'folder/suspendFolders',
  async (id) => {
    const response = await suspendFoldersAPI(id)
    return response.data
  }
)

export const restoreFolder = createAsyncThunk(
  'folder/restoreFolder',
  async (id) => {
    const response = await restoreFoldersAPI(id)
    return response.data
  }
)

const initialState = {
  folders: [],
  excludedFolders: [],
  allFolders: [],
  deletedFolders: [],
  loading: false,
  error: null
}

export const folders = createSlice({
  name: 'folders',
  initialState,
  // reducers: {
  //   fetchingCatolog: state => {
  //     state.loading = true
  //   } 
  // },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getAllFolders.pending, (state, action) => {
      state.folders = []
      state.excludedFolders = []
      state.allFolders = []
      state.loading = true
    })
    builder.addCase(getAllFolders.fulfilled, (state, action) => {
      state.folders = action?.payload?.folders
      state.excludedFolders = action?.payload?.excludedFolders
      state.allFolders = action?.payload?.allFolders
      state.loading = false
    })

    builder.addCase(suspendFolders.fulfilled, (state, action) => {
      const excludedFoldersIds = action?.payload?.data?.excludedFolders || []
      const newExcludedFolders = []
      const newFolders = []
      state?.allFolders?.forEach(folder => {
        if (excludedFoldersIds.includes(folder._id || folder.id)) {
          newExcludedFolders.push(folder)
        } else {
          newFolders.push(folder)
        }
      })
      state.excludedFolders = newExcludedFolders
      state.folders = newFolders
      state.loading = false
    })

    builder.addCase(restoreFolder.fulfilled, (state, action) => {
      const excludedFoldersIds = action?.payload?.data?.excludedFolders || []
      const newExcludedFolders = []
      const newFolders = []
      state?.allFolders?.forEach(folder => {
        if (excludedFoldersIds.includes(folder._id || folder.id)) {
          newExcludedFolders.push(folder)
        } else {
          newFolders.push(folder)
        }
      })
      state.excludedFolders = newExcludedFolders
      state.folders = newFolders
      state.loading = false
    })

    builder.addCase(createFolders.fulfilled, (state, action) => {
      const newFolder = action.payload
      if (newFolder) {
        state.folders.push(newFolder);
        state.allFolders.push(newFolder);
      }
      state.loading = false;
    })

    builder.addCase(deleteFolders.fulfilled, (state, action) => {
      const deletedFolder = action.payload
      if (deletedFolder) {
        state.deletedFolders.push(deletedFolder);
        state.folders = state.folders.filter(folder => (folder._id !== deletedFolder._id || folder.id !== deletedFolder.id));
      }
      state.loading = false;
    })

    builder.addCase(restoreDeletedFolders.fulfilled, (state, action) => {
      const restoredFolder = action.payload
      if (restoredFolder) {
        state.folders.push(restoredFolder);
        state.deletedFolders = state.deletedFolders.filter(folder => (folder._id !== restoredFolder._id || folder.id !== restoredFolder.id));
      }
      state.loading = false;
    })
  }
})

// export const { fetchingCatolog } = folders.actions

export default folders.reducer
