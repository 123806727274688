/* eslint-disable react/prop-types */
import styles from './css/Templates.module.scss';
import React, { useState, useEffect, useMemo } from 'react';
import TemplateGrid from './TemplateGrid';
import Photo from 'components/organisms/GalleryLayout/Photo';
import GridImagesLayout from '../../../pages/myTemplatesCatalogs/components/GridImagesLayout';
import { useTranslation } from 'react-i18next';

const Templates = ({ templateArray, reportsArray, handleClick, currentUser }) => {
  const [fancyShowcase, setFancyShowcase] = useState(window.innerWidth > 1100);
  const { t } = useTranslation();

  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  }

  const handleResize = () => {
    if (window.innerWidth > 1100) {
      !fancyShowcase && setFancyShowcase(true);
    } else {
      fancyShowcase && setFancyShowcase(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fancyShowcase]);

  const savedShowCase = useMemo(() => {
    return (
      <>
        <div className={styles.templates__wrapper}>
          <TemplateGrid
          currentUser={currentUser}
            templates={templateArray.slice(0, 2)}
            report={reportsArray[0]}
            handleClick={handleClick}
            isTouchScreen={isTouchScreen()}
          />
          <TemplateGrid
          currentUser={currentUser}
            templates={templateArray.slice(2, 4)}
            report={reportsArray[1]}
            handleClick={handleClick}
            isTouchScreen={isTouchScreen()}
          />
        </div>
        <div className={styles.templates__wrapper_row}>
          {templateArray.slice(3, 8).map((template, index) => {
            return (
              <Photo
                key={template?.id}
                index={0}
                assetUserId={template?.userId}
                currentUser={currentUser}
                onClick={handleClick}
                photo={template}
                catalog={template}
                allCatalogs={templateArray}
                margin={0}
                height={120}
                templates={true}
                useOriginal={true}
                mixedContent={true}
                homePage={true}
                isTouchScreen={isTouchScreen()}
                OverRuledRoles={[]}
              />
            );
          })}
        </div>
      </>
    );
  }, [templateArray, reportsArray, handleClick]);

  const savedUnShowCased = useMemo(() => {
    return (
      <div className={styles.templates__wrapper_row_unshowcased}>
        <GridImagesLayout
          catalogs={templateArray}
          onClick={handleClick}
          innerProps={{
            templates: true,
            reverseThumbnail: true,
            targetRowHeight: window.innerWidth > 800 ? 300 : 220,
            isTouchScreen: isTouchScreen(),
            margin: 10
          }}
        />
      </div>
    );
  }, [templateArray, reportsArray, handleClick, handleResize]);

  return (
    <div className={styles.container}>
      <div className={`${styles.templates__container}`}>
        <h1>{t('Creative templates from catalog')}</h1>
        <small>
          {t('embark-oncreativity-journey-text')}{' '}
        </small>
        {fancyShowcase ? savedShowCase : savedUnShowCased}
      </div>
    </div>
  );
};

export default Templates;
