/* eslint-disable react/prop-types */
import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as LeftArr } from '../assets/icon-arrow-left.svg';
import { ReactComponent as RightArr } from '../assets/icon-arrow-right.svg';
import { ReactComponent as ToggleFS } from '../assets/icon-full-screen.svg';

export default function ImageLoader ({
  setPreviewLoadingNext,
  previewLoadingNext,
  currentCatalog,
  tags,
  previewIndex,
  setPreviewIndex,
  allCatalogs,
  setPreviewPopup,
  changeToFullScreen
}) {
  return (
    <>
      <div
        className={styles.miniPreviewContainer}
        style={{
          maxHeight: '1000px',
          minHeight: '300px',
          minWidth: previewLoadingNext ? '80%' : 'unset'
        }}
      >
        <div className={previewLoadingNext ? `${styles.popupPreviewImgContainer} ${styles.loadingImgActiveBg}` : styles.popupPreviewImgContainer}
          style={{
            maxHeight: '800px',
            minHeight: '300px',
            minWidth: previewLoadingNext ? '80%' : 'unset'
          }}
        >
          <img
            className={previewLoadingNext ? `${styles.previewImg} ${styles.loadingImg}` : styles.previewImg}
            src={currentCatalog?.src?.original}
            onLoad={() => setPreviewLoadingNext(false)}
            style={{
              maxHeight: '800px',
              minHeight: '300px',
              width: '100%'
            }}
            alt="preview" />
          {
            previewLoadingNext && <div className={styles.loader} />
          }
          <div className={styles.popupPreviewImgContainer__controls}>
            <button
              className={styles.fullscreenBtn}
              onClick={() => { changeToFullScreen(true); setPreviewPopup(prev => { return { ...prev, state: 5 } }) }}>
              <ToggleFS />
            </button>
            <button
              className={styles.fullscreenBtn}
              onClick={() => setPreviewPopup(null)}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.popupPreviewImgContainer__info}>
            <h3>{currentCatalog?.name}</h3>
          </div>
          <div className={styles.tagsContainer}>
            <h2 className={styles.tagSpan}>Tags: </h2>
            {tags?.map((tag, index) => (
              <p className={styles.tag} key={index}>#{tag.trim()}</p>
            ))}
          </div>
          <div className={styles.popupPreviewImgContainer__footer}>
            <p>{currentCatalog?.content_description}</p>
          </div>
        </div>
      </div>
      <div className={styles.popupPreviewImgContainer__navigatorLeft}>
        <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex - 1) }} disabled={previewIndex === 0}>
          <LeftArr />
        </button>
      </div>
      <div className={styles.popupPreviewImgContainer__navigatorRight}>
        <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex + 1) }} disabled={previewIndex === allCatalogs.length - 1}>
          <RightArr />
        </button>
      </div>
    </>
  )
}
