import styles from './UploadSelectResourceModal.module.scss';
import React, { Fragment } from 'react';
import Gap from '../../../atoms/Gap/Index';
import templates from '../../../../assets/images/homeContent/templates@2x.png';
import imagePoster from '../../../../assets/images/homeContent/images@2x.png';
import mockups from '../../../../assets/images/homeContent/mockups@2x.png';
import video from '../../../../assets/images/homeContent/video.png';
import RoundedButton from '../../../atoms/RoundedButton/RoundedButton';
import ImageCard from '../../../atoms/Card/SmallImageCard';
import PropTypes from 'prop-types';
import Popup from 'components/atoms/Popup/Popup';

const DEFAULT_ASSET_TYPES = [
  {
    id: 1,
    name: 'Templates',
    image: templates
  },
  {
    id: 2,
    name: 'Images',
    image: imagePoster
  },
  {
    id: 3,
    name: 'Mockups',
    image: mockups
  },
  {
    id: 4,
    name: 'Videos',
    image: video
  }
];

const UploadSelectResourceModal = ({ data, cancel }) => {
  const {
    uploadModalVisible,
    setUploadModalVisibility,
    setShowUploadOptionsModal,
    setSelectedType,
    selectedType
  } = data;

  return (
    <>
      {uploadModalVisible && (
        <Popup
          popupData={{}}
          transparent={true}
          closePopup={() => { setUploadModalVisibility(false); setSelectedType('Templates'); }}
          bgColor={'#121212'}
          padding={window?.innerWidth > 800 ? '30px 60px 20px 60px' : '30px 20px 20px 20px'}
          extraStyles={{ width: '90%', maxWidth: '1200px' }}
        >
          <div className={styles.select__resources__headerContent}>
            <h4>Upload Content to MPublisher</h4>
            <p>Please select the type of content you&apos;d like to upload</p>
          </div>
          <Gap />

          <section className={styles.select__resources__tempGrid}>
            {DEFAULT_ASSET_TYPES.map((item, index) => {
              return (
                <Fragment key={index}>
                  <ImageCard
                    imageSource={item.image}
                    bottomCenterText={item.name}
                    onClick={() => {
                      if (item.name === 'Templates' || item.name === 'Images' || item.name === 'Videos') {
                        setSelectedType((prev) => prev === item.name ? prev : item.name)
                      }
                    }}
                    selected={selectedType && selectedType === item.name}
                    opacityEffect={(selectedType && selectedType !== item.name) || (item.name === 'Mockups')}
                    cursorPointer={item.name === 'Templates' || item.name === 'Images' || item.name === 'Videos'}
                  />
                </Fragment>
              );
            })}
          </section>

          <section className={styles.select__resources__footerSection}>
            <RoundedButton
              extraClass={styles.select__resources__button}
              onClick={cancel}
            >
              Cancel
            </RoundedButton>
            <RoundedButton
              extraClass={styles.select__resources__button}
              onClick={() => {
                if (!selectedType) return;
                setShowUploadOptionsModal(true);
                setUploadModalVisibility(false);
              }}
            >
              Next
            </RoundedButton>
          </section>
        </Popup>
      )}
    </>
  );
};

UploadSelectResourceModal.propTypes = {
  data: PropTypes.object,
  cancel: PropTypes.func || null
};

export default UploadSelectResourceModal;
