import activateNewRelease from '../../../molecules/HorizontalMenu/functions/activateNewRelease';
import { getNewRelease } from '../../../../Redux/content/contentSlice';

const displayNewRelease = (newReleaseData) => {
    const { dispatch, link, menuItems, navigate } = newReleaseData;
    activateNewRelease(menuItems);
    dispatch(getNewRelease());
    return navigate(link);
};

export default displayNewRelease;
