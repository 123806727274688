/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as RestoreIcon } from 'assets/icons/restore.svg';
import { errorToast } from 'toasts/Toasts';
import { restoreDeletedFolders } from 'Redux/folder/folderSlice';

const DeletedFolders = () => {
  const dispatch = useDispatch();
  const { deletedFolders } = useSelector((state) => state.folders);
  const managementArray = [
    {
      title: 'Images',
      folders: deletedFolders.filter(folder => folder.parent === 'Images')
    },
    {
      title: 'Videos',
      folders: deletedFolders.filter(folder => folder.parent === 'Videos')
    },
    {
      title: 'Templates',
      folders: deletedFolders.filter(folder => folder.parent === 'Templates')
    }
  ]

  const restoreFolderAdmin = (folder) => {
    dispatch(restoreDeletedFolders(folder.id || folder._id));
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Deleted Folders:
        {' '}
        <span className={styles.title_desc}>
          Deleted folders, and all content inside of which, will be permanently removed after 30 days
        </span>
      </h2>
      <div className={styles.management_container}>
        {managementArray.map((management) => (
          <div key={management.title} className={styles.management}>
            <div className={styles.management_header}>
              <h3 className={styles.management_title}>{management.title}</h3>
            </div>
            <ul className={styles.folder_list}>
              {management?.folders?.map((folder) => (
                <li key={folder?.name} className={styles.folder_item}>
                  <span>
                    {folder?.name}
                  </span>
                  <RestoreIcon className={styles.svg_icon} onClick={() => restoreFolderAdmin(folder)} />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeletedFolders;
