import { getNewRelease, getPopular, getSearchCats } from '../../../../Redux/content/contentSlice';

const getSearchResult = (paramsLink, dispatch) => {
    const link = String(paramsLink);
    if (link === 'name=new-release') {
        return dispatch(getNewRelease());
    } else if (link === 'name=popular') {
        return dispatch(getPopular());
    } else {
        return dispatch(getSearchCats(paramsLink));
    }
};

export default getSearchResult;
