/* eslint-disable no-unused-vars */
import Popup from 'components/atoms/Popup/Popup'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import styles from './styles.module.scss'
import MetaDataForm from 'components/pages/uploadContent/metaData/MetaDataForm'
import CollectionPopup from './CollectionPopup'
import DownloadPopup from './DownloadPopup'
import DeletePopup from './DeletePopup/DeletePopup'
import SinglePreviewPopup from './SinglePreviewPopup'
import FromCollectionDeletePopup from './FromCollectionDeletePopup/FromCollectionDeletePopup'

const index = ({ previewPopup, setPreviewPopup, allCatalogs, shareMode }) => {
  const { state, data, setterDelFn } = previewPopup || {}

  return (
    <>
      {
        (state === 1 || state === 5) &&
        <Popup closePopup={() => { }} transparent={true} extraStyles={{
          width: '100%',
          height: '100%',
          background: 'transparent',
          userSelect: 'none'
        }}>
          <SinglePreviewPopup previewPopup={previewPopup} allCatalogs={allCatalogs} setPreviewPopup={setPreviewPopup} />
        </Popup>
      }
      {
        state === 2 && !shareMode &&
        <Popup closePopup={() => setPreviewPopup(null)} transparent={true}
          bgColor={'#121212'}
          padding={window?.innerWidth > 800 ? '25px 60px' : '15px 30px'}
          extraStyles={{ width: '100%', maxWidth: '1000px', height: window?.innerWidth > 800 ? 'auto' : '100%', maxHeight: '1000px' }}>
          <CollectionPopup data={data} exitPopup={() => setPreviewPopup(null)} />
        </Popup>
      }
      { // download
        state === 3 &&
        <Popup closePopup={() => setPreviewPopup(null)} transparent={true} bgColor='#121212' extraStyles={{
          width: '90%',
          maxHeight: '800px',
          maxWidth: '800px'
        }}>
          <div className={styles.downloadContainer}>
            <DownloadPopup data={data} shareMode={shareMode}/>
          </div>
        </Popup>
      }
      { // edit
        state === 4 && !shareMode &&
        <Popup closePopup={() => setPreviewPopup(null)}
          transparent={true}
          bgColor={'#121212'}
          padding={window?.innerWidth > 500 ? '10px 60px' : '10px 20px'}
          extraStyles={{ width: window?.innerWidth > 500 ? '90%' : '100%', height: window?.innerWidth > 500 ? '90%' : '100%', maxWidth: '1200px', maxHeight: '1300px' }}>
          <div className={styles.collectionContainer}>
            <MetaDataForm arrayOfImages={[data]} selectedType={data.assest_type} editMode={true} setShowMetaDataForm={setPreviewPopup} />
          </div>
        </Popup>
      }

      {
        state === 7 && !shareMode &&
        <Popup closePopup={() => setPreviewPopup(null)}
          transparent={true}
          bgColor={'#121212'}
          padding={'10px 30px'}
          extraStyles={{ width: '90%', maxWidth: '800px', padding: '20px' }}>
          <div className={styles.deletePopUpContainer}>
            <DeletePopup onCancel={() => setPreviewPopup(null)} data={data} />
          </div>
        </Popup>
      }
            {
        state === 8 && !shareMode &&
        <Popup closePopup={() => setPreviewPopup(null)}
          transparent={true}
          bgColor={'#121212'}
          padding={'10px 30px'}
          extraStyles={{ width: '90%', maxWidth: '800px', padding: '20px' }}>
          <div className={styles.deletePopUpContainer}>
            <FromCollectionDeletePopup onCancel={() => setPreviewPopup(null)} data={data} setterDelFn={setterDelFn} />
          </div>
        </Popup>
      }
    </>
  )
}

export default index
