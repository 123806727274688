import styles from '../css/HorizontalMenu.module.scss';
import updateLabel from './updateLabel';
import updateHorizontalLines from './updateHorizontalLines';

const activatePopular = (menuItems) => {
    const { catalog, newRelease, popular } = menuItems;
    popular.classList.add(styles.horizontal__menu__container__display);
    const others = { first: newRelease, second: catalog };
    updateHorizontalLines(others);
    return updateLabel(popular, others);
};

export default activatePopular;
