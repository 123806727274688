/* eslint-disable react/prop-types */
import React from 'react';
import styles from './bottomArea.module.scss';

export default function BottomArea ({ description, dimensions, fileType, contributor }) {
  return (
    <div className={styles.bottomAreaGrid}>
      <div className={styles.bottomAreaGridChild}>
        {description 
          ? <p>{description}</p>
          : 'No description available' }
      </div>
      <div className={styles.bottomAreaGridChild}>
        <div>
          <p>Author</p>
          <span className={styles.orangeText}>{contributor || 'User'}</span>
        </div>
      </div>
      <div className={styles.bottomAreaGridChild}>
        <p>DIMENSIONS</p>
        <span>{`${dimensions?.width}px`} x {`${dimensions?.height}px`}</span>
      </div>
      <div className={styles.bottomAreaGridChild}>
        <p>File Type</p>
        <span>{fileType || 'JPEG'}</span>
      </div>
    </div>
  );
}
