/* eslint-disable react/prop-types */
import React from 'react'
import Container from './Container/Container'
import styles from './styles.module.scss'

export const Filter = ({
  data,
  extraClass,
  onChangeData,
  onSearch,
  camelizedValues,
  currentState,
  filterOn,
  withAssetBtn
}) => {
  return (
    <div className={styles.main}>
      <Container
        {...{ data, extraClass, currentState, withAssetBtn, onChangeData, onSearch, filterOn, camelizedValues }}
      />
    </div>
  )
}
