import React from 'react';
import styles from './styles.module.scss';
import sadFace from 'assets/images/sad_thing.png';
import PropTypes from 'prop-types';

export default function UnavailableContent ({ errMsg }) {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={sadFace} alt="sad ai face" />
      <div className={styles.header_container}>
        <h1 className={styles.title}>Content Unavailable</h1>
        <p className={styles.paragraph}>
          {errMsg ||
            "Sorry, but unless you've got a way to travel through time, the folder you're trying to access is unavailable"}
        </p>
      </div>
    </div>
  );
}

UnavailableContent.propTypes = {
  errMsg: PropTypes.string
};
