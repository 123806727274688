import React from 'react';
import MetaDataForm from '../metaData/MetaDataForm';
import MetaDataTemplates from '../MetaDataTemplates/MetaDataForm';
import MetaDataFormVideos from '../MetaDataVideos/MetaDataForm';
import PropTypes from 'prop-types';
import Popup from 'components/atoms/Popup/Popup';

const UploadAddDetails = ({
  data,
  uploadContent,
  selectedType,
  cancel,
  backFn
}) => {
  const { showMetaDataForm, setShowMetaDataForm, images } = data;
  return (
    <>
      {showMetaDataForm && (
        <Popup
          popupData={{}}
          transparent={true}
          closePopup={null}
          bgColor={'#121212'}
          padding={window?.innerWidth > 500 ? '10px 60px' : '10px 20px'}
          extraStyles={{
            width: window?.innerWidth > 500 ? '90%' : '100%',
            height: window?.innerWidth > 500 ? '90%' : '100%',
            maxWidth: '1200px',
            maxHeight: '1300px'
          }}
        >
          {selectedType === 'Images' || selectedType === 'Mockup'
? (
            <MetaDataForm
              arrayOfImages={images}
              setShowMetaDataForm={() => setShowMetaDataForm(false)}
              uploadContent={uploadContent}
              selectedType={selectedType}
              cancel={cancel}
              backFn={backFn}
            />
          )
: selectedType === 'Templates'
? (
            <MetaDataTemplates
              arrayOfImages={images}
              setShowMetaDataForm={() => setShowMetaDataForm(false)}
              uploadContent={uploadContent}
              selectedType={selectedType}
              cancel={cancel}
              backFn={backFn}
            />
          )
: (
            selectedType === 'Videos' && (
              <MetaDataFormVideos
                arrayOfImages={images}
                setShowMetaDataForm={() => setShowMetaDataForm(false)}
                uploadContent={uploadContent}
                selectedType={selectedType}
                cancel={cancel}
                backFn={backFn}
              />
            )
          )}
        </Popup>
      )}
    </>
  );
};

UploadAddDetails.propTypes = {
  data: PropTypes.object,
  uploadContent: PropTypes.func,
  selectedType: PropTypes.string,
  cancel: PropTypes.func,
  backFn: PropTypes.func
};

export default UploadAddDetails;
