import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCatalogAPI,
  deleteCatalogAPI,
  getCatalogByFolderIdAPI,
  getCatalogByCollectionIdAPI,
  getCatalogByIdAPI,
  getCatalogByAssetTypeAPI,
  getFeaturedCatalogAPI,
  getFeaturedCatalogByAssetTypeAPI,
  getSearchCatsAPI,
  getAllMyCatalogsAPI,
  searchForNewRelease,
  searchForPopular
} from 'api/contentUploadAPI';
import { softDeleteCatalog } from 'Redux/trash/trashSlice';
import { errorToast, successToast } from 'toasts/Toasts';

// First, create the thunk
export const getCatalog = createAsyncThunk(
  'catalog/getCatalog',
  async (data) => {
    const { folderId, CollectionId, assetType, templates, page, isPrivate } = data || {};
    if (folderId) {
      const response = await getCatalogByFolderIdAPI(folderId, page, isPrivate);
      return response.data;
    } else if (CollectionId) {
      const response = await getCatalogByCollectionIdAPI(data?.folderId, isPrivate);
      return response.data;
    } else if (assetType && !templates) {
      const response = await getCatalogByAssetTypeAPI(assetType, page, isPrivate);
      return response.data;
    } else if (assetType && templates) {
      const response = await getFeaturedCatalogByAssetTypeAPI(assetType, isPrivate);
      return response.data;
    } else {
      const response = await getCatalogAPI(isPrivate);
      return response.data;
    }
  }
);

export const deleteCatalog = createAsyncThunk(
  'catalog/deleteCatalog',
  async (id) => {
    const response = await deleteCatalogAPI(id);
    return response.data;
  }
);

export const getSearchCats = createAsyncThunk(
  'catalog/getSearchCats',
  async (params) => {
    const response = await getSearchCatsAPI(params);
    return response.data;
  }
);

export const getAllMyCatalogs = createAsyncThunk(
  'catalog/getAllMyCatalogs',
  async (params) => {
    const response = await getAllMyCatalogsAPI(params);
    return response.data;
  }
);

export const getNewRelease = createAsyncThunk(
  'catalog/getNewRelease',
  async () => {
    return await searchForNewRelease();
  }
);

export const getPopular = createAsyncThunk('catalog/getPopular', async () => {
  return await searchForPopular();
});
export const getTemplate = createAsyncThunk(
  'catalog/getTemplate',
  async (id) => {
    const response = await getCatalogByIdAPI(id);
    return response.data;
  }
);

export const getFeaturedCatalogs = createAsyncThunk(
  'catalog/getFeaturedCatalogs',
  async ({ isPrivate }) => {
    const response = await getFeaturedCatalogAPI(isPrivate);
    return response.data;
  }
);

const initialState = {
  catalog: [],
  searchResult: [],
  pageDetails: {},
  currentTemplate: [],
  deleteStatus: false,
  loading: true,
  myCatalogs: [],
  error: null
};

export const content = createSlice({
  name: 'content',
  initialState,
  // reducers: {
  //   fetchingCatolog: state => {
  //     state.loading = true
  //   }
  // },
  reducers: {
    manualCatalogUpdate: (state, action) => {
      const updatedCatalog = action.payload;
      if (
        (Array.isArray(state.catalog) && state.catalog.length > 0) ||
        (Array.isArray(state.searchResult) && state.searchResult.length > 0)
      ) {
        const index = state.catalog?.findIndex(
          (item) =>
            item._id === updatedCatalog.id || item.id === updatedCatalog.id
        );
        const indexFromSearch = state.searchResult?.findIndex(
          (item) =>
            item._id === updatedCatalog.id || item.id === updatedCatalog.id
        );
        if (index !== -1) {
          if (state.catalog[index]?.folders[0] === updatedCatalog?.folders[0]) {
            state.catalog[index] = updatedCatalog;
          } else {
            state.catalog.splice(index, 1);
          }
        }
        if (indexFromSearch !== -1) {
          state.searchResult[indexFromSearch] = updatedCatalog;
        }
      } else {
        try {
          const prevCatState =
            state.catalog[updatedCatalog.assest_type.toLowerCase()];
          const index = prevCatState?.findIndex(
            (item) =>
              item._id === updatedCatalog.id || item.id === updatedCatalog.id
          );
          if (index !== -1) {
            prevCatState[index] = updatedCatalog;
          }
          state.catalog[updatedCatalog.assest_type.toLowerCase()] =
            prevCatState;
        } catch {
          location.reload();
        }
      }
    },
    manualAddCatalog: (state, action) => {
      if (!action.payload) return;
      const newCatalog = action.payload;
      const usedFolderId = newCatalog.folders[0];
      const assetType = newCatalog.assest_type;
      const pathname = window.location.pathname;
      const folderId = pathname.split('/').pop();
      if (folderId === usedFolderId || folderId === assetType) {
        state.catalog = [newCatalog, ...state.catalog];
      }
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    // pendings

    builder.addCase(getCatalog.pending, (state, action) => {
      state.catalog = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(getAllMyCatalogs.pending, (state, action) => {
      state.myCatalogs = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(getTemplate.pending, (state, action) => {
      state.catalog = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(getNewRelease.pending, (state, action) => {
      state.catalog = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(getPopular.pending, (state, action) => {
      state.catalog = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(getSearchCats.pending, (state, action) => {
      state.catalog = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(getFeaturedCatalogs.pending, (state, action) => {
      state.catalog = [];
      state.pageDetails = {};
      state.loading = true;
      state.searchResult = [];
      state.currentTemplate = [];
      state.deleteStatus = false;
      state.error = null;
    });

    builder.addCase(softDeleteCatalog.pending, (state) => {
      state.deleteStatus = true;
    });

    // fulfilled

    builder.addCase(getCatalog.fulfilled, (state, action) => {
      let data = [];
      if (action?.payload?.data?.result) {
        data = action?.payload?.data?.result;
      } else if (action?.payload?.data?.length > 0) {
        data = action?.payload?.data[0]?.data || [];
      }
      state.catalog = data;
      state.loading = false;
      state.pageDetails = { ...action?.payload?.data, result: undefined };
    });

    builder.addCase(getFeaturedCatalogs.fulfilled, (state, action) => {
      let data = [];
      if (action?.payload?.data?.result) {
        data = action?.payload?.data?.result;
      } else if (action?.payload?.data?.length > 0) {
        data = action?.payload?.data[0] || [];
      }
      state.catalog = data;
      state.loading = false;
    });

    //
    builder.addCase(deleteCatalog.fulfilled, (state, action) => {
      state.catalog = action.payload.data.result
        ? action.payload.data.result
        : state.catalog;
      state.loading = false;
    });
    builder.addCase(getTemplate.fulfilled, (state, action) => {
      state.currentTemplate = action?.payload?.data ? action?.payload?.data : {};
      state.loading = false;
    });

    builder.addCase(getSearchCats.fulfilled, (state, action) => {
      state.searchResult = action?.payload?.data ? action?.payload?.data : {};
      state.pageDetails = { ...action?.payload?.data, result: undefined };
      state.loading = false;
    });
    builder.addCase(getNewRelease.fulfilled, (state, action) => {
      state.searchResult = action?.payload?.data ? action?.payload?.data : {};
      state.loading = false;
    });
    builder.addCase(getPopular.fulfilled, (state, action) => {
      state.searchResult = action?.payload?.data ? action?.payload?.data : {};
      state.loading = false;
    });

    builder.addCase(softDeleteCatalog.fulfilled, (state, action) => {
      if (Array.isArray(state.catalog)) {
        const index = state.catalog?.findIndex(
          // eslint-disable-next-line eqeqeq
          (item) =>
            item?.id === action?.payload?.data?.id ||
            item?._id === action?.payload?.data?.id
        );
        if (index !== -1) {
          state?.catalog?.splice(index, 1);
        }
      }
      successToast('Item moved to trash');
      state.deleteStatus = false;
    });

    builder.addCase(getAllMyCatalogs.fulfilled, (state, action) => {
      state.myCatalogs = action?.payload?.data ? action?.payload?.data : {};
      state.pageDetails = { ...action?.payload?.data, result: undefined };
      state.loading = false;
    });

    // rejects

    builder.addCase(softDeleteCatalog.rejected, (state) => {
      state.deleteStatus = false;
      errorToast('Failed to move item to trash');
    });

    builder.addCase(getTemplate.rejected, (state, action) => {
      console.log('error', action.error.message);
      state.error = action?.error?.message;
      state.loading = false;
    });

    builder.addCase(getAllMyCatalogs.rejected, (state, action) => {
      state.error = action?.error?.message;
      state.myCatalogs = [];
      state.loading = false;
    });
  }
});

export const { manualCatalogUpdate, manualAddCatalog } = content.actions;

export default content.reducer;
