import axiosInstance from '../axios/axiosInstance'

export const getDeletedFiles = async () => {
  const response = await axiosInstance.get('/catalog/deleted', {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return response
}

export const softDeleteFile = async id => {
  const response = await axiosInstance.delete(`/catalog/soft-delete/${id}`)
  return response
}

export const hardDeleteCatalog = async id => {
  const response = await axiosInstance.delete(`/catalog/hard-delete/${id}`)
  return response
}

export const restoreFile = async id => {
  const response = await axiosInstance.patch(`/catalog/restore/${id}`)
  return response
}

export async function restoreAllFiles () {
  const response = await axiosInstance.post('/catalog/restoreAll', {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (response.status === 200 || response.status === 201) {
    const deletedItems = await getDeletedFiles()
    return deletedItems
  }
  return null
}

export async function bulkDeleteCatalogs () {
  const response = await axiosInstance.delete('/catalog/hard-delete')
  return response
}
