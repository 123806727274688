/* eslint-disable no-unused-vars */
import styles from './TopNavigation.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularImage from 'components/atoms/CircularImage/CircularImage';
import HubLogo from 'assets/icons/HubLogo';
import HubLogoTrigger from 'assets/icons/HubLogoTrigger';
import RoundedButton from 'components/atoms/RoundedButton/RoundedButton';
import profile from 'assets/images/profiletop.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateUniState } from 'Redux/universalMenu/uniSlice';
import FrameIcon from '../../../assets/icons/header/FrameIcon';
import InformationIcon from '../../../assets/icons/header/InformationIcon';
import RectangleIcon from '../../../assets/icons/header/RectangleIcon';
import ShoppingCartIcon from '../../../assets/icons/header/ShoppingCartIcon';
import RingBell from 'assets/icons/RingBell';
import PropTypes, { any } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Usermenu from './Usermenu';

function TopNavigation ({ setUploadModalVisibility, currentUserRole }) {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const { view } = useSelector((state) => state.uniMenuState);
  const { userData, currentCompanyInfo } = useSelector(
    (state) => state.profile
  );

  // const handleNavigation = () => {
  //   navigate('/mpublisher/upload-content');
  // };

  const openMenu = () => {
    dispatch(updateUniState(!view));
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (showMenu && !e.target.closest(`.${styles.circularImageContainer}`)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [showMenu]);

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <button className={styles.logoMobile} onClick={openMenu}>
          <HubLogoTrigger />
        </button>
        <div className={styles.logoDesktop}>
          <HubLogo />
        </div>
        <span className={styles.text}>
          M<span className="bold">Publisher</span>
        </span>
      </div>
      <div className={styles.user}>
        {location.pathname !== '/mpublisher/upgrade' &&
          location.pathname !== '/mpublisher/upgrade/' && (
            <RoundedButton onClick={() => setUploadModalVisibility(true)}>
              {t('Upload content')}
            </RoundedButton>
            /* <InformationIcon />
            <RectangleIcon />
            <FrameIcon />
            <RectangleIcon />
            <RingBell />
            <RectangleIcon />
            <ShoppingCartIcon /> */
          )}
        <div
          className={styles.circularImageContainer}
          onClick={() => {
            setShowMenu((prev) => !prev);
          }}
        >
          <CircularImage image={profile} width={50} height={50} />
          <Usermenu showMenu={showMenu} setShowMenu={setShowMenu} />
        </div>
      </div>
    </div>
  );
}
TopNavigation.propTypes = {
  setUploadModalVisibility: PropTypes.function || PropTypes.any,
  currentUserRole: PropTypes.string || any
};
export default TopNavigation;
