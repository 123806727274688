/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import styles from './styles.module.scss'
import CaretUpFill from '../../FilesContainer/Folders/Icons/CaretUpFill';

const index = ({ children, title, expanded, zIndex }) => {
  const [expand, setExpand] = useState(true)
  return (
    <div className={styles.wrapper} style={{
      zIndex: zIndex || 'unset'
    }}>
      <div className={styles.header} onClick={() => setExpand(prev => !prev)}>
        <h3>{title || 'Untitled'}</h3>
        <CaretUpFill down={expand} />
      </div>
      <div className={expand ? `${styles.content} ${styles.expanded}` : styles.content}>
        {children}
      </div>
    </div>
  )
}

export default index
