/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as FolderIcon } from '../Assets/icons/folder-ico.svg';

import styles from './styles.module.scss'

const arrayOfColors = [
  '#f26257',
  '#edc799',
  '#df1717',
  '#7900fa',
  '#7cfbf3',
  '#abb6fc',
  '#e797ef',
  '#b7b5b5'
]

const index = ({ onSubmit, closePopup, popupData, handleEditFolder, handleDeleteFolder }) => {
  const { edit, data } = popupData || {}
  const [selectedColor, setSelectedColor] = useState(data?.color || null)
  const [folderName, setFolderName] = useState(data?.name || '')
  const [startedEditing, setStartedEditing] = useState(false)
  const canUpload = useRef(true)

  useEffect(() => {
    if (edit) {
      if ((!folderName || folderName === data?.name) && (!selectedColor || selectedColor === data?.color)) {
        setStartedEditing(false)
      } else {
        setStartedEditing(true)
      }
    } else {
      if (!folderName || !selectedColor) {
        setStartedEditing(false)
      } else {
        setStartedEditing(true)
      }
    }
  }, [folderName, selectedColor])

  const prepareDataAndSubmit = () => {
    if (!canUpload.current) {
      return
    }
    const data = {
      name: folderName,
      color: selectedColor
    }
    if (data.name && data.color) {
      canUpload.current = false
      if (edit) {
        handleEditFolder({ data, id: popupData?.data?.id })
      } else {
        onSubmit(data)
      }
    } else {
      // error handling missing data
    }
  }

  const getBorderColor = (color) => {
    const red = parseInt(color.substr(1, 2), 16);
    const green = parseInt(color.substr(3, 2), 16);
    const blue = parseInt(color.substr(5, 2), 16);
    const threshold = 128; // Adjust the threshold to control the contrast level
    // Calculate the luminance (perceptual brightness) of the color
    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;  
    // Use a contrasting color based on the luminance value
    return luminance > threshold ? '#000000' : '#FFFFFF';
  }

  return (
    <div className={styles.container}>
      <div className={styles.closeIconButtonContainer}>
        <button className={styles.closeIconButton} onClick={closePopup}>✕</button>
      </div>
      <div className={styles.headerContainer}>
        <FolderIcon
          style={{ fill: selectedColor || '#b7b5b5', height: '50px', width: '60px' }}
        />
        <h2 className={styles.headerText}>{!edit ? 'Create folder' : 'Edit folder'}</h2>
      </div>
      <div className={styles.inputContainer}>
        <input
        className={styles.folderInput}
        placeholder='Folder name'
        value={folderName}
        onChange={(e) => { 
          setFolderName(e.target.value);
         }}
        />
      </div>
      <div className={styles.folderSelectColorContainer}>
        <h3 className={styles.headerText}>Folder Color</h3>
        <ul className={styles.colorContainer}>
          {arrayOfColors.map((color, index) => (
            <li
              key={color}
              className={selectedColor === color ? `${styles.color} ${styles.selected}` : styles.color}
              onClick={() => {
                setSelectedColor(color)
                getBorderColor(color)
              }}
              style={{ backgroundColor: color, border: selectedColor === color ? `3px solid ${getBorderColor(selectedColor)}` : 'none' }}
            />
          ))}
        </ul>
      </div>
      <div className={styles.submitBtnContainer}>
        <button className={styles.submitBtn} disabled={!startedEditing} onClick={prepareDataAndSubmit}>{!edit ? 'Create folder' : 'Update folder'}</button>
        {edit && <button className={`${styles.submitBtn} ${styles.deleteBtn}`} onClick={() => edit && handleDeleteFolder(data)}>Delete</button>}
      </div>
      <div className={styles.extraInfo}>
        <p>It&apos;s great to organize your files into structured folders</p>
      </div>
    </div>
  )
}

export default index
