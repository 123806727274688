import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import Input from './Input';

const SearchBar = ({ onSelectChange, selectValue, onTextChange, textValue }) => {
  const selectArray = [
    { value: 'images', label: 'Images' },
    { value: 'templates', label: 'Templates' },
    { value: 'mockups', label: 'Mockups' },
    { value: 'videos', label: 'Videos' }
  ]
  return (
    <div className={styles.searchBarPosition}>
      <Input 
      selectArray={selectArray}
      onSelectChange={onSelectChange}
      selectValue={selectValue}
      onTextChange={onTextChange}
      textValue={textValue}
      />
    </div>
  );
}

SearchBar.propTypes = {
  onTextChange: PropTypes.func,
  textValue: PropTypes.string,
  selectValue: PropTypes.string,
  onSelectChange: PropTypes.func
}

export default SearchBar;
