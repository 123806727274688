import styles from './css/AnEditor.module.scss';
import React from 'react';
import image from '../../../../assets/images/anEditor/img.png';
import RoundedButton from '../../../atoms/RoundedButton/RoundedButton';
import BackHighlightEffect from 'assets/images/creativeSpace/BackHighlightEffect';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AnEditor = ({ setUploadModalVisibility, currentUserRole }) => {
  const { t } = useTranslation();
  return (
    <section
      className={styles.container}
      style={{
        backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(
          renderToStaticMarkup(<BackHighlightEffect fillColor={'#7b587b'} opacity={0.5} />)
        )}"), url("data:image/svg+xml,${encodeURIComponent(
          renderToStaticMarkup(<BackHighlightEffect fillColor={'#9E5037'} opacity={0.45} />)
        )}"), url("data:image/svg+xml,${encodeURIComponent(
          renderToStaticMarkup(<BackHighlightEffect fillColor={'#975EA6'} opacity={0.5} />)
        )}")`
      }}
    >
      <div className={styles.an__editor__container}>
        <div className={styles.flex__container}>
          <div className={styles.text__container}>
            <h1>{t('assetTitle3')}</h1>
            <small>{t('Making your material shine.')}</small>
            <RoundedButton
              // onClick={() => navigate('/mpublisher/upload-content')}
              onClick={() => setUploadModalVisibility(true)}
              extraClass={styles.an__editor__container__button}
            >
              {t('Upload content')}
            </RoundedButton>
          </div>
          <figure>
            <img src={image} alt="An Editor" />
          </figure>
        </div>
      </div>
    </section>
  );
};
AnEditor.propTypes = {
  setUploadModalVisibility: PropTypes.function || PropTypes.any,
  currentUserRole: PropTypes.any
};
export default AnEditor;
