import getMenuLabel from './getMenuLabel';
import styles from '../css/HorizontalMenu.module.scss';

const deactivateLabels = (refs) => {
    const { newRelease, popular } = refs;
    const newReleaseLabel = getMenuLabel(newRelease);
    const popularLabel = getMenuLabel(popular);
    newReleaseLabel.classList.add(styles.horizontal__menu__container__deactivate);
    return popularLabel.classList.add(styles.horizontal__menu__container__deactivate);
};

export default deactivateLabels;
