/* eslint-disable no-unused-vars */
import React, { useRef } from 'react'
import styles from './styles.module.scss'
import { errorToast, successToast } from 'toasts/Toasts'
import { useDispatch } from 'react-redux'
import { hardDeleteDraft } from 'Redux/draft/draftSlice'

const index = ({ exitPopup, popupData }) => {
  const dispatch = useDispatch()
  const isDeleting = useRef(false)

  const handleDeleteDraft = async () => {
    isDeleting.current = true
    const { data } = popupData
    try {
      if (!data.id) throw new Error('Draft id not found')
      dispatch(hardDeleteDraft(data.id))
      exitPopup()
      successToast('Draft Deleted successfully');
    } catch {
      exitPopup()
      errorToast('Something went wrong');
    }
    isDeleting.current = false
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Are you sure you would like<br /> to delete this Draft ?</h3>
      </div>
      <div className={styles.buttonsArea}>
        {/* <p className={styles.title}>Would you like to delete this collection</p> */}
        <div className={styles.cancelContainerLeft}>
          <button className={styles.cancelBtn} onClick={exitPopup}>Cancel</button>
        </div>
        <div className={styles.deleteContainerRight}>
          <button className={styles.deleteBtn} onClick={!isDeleting.current && handleDeleteDraft}>Delete</button>
        </div>
      </div>
    </div>
  )
}

export default index
