/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Fragment, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as TrashIcon } from 'assets/icons/trashicon-folder.svg';
import { ReactComponent as SuspendIcon } from 'assets/icons/suspend.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-icon-f.svg';
import { errorToast } from 'toasts/Toasts';
import { createFolders, suspendFolders, deleteFolders } from 'Redux/folder/folderSlice';

const FolderManagement = () => {
  const [isTyping, setIsTyping] = useState(null);
  const dispatch = useDispatch();
  const { folders } = useSelector((state) => state.folders);
  const nonGlobalFoldersOnly = folders.filter(folder => !folder.is_global_folder);
  const managementArray = [
    {
      title: 'Images',
      folders: nonGlobalFoldersOnly.filter(folder => folder.parent === 'Images')
    },
    {
      title: 'Videos',
      folders: nonGlobalFoldersOnly.filter(folder => folder.parent === 'Videos')
    },
    {
      title: 'Templates',
      folders: nonGlobalFoldersOnly.filter(folder => folder.parent === 'Templates')
    }
  ]

  const suspendFolder = (folder) => {
    dispatch(suspendFolders(folder.id || folder._id));
  }

  const deletePrivateFolder = (folder) => {
    const { _id, id } = folder;
    const isStatic = folder.static;
    if (isStatic) {
      errorToast('Cannot delete static folder');
      return
    }
    dispatch(deleteFolders(id || _id));
  }

  const handleInputClick = (inputParent) => {
    if (!isTyping || isTyping !== inputParent) {
      setIsTyping(inputParent);
      return
    }
  }

  const handleAddingFolder = (e, parent) => {
    let potentialValue = null
    if (e) {
      const value = e.target.value;
      if (value && value?.trim()) {
        potentialValue = {
          name: value,
          parent
        }
      }
    } else {
      const value = document.getElementById(`${parent}-input`).value;
      if (value && value?.trim()) {
        potentialValue = {
          name: value,
          parent
        }
      }
    }
    if (potentialValue.name && potentialValue.parent) {
      dispatch(createFolders(potentialValue));
    }
    setIsTyping(null);
  }

  return (
    <div className={styles.container} onMouseDown={() => setIsTyping(null)}>
      <h2 className={styles.title}>
        Folder Management
      </h2>
      <div className={styles.management_container}>
        {managementArray.map((management) => (
          <div key={management.title} className={styles.management}>
            <div className={styles.management_header}>
              <h3 className={styles.management_title}>{management.title}</h3>
            </div>
            <ul className={styles.folder_list}>
              {management?.folders?.map((folder) => (
                <li key={folder?.name} className={styles.folder_item}>
                  <span>
                    {folder?.name}
                  </span>
                  {
                    folder?.static ? <SuspendIcon className={styles.svg_icon} onClick={() => suspendFolder(folder)} /> : <TrashIcon className={styles.svg_icon} onClick={() => deletePrivateFolder(folder)} />
                  }
                </li>
              ))}
              <li className={`${styles.folder_item} ${styles.folder_item_w_input}`} onMouseDown={(e) => e.stopPropagation()} style={{ cursor: 'pointer' }} onClick={() => handleInputClick(management?.title)}>
                {
                  isTyping && management?.title === isTyping
                    ? (
                      <Fragment>
                        <input id={`${management?.title}-input`} onKeyDown={e => e.key === 'Enter' && handleAddingFolder(e, management.title)} className={styles.name_input} type="text" placeholder="Folder Name" />
                        <PlusIcon className={styles.svg_icon} onClick={() => handleAddingFolder(null, management.title)} />
                      </Fragment>
                    )
                    : (
                      <>
                        Add Folder
                        <PlusIcon className={styles.svg_icon} />
                      </>
                    )
                }
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FolderManagement;
