import styles from './css/TemplateGrid.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Photo from 'components/organisms/GalleryLayout/Photo';
const TemplateGrid = ({ templates, report, handleClick, isTouchScreen, currentUser }) => {
  return (
    <div className={styles.template__grid__container}>
      <div className={`${styles.template_row} ${styles.top}`}>
        <Photo
          key={templates[0]?.id}
          index={0}
          assetUserId={templates[0]?.userId}
          currentUser={currentUser}
          onClick={handleClick}
          photo={templates[0]}
          catalog={templates[0]}
          allCatalogs={templates}
          margin={0}
          linearLine={true}
          height={160}
          templates={true}
          useOriginal={true}
          homePage={true}
          isTouchScreen={isTouchScreen}
          OverRuledRoles={[]}
        />
      </div>
      <div className={`${styles.template_row} ${styles.bottom}`}>
        <Photo
          key={templates[1]?.id}
          index={0}
          currentUser={currentUser}
          assetUserId={templates[1]?.userId}
          onClick={handleClick}
          photo={templates[1]}
          catalog={templates[1]}
          allCatalogs={templates}
          margin={0}
          linearLine={true}
          height={160}
          templates={true}
          useOriginal={true}
          homePage={true}
          isTouchScreen={isTouchScreen}
          OverRuledRoles={[]}
        />
      </div>
      <div className={styles.template_spanned}>
        <Photo
          key={report?.id}
          index={0}
          currentUser={currentUser}
          assetUserId={report?.userId}
          onClick={handleClick}
          photo={report}
          catalog={report}
          allCatalogs={templates}
          margin={0}
          linearLine={true}
          height={340}
          templates={true}
          useOriginal={true}
          mixedContent={true}
          homePage={true}
          isTouchScreen={isTouchScreen}
          OverRuledRoles={[]}
        />
      </div>
    </div>
  );
};

TemplateGrid.propTypes = {
  templates: PropTypes.array,
  report: PropTypes.object,
  handleClick: PropTypes.func,
  isTouchScreen: PropTypes.bool,
  currentUser: PropTypes.object
};

export default TemplateGrid;
