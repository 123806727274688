import { useEffect } from 'react';
import deactivateLabels from '../functions/deactivateLabels';
import hideInactiveHorizontalLines from '../functions/hideInactiveHorizontalLines';
const useHideInactiveMenus = (refs) => {
    const { newReleaseRef, popularRef } = refs;
    useEffect(() => {
        const newRelease = newReleaseRef.current;
        const popular = popularRef.current;
        const menuItems = { newRelease, popular };
        hideInactiveHorizontalLines(menuItems);
        deactivateLabels(menuItems);
    }, [])
};

export default useHideInactiveMenus;
