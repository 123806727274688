/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from 'react'
import styles from './styles.module.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCatalogByLinkShare } from 'api/contentUploadAPI'
import SingleAssetPM from '../myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../myTemplatesCatalogs/components/PopupManger'
import Gallery from 'components/organisms/GalleryLayout/Gallery'
import HubLogo from 'assets/icons/HubLogo'

const LinkSharePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams()
  const [catalogs, setCatalogs] = useState([]);
  const [collectionInfo, setCollectionInfo] = useState([]);
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);
  const [noResults, setNoResults] = useState(true);
  const [permission, setPermission] = useState([]);

  const getCatalogs = async (id) => {
    const response = await getCatalogByLinkShare(id);
    if (response?.data?.statusCode === 200 && response?.data?.data) {
      return response.data.data;
    } return []
  }

  useEffect(() => {
    if (params.shareId) {
      getCatalogs(params.shareId).then(data => {
        setCatalogs(data?.catalogs?.map((catalog, i) => {
          if (catalog?.assest_type === 'Templates') {
            return {
              ...catalog,
              index: i,
              id: catalog?._id || catalog?.id,
              src: catalog?.template_data?.templates[0],
              width: catalog?.template_data?.templates[0]?.width || 1920,
              height: catalog?.template_data?.templates[0]?.height || 1080
            }
          } else if (catalog.assest_type === 'Videos') {
            return {
              ...catalog,
              src: catalog.video_urls,
              width: catalog?.vid_metadata?.width || 16,
              height: catalog?.vid_metadata?.height || 9
            }
          }
          return {
            ...catalog,
            index: i,
            id: catalog?._id || catalog?.id,
            src: catalog?.assest_urls[0],
            width: catalog?.image_size?.width || 16,
            height: catalog?.image_size?.height || 9
          }
        }))
        if (data?.permissions.length > 0) {
          const firstPermission = data?.permissions[0]
          setPermission([
            firstPermission?.toString()?.toUpperCase()
          ])
        }
        setCollectionInfo(data?.collection)
      })
    } else {
      navigate('/404')
    }
  }, [location, params]);

  const handleClickMulti = (state, catalog) => {
    if (state === 6) {
      return
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClickSingle = (state, catalog) => {
    if (state === 6) {
      return
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClick = (state, catalog) => {
    if (catalog.assest_type === 'Templates') {
      handleClickMulti(state, catalog)
    } else {
      handleClickSingle(state, catalog)
    }
  }

  return (
    <>
      <div id="modal-root" />
      <div className={styles.sharePageWrapper}>
        <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={catalogs} shareMode={true} />
        <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} shareMode={true} />
        <div className={styles.main__frame}>
          <div className={styles.header__container}>
            <div className={styles.header__logo}>
              <HubLogo />
            </div>
            <div className={styles.header__title}>
              <h1 className={styles.title}>MPublisher</h1>
              <p className={styles.sub_title}>The Future of presenting, today</p>
            </div>
          </div>
          <div className={styles.main__container}>
            <div className={styles.collectionInfoContainer}>
              <h2 className={styles.gallery__name}>{collectionInfo?.name || 'Undefined Collection'}</h2>
              <p className={styles.gallery__count}>{catalogs?.length || 0} items were shared with you</p>
            </div>
            <div className={styles.contentData}>
              {catalogs?.length > 0 &&
                <Gallery photos={catalogs}
                  onClick={handleClick}
                  margin={10}
                  targetRowHeight={220}
                  OverRuledRoles={permission}
                />
              }
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.pwrd}>
              <span>
                Powered by <a href='https://masterwizr.com/' target='_blank' rel="noreferrer"><strong>MASTER WiZR</strong></a>
              </span>
            </div>
            <div className={styles.sign_int_container}>
              <span className={styles._q}>
                You don’t have access to <strong>Mpublisher</strong>
              </span>
              <div className={styles.btn_container}>
                <a className={styles.sign_in_btn} href='https://masterwizr.com/' target='_blank' rel="noreferrer" >Try for free</a>
                <a className={styles.sign_up_btn} href={window.location.origin} target='_blank' rel="noreferrer">Login</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default LinkSharePage
