/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import MiddleArea from './MiddleArea';
import TopArea from './TopArea';
import BottomArea from './BottomArea';

const index = ({ data, shareMode }) => {
  const { src, content_description, image_size, assest_type, id, _id, contributor } = data
  return (
    <div className={styles.popUpContainer}>
        <TopArea id={_id || id} type={assest_type} shareMode={shareMode} data={data}/>
        <MiddleArea img={assest_type === 'Images' ? src.original : src.thumbnail}/>
        <BottomArea description={content_description} dimensions={image_size} fileType={assest_type} contributor={contributor}/>
    </div>
  )
}

export default index
