/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import style from './style.module.scss';
import Checkbox from 'react-custom-checkbox';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';

function UpperSegment ({ imgState, setImgState, selectedN, phaseState, contentState, setContentState, editMode }) {
  const checkAllSelected = (value) => {
    if (editMode) { return }
    setImgState((prev) => {
      return prev.map((el) => {
        return { ...el, active: value };
      });
    });
  };

  const hasErrors = {
    border: '1px solid red'
  }

  // phaseOne
  const setTagImgActive = (img) => {
    if (editMode) { return }
    const newImg = {
      ...img,
      active: !img.active
    };
    setImgState((prev) => {
      return prev.map((el) => (el.id === img.id ? newImg : el));
    });
  };

  const deleteImg = (id) => {
    if (editMode) { return }
    setImgState((prev) => prev.filter((el) => el.id !== id));
  };

  // phaseTwo
  const setRadioImgactive = (img) => {
    if (editMode) { return }
    let possiblePrevImg = imgState.find((el) => el.active);
    if (possiblePrevImg && !img.active) {
      possiblePrevImg = {
        ...possiblePrevImg,
        data: contentState,
        active: false
      };
      setImgState((prev) => prev.map((el) => {
        if (el.id === possiblePrevImg.id) {
          return possiblePrevImg;
        }
        if (el.id === img.id) {
          return { ...img, active: true };
        }
        return el;
      }));
    } else if (!img.active) {
      setImgState((prev) => prev.map((el) => el.id === img.id ? { ...img, active: true } : el))
    } else {
      setImgState((prev) => prev.map((el) => el.id === img.id ? { ...el, data: contentState, active: false } : el))
    }
    setContentState(img.data);
  }

  const checkboxOne = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: '0.6rem',
    backgroundColor: '#1d1d1d'
  }

  const checkboxTwo = {
    ...checkboxOne,
    backgroundColor: 'var(--main-color)',
    alignSelf: 'stretch'
  }

  return (
    <>
      {!editMode &&
        <>
          <h4 className={style.header}>{phaseState === 1 ? `Add details to multiple assets: Phase(${phaseState})` : 'Add details to each element: Phase(2)'}</h4>
          <div className={style.descriptionAndBtnContainer}>
            <div className={style.descriptionContainer}>
              <p className={style.descriptionPargrph}>
                {
                  phaseState === 1 
                  ? 'To ease bulk uploading, you can add tags and description to multiple assets at once.' 
                  : 'Select an asset to add tags and description specifically to that asset.'
                }
              </p>
              <p className={style.descriptionPargrph}>
                {
                  phaseState === 1 
                  ? 'Tag as many elements you feel have the same theme or are subject to the same description.'
                  : 'Each element will have its own tags and description in this phase'
                }
              </p>
            </div>
            {/* <button className={style.addImgsBtn}>
              <img className={style.addImgsBtnIcon} src='https://i.imgur.com/TkalPb8.png' alt='add' />
              <span>Add images</span>
            </button> */}
          </div>
        </>
      }
      <div className={style.ImgsContainer}>
        {imgState.length
          ? (
            imgState.map((img) => {
              return (
                <div key={img.id} className={style.singleImgContainer}>
                  <img
                    className={editMode ? style.inEditMode : img.active ? `${style.active}` : ''}
                    src={typeof img.uriData === 'string' ? img.uriData : URL.createObjectURL(img.uriData)}
                    alt="img"
                    onClick={() => phaseState === 1 ? setTagImgActive(img) : setRadioImgactive(img)}
                    style={img.errors ? hasErrors : {}}
                  />
                  { !editMode &&
                    <>
                      <div className={style.imgChckbox}>
                        <Checkbox
                          checked={img.active}
                          icon={
                            <div
                              style={checkboxOne}
                            >
                              ✓
                            </div>
                          }
                          onChange={() => phaseState === 1 ? setTagImgActive(img) : setRadioImgactive(img)}
                          borderColor="#1d1d1d"
                          borderWidth={1}
                          borderRadius={5}
                          style={{ overflow: 'hidden' }}
                          size={20}
                        />
                      </div>
                      <div className={style.deleteimgBtn}>
                        <button onClick={() => deleteImg(img.id)}>×</button>
                      </div>
                    </>
                  }
                  {
                    img.errors && (
                      <div className={style.imgErrors}>
                        <Failed />
                      </div>
                    )
                  }
                </div>
              )
            })
          )
          : (
            <div className={style.noImgs}>No images added</div>
          )}
      </div>
      {phaseState === 1 && <div className={style.selectAll}>
        {selectedN ? <p>Selected {selectedN}</p> : <p />}
        <Checkbox
          checked={false}
          disabled={!imgState.length}
          icon={
            <div
              style={checkboxTwo}
            >
              ✓
            </div>
          }
          onChange={(value) => {
            checkAllSelected(value);
          }}
          borderColor="gray"
          borderWidth={1}
          borderRadius={5}
          style={{ overflow: 'hidden' }}
          size={16}
          label="Select all"
        />
      </div>}
    </>
  );
}

export default UpperSegment;
