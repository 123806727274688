/* eslint-disable react/prop-types */
import React from 'react';

const FilledLogo = ({ mainColor }) => (
  <svg
    width="616"
    height="624"
    viewBox="0 0 562 624"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_0_1943)">
      <path
        d="M109.641 103.065C89.448 122.171 74.9725 146.522 67.8359 173.39C60.6993 200.258 61.1833 228.583 69.2339 255.191C69.7693 256.902 70.7174 258.455 71.9946 259.713C73.2718 260.971 74.8389 261.895 76.5576 262.404C78.2764 262.914 80.094 262.992 81.8503 262.633C83.6066 262.274 85.2476 261.488 86.6285 260.345C123.946 230.155 171.117 214.845 219.052 217.366C266.987 219.888 312.291 240.063 346.235 274.002C346.87 274.637 347.509 275.277 348.106 275.921C363.032 244.332 366.449 208.521 357.769 174.679C349.088 140.837 328.855 111.092 300.568 90.5872C272.28 70.0824 237.717 60.1068 202.854 62.3852C167.991 64.6636 135.02 79.0527 109.641 103.065Z"
        fill="#9E4C7B"
        fillOpacity="0.15"
      />
      <path
        d="M515.697 117.743C497.271 96.9296 473.414 81.6559 446.799 73.6326C420.184 65.6092 391.861 65.1528 365.001 72.3146C363.272 72.7913 361.687 73.6862 360.386 74.9203C359.085 76.1544 358.107 77.6898 357.54 79.391C356.973 81.0922 356.833 82.9069 357.133 84.675C357.432 86.4431 358.163 88.1101 359.259 89.5292C388.206 127.826 401.949 175.484 397.838 223.314C393.727 271.144 372.053 315.758 336.995 348.553C336.356 349.193 335.669 349.766 335.01 350.358C366.089 366.339 401.777 370.955 435.899 363.407C470.021 355.858 500.432 336.62 521.87 309.021C543.308 281.422 554.425 247.197 553.298 212.268C552.171 177.339 538.87 143.903 515.697 117.743Z"
        fill="#9E4C7B"
        fillOpacity="0.15"
      />
      <path
        d="M505.76 520.898C525.95 501.791 540.423 477.442 547.559 450.576C554.696 423.71 554.214 395.388 546.168 368.781C545.633 367.069 544.686 365.515 543.409 364.256C542.133 362.997 540.566 362.072 538.847 361.562C537.128 361.052 535.31 360.972 533.553 361.331C531.796 361.689 530.155 362.474 528.773 363.617C491.453 393.81 444.277 409.12 396.34 406.596C348.402 404.072 303.095 383.892 269.152 349.946C268.513 349.307 267.878 348.672 267.281 348.027C252.34 379.619 248.911 415.439 257.587 449.291C266.263 483.143 286.498 512.898 314.792 533.409C343.086 553.92 377.659 563.896 412.53 561.612C447.402 559.329 480.378 544.929 505.756 520.903L505.76 520.898Z"
        fill="#9E4C7B"
        fillOpacity="0.15"
      />
      <path
        d="M99.7015 506.374C118.129 527.185 141.985 542.456 168.599 550.479C195.213 558.501 223.534 558.958 250.393 551.798C252.122 551.32 253.707 550.423 255.007 549.186C256.307 547.95 257.283 546.412 257.848 544.709C258.414 543.006 258.551 541.191 258.248 539.422C257.945 537.654 257.211 535.987 256.111 534.57C227.176 496.274 213.441 448.624 217.554 400.803C221.666 352.982 243.336 308.376 278.384 275.583C279.024 274.944 279.716 274.366 280.365 273.774C249.288 257.813 213.612 253.213 179.503 260.768C145.395 268.323 114.998 287.558 93.568 315.149C72.1382 342.739 61.0224 376.951 62.1423 411.868C63.2623 446.785 76.5476 480.214 99.7015 506.374Z"
        fill="#9E4C7B"
        fillOpacity="0.15"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1943">
        <rect width="615.441" height="623.999" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default FilledLogo;
