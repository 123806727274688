/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react'
import styles from './styles.module.scss'
import RoundedButton from 'components/atoms/RoundedButton/RoundedButton'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { successToast } from 'toasts/Toasts'
import { toast } from 'react-toastify'

const arrayOfEls = [
  {
    id: 1,
    value: 'Presentations'
  },
  {
    id: 2,
    value: 'Reports'
  },
  {
    id: 3,
    value: 'Cover Pages'
  },
  {
    id: 4,
    value: 'Images'
  },
  {
    id: 5,
    value: 'Social Media'
  },
  {
    id: 6,
    value: 'Filters'
  },
  {
    id: 7,
    value: 'Backgrounds'
  }
]

const UpgradeComponent = () => {
  const { isModuleAccessible } = useSelector((state) => state.profile);
  const navigateBackHome = useCallback(() => {
    // if (!toast.isActive(1)) {
    //   successToast('You are already subscribed to Mpublisher', {
    //     toastId: 1
    // })
    // }
    return <Navigate to="/mpublisher" />
  }, [])

  return (
    !isModuleAccessible
      ? (
        <div className={styles.wrappingElement}>
          <div className={styles.bgFilter}>
            <div className={styles.main_container}>
              <div className={styles.introapology}>
                <h1 className={styles.header}>We are sorry</h1>
                <h2 className={styles.sc_header}>you don&apos;t have an active license</h2>
              </div>
              <div className={styles.entrance}>
                <p className={styles.info}>
                  Say goodbye to generic designs and hello to a
                  {' '}
                  <span className={styles.colored_span}>
                    world of limitless creativity.
                  </span>
                </p>
                <div className={styles.btn_container}>
                  <RoundedButton onClick={() => null}>Go to Licenses</RoundedButton>
                </div>
              </div>
            </div>
            <footer className={styles.mw_footer}>
              <div className={styles.tags_container}>
                {
                  arrayOfEls.map(tag => (
                    <span key={tag.id} className={styles.tag_span}>{tag.value}</span>
                  ))
                }
              </div>
              <div className={styles.powerdBy_container}>
                <span>Powered by <strong>MASTER WiZR</strong></span>
              </div>
            </footer>
          </div>
        </div>
      )
      : navigateBackHome()
  )
}

export default UpgradeComponent
