import React from 'react';

function DownloadSvg () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.223"
      height="11.475"
      viewBox="0 0 9.223 11.475"
    >
      <g id="download-svgrepo-com" transform="translate(-4.888 -1.525)">
        <path
          id="Path_1443"
          data-name="Path 1443"
          d="M14.111,9.517a1.96,1.96,0,0,1-1.957,1.957H6.845A1.96,1.96,0,0,1,4.888,9.517a.806.806,0,0,1,1.612,0,.346.346,0,0,0,.345.345h5.308a.346.346,0,0,0,.345-.345.806.806,0,0,1,1.612,0ZM8.93,7.9a.806.806,0,0,0,1.14,0l2.38-2.38a.806.806,0,1,0-1.14-1.14l-1,1V.806a.806.806,0,1,0-1.612,0V5.383l-1-1A.806.806,0,0,0,6.55,5.52Z"
          transform="translate(0 1.525)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default DownloadSvg;
