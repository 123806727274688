/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { pages } from './pages';
import queryString from 'query-string';
import { login, getPublisherUser } from '../Redux/auth/authSlice';
import { fetchProfile } from '../Redux/auth/profileSlice';
import { getAllFolders } from '../Redux/folder/folderSlice';
import { getAllCollections } from '../Redux/collection/collectionSlice';
import TopNavigation from 'components/organisms/TopNavigation/TopNavigation';
import EditorContainer from 'components/organisms/EditorContainer/EditorContainer';
import BottomInteractiveBar from 'components/organisms/BottomInteractiveBar/BottomInteractiveBar';
import GlobalMenu from '../components/organisms/UniversalMenu/GlobalMenu';
import CheckAuthLoadingPage from 'components/pages/CheckAuthLoadingPage/CheckAuthLoadingPage';
import UpgradeComponent from 'components/pages/UpgradeComponent/UpgradeComponent';
import TemplatesContainer from 'components/templates/Layout/Layout';
import UploadContent from 'components/pages/uploadContent/ContentUpload.jsx';
import useContentUpload from 'components/pages/hooks/useContentUpload.js';
import { useTranslation } from 'react-i18next';
import { getUserData } from 'Redux/userdata/userdataSlice';
import { getDrafts } from 'Redux/draft/draftSlice';
import { getProfileAdministration } from 'Redux/profileAdministration/profileAdministrationSlice';

export default function PrivateRoutes () {
  const dispatch = useDispatch();
  const parsed = queryString.parse(window.location.search);
  const { authToken } = parsed;
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const { isModuleAccessible, status, selectedProfile } = useSelector((state) => state.profile);
  const { userdata, loadingUD } = useSelector((state) => state.userdata);
  const { profileAdministration, loadingPA } = useSelector((state) => state.profileAdministration);
  
  const {
    i18n: { changeLanguage }
  } = useTranslation();
  const location = useLocation();

  const user = useSelector((state) => state.auth?.user);
  const [currentUserRole, setCurrentUserRole] = useState({});

  useEffect(() => {
    const role = user?.roles?.length > 0 ? user?.roles[0] : '';
    setCurrentUserRole(role);
  }, [user])

  useEffect(() => {
    changeLanguage(selectedProfile?.locale?.toLowerCase() || 'en');
  }, [selectedProfile?.locale, changeLanguage])

  // To be moved back to UploadContent component later
  const data = useContentUpload();

  useEffect(() => {
    dispatch(login());
  }, []);

  useEffect(() => {
    if (window.location.search.includes('authToken')) {
      const url = window.location.href.split('?authToken')[0];
      if (url) {
        window.history.pushState({}, document.title, url);
      } else {
        window.history.pushState({}, document.title, '/');
      }
    }
  }, [location]);

  useMemo(() => {
    if (loading) return;
    if (isAuthenticated) {
      dispatch(fetchProfile());
      dispatch(getProfileAdministration());
      dispatch(getPublisherUser());
    } else {
      const url = window.location.href?.split('/mpublisher')[1] || '';
      url && localStorage.setItem('redirect-log', url);
      // window.location.href = ;
      window.location.replace(`${process.env.REACT_APP_MASTER_WIZR_LOGIN}?_rdr=${window.location}`);
    }
  }, [loading, isAuthenticated]);

  useMemo(() => {
    if (status === 'succeeded' && isModuleAccessible) {
      dispatch(getUserData());
      dispatch(getDrafts());
      dispatch(getAllCollections());
      if (profileAdministration) {
        const { excludedFolders = [] } = profileAdministration;
        dispatch(getAllFolders(excludedFolders));
      }
    }
  }, [status, isModuleAccessible, profileAdministration]);

  useEffect(() => {
    if (location.pathname?.includes('mpublisher/create') || location.pathname?.includes('mpublisher/edit')) {
      document.body.style.zoom = '1';
    } else {
      document.body.style.zoom = 'var(--currentzooom)';
    }
  }, [location.pathname]);

  /**
   * Recursively return child routes for a route in a list of routes
   * @param  {[pathnames]} arg1 An array of routes to loop through
   * @param  {prefix} arg2 A prefix to add onto the previous route path
   */
  const routeHandler = (pagenames, prefix = '') => {
    return pagenames.map((route) => {
      if (!route.children) {
        return (
          <Route
            key={`${prefix}${route.path}`}
            path={`${prefix}${route.path}`}
            exact={route.exact}
            element={
              <route.layout>
                <route.element setUploadModalVisibility={data.setUploadModalVisibility} currentUserRole={currentUserRole} />
              </route.layout>
            }
          />
        );
      }
      return routeHandler(route.children, route.path);
    });
  };

  return (
    <>
      {(isAuthenticated && status === 'succeeded') ? (
        <>
          {!(location.pathname?.includes('/mpublisher/create') || location.pathname?.includes('/mpublisher/edit')) ? (
            <>
              <TopNavigation setUploadModalVisibility={data.setUploadModalVisibility} currentUserRole={currentUserRole} />
              <GlobalMenu />
              <Routes>
                {
                  isModuleAccessible && userdata
                    ? routeHandler(pages)
                    : <>
                      <Route path="*" element={<Navigate to="/mpublisher/upgrade" />} />
                      <Route path="/upgrade" element={
                        <TemplatesContainer>
                          <UpgradeComponent />
                        </TemplatesContainer>
                      } />
                    </>
                }
              </Routes>
              <BottomInteractiveBar />
              <div id="modal-root" />
              <div id="context-root" />
              <UploadContent data={data} />
            </>
          ) : (
            <Routes>
              <Route exact path="/create/:draftId" element={<EditorContainer currentUserRole={currentUserRole} />} />
              <Route exact path="/create/:draftId/view" element={<EditorContainer currentUserRole={currentUserRole} />} />
              <Route exact path="/edit/:templateId" element={<EditorContainer currentUserRole={currentUserRole} editing={true} />} />
              <Route exact path="/edit/:templateId/view" element={<EditorContainer currentUserRole={currentUserRole} editing={true} />} />
              <Route path="*" element={<Navigate to="/mpublisher" />} />
            </Routes>
          )}
        </>
      ) : (
        <CheckAuthLoadingPage />
      )}
    </>
  );
}
