import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Button'
import { softDeleteCatalog } from 'Redux/trash/trashSlice'
import styles from './styles.module.scss'
import PropTypes from 'prop-types';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

const DeletePopup = ({ onCancel, data }) => {
  const { deleteStatus } = useSelector(state => state.content)
  const dispatch = useDispatch()
  return (
    <div className={styles.container}>
      <Trash />
      <h3>Are you sure you want to delete?</h3>
      <div className={styles.twoButtons}>
        <Button clickEvent={onCancel} text='No' extraClass='grayBtn' disabled={deleteStatus} />
        <Button
          clickEvent={async () => {
            await dispatch(softDeleteCatalog(data?.id))
            onCancel()
          }}
          text={deleteStatus ? 'Deleting...' : 'Delete'}
          disabled={deleteStatus}
        />
      </div>
    </div>
  )
}

DeletePopup.propTypes = {
  onCancel: PropTypes.func,
  data: PropTypes.object
}

export default DeletePopup
