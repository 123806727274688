/* eslint-disable react/prop-types */
import React from 'react';

const LinedLogo = ({ mainColor }) => (
  <svg 
  width="616" 
  height="624" 
  viewBox="0 0 616 624" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_1950)">
      <path d="M85.6851 259.179L85.6786 259.185L85.6721 259.19C84.4807 260.176 83.065 260.854 81.5499 261.164C80.0347 261.473 78.4666 261.406 76.9838 260.966C75.5009 260.527 74.149 259.729 73.0472 258.644C71.9469 257.561 71.1298 256.223 70.6675 254.75C62.6996 228.41 62.2211 200.372 69.2856 173.775C76.3507 147.176 90.6813 123.069 110.672 104.154C135.796 80.3826 168.437 66.1376 202.952 63.882C237.466 61.6264 271.683 71.5021 299.687 91.8016C327.692 112.101 347.722 141.548 356.316 175.051C364.73 207.853 361.662 242.523 347.663 273.31C347.543 273.189 347.424 273.069 347.305 272.951L347.296 272.942C313.09 238.74 267.435 218.409 219.131 215.868C170.826 213.327 123.291 228.755 85.6851 259.179Z" stroke="#63324E" strokeOpacity="0.2" strokeWidth="3" />
      <path d="M514.574 118.738L514.574 118.738C537.515 144.635 550.683 177.737 551.799 212.317C552.915 246.896 541.909 280.778 520.686 308.101C499.462 335.424 469.356 354.469 435.575 361.942C402.506 369.258 367.952 365.033 337.647 350.009C337.779 349.885 337.91 349.759 338.038 349.632C373.356 316.585 395.19 271.633 399.333 223.442C403.476 175.243 389.627 127.217 360.456 88.6247L360.451 88.6183L360.446 88.612C359.5 87.3877 358.87 85.9495 358.611 84.4242C358.353 82.8988 358.473 81.3332 358.963 79.8656C359.452 78.3979 360.296 77.0733 361.418 76.0086C362.539 74.9453 363.904 74.1739 365.394 73.7622C391.983 66.674 420.02 67.1264 446.366 75.0687C472.715 83.0117 496.332 98.1323 514.574 118.738Z" stroke="#63324E" strokeOpacity="0.2" strokeWidth="3" />
      <path d="M504.729 519.809L504.724 519.814L504.714 519.823L504.71 519.827C479.589 543.604 446.948 557.855 412.432 560.116C377.91 562.377 343.683 552.5 315.672 532.194C287.662 511.889 267.629 482.432 259.04 448.918C250.631 416.107 253.709 381.427 267.723 350.638C267.843 350.758 267.962 350.878 268.081 350.996L268.092 351.007C302.296 385.214 347.953 405.55 396.261 408.094C444.569 410.638 492.108 395.21 529.717 364.783L529.723 364.778L529.729 364.773C530.921 363.787 532.337 363.11 533.853 362.8C535.369 362.491 536.937 362.56 538.42 363C539.903 363.44 541.255 364.238 542.356 365.324C543.456 366.409 544.272 367.747 544.734 369.221C552.698 395.56 553.174 423.596 546.11 450.191C539.045 476.787 524.717 500.893 504.729 519.809Z" stroke="#63324E" strokeOpacity="0.2" strokeWidth="3" />
      <path d="M254.914 535.474L254.92 535.481L254.926 535.489C255.875 536.712 256.508 538.15 256.769 539.676C257.031 541.201 256.912 542.768 256.425 544.237C255.937 545.706 255.095 547.032 253.974 548.099C252.853 549.164 251.488 549.938 249.999 550.351C223.412 557.437 195.377 556.984 169.032 549.043C142.684 541.101 119.067 525.982 100.825 505.38C77.9026 479.481 64.7503 446.388 63.6416 411.82C62.5328 377.252 73.5374 343.383 94.7527 316.069C115.968 288.755 146.06 269.712 179.828 262.232C212.885 254.91 247.43 259.121 277.733 274.127C277.6 274.252 277.469 274.378 277.342 274.505C242.033 307.549 220.203 352.492 216.059 400.674C211.914 448.864 225.755 496.883 254.914 535.474Z" stroke="#63324E" strokeOpacity="0.2" strokeWidth="3" />
    </g>
    <defs>
      <clipPath id="clip0_0_1950">
        <rect width="615.441" height="623.999" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LinedLogo;
