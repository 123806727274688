import styles from './css/HorizontalMenu.module.scss';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useHideInactiveMenus from './hooks/useHideInactiveMenus';
import activateMenuItem from './functions/activateMenuItem';
import { useTranslation } from 'react-i18next';

const HorizontalMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const catalogRef = useRef();
    const newReleaseRef = useRef();
    const popularRef = useRef();
    const { t } = useTranslation();
    const refs = { catalogRef, newReleaseRef, popularRef, navigate };
    useHideInactiveMenus(refs);

    return (
        <section className={`${styles.container}`}>
            <div className={`${styles.horizontal__menu__container}`}>
                <div>
                    <div>
                        <p onClick={() => activateMenuItem('catalog', refs, dispatch)}>{t('Catalogs')}</p>
                        <hr ref={ catalogRef }/>
                    </div>
                    <div>
                        <p onClick={() => activateMenuItem('new-release', refs, dispatch)}>{t('New releases')}</p>
                        <hr ref={ newReleaseRef }/>
                    </div>
                    <div>
                        <p onClick={() => activateMenuItem('popular', refs, dispatch)}>{t('Popular')}</p>
                        <hr ref={ popularRef }/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HorizontalMenu;
