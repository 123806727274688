/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './styles.module.scss'
import Slider from '../Slider'
import './modedslider.scss'
import { ReactComponent as BrightnessIcon } from './icons/brightness.svg'
import { ReactComponent as ContrastIcon } from './icons/contrast.svg'
import { ReactComponent as SaturationIcon } from './icons/saturation.svg'
import { ReactComponent as HueIcon } from './icons/hue.svg'

const LightEditor = ({
  setBrightness,
  setContrast,
  setSaturation,
  setHue,
  brightness,
  contrast,
  saturation,
  hue,
  download
}) => {
  return (
    <div className={styles.lightEditorContainer}>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderIcon}>
          <BrightnessIcon />
        </div>
        <div className={styles.slider}>
          <Slider
            min={0}
            max={200}
            value={brightness !== false ? brightness : 100}
            onChange={(value) => setBrightness(value)}
          />
        </div>

      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderIcon}>
          <ContrastIcon />
        </div>
        <div className={styles.slider}>
          <Slider
            min={0}
            max={200}
            value={contrast !== false ? contrast : 100}
            onChange={(value) => setContrast(value)}

          />
        </div>

      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderIcon}>
          <SaturationIcon />
        </div>
        <div className={styles.slider}>
          <Slider
            min={0}
            max={400}
            value={saturation !== false ? saturation : 200}
            onChange={(value) => setSaturation(value)}

          />
        </div>

      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderIcon}>
          <HueIcon />
        </div>
        <div className={styles.slider}>
          <Slider
            min={-180}
            max={180}
            value={hue !== false ? hue : 0}
            onChange={(value) => setHue(value)}
          />
        </div>
      </div>
      <div className={styles.saveDLBtnContainer}>
        <button className={styles.saveBtn} onClick={download}>Download</button>
      </div>
    </div>
  );
};

export default LightEditor;
