/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './style.module.scss'
import UpperSegment from './UpperSegment/Index'
import InteractionArea from './InteractionArea/Index'
import PropTypes from 'prop-types'
import UploadOptions from '../UploadOptions';
import { addToUploadingQueue, createCatalog, updateCatalog, uploadingInitiated } from 'Redux/contentUpload/contentUploadSlice'
import { errorToast, successToast } from 'toasts/Toasts'
import DropSample from './DropSample';
import Compressor from 'compressorjs'
import { prepareCatalogsAPI } from 'api/contentUploadAPI'
import { hardDeleteCatalog } from 'api/trashAPI'

function MetaDataForm ({ arrayOfImages, setShowMetaDataForm, uploadContent, selectedType, editMode, cancel, backFn }) {
  const DEFAULT_CONTENT_STATE = {
    assetType: selectedType,
    contributers: '',
    name: '',
    folder: {},
    isPublic: undefined,
    wordTags: [],
    color: [],
    contentDescription: '',
    format: [],
    size: [],
    filesType: [],
    style: [],
    series: []
  }

  const isUploading = useRef(false)
  const [imgState, setImgState] = useState([])
  const [phaseState, setPhase] = useState(1)
  const [sampleImage, setSampleImage] = useState(null)
  const [atLeastOneSelected, setAtLeastOneSelected] = useState(false)
  const [errors, setErrors] = useState(false)
  const [contentState, setContentState] = useState(DEFAULT_CONTENT_STATE)
  const { uploading } = useSelector(state => state.contentUpload)
  let { folders } = useSelector((state) => state.folders);
  folders = folders?.filter((folder) => folder.parent?.toLowerCase() === selectedType.toLowerCase()) || [];
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth?.user);
  const [currentUserRole, setCurrentUserRole] = useState({});

  useEffect(() => {
    const role = user?.roles?.length > 0 ? user?.roles[0] : '';
    setCurrentUserRole(role);
  }, [user])

  useEffect(() => {
    if (!editMode) {
      const NewArrOfImages = arrayOfImages.map(el => {
        return {
          id: el.id,
          uriData: el.src,
          active: false
        }
      })
      setImgState(NewArrOfImages)
    } else {
      const NewArrOfImages = arrayOfImages[0]?.template_data?.templates?.map((el, i) => {
        return {
          id: el.id || i,
          uriData: el.thumbnail,
          active: i === 0
        }
      }) || []
      const contentData = {
        contributers: arrayOfImages[0].contributor || '',
        name: arrayOfImages[0].name || '',
        folder: folders.find(f => f.id === arrayOfImages[0].folders[0]) || {},
        isPublic: arrayOfImages[0].is_public,
        wordTags: arrayOfImages[0].word_tags?.split(',') || [],
        format: arrayOfImages[0].format?.split(',') || [],
        size: arrayOfImages[0].size?.split(',') || [],
        style: arrayOfImages[0].style?.split(',') || [],
        series: arrayOfImages[0].series?.split(',') || [],
        filesType: arrayOfImages[0].files_type?.split(',') || [],
        color: arrayOfImages[0].color?.split(',') || [],
        contentDescription: arrayOfImages[0]?.content_description || '',
        assetType: arrayOfImages[0].assest_type || ''
      };
      setImgState(NewArrOfImages)
      setPhase(2)
      setContentState(contentData || DEFAULT_CONTENT_STATE)
    }
  }, [])

  useEffect(() => {
    if (imgState.length) {
      const activeOne = imgState.find(el => el.active)
      if (activeOne) {
        setAtLeastOneSelected(activeOne)
      }
    }
  }, [imgState])

  const onChangeData = changes => {
    const filterSelection = { ...contentState }
    let newObj = {}
    const key = changes.category
    if (changes.selected) {
      const newArr = [...filterSelection[key], changes.value]
      newObj = { ...filterSelection, [key]: newArr }
    } else {
      const newArr = filterSelection[key].filter(
        item => item !== changes.value
      )
      newObj = { ...filterSelection, [key]: newArr }
    }
    setContentState(newObj)
  }

  const dataValidity = (data) => {
    const { name, folders, word_tags, files, contributor, assest_type, is_public } = data
    if (!editMode && !files.length > 2) {
      setErrors(prev => { return { ...prev, files: true } })
      return false
    }
    if (!name || !folders[0] || !contributor || !word_tags.length || !assest_type || is_public === undefined) {
      !assest_type && setErrors(prev => { return { ...prev, sourceType: true } })
      !name && setErrors(prev => { return { ...prev, name: true } })
      !contributor && setErrors(prev => { return { ...prev, contributers: true } })
      !folders[0] && setErrors(prev => { return { ...prev, folder: true } })
      !word_tags.length && setErrors(prev => { return { ...prev, wordTags: true } })
      is_public === undefined && setErrors(prev => { return { ...prev, isPublic: true } })
      return false
    }
    return true
  }

  const submitData = async () => {
    let copyOfFile;
    let copyOfSelected;
    let files;

    if (!editMode) {
      const compressedFile = sampleImage.file === 'image/jpeg'
        ? await new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(sampleImage.file, {
            quality: 0.50,
            success (result) {
              resolve(new File([result], sampleImage.file.name, { type: sampleImage.file.type }));
            },
            error () {
              reject(sampleImage.file);
            }
          });
        })
        : sampleImage.file;
      copyOfFile = compressedFile ? new File([compressedFile], `sample-file-${compressedFile.name}`, { type: compressedFile.type }) : null
      copyOfSelected = atLeastOneSelected.uriData ? new File([atLeastOneSelected.uriData], `selected-file-${atLeastOneSelected.uriData.name || atLeastOneSelected.uriData.path}`, { type: atLeastOneSelected.uriData.type }) : null
      files = [copyOfFile, copyOfSelected, ...imgState.filter(el => el.id !== atLeastOneSelected.id).map(el => el.uriData)]
    }
    const {
      wordTags,
      name,
      contentDescription,
      contributers,
      format,
      size,
      style,
      series,
      filesType,
      folder,
      isPublic,
      assetType,
      color
    } = { ...contentState }
    try {
      const data = !editMode
        ? {
          name,
          contributor: contributers,
          assest_type: assetType,
          format,
          size,
          style,
          series,
          files_type: filesType,
          word_tags: wordTags,
          content_description: contentDescription,
          folders: [folder.id],
          files,
          is_public: isPublic,
          color,
          tempId: atLeastOneSelected.id
        }
        : {
          name,
          contributor: contributers,
          assest_type: assetType,
          format: format.join(','),
          size: size.join(','),
          style: style.join(','),
          series: series.join(','),
          files_type: filesType.join(','),
          word_tags: wordTags.join(','),
          content_description: contentDescription,
          folders: [folder.id],
          is_public: isPublic,
          color: color.join(','),
          tempId: arrayOfImages[0].id
        }
      if (dataValidity(data)) {
        if (isUploading.current) {
          errorToast('Please wait until the current upload is finished')
          return;
        }
        if (!editMode && !isUploading.current) {
          isUploading.current = true
          const preparedCatsRes = await prepareCatalogsAPI([data]);
          const preparedCats = preparedCatsRes?.data?.data;
          if (!preparedCats) {
              errorToast('An error occurred, we could not upload your content')
              isUploading.current = false
              return;
          }
          dispatch(uploadingInitiated())
          dispatch(addToUploadingQueue({
            id: data.tempId,
            name: data.name,
            type: data.assest_type,
            uploading: true,
            progress: 0
          }))
          dispatch(createCatalog({ ...data, id: preparedCats[0].id }))
          isUploading.current = false
          setShowMetaDataForm(false)
          successToast('Elements are being uploaded')
          cancel()
        } else {
          try {
            dispatch(updateCatalog(data))
            setShowMetaDataForm(false)
            successToast('Content updated successfully')
          } catch (error) {
            errorToast('Something went wrong')
          }
        }
      } else {
        errorToast('Please fill all the required fields')
      }
    } catch {
      errorToast('Something went wrong')
    }
  }

  const goNextPhase = () => {
    if (atLeastOneSelected && sampleImage) {
      setPhase(prev => prev + 1)
    }
  }

  return (
    <div className={style.wrapper}>
      <section className={editMode ? `${style.upperImgsSegment} ${style.inEditMode}` : style.upperImgsSegment}>
        <UpperSegment
          imgState={imgState}
          setImgState={setImgState}
          selectedN={atLeastOneSelected}
          phaseState={phaseState}
          contentState={contentState}
          setContentState={setContentState}
          editMode={editMode}
          sampleImage={sampleImage}
          atLeastOneSelected={atLeastOneSelected}
        />
      </section>
      <section className={editMode ? `${style.interactionArea} ${style.inEditMode}` : style.interactionArea}>
        {phaseState === 2
          ? (
            <InteractionArea
              contentState={contentState}
              setContentState={setContentState}
              onChangeData={onChangeData}
              phaseState={phaseState}
              folders={folders}
              errors={errors}
              setErrors={setErrors}
              currentUserRole={currentUserRole}
            />
          )
          : (
            <div className={style.noImgSelected}>
              <DropSample setSampleImage={setSampleImage} sampleImage={sampleImage} />
            </div>
          )}
      </section>
      <section className={style.uploadSection}>
        <div className={style.uploadBtn}>
          {
            !editMode && (
              <button
                type='button'
                onClick={() => phaseState === 1 ? backFn() : setPhase(1)}
                className={`${style.cancel}`}
                // disabled={uploading}
              >
                Back
              </button>
            )
          }
          <button
            type='submit'
            className={
              atLeastOneSelected
                ? `${style.submitBtn}`
                : `${style.submitBtn} ${style.inactive}`
            }
            onClick={() => phaseState === 1 ? goNextPhase() : submitData()}
          >
            {phaseState === 1 ? 'Next' : editMode ? 'Finish' : 'Submit'}
          </button>
        </div>
        <div className={style.uploadBackAndSkip}>
          <button
            type='button'
            onClick={() => cancel ? cancel() : setShowMetaDataForm(false)}
            className={`${style.cancel}`}
            // disabled={uploading}
          >
            Cancel
          </button>
        </div>
      </section>
    </div>
  )
}

MetaDataForm.propTypes = {
  arrayOfImages: PropTypes.array,
  setShowMetaDataForm: PropTypes.func,
  uploadContent: PropTypes.func,
  selectedType: PropTypes.string,
  editMode: PropTypes.bool,
  cancel: PropTypes.func,
  backFn: PropTypes.func
}

export default MetaDataForm
