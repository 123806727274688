import React from 'react';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NoCatalogsCollection = ({ collectionName, selectValue, onTextChange, textValue }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>You have no catalogs in {`"${collectionName}"` || 'here'}.</h2>
      <p className={styles.text}>You can create a new catalog or import an existing one.</p>
      <ul className={styles.instructions__container}>
        <li className={styles.instructions__item}>
          <p className={styles.instructions__text}>Navigate through mpublisher to find countless catalogs you might like.</p>
        </li>
        <li className={styles.instructions__item}>
          <p className={styles.instructions__text}>Find the ones you like and add them to this collection.</p>
        </li>
        <li className={styles.instructions__item}>
          <p className={styles.instructions__text}>Catalogs added will be found here and you’ll be able to share them with others.</p>
        </li>
      </ul>
      <Link to={'/mpublisher/catalogs'} className={styles.go_button}>Go to catalogs</Link>
    </div>
  );
}

NoCatalogsCollection.propTypes = {
  onTextChange: PropTypes.func,
  textValue: PropTypes.string,
  selectValue: PropTypes.string,
  onSelectChange: PropTypes.func,
  collectionName: PropTypes.string
}

export default NoCatalogsCollection;
