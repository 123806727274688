/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Popup from 'components/atoms/Popup/Popup';
import FolderManagement from './FolderManagements';
import ExcludedFolders from './ExcludedFolders';
import DeletedFolders from './DeletedFolders';
import styles from './styles.module.scss';

const arrayOfTabs = [
  {
    title: 'Folders',
    content: <FolderManagement />
  },
  {
    title: 'Suspended Folders',
    content: <ExcludedFolders />
  },
  {
    title: 'Deleted Folders',
    content: <DeletedFolders />
  }
];

const SettingsPopup = ({
  closePopup
}) => {
  const [activeTab, setActiveTab] = useState(arrayOfTabs[0]);
  return (
    <Popup
      bgColor={'#232323'}
      closePopup={closePopup}
      extraStyles={{
        borderRadius: '16px',
        width: '90%',
        height: '550px',
        maxWidth: '900px'
      }}>
      <div className={styles.settings_header}>
        <h1 className={styles.settings_title}>Profile Management</h1>
        <desc className={styles.settings_desc}>
          Manage your profile settings here
        </desc>
      </div>
      <div className={styles.settings_content_management}>
        <ul className={styles.settings_tabs}>{
          arrayOfTabs.map((tab, index) => (
            <li
              key={tab.title}
              className={`${styles.settings_tab} ${activeTab.title === tab.title ? styles.active : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.title}
            </li>
          ))
          }</ul>
        <div className={styles.settings_content}>
          {activeTab.content}
        </div>
      </div>

    </Popup>
  );
};

export default SettingsPopup;
