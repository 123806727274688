import React from 'react';
import styles from './styles.module.scss';
import Button from 'components/atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
export default function NotAvailable () {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.mainWrapper}>
                <div className={styles.content}>
                    <h3 className={styles.subtitle}>404 error</h3>
                    <p className={styles.desc}>The page you are looking for doesn&apos;t exist.</p>
                    <Button onClick={() => navigate('/mpublisher')} extraClass='gradientBtn'>Back Home</Button>
                </div>
            </div>
        </div>
    )
}
