import React from 'react';
import DustBin from '../icons/DustinBin';
import styles from './styles.module.scss';

export default function Header () {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <div className={styles.trashIcon}><DustBin color="orange" /></div>
        <div>
          <h3 className={styles.headerTitle}>Trash</h3>
          <p>
            View and Retrive all your deleted catalogs, templates and more.
          </p>
        </div>
      </div>
    </div>
  );
}
