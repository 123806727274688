/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { updateCatalog } from 'Redux/contentUpload/contentUploadSlice'
import { createCollectionsFromCatalogPopup, getAllCollections } from 'Redux/collection/collectionSlice'
import { errorToast, successToast } from 'toasts/Toasts'
import { ReactComponent as Plus } from '../../../../../../assets/icons/plus.svg'
import imgIcon from '../../../../../../assets/icons/imgicon.png'
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { createCollectionsAPI } from 'api/collections';
import { useTranslation } from 'react-i18next';

const index = ({ data, exitPopup }) => {
  const dispatch = useDispatch()
  const [userInputCreate, setUserInput] = useState('')
  const [userInputSearch, setUserInputSearch] = useState('')
  const [isPublic, setIsPublic] = useState(true)
  const [collectionState, setCollections] = useState([])
  const { collections } = useSelector((state) => state.collections);
  const { t } = useTranslation();
    
  const { userdata } = useSelector((state) => state.userdata);

  const collectionsCount = collections?.length + collectionState?.length || 0;
  const collectionLimit = userdata?.monthlyLimits?.collections || 0
  const canAddcollections = collectionsCount < collectionLimit

  const addnewTempCollection = () => {
    if (collectionState.some((item) => item.name === userInputCreate)) {
      errorToast(t('Collection already exists'))
      return null;
    }
    if (userInputCreate) {
      setCollections((prev) => [
        {
          id: Math.random(),
          name: userInputCreate,
          icon: '',
          selected: true
        },
        ...prev
      ])
      setUserInput('')
    }
  }

  const handleSelectingCollection = (item) => {
    setCollections((prev) => prev.map((collection) => {
      if (collection.id === item.id) {
        return {
          ...collection,
          selected: true
        }
      }
      return collection
    }))
  }

  const createNewCollections = async (collections) => {
    const response = await createCollectionsAPI(collections);
    const newCollections = response.data.data.map(el => el.id || el._id)
    dispatch(getAllCollections())
    return newCollections;
  };

  const handleSubmittingData = () => {
    try {
      const selectedCollections = collectionState.filter((item) => item.selected);
      const oldCollections = [];
      const newCollections = [];
      selectedCollections.forEach((item) => {
        collections.some((oldc) => item.id === oldc.id)
          ? oldCollections.push(item.id)
          : newCollections.push({
            name: item.name,
            is_public: isPublic
          })
      })
      createNewCollections(newCollections).then((newData) => {
        dispatch(createCollectionsFromCatalogPopup({
          collections: [...oldCollections, ...newData],
          catalogData: { ...data, tempId: data.id },
          callback: () => {
            successToast(t('Successfully added to collection'))
            exitPopup()
          }
        }))
      })
    } catch {
      errorToast(t('Something went wrong'))
    }
  }

  useEffect(() => {
    if (collections.length) {
      setCollections(collections.map((item) => (
        {
          ...item,
          selected: data.collections?.some((catalogcoll) => catalogcoll?.includes(item.id))
        }
      )))
    }
  }, [collections])

  const deselectCollection = (e, item) => {
    e.stopPropagation()
    setCollections((prev) => prev.map((collection) => {
      if (collection.id === item.id) {
        return {
          ...collection,
          selected: false
        }
      }
      return collection
    }))
  }

  return (
    <>
      <button
        className={styles.closeBtn}
        onClick={() => exitPopup()}>
        <CloseIcon />
      </button>
      <div className={styles.collectionContainer}>
        <div className={styles.leftContainerOfImage}>
          <h4 className={styles.headerTitle}>{t('Save to collection')}</h4>
          <div className={styles.collectionImageContainer}>
            <img src={data?.template_data?.templates[0]?.thumbnail || ''} alt={data.word_tags} />
          </div>
        </div>
        <div className={styles.rightContainerOfData}>
          <div className={styles.createCollectionContainer}>
            <span>{t('Create a new collection')}</span>
            <div className={styles.createCollectionInputContainer}>
              <input disabled={!canAddcollections} type="text" className={styles.input} aria-hidden onKeyDown={(e) => e.key === 'Enter' && addnewTempCollection()} value={userInputCreate} placeholder={canAddcollections ? t('Collection name') : t("you've reached the limit")} onChange={e => setUserInput(e.target.value)} />
              <button disabled={!canAddcollections} onClick={addnewTempCollection}>
                <Plus />
              </button>
            </div>
          </div>
          <div className={styles.isPublicSelector}>
            <span className={styles.headerSpan}>{t('Select')}</span>
            <div className={styles.isPublicSelector_radioContainer} onClick={() => setIsPublic(true)}>
              <div id='public-radio' className={isPublic ? `${styles.customRadio} ${styles.active}` : styles.customRadio}>
                {
                  isPublic && <div className={styles.customRadio_inner}>✓</div>
                }
              </div>
              <label htmlFor='public-radio'>{t('Public')}</label>
            </div>
            <div className={styles.isPublicSelector_radioContainer} onClick={() => setIsPublic(false)}>
              <div id='private-radio' className={!isPublic ? `${styles.customRadio} ${styles.active}` : styles.customRadio}>
                {
                  !isPublic && <div className={styles.customRadio_inner}>✓</div>
                }
              </div>
              <label htmlFor='private-radio'>{t('Private')}</label>
            </div>
          </div>
          <div className={styles.allCollectionShowcaseContainer}>
            <div className={styles.searchInputContainer}>
              <input type="text" className={styles.input} value={userInputSearch} placeholder={t('Collection name')} onChange={e => setUserInputSearch(e.target.value)} />
            </div>
            <div className={styles.showcaseContainer}>
              <ul className={styles.allCollectionShowcaseContainer_list}>
                {
                  collectionState.length
                    ? collectionState.filter(item => item.name.toLowerCase().includes(userInputSearch.toLowerCase())).map((item) => (
                      <li key={item.id} className={item.selected ? `${styles.allCollectionShowcaseContainer_list_item} ${styles.active}` : styles.allCollectionShowcaseContainer_list_item} onClick={() => handleSelectingCollection(item)}>
                        <div className={styles.collection_dataInfo}>
                          {/* <img src={item.icon || imgIcon} alt='none' /> */}
                          <p>{item.name}</p>
                        </div>
                        {
                          item.selected &&
                          <div className={styles.btnInteractionContainer}>
                            <button className={styles.deselectBtn} onClick={(e) => deselectCollection(e, item)}>x</button>
                          </div>
                        }
                      </li>
                    ))
                    : (
                      <div className={styles.noCollectionContainer}>{t('You have no collections')}</div>
                    )
                }
              </ul>
            </div>
          </div>
          <div className={styles.saveButtonContainer}>
            <button className={styles.saveButton} onClick={handleSubmittingData}>{t('Save')}</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default index
