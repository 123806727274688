/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { updateCatalog } from 'Redux/contentUpload/contentUploadSlice'
import { createCollectionsFromFolderPopup, createCollections } from 'Redux/collection/collectionSlice'
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import imgIcon from 'assets/icons/imgicon.png';
import { errorToast, successToast } from 'toasts/Toasts';
import { useTranslation } from 'react-i18next';

const index = ({ folderData, updateFolderData, exitPopup, createOnly }) => {
  const dispatch = useDispatch()
  const { collections } = useSelector((state) => state.collections)
  const { userdata } = useSelector((state) => state.userdata);
  const { t } = useTranslation();

  const [userInputCreate, setUserInput] = useState('')
  const [userInputSearch, setUserInputSearch] = useState('')
  const [isPublic, setIsPublic] = useState(true)
  const [collectionState, setCollections] = useState([])
  const [interactedWithCollections, setInteractedWithCollections] = useState([])

  const collectionsCount = collections?.length + collectionState?.length || 0;
  const collectionLimit = userdata?.monthlyLimits?.collections || 0
  const canAddcollections = collectionsCount < collectionLimit

  useEffect(() => {
    if (collections.length) {
      !createOnly && setCollections(collections?.filter((item) => !item?.folders?.includes(folderData?.id)) || [])
    }
  }, [collections])

  const addnewTempCollection = () => {
    if ([...collectionState, ...collections].some((item) => item.name === userInputCreate)) {
      errorToast('Collection already exists')
      return null;
    }
    if (userInputCreate) {
      const newData = {
        id: Math.floor(Math.random() * 10000),
        name: userInputCreate,
        icon: '',
        selected: true,
        is_public: isPublic,
        new: true
      }
      setCollections((prev) => [
        newData,
        ...prev
      ])
      setInteractedWithCollections((prev) => [...prev, newData.id])
      setUserInput('')
    }
  }

  const handleSelectingCollection = (item) => {
    setInteractedWithCollections((prev) => prev.includes(item.id) ? prev : [...prev, item.id])
    setCollections((prev) => prev.map((collection) => {
      if (collection.id === item.id) {
        return {
          ...collection,
          selected: true
        }
      }
      return collection
    }))
  }

  const handleSubmittingData = () => {
    try {
      const selectedCollections = collectionState.filter((item) => interactedWithCollections.includes(item.id));
      const oldCollections = [];
      const newCollections = [];
      selectedCollections.forEach((item) => {
        !item.new
          ? oldCollections.push(item)
          : newCollections.push(item)
      })
      const data = {
        newCollections: newCollections.filter((item) => item.selected).map(element => {
          return {
            ...element,
            folders: [folderData?.id]
          }
        }),
        oldCollections: oldCollections.map((item) => {
          if (item.selected) {
            return {
              ...item,
              folders: item.folders.includes(folderData?.id)
                ? item.folders
                : [...item.folders, folderData?.id]
            }
          } else {
            return {
              ...item,
              folders: item.folders.filter((folder) => folder !== folderData?.id)
            }
          }
        })
      }
      dispatch(createCollectionsFromFolderPopup(data))
      updateFolderData && updateFolderData()
      exitPopup()
    } catch {
      errorToast(t('Something went wrong'))
    }
  }

  const deselectCollection = (e, item) => {
    e.stopPropagation()
    setInteractedWithCollections((prev) => prev.includes(item.id) ? prev : [...prev, item.id])
    setCollections((prev) => prev.map((collection) => {
      if (collection.id === item.id) {
        return {
          ...collection,
          selected: false
        }
      }
      return collection
    }))
  }

  const createNewCollections = async () => {
    try {
        dispatch(createCollections(collectionState.map(item => {
          return {
            name: item.name,
            icon: item.icon,
            is_public: item.is_public,
            folders: []
          }
        })))
        exitPopup()
    } catch {
      errorToast(t('Something went wrong'))
    }
  }

  const actuallyDeleteCollection = (item) => {
    setCollections((prev) => prev.filter((collection) => collection.id !== item.id))
  }

  return (
    <div className={styles.collectionContainer}>
      <div className={styles.banner}>
        <h2>{t('Create New Collection')}</h2>
      </div>
      <div className={styles.collectionContent}>
        <div className={styles.createCollectionContainer}>
          <div className={styles.createCollectionInputContainer}>
            <input disabled={!canAddcollections} type="text" className={styles.input} aria-hidden onKeyDown={(e) => e.key === 'Enter' && addnewTempCollection()} value={userInputCreate} placeholder={canAddcollections ? t('Collection name') : t("you've reached the limit")} onChange={e => setUserInput(e.target.value)} />
            <button disabled={!canAddcollections} onClick={addnewTempCollection}>
              <Plus />
            </button>
          </div>
        </div>
        <div className={styles.allCollectionShowcaseContainer}>
          {
            !createOnly && (
              <div className={styles.searchInputContainer}>
                <input type="text" className={styles.input} value={userInputSearch} placeholder={t('Search collections')} onChange={e => setUserInputSearch(e.target.value)} />
                <button className={styles.searchButton}>
                </button>
              </div>)
          }
          <div className={styles.showcaseContainer}>
            <ul className={styles.allCollectionShowcaseContainer_list}>
              {
                collectionState.length
                  ? collectionState.filter(item => item.name.toLowerCase().includes(userInputSearch.toLowerCase())).map((item) => (
                    <li key={item.id} className={item.selected ? `${styles.allCollectionShowcaseContainer_list_item} ${styles.active}` : styles.allCollectionShowcaseContainer_list_item} onClick={() => !item.selected && handleSelectingCollection(item)}>
                      <div className={styles.collection_dataInfo}>
                        {/* <img src={item.icon || imgIcon} alt='none' /> */}
                        <p>{item.name}</p>
                      </div>
                      {
                        item.selected &&
                        <div className={styles.btnInteractionContainer}>
                          <button className={styles.deselectBtn} onClick={(e) => !createOnly ? deselectCollection(e, item) : actuallyDeleteCollection(item)}>x</button>
                        </div>
                      }
                    </li>
                  ))
                  : (
                    <div className={styles.noCollectionContainer}>{!createOnly ? t('You have no collections') : t('You have not added any new collection')}</div>
                  )
              }
            </ul>
          </div>
        </div>
        <div className={styles.saveButtonContainer}>
          <button className={styles.saveButton}
            onClick={!createOnly ? handleSubmittingData : createNewCollections}
          >{t('Save')}</button>
        </div>
      </div>
    </div>
  )
}

export default index
