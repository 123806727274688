import React from 'react'
import PropTypes from 'prop-types'
import styles from './RoundedButton.module.scss'

const RoundedButton = ({ children, onClick, extraClass, disabled, disableMsg }) => {
  return (
    <button className={`${extraClass} ${styles.button}`} onClick={onClick} disabled={disabled}>
      {children}
      {disabled && disableMsg && <span className={styles.disableMsg}>{disableMsg}</span>}
    </button>
  )
}

RoundedButton.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool,
  disableMsg: PropTypes.string
}

export default RoundedButton
