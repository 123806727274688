/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from 'react'
import styles from './styles.module.scss'
import { Filter } from '../../organisms/Filter'
import '@master-wizr/wizr-filter/dist/index.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { filtersData } from '../../../constants/filtersData';
import { useDispatch, useSelector } from 'react-redux'
import { getFeaturedCatalogs } from 'Redux/content/contentSlice';
import SingleAssetPM from '../myImageCatalogs/components/PopupManger'
import MultiAssetPM from '../myTemplatesCatalogs/components/PopupManger'
import { filtersData as templatesFD } from '../../../constants/templatesFilterData';
import { getLink } from 'api/contentUploadAPI'
import { toast } from 'react-toastify'
import DataCardShowSlide from 'components/molecules/DataCardShowSlide'
import NotFound from '../NotFound/NotFound'
import ComponentLoaderTri from 'components/molecules/ComponentLoaderTri/ComponentLoaderTri';
import { useTranslation } from 'react-i18next';
import { featureCatalog } from 'Redux/contentUpload/contentUploadSlice'

const CatalogMainView = () => {
  const isTouchScreen = () => {
    return ('ontouchstart' in document.documentElement);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const beCatalogs = useSelector(state => state.content.catalog);
  const { loading } = useSelector(state => state.content);
  const user = useSelector((state) => state.auth?.user);
  const isPrivate = location.pathname.includes('my-catalogs');
  const profile = useSelector((state) => state.profile);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const userWithProfile = {};
    userWithProfile.user = user;
    userWithProfile.profile = profile;
    setCurrentUser(userWithProfile);
  }, [])

  useLayoutEffect(() => {
    dispatch(getFeaturedCatalogs({ isPrivate }));
  }, [location])

  const [imageCatalogs, setImageCatalogs] = useState([]);
  const [templateCatalogs, setTemplateCatalogs] = useState([]);
  const [videoCatalogs, setVideoCatalogs] = useState([]);
  const [filterSelection, setFilterSelection] = useState({});
  const [viewSize, setViewSize] = useState(200);
  const [multiPreviewPopup, setMultiPreviewPopup] = useState(null);
  const [singlePeviewPopup, setSinglePreviewPopup] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setViewSize((20 * window.innerHeight) / 60);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const onChangeData = (changes) => {
    const key = changes.category
    const value = changes.value?.toLowerCase()
    if (changes.category === 'searchText') {
      setFilterSelection({ ...filterSelection, name: [changes.value] })
    } else if (changes.category === 'assest_type') {
      setFilterSelection({ name: [filterSelection.name], assest_type: [changes.value] })
    } else if (!filterSelection[key]) {
      const newData = {}
      newData[key] = [value]
      setFilterSelection({ ...filterSelection, ...newData })
    } else {
      if (changes.selected) {
        const newArr = [...filterSelection[key], value]
        setFilterSelection({ ...filterSelection, [key]: newArr })
      } else {
        const newArr = filterSelection[key].filter((item) => item !== value)
        newArr.length > 0 ? setFilterSelection({ ...filterSelection, [key]: newArr }) : setFilterSelection((prev) => { const newState = { ...prev }; delete newState[key]; return newState })
      }
    }
  }

  useEffect(() => {
    if (loading) {
      setErrorMsg('')
      setImageCatalogs([])
      setTemplateCatalogs([])
      setVideoCatalogs([])
    }
    if (beCatalogs) {
      if (beCatalogs?.templates?.length > 0) {
        const templateArray = beCatalogs.templates.map((template) => {
          return {
            ...template,
            id: template._id || template.id,
            src: template?.template_data?.templates[0],
            width: template?.template_data?.templates[0]?.width || 1920,
            height: template?.template_data?.templates[0]?.height || 1080
          }
        })?.filter(el => el?.is_featured)
        setTemplateCatalogs(templateArray);
      }
      if (beCatalogs?.images?.length > 0) {
        const imagesArr = beCatalogs.images.map((image) => {
          return {
            ...image,
            id: image._id || image.id,
            src: image.assest_urls[0],
            width: image.image_size?.width || 16,
            height: image.image_size?.height || 9
          }
        })?.filter(el => el?.is_featured)
        setImageCatalogs(imagesArr);
      }
      if (beCatalogs?.videos?.length > 0) {
        const imagesArr = beCatalogs.videos.map((catalog) => {
          return {
            ...catalog,
            src: catalog.video_urls,
            width: catalog?.vid_metadata?.width || 16,
            height: catalog?.vid_metadata?.height || 9
          }
        })?.filter(el => el?.is_featured)
        setVideoCatalogs(imagesArr);
      }
      if (beCatalogs?.templates && beCatalogs?.images && beCatalogs?.videos &&
        Array.isArray(beCatalogs?.templates) && Array.isArray(beCatalogs?.images) && Array.isArray(beCatalogs?.videos) &&
        beCatalogs?.templates?.length === 0 && beCatalogs?.images?.length === 0 && beCatalogs?.videos?.length === 0) {
        setErrorMsg('No featured catalogs found')
      }
    }
  }, [beCatalogs, loading, location])

  const navigateToSearchPage = () => {
    const link = `/mpublisher/search/result&${Object.keys(filterSelection).map((key) => `${key}=${filterSelection[key].join(',').toLowerCase()}`).join('&')}${!isPrivate ? '&use_global=true' : '&use_global=false'}`
    navigate(link, { state: { typeOfSearch: !isPrivate ? '&use_global=true' : '&use_global=false' } })
  }

  const handleClickMulti = (state, catalog) => {
    if (state === 9) {
      const payload = {
        is_featured: !catalog.is_featured
      }
      dispatch(featureCatalog({ payload, catalog }))
      return;
    }
    if (state === 6) {
      navigate(`Templates/${catalog.folders[0]}/${catalog.id}`, { state: { catalog } })
    } else {
      if (state === 7) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setMultiPreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const handleClickSingle = (state, catalog) => {
    if (state === 9) {
      const payload = {
        is_featured: !catalog.is_featured
      }
      dispatch(featureCatalog({ payload, catalog }))
      return;
    }
    if (state === 6) {
      navigate(`Templates/${catalog.folders[0]}/${catalog.id}`, { state: { catalog } })
    } else {
      if (state === 1) {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      } else {
        setSinglePreviewPopup({
          state,
          data: catalog
        })
      }
    }
  }

  const getMenu = () => {
    const currentAsset = filterSelection?.assest_type ? filterSelection.assest_type[0] : null;
    if (currentAsset?.includes('images') || currentAsset?.includes('Images')) {
      return filtersData;
    } else if (currentAsset === 'templates' || currentAsset?.includes('Templates')) {
      return templatesFD;
    } else {
      return [...filtersData.filter(el => el.dbTitle !== 'color' && el.dbTitle !== 'style'), ...templatesFD]; // like this for now
    }
  }

  const downloadTemplate = async (id) => {
    getLink(id).then((res) => {
      if (res) {
        const link = document.createElement('a');
        link.href = res;
        link.click();
      } else {
        toast.error('Something went wrong, please try again later');
      }
    })
  }

  const usedShowCaseCatalogs = singlePeviewPopup?.data?.assest_type === 'Images' ? imageCatalogs : singlePeviewPopup?.data?.assest_type === 'Videos' ? videoCatalogs : [];

  return (
    <div className={styles.wrappingElement}>
      <SingleAssetPM previewPopup={singlePeviewPopup} setPreviewPopup={setSinglePreviewPopup} allCatalogs={usedShowCaseCatalogs.slice(0, 8)} />
      <MultiAssetPM previewPopup={multiPreviewPopup} setPreviewPopup={setMultiPreviewPopup} dlAll={downloadTemplate} />
      <div className={styles.searchBarContainer}>
        <div className={styles.searchBar}>
          <Filter data={getMenu()} withAssetBtn={true} onChangeData={onChangeData} onSearch={navigateToSearchPage} currentState={filterSelection} camelizedValues={true} type="wizrx" />
        </div>
        <h1>{t('Inspire Your Designs')} </h1>
      </div>
      <ul className={styles.linksContainer}>
        <li className={styles.linkItem}>
          <Link to="Templates" className={styles.link}>
            {t('Templates')}
          </Link>
          <Link to="Images" className={styles.link}>
            {t('Images')}
          </Link>
          <Link to="Videos" className={styles.link}>
            {t('Videos')}
          </Link>
          <Link to="Mockups" className={styles.link}>
            {t('Mockups')}
          </Link>
          {
            !isPrivate &&
            <Link to="Premium" className={styles.link}>
              {t('Premium')}
            </Link>
          }
        </li>
      </ul>
      {
        !errorMsg && !loading
          ? (
            <div className={styles.contentArea}>
              {templateCatalogs.length > 0 &&
                <DataCardShowSlide
                  catalogs={templateCatalogs.slice(0, 8) || []}
                  currentUser={currentUser}
                  handleClick={handleClickMulti}
                  header={'Templates'}
                  linkForMore={'Templates'}
                  directOtherProps={
                    {
                      templates: true,
                      reverseThumbnail: true
                    }
                  }
                  isTouchScreen={isTouchScreen()}
                />
              }
              {imageCatalogs.length > 0 &&
                <DataCardShowSlide
                  catalogs={imageCatalogs.slice(0, 8) || []}
                  handleClick={handleClickSingle}
                  currentUser={currentUser}
                  header={'Images'}
                  linkForMore={'Images'}
                  isTouchScreen={isTouchScreen()}
                />
              }
              {videoCatalogs.length > 0 &&
                <DataCardShowSlide
                  catalogs={videoCatalogs.slice(0, 8) || []}
                  handleClick={handleClickSingle}
                  currentUser={currentUser}
                  header={'Videos'}
                  linkForMore={'Videos'}
                  isTouchScreen={isTouchScreen()}
                />
              }
            </div>
          )
          : !loading
            ? (
              <div className={styles.noCatalogs}>
                <NotFound errMsg={errorMsg} />
              </div>
            )
            : loading && (
              <ComponentLoaderTri />
            )
      }

    </div>
  )
}

export default CatalogMainView
