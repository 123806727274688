/* eslint-disable react/prop-types */
import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as LeftArr } from '../assets/icon-arrow-left.svg';
import { ReactComponent as RightArr } from '../assets/icon-arrow-right.svg';
// import { ReactComponent as ToggleFS } from '../assets/icon-full-screen.svg';

export default function VideoLoader ({
  setPreviewLoadingNext,
  previewLoadingNext,
  currentCatalog,
  tags,
  previewIndex,
  setPreviewIndex,
  allCatalogs,
  setPreviewPopup,
  changeToFullScreen
}) {
  const { vid_metadata: { width, height } } = currentCatalog || {
    vid_metadata: {
      width: 0,
      height: 0
    }
  };
  
  const isPortrait = height > width;
  const aspectRatio = width / height;
  let usedWidth = 0;
  let usedHeight = 0;
  if (isPortrait) {
    usedHeight = 0.65 * window.innerHeight;
    usedWidth = usedHeight * aspectRatio;
  } else {
    usedWidth = 0.65 * window.innerWidth;
    usedHeight = usedWidth / aspectRatio;
  }
  return (
    <>
      <div
        className={styles.miniPreviewContainer}
        style={{
          height: (usedHeight + 'px') || '1000px',
          width: (usedWidth + 'px') || '1000px'
        }}
      >
        <div className={previewLoadingNext ? `${styles.popupPreviewImgContainer} ${styles.loadingImgActiveBg}` : styles.popupPreviewImgContainer}
          style={{
            height: (usedHeight + 'px') || '1000px',
            width: (usedWidth + 'px') || '1000px'
          }}
        >
          <video
            className={styles.videoPreview}
            src={currentCatalog?.src?.watermarked}
            poster={currentCatalog?.src?.thumbnail}
            muted
            autoPlay
            controls={true}
            style={{
              height: (usedHeight + 'px') || '1000px',
              width: (usedWidth + 'px') || '1000px'
              // background: `url(${currentCatalog?.src?.thumbnail})`,
            }}
            alt="preview" />
          <div className={styles.popupPreviewImgContainer__controls}>
            {/* <button
              className={styles.fullscreenBtn}
              onClick={() => { changeToFullScreen(true); setPreviewPopup(prev => { return { ...prev, state: 5 } }) }}>
              <ToggleFS />
            </button> */}
            <button
              className={styles.fullscreenBtn}
              onClick={() => setPreviewPopup(null)}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.popupPreviewImgContainer__info}>
            <h3>{currentCatalog?.name}</h3>
          </div>
          <div className={`${styles.tagsContainer} ${styles.videos}`}>
            <h2 className={styles.tagSpan}>Tags: </h2>
            {tags?.map((tag, index) => (
              <p className={styles.tag} key={index}>#{tag.trim()}</p>
            ))}
          </div>
          <div className={styles.popupPreviewImgContainer__footer}>
            <p>{currentCatalog?.content_description}</p>
          </div>
        </div>
      </div>
      <div className={styles.popupPreviewImgContainer__navigatorLeft}>
        <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex - 1) }} disabled={previewIndex === 0}>
          <LeftArr />
        </button>
      </div>
      <div className={styles.popupPreviewImgContainer__navigatorRight}>
        <button onClick={() => { setPreviewLoadingNext(true); setPreviewIndex(previewIndex + 1) }} disabled={previewIndex === allCatalogs.length - 1}>
          <RightArr />
        </button>
      </div>
    </>
  )
}
